import React, { useEffect, useState } from "react";
import { cardsApi } from "../apiCalls";
import "../css/style.css";
import {
  EditOutlined,
  FileAddOutlined,
  HeartOutlined,
  RollbackOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
const { Text } = Typography;
const { Meta } = Card;

const CardIndividual = () => {
  const { t } = useTranslation();
  const [cardSelected, setCardSelected] = useState();
  const { cardNumber } = useParams();

  const columns = [
    {
      title: t("NAME"),
      dataIndex: "name",
      align: "center",
      render: (text) => (
        <div >
          <strong>{text}</strong>
        </div>
      ),
    },
    {
      title: t(cardSelected?.name),
      dataIndex: "card1",
    },
  ];
  const data = [
    {
      key: "1",
      name: t("VERSION_NAME"),
      card1: t(cardSelected?.versionName),
    },
    {
      key: "2",
      name: t("TYPE"),
      card1: t(cardSelected?.type),
    },
    {
      key: "3",
      name: t("CLASSIFICATIONS"),
      card1: (
        <>
          {cardSelected?.Classifications.map((item) => {
            return <p>{"- " + t(item.type)}</p>;
          })}
        </>
      ),
    },
    {
      key: "4",
      name: t("INK_COST"),
      card1: t(cardSelected?.inkCost),
    },
    {
      key: "5",
      name: t("INKWELL"),
      card1: <>{cardSelected?.inkwell ? t("YES") : t("NOT")}</>,
    },
    {
      key: "6",
      name: t("INK"),
      card1: (
        <>
          {cardSelected?.Inks.map((item) => {
            return (
              <>
                {t(item?.ink) + " "}
                {/* <img src={item.inkImage} width={20} /> */}
              </>
            );
          })}
        </>
      ),
    },
    {
      key: "7",
      name: t("KEYWORDS"),
      card1: (
        <>
          {cardSelected?.Keywords.map((item) => {
            return (
              <p>
                {"- "} <Text strong>{t(item.keyword)}</Text>
                {": "}
                {item.keywordText === null ? (
                  <>
                    {cardSelected?.keywordTextX?.split(", ").map((valor) => {
                      return <>{valor.includes(item.keyword) && t(valor)}</>;
                    })}
                  </>
                ) : (
                  t(item.keywordText)
                )}
              </p>
            );
          })}
        </>
      ),
    },
    {
      key: "8",
      name: t("ABILITY"),
      card1: cardSelected?.ability && (
        <Trans
          i18nKey={cardSelected?.ability}
          components={{ 1: <strong />, 2: <br /> }}
        />
      ),
    },
    {
      key: "9",
      name: t("BODY_TEXT"),
      card1: t(cardSelected?.bodyText),
    },
    {
      key: "10",
      name: t("FLAVOR_TEXT"),
      card1: t(cardSelected?.flavorText),
    },
    {
      key: "11",
      name: t("STRENGTH"),
      card1: t(cardSelected?.strength),
    },
    {
      key: "12",
      name: t("WILLPOWER"),
      card1: t(cardSelected?.willpower),
    },
    {
      key: "13",
      name: t("LORE"),
      card1: t(cardSelected?.loreValue),
    },
    {
      key: "14",
      name: t("MOVE_COST"),
      card1: t(cardSelected?.moveCost),
    },
    {
      key: "15",
      name: t("RARITY"),
      card1: t(cardSelected?.rarity),
    },
    {
      key: "16",
      name: t("ARTIST"),
      card1: cardSelected?.artist,
    },
    {
      key: "17",
      name: t("CARD_NUMBER"),
      card1: <>{cardSelected?.cardNumber?.split("_")[1]}</>,
    },
    {
      key: "18",
      name: t("SET"),
      card1: t(cardSelected?.set),
    },
    {
      key: "19",
      name: t("RELEASE_DATE"),
      card1: t(cardSelected?.releaseDate),
    },
    {
      key: "20",
      name: t("FRANCHISE"),
      card1: t(cardSelected?.franchise),
    },
    {
      key: "21",
      name: t("OTHER_EDITIONS"),
      card1: (
        <>
          {cardSelected?.otherEditions?.map((item) => {
            return (
              <Link to={`/card-detail/${item.cardNumber}`}>
                <Tooltip title={t("GO")}>
                  <p>
                    {"-"}
                    {item?.edition?.map((valor) => {
                      return <>{t(valor) + " "}</>;
                    })}
                    <SelectOutlined />
                  </p>
                </Tooltip>
              </Link>
            );
          })}
        </>
      ),
    },
    {
      key: "22",
      name: t("PROMO_INFO"),
      card1: t(cardSelected?.promoInfo),
    },
    {
      key: "23",
      name: t("OVERSIZED"),
      card1: <>{cardSelected?.oversized ? t("YES") : t("NOT")}</>,
    },
    {
      key: "24",
      name: t("FOIL"),
      card1: <>{cardSelected?.foil ? t("YES") : t("NOT")}</>,
    },
    {
      key: "25",
      name: t("IN_PRODUCT"),
      card1: (
        <>
          {cardSelected?.Products.map((item) => {
            return (
              <p>
                {"- " +
                  t(item.product) +
                  t("AMOUNT") +
                  item.cardProduct.quantity}
              </p>
            );
          })}
        </>
      ),
    },
  ];

  const getOneCard = async (cardNumber) => {
    const cardsByNumber = await cardsApi.getCardNumber(cardNumber);
    let cards = cardsByNumber;
    cards = cards[0];
    if (cards && cards.name) {
      setCardSelected(cards);
    }
  };

  useEffect(() => {
    getOneCard(cardNumber);
  }, [cardNumber]);

  return (
    <Content className="container" >
      <Text className="titles">{t("CARD_DETAILS")}</Text>
      {cardSelected && cardSelected.name && (
        <Row justify="start" align="middle">
          <Col span={24}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Card
                title={
                  <div style={{ whiteSpace: "normal" }}>
                    {t(cardSelected?.name) +
                      " - " +
                      t(cardSelected?.versionName)}
                  </div>
                }
                extra={
                  <Button type="text" size="small" href={`/cards`}>
                    {t("BACK")}
                  </Button>
                }
                style={{
                  minWidth: "50vw",
                  margin: "10px",
                }}
                actions={[
                  <Tooltip title={t("ADD_TO_COLLECTION")}>
                    <FileAddOutlined key="add" />
                  </Tooltip>,
                  <Tooltip title={t("ADD_TO_WISHLIST:")}>
                    <HeartOutlined key="wishlist" />
                  </Tooltip>,
                  <Tooltip title={t("ADD_TO_DECK")}>
                    <EditOutlined key="deck" />
                  </Tooltip>,
                  <Link to={`/cards`}>
                    <Tooltip title={t("BACK")}>
                      <RollbackOutlined key="back" />
                    </Tooltip>
                  </Link>,
                  ,
                ]}
              >
                <Meta
                  description={
                    <>
                      <Row justify="center" align="middle">
                        <Col span={24}>
                          <div
                            style={{
                              margin: "15px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Table
                              columns={columns}
                              dataSource={data}
                              bordered
                              size="small"
                              title={() => (
                                <Row justify="center" align="middle">
                                  <Col span={24}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Image
                                        style={{ maxWidth: "20vw" }}
                                        src={cardSelected?.imageUrls?.medium}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              pagination={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  }
                />
              </Card>
            </div>
          </Col>
        </Row>
      )}
      <br />
    </Content>
  );
};
export default CardIndividual;
