import React from "react";
import { cardsApi } from "../apiCalls";
import { useContext, useEffect, useState } from "react";
import { SelectedContext } from "../context/SelectedContext";
import { Card, Col, Collapse, Input, Row, Select, Slider, Typography } from "antd";
import TextInfo from "./TextInfo";
import { useTranslation } from "react-i18next";
import "../css/style.css";
const { Search } = Input;
const { Text } = Typography;

const FiltersCardsView = () => {
  const { t } = useTranslation();
  const [selectedSet, setSelectedSet] = useState();
  const [selectedInk, setSelectedInk] = useState();
  const [selectedType, setSelectedType] = useState();
  const [selectedKeywords, setSelectedKeywords] = useState();
  const [selectedClassifications, setSelectedClassifications] = useState();
  const [selectedRarity, setSelectedRarity] = useState();
  const [selectedInkwell, setSelectedInkwell] = useState();
  const [selectedFranchise, setSelectedFranchise] = useState();
  const [selectedArtist, setSelectedArtist] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedStrengthMin, setSelectedStrengthMin] = useState();
  const [selectedStrengthMax, setSelectedStrengthMax] = useState();
  const [selectedWillpowerMin, setSelectedWillpowerMin] = useState();
  const [selectedWillpowerMax, setSelectedWillpowerMax] = useState();
  const [selectedInkCostMin, setSelectedInkCostMin] = useState();
  const [selectedInkCostMax, setSelectedInkCostMax] = useState();
  const [selectedLoreValueMin, setSelectedLoreValueMin] = useState();
  const [selectedLoreValueMax, setSelectedLoreValueMax] = useState();
  const [selectedMoveCostMin, setSelectedMoveCostMin] = useState();
  const [selectedMoveCostMax, setSelectedMoveCostMax] = useState();
  const [selectedSearchName, setSelectedSearchName] = useState();
  const [catalogos, setCatalogos] = useState({});
  const assignSelected = useContext(SelectedContext);

  const resetCards = () => {
    assignSelected.setPage(1);
    assignSelected.setAllCards([]);
  };

  const getFilteredCards = async (
    cardNumber,
    name,
    set,
    ink,
    types,
    keyword,
    classification,
    rarity,
    inkwell,
    franchise,
    artist,
    product,
    strengthMin,
    strengthMax,
    willpowerMin,
    willpowerMax,
    inkCostMin,
    inkCostMax,
    loreValueMin,
    loreValueMax,
    moveCostMin,
    moveCostMax,
    page
  ) => {
    assignSelected.setLoadingCards(true);
    const cardsAndCount = await cardsApi.getCards(
      cardNumber,
      name,
      set,
      ink,
      types,
      keyword,
      classification,
      rarity,
      inkwell,
      franchise,
      artist,
      product,
      strengthMin,
      strengthMax,
      willpowerMin,
      willpowerMax,
      inkCostMin,
      inkCostMax,
      loreValueMin,
      loreValueMax,
      moveCostMin,
      moveCostMax,
      page
    );
    const cards = cardsAndCount.cards;
    assignSelected.setCardsCount(cardsAndCount.cardsCount);

    if (cards && cards.length > 0) {
      if (assignSelected.page > 1) {
        const concatedArrays = assignSelected.allCards.concat(cards);
        assignSelected.setAllCards(concatedArrays);
      } else {
        assignSelected.setAllCards(cards);
      }
      assignSelected.setLoadingCards(false);
      assignSelected.setLoadingButton(false);
    } else {
      assignSelected.setAllCards([]);
      assignSelected.setLoadingCards(false);
      assignSelected.setLoadingButton(false);
    }
  };

  const handleChangeSet = async (value) => {
    setSelectedSet(value);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      value,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeInk = async (value) => {
    setSelectedInk(value);
    assignSelected.setPage(1);
    assignSelected.setAllCards([]);
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      value,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeType = async (value) => {
    if (value !== "CHARACTER") {
      setSelectedStrengthMin(undefined);
      setSelectedStrengthMax(undefined);
      setSelectedWillpowerMin(undefined);
      setSelectedWillpowerMax(undefined);
      setSelectedLoreValueMin(undefined);
      setSelectedLoreValueMax(undefined);
    }
    setSelectedType(value);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      value,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      undefined,
      undefined,
      undefined,
      undefined,
      selectedInkCostMin,
      selectedInkCostMax,
      undefined,
      undefined,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeKeyword = async (value) => {
    setSelectedKeywords(value);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      value,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeClassification = async (value) => {
    setSelectedClassifications(value);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      value,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeRarity = async (value) => {
    setSelectedRarity(value);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      value,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeInkwell = async (value) => {
    setSelectedInkwell(value);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      value,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeFranchise = async (value) => {
    setSelectedFranchise(value);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      value,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeArtist = async (value) => {
    setSelectedArtist(value);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      value,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeProduct = async (value) => {
    setSelectedProduct(value);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      value,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeStrength = async (value) => {
    setSelectedStrengthMin(value[0]);
    setSelectedStrengthMax(value[1]);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      value[0],
      value[1],
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeWillpower = async (value) => {
    setSelectedWillpowerMin(value[0]);
    setSelectedWillpowerMax(value[1]);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      value[0],
      value[1],
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeInkCost = async (value) => {
    setSelectedInkCostMin(value[0]);
    setSelectedInkCostMax(value[1]);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      value[0],
      value[1],
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeLoreValue = async (value) => {
    setSelectedLoreValueMin(value[0]);
    setSelectedLoreValueMax(value[1]);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      value[0],
      value[1],
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };
  const handleChangeMoveCost = async (value) => {
    setSelectedMoveCostMin(value[0]);
    setSelectedMoveCostMax(value[1]);
    resetCards();
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      value[0],
      value[1]
    );
  };
  const handleSearchName = async (value) => {
    setSelectedSearchName(value);
    resetCards();
    getFilteredCards(
      undefined,
      value,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax
    );
  };

  useEffect(() => {
    const getAllFiltersList = async () => {
      const catalogos = await cardsApi.getCatalogos();
      if (catalogos && catalogos.loreValue) {
        setCatalogos(catalogos);
      }
    };
    getAllFiltersList();
  }, []);

  useEffect(() => {
    getFilteredCards(
      undefined,
      selectedSearchName,
      selectedSet,
      selectedInk,
      selectedType,
      selectedKeywords,
      selectedClassifications,
      selectedRarity,
      selectedInkwell,
      selectedFranchise,
      selectedArtist,
      selectedProduct,
      selectedStrengthMin,
      selectedStrengthMax,
      selectedWillpowerMin,
      selectedWillpowerMax,
      selectedInkCostMin,
      selectedInkCostMax,
      selectedLoreValueMin,
      selectedLoreValueMax,
      selectedMoveCostMin,
      selectedMoveCostMax,
      assignSelected.page
    );
  }, [assignSelected.page]);

  return (
    <>
      <Text className="titles">{t("TITLE_CARDS")}</Text>
      <Card
        bordered={false}
        size="small"
        style={{
          width: "auto",
          textAlign: "center",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <TextInfo labelText="SET" tooltipText="set" />
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              onChange={handleChangeSet}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={catalogos?.set?.map((option) => ({
                value: option,
                label: t(option),
              }))}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <TextInfo labelText="INK" tooltipText="ink" />
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              onChange={handleChangeInk}
              filterSort={(optionA, optionB) =>
                (optionA?.name ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.name ?? "").toLowerCase())
              }
              options={catalogos?.ink?.map((option) => ({
                value: option?.ink,
                label: (
                  <div>
                    <img
                      src={option.inkImage}
                      style={{ width: "13px", marginRight: "4px" }}
                    />
                    {t(option?.ink)}
                  </div>
                ),
                name: t(option?.ink),
              }))}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <TextInfo labelText="TYPE" tooltipText="type" />
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              onChange={handleChangeType}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={catalogos?.type?.map((option) => ({
                value: option,
                label: t(option),
              }))}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <TextInfo labelText="KEYWORDS" tooltipText="keywords" />
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              onChange={handleChangeKeyword}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={catalogos?.keyword?.map((option) => ({
                value: option,
                label: t(option),
              }))}
            />
          </Col>
        </Row>
        <Collapse
          size="small"
          expandIconPosition="start"
          bordered={false}
          style={{ width: "100%", marginTop: 10, textAlign: "left" }}
          items={[
            {
              key: "1",
              label: t("MORE_FILTERS"),
              children: (
                <>
                  <Row gutter={[8, 18]}>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo
                        labelText="CLASSIFICATIONS"
                        tooltipText="classifications"
                      />
                      <Select
                        mode="multiple"
                        allowClear
                        style={{
                          width: "100%",
                        }}
                        onChange={handleChangeClassification}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={catalogos?.classification?.map((option) => ({
                          value: option,
                          label: t(option),
                        }))}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo labelText="RARITY" tooltipText="rarity" />
                      <Select
                        mode="multiple"
                        allowClear
                        style={{
                          width: "100%",
                        }}
                        onChange={handleChangeRarity}
                        filterSort={(optionA, optionB) =>
                          optionA?.order - optionB?.order
                        }
                        options={catalogos?.rarity?.map((option) => ({
                          value: option.rarity,
                          label: (
                            <div>
                              <img
                                src={option.imgRarity}
                                style={{ width: "13px", marginRight: "4px" }}
                              />
                              {t(option?.rarity)}
                            </div>
                          ),
                          order: option.order,
                        }))}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo labelText="INKWELL" tooltipText="inkwell" />
                      <Select
                        allowClear
                        style={{
                          width: "100%",
                        }}
                        onChange={handleChangeInkwell}
                        options={[
                          { value: true, label: t("INKWELL_YES") },
                          { value: false, label: t("INKWELL_NO") },
                        ]}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo labelText="FRANCHISE" tooltipText="franchise" />
                      <Select
                        style={{
                          width: "100%",
                        }}
                        allowClear
                        onChange={handleChangeFranchise}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={catalogos?.franchise?.map((option) => ({
                          value: option,
                          label: t(option),
                        }))}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo labelText="ARTIST" tooltipText="artist" />
                      <Select
                        style={{
                          width: "100%",
                        }}
                        allowClear
                        onChange={handleChangeArtist}
                        options={catalogos?.artist?.map((option) => ({
                          value: option,
                          label: t(option),
                        }))}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo labelText="PRODUCT" tooltipText="product" />
                      <Select
                        mode="multiple"
                        allowClear
                        style={{
                          width: "100%",
                        }}
                        onChange={handleChangeProduct}
                        options={catalogos?.product?.map((option) => ({
                          value: option,
                          label: t(option),
                        }))}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo labelText="INK_COST" tooltipText="ink cost" />
                      <Slider
                        range
                        defaultValue={[0, 9]}
                        max={9}
                        onAfterChange={handleChangeInkCost}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo labelText="STRENGTH" tooltipText="strength" />
                      <Slider
                        range
                        defaultValue={[0, 10]}
                        max={10}
                        onAfterChange={handleChangeStrength}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo labelText="WILLPOWER" tooltipText="willpower" />
                      <Slider
                        range
                        defaultValue={[0, 10]}
                        max={10}
                        onAfterChange={handleChangeWillpower}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo
                        labelText="LORE_VALUE"
                        tooltipText="lore value"
                      />
                      <Slider
                        range
                        defaultValue={[0, 4]}
                        max={4}
                        onAfterChange={handleChangeLoreValue}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={4}>
                      <TextInfo labelText="MOVE_COST" tooltipText="move cost" />
                      <Slider
                        range
                        defaultValue={[0, 3]}
                        max={3}
                        onAfterChange={handleChangeMoveCost}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                      <TextInfo
                        labelText="SEARCH_NAME"
                        tooltipText="search name"
                      />
                      <br />
                      <Search
                        allowClear
                        placeholder={t("ENTER_NAME")}
                        onSearch={handleSearchName}
                      />
                    </Col>
                  </Row>
                </>
              ),
            },
          ]}
        />
      </Card>
    </>
  );
};

export default FiltersCardsView;
