import React from "react";
import { Alert, Carousel, Col, Image, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
const { Text, Title } = Typography;

const contentStyle = {
  width: "100%",
  height: "auto",
};

const LorcanaStory = () => {
  return (
    <Content className="container" >
      <Carousel
        arrows
        adaptiveHeight={true}
        autoplay
        effect="fade"
      >
        <div>
          <img
            style={contentStyle}
            src="https://reinodetinta.s3.us-east-2.amazonaws.com/img/all/lorcana_story_img1.png"
          // style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div>
          <img
            style={contentStyle}
            src="https://reinodetinta.s3.us-east-2.amazonaws.com/img/all/lorcana_story_img2.png"
          // style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div>
          <img
            style={contentStyle}
            src="https://reinodetinta.s3.us-east-2.amazonaws.com/img/all/lorcana_story_img3.png"
          // style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div>
          <img
            style={contentStyle}
            src="https://reinodetinta.s3.us-east-2.amazonaws.com/img/all/lorcana_story_img4.png"
          // style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </Carousel>

      <Alert
        className="alertStyle"
        message={
          <>
            <Text strong style={{ fontSize: "1rem" }}>
              <center>UN REMOLINO DE LUZ DE ESTRELLAS COLORIDAS APARECE</center>
            </Text>
            <Text>
              volviéndose cada vez más brillante hasta que es todo lo que puedes
              ver. Cuando la explosión de luz se disipa, te encuentras en un
              lugar maravilloso y nuevo. Bienvenido al Gran Iluminario, el
              centro de un reino mágico llamado Lorcana.
            </Text>
            <br />
            <br />
            <Text>
              El Iluminario te convocó aquí debido a tu poderosa imaginación.
              Sigues una línea de luz pulsante a través de pasillos curvos y
              numerosas habitaciones para salir a un vasto atrio. Allí, una
              máquina se alza sobre un libro abierto. Desciende destellante
              desde arriba una corriente de coloridas estrellas de historias,
              cada una conteniendo fragmentos de historias de Disney.
            </Text>
          </>
        }
      />
      <Alert
        className="alertStyle"
        message={
          <>
            <Text>
              Cerca de ti, una herramienta única llama tu atención. Es un
              lanzatintas, y cuando lo recoges, se siente emocionante y familiar
              a la vez. Instintivamente, lo sostienes alto sobre el libro
              abierto. Tinta mágica pulsante con energía fluye a través del
              mecanismo y se combina con la luz de una estrella de historia en
              una página del libro de la tradición frente a ti, creando una
              imagen de un personaje de Disney. Con el poder de tu lanzatintas,
              la imagen se eleva de la página. Esto es un destello, una nueva
              versión del personaje que solo existe en este mundo. Como
              Iluminador, puedes crear destellos de personajes y objetos para
              agregar al legado de Lorcana, un tesoro que debe ser preservado y
              protegido a toda costa.
            </Text>
            <br />
            <br />
            <Text>
              Pronto, otros Iluminadores de todo el mundo serán convocados para
              ayudar a Lorcana. Convocarás destellos para embarcarte en una
              búsqueda mientras buscas las tradiciones faltantes en una carrera
              contra el tiempo. Solo juntos pueden proteger este asombroso reino
              de las amenazas.
            </Text>
          </>
        }
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ fontSize: "0.7rem" }}>
          Texto original e imágenes pertenecen a disneylorcana.com
        </Text>
      </div>
      <br />
    </Content>
  );
};

export default LorcanaStory;
