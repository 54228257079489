import { Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const TextInfo = ({ labelText, tooltipText }) => {
  const { t } = useTranslation();

  return (
    <Text type="secondary" style={{ color: "white"}}>
      {t(labelText)}
      <Tooltip title={tooltipText}>
        <InfoCircleOutlined style={{ marginLeft: 3 }} />
      </Tooltip>
    </Text>
  );
};

export default TextInfo;
