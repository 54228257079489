import React from "react";
import FiltersCardsView from "../components/Filters";
import LorcanaCard from "../components/LorcanaCard";
import { Content } from "antd/es/layout/layout";
import "../css/style.css";

const CardsViewList = () => {
  return (
    <Content className="container" >
      <FiltersCardsView />
      <LorcanaCard />
    </Content>
  );
};
export default CardsViewList;
