import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
const { Text } = Typography;

const NewsProv = () => {
  const { t } = useTranslation();


  return (
    <Content className="container" >
      <section id="intro">
        <div className="about">
          <div className="contentBx">
            <Text className="hello">Bienvenidos a,</Text>
            <Text className="introName">Reino de Tinta!</Text>
            <Text className="subHello">Todo sobre Disney Lorcana TCG</Text>
            <p className="introTexto">
              Aquí encontrarás información actual del juego de cartas coleccionables
              de Disney. <br />Podrás ver detalles de cada carta, compararlas y armar tus
              mazos.
            </p>
            <Button
              type="primary"
              href={`/cards`}
              style={{ marginTop: "1em" }}
            >
              {t("CARD_CATALOG")}
            </Button>
          </div>
          <img
            src="https://reinodetinta.s3.us-east-2.amazonaws.com/img/web/disney_lorcana_Logo.png" alt="disney lorcana logo oficial"
          />
        </div>
      </section>
    </Content>
  );
};

export default NewsProv;
