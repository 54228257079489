import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

export const authApi = {
  login: async function (user) {
    let options = {
      method: "post",
      url: `${baseURL}/login`,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      data: user,
    };
    try {
      const response = await axios(options);
      if (response.data) {
        return response.data;
      }
    } catch (err) {
      let errText = err.response.data.error;
      if (err.response.status === 400) {
        if (errText.includes("email")) {
          return { emailText: errText, requiredFieldEmail: true };
        }
        if (errText.includes("password")) {
          return { passwordText: errText, requiredFieldPassword: true };
        }
      }
      if (err.response.status === 401) {
        return {
          incorrectCredentials: errText,
          requiredFieldEmail: true,
          requiredFieldPassword: true,
        };
      }
    }
  },

  logout: function () {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
  },
};
