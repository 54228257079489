import { Layout, Typography } from "antd";
import "../css/style.css";
const { Header } = Layout;
const { Text } = Typography;


const MainHeader = () => {
  return (
    <Header className="header">
      <Text className="headerText">Reino de Tinta</Text>
      <a href="www.reinodetinta.com">
        <img src="https://reinodetinta.s3.us-east-2.amazonaws.com/img/web/dragon5.png" alt="logo dragon" />
        </a>
    </Header>
  );
};

export default MainHeader;
