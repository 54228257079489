const TRANSLATIONS_ES = {
  NEW_CLIENT_MODAL_TITLE: "Crear nuevo cliente Dis",
  // OTROS
  TITLE_CARDS: "Colección de Cartas Disney Lorcana",
  SHOW_IMG: "Mostrar imágen",
  SHOW_ALL_INFO: "Mostrar info extra",
  CARDS: "Cartas",
  SET: "Colección",
  INK: "Tinta",
  RARITY: "Rareza",
  TYPE: "Tipo",
  CLASSIFICATIONS: "Clasificación",
  KEYWORDS: "Palabra clave",
  FRANCHISE: "Franquicia",
  NAME: "Nombre",
  STRENGTH: "Fuerza",
  WILLPOWER: "Voluntad",
  LORE_VALUE: "Valor de Leyenda (Lore value)",
  MOVE_COST: "Coste de Movimiento",
  INKWELL: "Tintero",
  INKWELL_YES: "Tinta",
  INKWELL_NO: "No Tinta",
  YES: "Sí",
  NOT: "No",
  INK_COST: "Coste de Tinta",
  ARTIST: "Artista",
  PRODUCT: "Producto",
  EXTRAS: "Extras",
  SEARCH_NAME: "Buscar por Personaje",
  SEARCH_ALL: "Buscar cartas:",
  OPEN: "Abrir",
  CLOSE: "Cerrar",
  PREVIEW: "Vista previa",
  // INFO: "más info",
  GO: "Ir a la carta",
  ABILITY: "Habilidad",
  LORE: "Puntos de Leyenda (Lore)",
  BODY_TEXT: "Texto del cuerpo",
  VERSION_NAME: "Nombre de versión",
  FLAVOR_TEXT: "Texto de ambiente",
  CARD_NUMBER: "Número de carta",
  RELEASE_DATE: "Fecha de lanzamiento",
  PROMO_INFO: "Tipo de promoción",
  VARIANT: "Variant",

  // MENU
  HOME: "Inicio",
  COMPARE_CARDS: "Comparar Cartas",
  CARD_DETAILS: "Información de Carta",
  DECKS: "Mazos",
  CREATE_DECKS: "Crear Mazo",
  USER: "Usuario",
  PROFILE: "Perfil",
  LORCANA: "Lorcana",
  STORY: "Historia",
  PLAY: "Cómo Jugar",
  PRODUCTS: "Productos",
  QUICK_RULES: "Guía Rápida",
  WIKI: "Wiki",
  MY_COLLECTION: "Mi colección",
  WISHLIST: "Lista de deseos",
  CHANGE_LANGUAGE: "Change Language",
  LORCANA_COPY:
    "“Este sitio web utiliza marcas comerciales y/o derechos de autor asociados con Disney Lorcana TCG, utilizados según la Política del Código de la Comunidad de Ravensburger (https://cdn.ravensburger.com/lorcana/community-code-en). Tenemos expresamente prohibido cobrarle por usar o acceder a este contenido. Este sitio web no está publicado, respaldado ni aprobado específicamente por Disney o Ravensburger. Para obtener más información sobre Disney Lorcana TCG, visite https://www.disneylorcana.com/en-US/.”",

  // VISTA HOME(noticias)
  NEWS: "ÚTLIMAS NOTICIAS",
  NEWS_TEXT:
    "Enterate de lo todo lo que se está hablando sobre Disney Lorcana en el momento!",
  CARD_CATALOG: "CATÁLOGO DE CARTAS",
  TOTAL: "Total de cartas encontradas: ",
  NOT_CARDS: "No se econtraron resultados que coincidan con tu búsqueda.",
  LOAD_MORE: "Cargar más",
  MORE_FILTERS: "Más Filtros",
  // VISTA COMPARE CARDS
  OVERSIZED: "Tiene tamaño grande?",
  FOIL: "Tiene Foil?",
  BACK: "Volver a Cartas",
  COMPARE: "Comparar",
  COMPARE_WITH: "Comparar con:",
  ENTER_NAME: "Ingresar personaje...",
  ENTER_SEARCH: "Ingresar...",
  SEARCH_BY_NAME: "buscar por nombre",
  EMPTY_DATA: "Búsqueda vacía",
  // VISTA CARD DETAILS
  ADD_TO_COLLECTION: "Agregar a colección (próximamente)",
  ADD_TO_WISHLIST: "Agregar a lista de deseos (próximamente)",
  ADD_TO_DECK: "Agregar al mazo (próximamente)",
  IN_PRODUCT: "Parte del producto",
  AMOUNT: ". Cantidad: ",
  OTHER_EDITIONS: "Otras ediciones",
  ALT_ART: "Arte alternativo",
  STANDARD: "Estándar",
  // VISTA QUICK RULES:
  OFFICIAL_RULES: "REGLAS OFICIALES",
  OFFICIAL_RULES_INTRO:
    "En esta sección encontrarás la guía rápida de reglas en español, junto al manual orginal.",

  // SET (todos)
  THE_FIRST_CHAPTER: "Set 1 - El Primer Capítulo",
  RISE_OF_THE_FLOODBORN: "Set 2 - El Ascenso de los Nacidos de las Inundaciones",
  INTO_THE_INKLANDS: "Set 3 - En las Tierras de Tinta",
  URSULAS_RETURN: "Set 4 - El regreso de Úrsula",

  // PRODUCT decks and boxes (todos)
  TFC_SD_SS: "Zafiro/Acero, Mazo de Inicio (Set 1)",
  TFC_SD_SS_TEXT:
    "El Primer Capítulo, Mazo de Inicio, tintas Zafiro y Acero. En portada: Aurora y Simba",
  TFC_SD_ER: "Esmeralda/Rubí, Mazo de Inicio (Set 1)",
  TFC_SD_ER_TEXT:
    "El Primer Capítulo, Mazo de Inicio, tintas Esmeralda y Rubí. En portada: Cruella De Vil y Aladdín",
  TFC_SD_AA: "Ámbar/Amatista, Mazo de Inicio (Set 1)",
  TFC_SD_AA_TEXT:
    "El Primer Capítulo, Mazo de Inicio, tintas Ámbar y Amatista. En portada: Mickey Mouse y Moana",
  // TFC_GS: "El Primer Capítulo, Set de Regalo",
  D100CE: "Disney 100 Edición de Coleccionista",
  D100CE_TEXT: "Disney 100 Collector's Edition",
  S2_STARTER_DECK_AMBER_SAPPHIRE: "Ámbar/Zafiro, Mazo de Inicio (Set 2)",
  S2_STARTER_DECK_AMETHYST_STEEL: "Amatista/Acero, Mazo de Inicio (Set 2)",
  S2_STARTER_DECK_AMBER_SAPPHIRE_TEXT:
    "El Ascenso de los Nacidos de las Inundaciones, Mazo de Inicio, tintas Ámbar y Zafiro. En portada: Reina Malvada y Gastón",
  S2_STARTER_DECK_AMETHYST_STEEL_TEXT:
    "El Ascenso de los Nacidos de las Inundaciones, Mazo de Inicio, tintas Amatista y Acero. En portada: Merlín y Tiana",
  S3_STARTER_DECK_AMBER_EMERALD: "Ámbar/Esmeralda, Mazo de Inicio (Set 3)",
  S3_STARTER_DECK_RUBY_SAPPHIRE: "Rubí/Zafiro, Mazo de Inicio (Set 3)",
  S3_STARTER_DECK_AMBER_EMERALD_TEXT:
    "En las Tierras de Tinta, Mazo de Inicio, tintas Ámbar y Esmeralda. En portada: 101 Dálmatas y Peter Pan",
  S3_STARTER_DECK_RUBY_SAPPHIRE_TEXT:
    "En las Tierras de Tinta, Mazo de Inicio, tintas Rubí y Zafiro. En portada: Moana y Rico McPato",
  S4_STARTER_DECK_AMBER_AMETHYST: "Ámbar/Amatista Mazo de Inicio (Set 4)",
  S4_STARTER_DECK_SAPPHIRE_STEEL: "Zafiro/Acero Mazo de Inicio (Set 4)",
  S4_STARTER_DECK_AMBER_AMETHYST_TEXT:
    "El regreso de Úrsula, Mazo de Inicio, tintas Ámbar and Amatista. Mirabel y Bruno",
  S4_STARTER_DECK_SAPPHIRE_STEEL_TEXT:
    "El regreso de Úrsula, Mazo de Inicio, tintas Zafiro y Acero. Anna y Hercules",
  ILLUMINEERS_QUEST_DEEP_TROUBLE: "La búsqueda del iluminador: Problemas profundos",

  // TYPE CARD
  CHARACTER: "Personaje",
  //ACTION
  ACTION_SONG: "Acción - Canción",
  ITEM_CARD: "Objeto",
  LOCATION: "Ubicación",

  // CHARACTER CARD (todos)
  ABU: "Abú",
  ALADDIN: "Aladdín",
  ALICE: "Alicia",
  ANNA: "Anna",
  ARCHIMEDES: "Arquímedes",
  ARIEL: "Ariel",
  ARTHUR: "Arturo",
  AUDREY_RAMIREZ: "Audrey Ramirez",
  AURORA: "Aurora",
  BALOO: "Baloo",
  BASHFUL: "Tímido",
  BASIL: "Basil",
  BEAST: "Bestia",
  BELLE: "Bella",
  BENJA: "Benja",
  BERNARD: "Bernardo",
  BILLY_BONES: "Billy Bones",
  BLUE_FAIRY: "Hada Azul",
  BOUND: "Bound",
  BRAWL: "Brawl",
  BRUNO_MADRIGAL: "Bruno Madrigal",
  BUCKY: "Bucky",
  CAMILO_MADRIGAL: "Camilo Madrigal",
  // CAPTAIN
  CAPTAIN_AMELIA: "Capitana Amelia",
  CAPTAIN_HOOK: "Capitán Garfio",
  CARD_SOLDIERS: "Cartas Soldado",
  CATERPILLAR: "Oruga",
  CERBERUS: "Cerbero",
  CHERNABOG: "Chernabog",
  CHERNABOGS_FOLLOWERS: "Seguidores de Chernabog",
  CHESHIRE_CAT: "Gato de Cheshire",
  CHIEF_BOGO: "Chief Bogo",
  CHIEF_TUI: "Jefe Tui",
  CHIEN_PO: "Chien-Po",
  CHI_FU: "Chi-Fu",
  CHIP_THE_TEACUP: "Chip la Taza de Té",
  CHRISTOPHER_ROBIN: "Christopher Robin",
  CINDERELLA: "Cenicienta",
  COBRA_BUBBLES: "Cobra Burbujas",
  COGSWORTH: "Din Don",
  CRI_KEE: "Cri-Kee",
  CRUELLA_DE_VIL: "Cruella de Vil",
  CUBBY: "Cubby",
  CURSED_MERFOLK: "Gente del mar maldita",
  DAISY_DUCK: "Pata Daisy",
  DALMATIAN_PUPPY: "Cachorro Dálmata",
  DEWEY: "Paco / Jaimito",
  DELLA_DUCK: "Pata Della",
  DIABLO: "Diablo",
  DOC: "Sabio",
  DOLORES_MADRIGAL: "Dolores Madrigal",
  DON_KARNAGE: "Don Karnage",
  DONALD_DUCK: "Pato Donald",
  DOPEY: "Tontín",
  DR_FACILIER: "Dr. Facilier",
  DUKE_OF_WESELTON: "Duque de Weselton",
  DUKE_WEASELTON: "Duke Roedriguez",
  EEYOTE: "Ígor",
  ELI_LA_BOUFF: "Eli La Bouff",
  ELSA: "Elsa",
  ENCHANTRESS: "La Hechicera",
  EUDORA: "Eudora",
  FAIRY_GODMOTHER: "Hada Madrina",
  FA_ZHOU: "Fa Zhou",
  FELICIA: "Felicia",
  FELIX_MADRIGAL: "Félix Madrigal",
  FIDGET: "Fidget",
  FLINTHEART_GLOMGOLD: "Flintheart Glomgold",
  FLOTSAM: "Flotsam",
  FLOTSAM_JETSAM: "Flotsam & Jetsam",
  FLOUNDER: "Flounder",
  FLYNN_RIDER: "Flynn Rider",
  FRIAR_TUCK: "Fraile Tuck",
  GANTU: "Gantu",
  GASTON: "Gastón",
  GENIE: "Genio",
  GOOFY: "Goofy",
  GOONS: "Secuaces de Maléfica",
  GRAMMA_TALA: "Abuela Tala",
  GRAND_DUKE: "Gran Duque",
  GRAND_PABBIE: "Gran Pabbie",
  GRUMPY: "Gruñón",
  GUNTHER: "Gunther",
  GUSTAV_THE_GIANT: "Gustav el Gigante",
  GYRO_GEARLOOSE: "Ciro Peraloca",
  HADES: "Hades",
  HANS: "Hans",
  HAPPY: "Feliz",
  HEIHEI: "Heihei",
  HELGA_SINCLAIR: "Helga Sinclair",
  HERCULES: "Hércules",
  HIRAM_FLAVERSHAM: "Hiram Flaversham",
  HONEST_JOHN: "Honrado Juan",
  HORACE: "Horacio",
  HUEY: "Hugo / Juanito",
  HYDRA: "Hydra",
  HYDROS: "Hydros",
  IAGO: "Iago",
  ISABELA_MADRIGAL: "Isabela Madrigal",
  JAFAR: "Jafar",
  JAQ: "Jaq",
  JAMES: "James",
  JASMINE: "Jasmín",
  JASPER: "Gaspar",
  JETSAM: "Jetsam",
  JIM_HAWKINS: "Jim Hawkins",
  JIMINY_CRICKET: "Pepe Grillo",
  JOHN_SILVER: "John Silver",
  JOSHUA_SWEET: "Joshua Sweet",
  JUDY_HOPPS: "Judy Hopps",
  JULIETA_MADRIGAL: "Julieta Madrigal",
  JUMBA_JOOKIBA: "Jumba Jookiba",
  KAKAMORA: "Kakamora",
  KHAN: "Khan",
  KIDA: "Kida",
  KING_LOUIE: "Rey Louie",
  KIT_CLOUDKICKER: "Kit Nubarrón",
  KRISTOFF: "Kristoff",
  KRONK: "Kronk",
  KUZCO: "Kuzco",
  LADY_TREMAINE: "Lady Tremaine",
  LAWRENCE: "Lawrence",
  LEFOU: "LeFou",
  LENA_SABREWING: "Lena Ala de Sable",
  LING: "Ling",
  LI_SHANG: "Li Shang",
  LILO: "Lilo",
  LITTLE_JOHN: "Pequeño Juan",
  LOUIE: "Luis / Jorgito",
  LUCIFER: "Lucifer",
  LUCKY: "Lucky",
  LUISA_MADRIGAL: "Luisa Madrigal",
  LUMIERE: "Lumiere",
  LYLE_TIBERIUS_ROURKE: "Lyle Tiberius Rourke",
  LYTHOS: "Lythos",
  MAD_HATTER: "Sombrero Loco",
  MADAM_MIM: "Madam Mim",
  MADAME_MEDUSA: "Madame Medusa",
  MAID_MARIAN: "Lady Marian",
  MAGIC_BROOM: "Escoba Mágica",
  MAGIC_CARPET: "Alfombra Mágica",
  MAGICA_DE_SPELL: "Mágica de Hechizo",
  MALEFICENT: "Maléfica",
  MAMA_ODIE: "Mamá Odie",
  MAUI: "Maui",
  MAURICE: "Maurice",
  MARSHMALLOW: "Marshmallow",
  MAXIMUS: "Maximus",
  MERLIN: "Merlín",
  MICKEY_MOUSE: "Mickey Mouse",
  MILO_THATCH: "Milo Thatch",
  MINNIE_MOUSE: "Minnie Mouse",
  MIRABEL_MADRIGAL: "Mirabel Madrigal",
  MISS_BIANCA: "Bianca",
  MEGARA: "Megara",
  MOANA: "Moana / Vaiana",
  MORPH: "Morph",
  MOTHER_GOTHEL: "Madre Gothel",
  MRS_JUDSON: "Sra. Judson",
  MR_SMEE: "Sr. Smee",
  MR_SNOOPS: "Sr. Snoops",
  MUFASA: "Mufasa",
  MULAN: "Mulán",
  NALA: "Nala",
  NAMAARI: "Namaari",
  NANA: "Nana",
  NANI: "Nani",
  NICK_WILDE: "Nick Wilde",
  NOI: "Noi",
  NUTSY: "Lelo",
  OLAF: "Olaf",
  ORVILLE: "Orville",
  OWL: "Búho",
  PACHA: "Pacha",
  PAIN: "Pena",
  PANIC: "Pánico",
  PASCAL: "Pascal",
  PATCH: "Patch",
  PEGASUS: "Pegasus",
  PEPA_MADRIGAL: "Pepa Madrigal",
  PERDITA: "Perdita",
  PETE: "Pete",
  PETER_PAN: "Peter Pan",
  PETER_PANS_SHADOW: "Sombra de Peter Pan",
  PHILOCTETES: "Filoctetes",
  PIGLET: "Piglet",
  PINOCCHIO: "Pinocho",
  PLUTO: "Pluto",
  PONGO: "Pongo",
  PRINCE_CHARMING: "Príncipe Encantador",
  PRINCE_ERIC: "Príncipe Eric",
  PRINCE_JOHN: "Príncipe Juan",
  PRINCE_NAVEEN: "Prince Naveen",
  PRINCE_PHILLIP: "Príncipe Felipe",
  PUA: "Pua",
  PUMBAA: "Pumba",
  PYROS: "Pyros",
  QUEEN_OF_HEARTS: "Reina de Corazones",
  RABBIT: "Rabbit",
  RAFIKI: "Rafiki",
  RAJAH: "Rajah",
  RAPUNZEL: "Rapunzel",
  RATIGAN: "Ratigan",
  RAY: "Ray",
  RAYA: "Raya",
  RAZOUL: "Razoul",
  ROBIN_HOOD: "Robin Hood",
  ROLLY: "Rolly",
  RUFUS: "Rufus",
  SCAR: "Scar",
  SCROOP: "Scroop",
  SCROOGE_MCDUCK: "Rico McPato / Gilito",
  SEBASTIAN: "Sebastián",
  SERGEANT_TIBBS: "Sargento Tibbs",
  SHENZI: "Shenzi",
  SHERE_KHAN: "Shere Khan",
  SHERIFF_OF_NOTTINGHAM: "Sheriff de Nottingham",
  SIMBA: "Simba",
  SIR_HISS: "Sir Hiss",
  SISU: "Sisu",
  SKIPPY: "Skippy",
  SLEEPY: "Dormilón",
  SLIGHTLY: "Ligero",
  SNEEZY: "Mocoso",
  SNOW_WHITE: "Blanca Nieves",
  STARKEY: "Starkey",
  STITCH: "Stitch",
  STRATOS: "Stratos",
  SVEN: "Sven",
  TAMATOA: "Tamatoa",
  TE_KA: "Te Ka",
  TIANA: "Tiana",
  TICK_TOCK: "Tick-Tock",
  TIGGER_CHAR: "Tigger",
  TIMON: "Timón",
  TINKER_BELL: "Campanita",
  THADDEUS_E_KLANG: "Thaddeus E. Klang",
  THE_FIREBIRD: "El Pájaro de Fuego",
  THE_HUNTSMAN: "El Cazador",
  THE_MUSES: "Las Musas",
  THE_NOKK: "The Nokk",
  THE_PRINCE: "El Príncipe",
  THE_QUEEN: "La Reina",
  THE_WARDROBE: "El Armario",
  TOR: "Tor",
  TREASURE_GUARDIAN: "Guardián del tesoro",
  TRIGGER: "Tiro Listo",
  TRITON: "Tritón",
  TUK_TUK: "Tuk Tuk",
  URSULA: "Úrsula",
  VIRANA: "Virana",
  WEBBY_VANDERQUACK: "Rosita Vanderquack",
  WENDY_DARLING: "Wendy Darling",
  WILDCAT: "Wildcat",
  WINNIE_THE_POOH: "Winnie Pooh",
  YEN_SID: "Yen Sid",
  YZMA: "Yzma",
  ZAZU: "Zazu",
  ZEUS: "Zeus",

  // CLASSIFICATIONS (todos)
  ACTION: "Acción",
  ALIEN: "Alien",
  ALLY: "Aliado",
  BROOM: "Escoba",
  CAPTAIN: "Capitán",
  DEITY: "Deidad",
  DETECTIVE: "Detective",
  DRAGON: "Dragón",
  DREAMBORN: "Nacido en Sueños",
  FAIRY: "Hada",
  FLOODBORN: "Nacido de la Inundación",
  HERO: "Héroe",
  INVENTOR: "Inventor",
  ITEM: "Artículo",
  KING: "Rey",
  KNIGHT: "Caballero",
  MADRIGAL: "Madrigal",
  MENTOR: "Mentor",
  MUSKETEER: "Mosquetero",
  PIRATE: "Pirata",
  PRINCE: "Principe",
  PRINCESS: "Princesa",
  PUPPY: "Cachorro",
  QUEEN: "Reina",
  SEVEN_DWARFS: "Siete Enanitos",
  SONG: "Canción",
  SORCERER: "Hechicero",
  STORYBORN: "Nacido del cuento",
  TIGGER: "Tigre",
  TITAN: "Titán",
  VILLAIN: "Villano",

  // INK (todos)
  AMBER: "Ámbar",
  AMETHYST: "Amatista",
  EMERALD: "Esmeralda",
  RUBY: "Rubí",
  SAPPHIRE: "Zafiro",
  STEEL: "Acero",

  // INK COLOR (todos)
  YELLOW: "Amarillo",
  PURPLE: "Violeta",
  GREEN: "Verde",
  RED: "Rojo",
  BLUE: "Azul",
  GREY: "Gris",

  // RARITY (todos)
  COMMON: "Común",
  UNCOMMON: "Poco Común",
  RARE: "Rara",
  SUPER_RARE: "Super Rara",
  LEGENDARY: "Legendaria",
  ENCHANTED: "Encantada",
  PROMO: "Promoción",

  // KEYWORDS (todos)
  BODYGUARD: "Guardaespaldas",
  CHALLENGER: "Retador X",
  EVASIVE: "Escurridizo",
  RECKLESS: "Temerario",
  RESIST: "Resistir X",
  RUSH: "Prisa",
  SHIFT: "Cambio X",
  SINGER: "Cantante X",
  SING_TOGETHER: "Cantar juntos X",
  SUPPORT: "Apoyo",
  WARD: "Guarda",

  // KEYWORDS TEXT (todos)
  BODYGUARD_TEXT:
    "(Bodyguard) Este personaje puede entrar en juego agotado. Un personaje contrario que desafíe a uno de tus personajes debe elegir uno con Guardaespaldas (Bodyguard) si puede.",
  CHALLENGER_X_TEXT:
    "(Challenger X) Cuando es retado, este personaje obtiene +X de Fuerza.",
  EVASIVE_TEXT:
    "(Evasive) Sólo los personajes con Escurridizo (Evasive) pueden desafiar a este personaje.",
  RECKLESS_TEXT:
    "(Reckless) Este personaje no puede explorar (Quest) y debe desafiar si puede.",
  RESIST_X_TEXT:
    "(Resist X) El daño infligido a este personaje se reduce en X.",
  RUSH_TEXT:
    "(Rush) Este personaje puede desafiar el turno en el que se juega.",
  SHIFT_X_TEXT:
    "(Shift X) Puedes pagar X tinta para jugar esto encima de uno de tus Y personajes.",
  SINGER_X_TEXT:
    "(Singer X) Este personaje cuenta como costo X para cantar Canciones (Action - Song).",
  SING_TOGETHER_X_TEXT: "(Sing Together X) (Cualquier número de personajes de tus compañeros de equipo con un coste total X o más puede agotarse para cantar esta Canción gratis.)",
  SUPPORT_TEXT:
    "(Support) Siempre que este personaje explore (Quest), puedes agregar su Fuerza a la Fuerza de otro personaje elegido este turno.",
  WARD_TEXT: "(Ward) Los oponentes no pueden elegirlos excepto para desafiar.",

  // KEYWORDS TEXT (todos)    CHALLENGER_X, SINGER_X, RESIST_X
  KEYWORDS_CHALLENGER_2:
    "+2 (Challenger) (Mientras desafía, este personaje obtiene +2 de fuerza).",
  KEYWORDS_CHALLENGER_3:
    "+3 (Challenger) (Mientras desafía, este personaje obtiene +3 de fuerza).",
  KEYWORDS_CHALLENGER_4:
    "+4 (Challenger) (Mientras desafía, este personaje obtiene +4 de fuerza).",

  KEYWORDS_RESIST_1:
    "+1 (Resist) (El daño infligido a este personaje se reduce en 1.)",
  KEYWORDS_RESIST_2:
    "+2 (Resist) (El daño infligido a este personaje se reduce en 2.)",

  KEYWORDS_SINGER_3:
    "3 (Singer) (Este personaje cuenta como costo 3 para cantar Canciones (Action - Song)).",
  KEYWORDS_SINGER_4:
    "4 (Singer) (Este personaje cuenta como costo 4 para cantar Canciones (Action - Song)).",
  KEYWORDS_SINGER_5:
    "5 (Singer) (Este personaje cuenta como costo 5 para cantar Canciones (Action - Song)).",
  KEYWORDS_SINGER_7:
    "7 (Singer) (Este personaje cuenta como costo 7 para cantar Canciones (Action - Song)).",

  KEYWORDS_SING_TOGETHER_6: "6 (Sing Together) (Cualquier número de personajes de tus compañeros de equipo con un coste total 6 o más puede agotarse para cantar esta Canción gratis.)",
  KEYWORDS_SING_TOGETHER_7: "7 (Sing Together) (Cualquier número de personajes de tus compañeros de equipo con un coste total 7 o más puede agotarse para cantar esta Canción gratis.)",
  KEYWORDS_SING_TOGETHER_8: "8 (Sing Together) (Cualquier número de personajes de tus compañeros de equipo con un coste total 8 o más puede agotarse para cantar esta Canción gratis.)",
  KEYWORDS_SING_TOGETHER_10: "10 (Sing Together) (Cualquier número de personajes de tus compañeros de equipo con un coste total 10 o más puede agotarse para cantar esta Canción gratis.)",

  // FRANCHISE (todos)
  // ALADDIN
  ALICE_IN_WONDERLAND: "Alicia en el país de las maravillas",
  ATLANTIS_THE_LOST_EMPIRE: "Atlantis: el imperio perdido",
  BEAUTY_AND_THE_BEAST: "La Bella y la Bestia",
  // CINDERELLA
  DALMATIANS_101: "101 Dálmatas",
  DUCKTALES: "Patoaventuras",
  ENCANTO: "Encanto",
  FANTASIA: "Fantasía",
  FROZEN: "Frozen",
  // HERCULES
  LILO_AND_STITCH: "Lilo y Stitch",
  // LORCANA
  MICKEY_AND_FRIENDS: "Mickey y amigos",
  // MOANA
  // MULAN
  // PETER_PAN
  // PINOCCHIO
  RAYA_AND_THE_LAST_DRAGON: "Raya y el último dragón",
  // ROBIN_HOOD
  SLEEPING_BEAUTY: "La bella durmiente",
  SNOW_WHITE_AND_THE_SEVEN_DWARFS: "Blanca Nieves y los siete enanos",
  TALESPIN: "Aventureros del Aire",
  TANGLED: "Enredados",
  THE_EMPERORS_NEW_GROOVE: "Las locuras del emperador",
  THE_GREAT_MOUSE_DETECTIVE: "Policías y ratones",
  THE_JUNGLE_BOOK: "El libro de la selva",
  THE_LION_KING: "El Rey León",
  THE_LITTLE_MERMAID: "La Sirenita",
  THE_PRINCESS_AND_THE_FROG: "La princesa y el sapo",
  THE_RESCUERS: "Bernardo y Bianca, Los rescatadores",
  THE_SWORD_IN_THE_STONE: "La espada en la piedra",
  TREASURE_PLANET: "El planeta del tesoro",
  // WINNIE_THE_POOH: "Winnie Pooh",
  WRECK_IT_RALPH: "Ralph, el demoledor",
  ZOOTOPIA: "Zootrópolis",

  // SET 1
  // ITEM CARD set 1
  BEAST_MIRROR: "Espejo de Bestia",
  COCONUT_BASKET: "Cesta de coco",
  DINGLEHOPPER: "Rizador de pelo",
  DR_FACILIERS_CARDS: "Cartas del Dr. Facilier",
  EYE_OF_THE_FATES: "Ojo de los destinos",
  FISHBONE_QUILL: "Pluma de espina de pescado",
  FRYING_PAN: "Sartén",
  LANTERN: "Linterna",
  MAGIC_GOLDEN_FLOWER: "Flor dorada mágica",
  MAGIC_MIRROR: "Espejo mágico",
  MUSKETEER_TABARD: "Tabardo de mosquetero",
  PLASMA_BLASTER: "Lanzador de plasma",
  POISONED_APPLE: "Manzana envenenada",
  SCEPTER_OF_ARENDELLE: "Cetro de Arendelle",
  SHIELD_OF_VIRTUE: "Escudo de virtud",
  STOLEN_SCIMITAR: "Cimitarra robada",
  SWORD_OF_TRUTH: "Espada de la verdad",
  URSULAS_CAULDRON: "El caldero de Úrsula",
  URSULAS_SHELL_NECKLACE: "Collar de conchas de Úrsula",
  WHITE_RABBITS_POCKET_WATCH: "Reloj de bolsillo del conejo blanco",

  // ACTION CARD set 1
  BEFUDDLE: "Confundir",
  BREAK: "Romper",
  CONTROL_YOUR_TEMPER: "Controla tu temperamento",
  CUT_TO_THE_CHASE: "Ir al grano",
  DEVELOP_YOUR_BRAIN: "Desarrolla tu cerebro",
  DO_IT_AGAIN: "Hazlo otra vez",
  DRAGON_FIRE: "Fuego de Dragon",
  FAN_THE_FLAMES: "Avivar las llamas",
  FIRE_THE_CANNONS: "Dispara los cañones",
  FREEZE: "Congelar",
  HEALING_GLOW: "Brillo curativo",
  HES_GOT_A_SWORD: "Él tiene una espada",
  IF_ITS_NOT_BAROQUE: "Si no es barroco",
  JUST_IN_TIME: "Justo a tiempo",
  RANSACK: "Saquear",
  SMASH: "Aplastar",
  STAMPEDE: "Estampida",
  STEAL_FROM_THE_RICH: "Robar a los ricos",
  TANGLE: "Enredo",
  THE_BEAST_IS_MINE: "La bestia es mia",
  VICIOUS_BETRAYAL: "Traición viciosa",
  YOU_HAVE_FORGOTTEN_ME: "Me has olvidado",
  WORK_TOGETHER: "Trabajar juntos",

  // ACTION SONG CARD set 1
  A_WHOLE_NEW_WORLD: "Un mundo ideal",
  BE_OUR_GUEST: "Nuestro Huésped sea usted",
  BE_PREPARED: "Listos ya",
  FRIENDS_ON_THE_OTHER_SIDE: "Influencia en el más allá",
  GRAB_YOUR_SWORD: "Toma tu espada",
  HAKUNA_MATATA: "Hakuna Matata",
  LET_IT_GO: "Libre soy",
  MOTHER_KNOWS_BEST: "Sabia es mamá",
  ONE_JUMP_AHEAD: "Un salto adelante",
  PART_OF_YOUR_WORLD: "Parte de tu mundo",
  REFLECTION: "Reflejo",
  SUDDEN_CHILL: "Escalofrío repentino",

  // VERSION NAME set 1
  ABOMINATION: "Abominación",
  AGENT_PROVOCATEUR: "Agente Provocador",
  ALCHEMIST: "Alchemist",
  ALIEN_PIRATE: "Pirata Alienígena",
  ALWAYS_CLASSY: "Siempre Elegante",
  ARROGANT_HUNTER: "Cazador Arrogante",
  ARTFUL_ROGUE: "Pícaro ingenioso",
  BELLES_CONFIDANT: "Confidente de Bella",
  BELOVED_PRINCESS: "Princesa Amada",
  BIDING_HER_TIME: "Esperando su Momento",
  BOAT_SNACK: "Aperitivo de Barco",
  BOISTEROUS_FOWL: "Ave Alborotadora",
  BRAVE_LITTLE_TAILOR: "Valiente Sastrecillo",
  BRIAR_ROSE: "Briar Rose",
  BUCKET_BRIGADE: "Brigada de Cubos",
  BUMBLER: "Torpe",
  CAPTAIN_OF_THE_JOLLY_ROGER: "Capitán del Jolly Roger",
  CAREFREE_SURFER: "Surfista Despreocupado",
  CHARLATAN: "Charlatán",
  CHARMING_ROGUE: "Pícaro Encantador",
  CHOSEN_BY_THE_OCEAN: "Elegido por el océano",
  COMMON_CROOK: "Ladrón Común",
  COLONELS_LIEUTENANT: "Teniente del Coronel",
  CORNERED_SWORDSMAN: "Espadachín acorralado",
  COURAGEOUS_CAT: "Gato Valiente",
  COURT_COMPOSER: "Compositor de la Corte",
  DAREDEVIL: "Temerario",
  DASHING_AND_BRAVE: "Valiente y Apuesto",
  DEMIGOD: "Semidiós",
  // DETECTIVE
  DISGUISED: "Disfrazado/a",
  DRAB_LITTLE_CRAB: "Pequeño cangrejo monótono",
  DRAGONSLAYER: "Cazadragones",
  DREAMING_GUARDIAN: "Guardián de los sueños",
  FEARLESS_FIGHTER: "Luchador audaz",
  FIERY_USURPER: "Usurpador Ardiente",
  FORCEFUL_DUELIST: "Duelista Enérgico",
  FRIENDLY_SNOWMAN: "Muñeco de Nieve Amigable",
  FRIENDLY_WARTHOG: "Jabalí amigable",
  FUTURE_KING: "Futuro Rey",
  GALACTIC_FEDERATION_CAPTAIN: "Capitán de la Federación Galáctica",
  GALACTIC_HERO: "Héroe galáctico",
  GENTLE_AND_KIND: "Dulce y Amable",
  GIANT_FAIRY: "Hada gigante",
  GIFTED_WITH_HEALING: "Dotada con la Sanación",
  GOD_OF_LIGHTNING: "Dios del Rayo",
  GRACIOUS_HOST: "Anfitrión Amable",
  GRUB_RUSTLER: "Ratero de Larvas",
  HARDHEADED: "Terco",
  HEARTLESS: "Cruel",
  HEIR_TO_ARENDELLE: "Heredera de Arendelle",
  HERO_TO_ALL: "Héroe para todos",
  HEROIC_OUTLAW: "Forajido Heroico",
  HIGHLY_EDUCATED_OWL: "Búho Altamente Educado",
  HOOKS_HENCHMAN: "Secuaz de Hook",
  ICE_SURFER: "Surfista de Hielo",
  IMPERIAL_SOLDIER: "Soldado Imperial",
  INFERNAL_SCHEMER: "Conspirador infernal",
  INSTIGATOR: "Instigador",
  INVENTIVE_ENGINEER: "Ingeniero Inventivo",
  KEEPER_OF_SECRETS: "Guardián de Secretos",
  KING_OF_OLYMPUS: "Rey del Olimpo",
  KING_OF_THE_PRIDE_LANDS: "Rey de las Tierras del Orgullo",
  LETTING_DOWN_HER_HAIR: "Soltando su Pelo",
  LORD_OF_THE_UNDERWORLD: "Señor del Inframundo",
  LOUD_MOUTHED_PARROT: "Loro de Boca Grande",
  LOYAL_FIRST_MATE: "Primer oficial leal",
  MAKING_A_WISH: "Haciendo un Deseo",
  MALEFICENTS_UNDERLINGS: "Secuaces de Maléfica",
  MASTERMIND: "Mente Maestra",
  MISCHIEVOUS_MONKEY: "Mono Travieso",
  MISERABLE_AS_USUAL: "Miserable Como Siempre",
  MONSTROUS_DRAGON: "Dragón Monstruoso",
  MOST_HELPFUL: "Más útil",
  // MUSKETEER
  MYSTERIOUS_SAGE: "Sabio Misterioso",
  NEVER_LANDING: "Nunca Aterrizando",
  NEW_DOG: "Perro Nuevo",
  NO_GOOD_SCOUNDREL: "Canalla Sin Remedio",
  NOT_ALL_THERE: "No Del Todo Allí",
  OFFICIAL_ICE_DELIVERER: "Entregador Oficial de Hielo",
  OFFICIAL_ICE_MASTER: "Maestro del Hielo Oficial",
  OF_MOTUNUI: "De Motunui",
  OL_RASCAL: "Viejo Truhán",
  ON_HUMAN_LEGS: "Sobre Piernas Humanas",
  ON_THE_JOB: "En el trabajo",
  OPPORTUNISTIC_OFFICIAL: "Funcionario Oportunista",
  PALACE_HORSE: "Caballo del Palacio",
  PERSISTENT_GUARDIAN: "Guardián Persistente",
  PETER_PANS_ALLY: "Aliado de Peter Pan",
  POWER_HUNGRY: "Hambrientos de poder",
  POWER_UNLEASHED: "Poderes desatados",
  PRINCE_ALI: "Príncipe Ali",
  PROTECTIVE_CUB: "Cachorro Protector",
  PULLING_THE_STRINGS: "Moviendo los Hilos",
  QUEEN_OF_AGRABAH: "Reina de Agrabah",
  QUEEN_REGENT: "Reina Regente",
  RAPUNZELS_COMPANION: "Compañero de Rapunzel",
  REGAL_PRINCESS: "Princesa Real",
  RELENTLESS_PURSUER: "Perseguidor Incansable",
  REMARKABLE_GENTLEMAN: "Caballero notable",
  RENEGADE_SCIENTIST: "Científico Renegado",
  RESPECTED_LEADER: "Líder respetado",
  RETURNED_KING: "Rey Regresado",
  RIGHTFUL_HEIR: "Heredero legítimo",
  RIGHT_HAND_MAN: "Hombre de Confianza",
  ROCK_STAR: "Estrella de Rock",
  RUTHLESS_PIRATE: "Pirata Despiadado",
  SCHEMING_PRINCE: "Príncipe Maquinador",
  SELF_APPOINTED_MENTOR: "Mentor Autoproclamado",
  SELFISH_MANIPULATOR: "Manipulador Egoísta",
  SHAMELESS_FIREBRAND: "Alborotador Desvergonzado",
  SINISTER_VISITOR: "Visitante Siniestro",
  SNOW_QUEEN: "Reina de las Nieves",
  SORCERESS: "Hechicera",
  SO_SHINY: "¡Tan brillante!",
  SPECTACULAR_SINGER: "Cantante Espectacular",
  SPIRIT_OF_WINTER: "Espíritu del Invierno",
  STEAMBOAT_PILOT: "Piloto de Barco de Vapor",
  STORYTELLER: "Cuentacuentos",
  STRANGE_BUT_SPECIAL: "Extraño pero especial",
  STREET_RAT: "Rata de Calle",
  STRUTTING_HIS_STUFF: "Luciendo Sus Habilidades",
  TEMPERAMENTAL_EMPEROR: "Emperador Temperamental",
  THE_BURNING_ONE: "El Ardiente",
  THE_EVER_IMPRESSIVE: "El Siempre Impresionante",
  THE_SEA_KING: "El Rey del Mar",
  THINKING_A_HAPPY_THOUGHT: "Pensando en un Pensamiento Feliz",
  THIRTEENTH_IN_LINE: "Decimotercero en la Línea",
  THREE_HEADED_DOG: "Perro de Tres Cabezas",
  TINY_TACTICIAN: "Táctico Pequeñito",
  TRAINER_OF_HEROES: "Entrenador de héroes",
  TRUE_FRIEND: "Amigo Verdadero",
  TRUE_HERO: "Héroe Verdadero",
  UNINVITED: "No Invitada",
  UNRIVALED_ARCHER: "Arquero Inigualable",
  URSULAS_SPY: "Espía de Úrsula",
  VOICE_OF_REASON: "Voz de la Razón",
  WAYWARD_SORCERER: "Hechicero Rebelde",
  WICKED_AND_VAIN: "Malvada y vanidosa",
  WICKED_SORCERER: "Hechicero Malvado",
  WICKED_STEPMOTHER: "Malvada Madrastra",
  WHOSEIT_COLLECTOR: "Coleccionista de Cualquier Cosa",
  WOLFSBANE: "Aconito",
  WONDERFUL_THING: "Cosa Maravillosa",
  WORLD_FAMOUS_INVENTOR: "Inventor Mundialmente Famoso",

  // ABILITIES set 1
  ADORING_FANS:
    "Fanáticos adoradores: Siempre que juegues un personaje con coste 2 o menos, puedes agotarlo para robar una carta.",
  ALL_FOR_ONE:
    "Todos para uno: Tus otros personajes Mosqueteros obtienen +1 de Fuerza.",
  ALL_FOR_ONE_AND_ONE_FOR_ALL:
    "Todos para uno y uno para todos: Siempre que uno de tus personajes con Guardaespaldas (Bodyguard) sea desterrado, podrás robar una carta.",
  AND_TWO_FOR_TEA:
    "¡Y dos para el té!: Cuando juegas este personaje, puedes remover hasta 2 de daño a cada uno de tus personajes Mosquetero.",
  ANIMATE_BROOM_CEASELESS_WORKER:
    "<1>Escoba animada:</1> Pagas 1 Tinta menos para jugar con los personajes Escoba. <2/><1>Trabajador incesable:</1> Siempre que uno de tus personajes Escoba sea desterrado en un desafío, puedes devolver esa carta a tu mano.",
  AYE_AYE_CAPTAIN:
    "Sí sí, Capitán: Mientras tengas un personaje Capitán en juego, este personaje obtiene +1 punto de Leyenda (Lore).",
  A_WONDERFUL_DREAM:
    "Un sueño maravilloso: Agotar - Remueve hasta 3 de daño al personaje Princesa elegido.",
  BATTLE_PLANS:
    "Planes de batalla: Agotar - Roba una carta, luego elige y descarta una carta.",
  BIRTHDAY_LIGHTS:
    "Luces de cumpleaños: Agotar - Pagas 1 tinta menos por el siguiente personaje que juegues en este turno.",
  BREAK_ABILITIES:
    "Romper: Cuando juegas este personaje, puedes desterrar la carta Objeto (Item) elegido.",
  CAMOUFLAGE:
    "Camuflaje: Mientras tengas otro personaje en juego, este personaje gana Escurridizo (Evasive). (Solo los personajes con Escurridizo pueden desafiarlos).",
  CARETAKER:
    "Vigilante: Cuando juegas este personaje y cada vez que explore (Quest), puedes remover hasta 2 de daño a cada uno de tus personajes.",
  CAST_MY_SPELL:
    "¡Lanza mi hechizo!: Cuando juegas este personaje, puedes robar una carta.",
  CLANG:
    "Clang: Desterrar este Objeto (Item): el personaje elegido no puede desafiar durante su próximo turno.",
  COMMAND:
    "Dominio: Agotar - El personaje elegido gana Apoyo (Support) este turno. (Siempre que explore (Quest), puedes agregar su Fuerza a la Fuerza de otro personaje elegido este turno).",
  CONSIDER_THE_COCONUT:
    "Considere el coco: Cada vez que juegas un personaje, puedes remover hasta 2 de daño del personaje elegido.",
  DARING_EXPLOIT:
    "Explotación atrevida: Durante tu turno, cada vez que este personaje destierra a otro personaje en un desafío, ganas 2 puntos de Leyenda (Lore) y cada oponente pierde 2 puntos de Leyenda (Lore).",
  DEEP_FREEZE:
    "Congelación profunda: Cuando juegues este personaje, agota hasta 2 personajes elegidos. No pueden prepararse al comienzo de su siguiente turno.",
  DEXTEROUS_LUNGE:
    "Estocada diestra: Tus personajes llamados Jetsam obtienen Prisa (Rush).",
  DISAPPEAR:
    "Desaparecer: Cuando juegas este personaje, puedes devolver un personaje elegido a la mano de su jugador.",
  DISTURBING_BEAUTY:
    "Belleza inquietante: Cuando juegas este personaje, el personaje elegido obtiene -2 de fuerza por el resto del turno.",
  DO_IT_AGAIN_ABILITIES:
    "Hazlo otra vez: Cuando juegas este personaje, puedes devolver una carta Acción (Action) de tu descarte a tu mano.",
  DOUBLE_THE_POWDER:
    "¡Duplica el polvo!: Cuando juegues este personaje, podrás devolver una carta de Acción (Action) llamada ¡Dispara los cañones! (Fire the Cannons!) desde tu descarte a tu mano.",
  DRAGON_FIRE_ABILITIES:
    "Fuego de Dragon: Cuando juegas este personaje, puedes desterrar al personaje elegido.",
  DREAMS_MADE_REAL:
    "Sueños hechos realidad: Cada vez que jueges una Canción (Action - Song), puedes mirar las 2 cartas superiores de tu mazo. Coloca una en la parte superior de tu mazo y la otro en la parte inferior.",
  DURABLE:
    "Duradero: Cuando este personaje es desterrado en un desafío, puedes devolver esta carta a tu mano.",
  FAN_THE_FLAMES_ABILITIES:
    "Avivar las llamas: Cuando juegues este personaje, prepara al personaje elegido. No puede explorar (Quest) durante el resto de este turno.",
  FEED_THE_POOR_GOOD_SHOT:
    "Alimentar a los pobres: Cuando juegas este personaje, si un oponente tiene más cartas en su mano que tú, roba una carta. Buen tiro: Durante tu turno, este personaje gana Escurridizo (Evasive). (Puede desafiar a los personajes con Escurridizo).",
  FINAL_ENCHANTMENT:
    "Encantamiento final: Destierra este Objeto (Item) - Destierra al personaje Villano elegido.",
  FIREPROOF:
    "Incombustible: Agotar, paga 3 tintas - Prepara al personaje elegido. No puede explorar (Quest) durante el resto de este turno.",
  FREEZE_ABILITIES: "Congelar: Agotar - Agota al personaje contrario elegido.",
  GET_A_CLUE:
    "Obtener una pista: Cuando juegas este personaje, puedes poner la carta superior de tu mazo en tu tintero boca abajo y agotarla.",
  GIVE_IT_A_TRY_IT_WORKS:
    "<1>Pruébalo:</1> Cada vez que este personaje explore (Quest), pagas 2 tintas menos por el siguiente Objeto (Item) que juegues en este turno. <2/><1>¡Funciona!:</1> Cada vez que juegas un Objeto (Item), puedes robar una carta.",
  GLEAM_AND_GLOW:
    "Brilla y brilla: Cuando juegues este personaje, remueve hasta 3 de daño de uno de tus personajes. Roba una carta por cada 1 daño eliminado de esta manera.",
  GO_AHEAD_AND_SIGN:
    "Adelante y firma: Agotar - Pon cualquier carta de tu mano en tu tintero boca abajo.",
  GUESS_WHAT:
    "¿Adivina qué?: Cuando juegas este personaje, puedes robar una carta, luego elegir y descartar una carta.",
  HEALING_POLLEN:
    "Polen curativo: Desterrar este Objeto (Item) - Remueve hasta 3 de daño al personaje elegido.",
  HERE_COMES_THE_SMOLDER:
    "Aquí viene el encanto: Siempre que se desafía a este personaje, el jugador desafiante elige y descarta una carta.",
  HEROISM:
    "Heroísmo: Cuando este personaje desafía y es desterrado, puedes desterrar al personaje desafiado.",
  HIDDEN_WONDERS:
    "Maravillas ocultas: Este personaje obtiene +1 de Fuerza por cada carta en tu mano.",
  HORSE_KICK:
    "Patada de caballo: Cuando juegas este personaje, el personaje elegido obtiene -2 de fuerza este turno.",
  I_KNOW_WHAT_I_HAVE_TO_DO:
    "Se lo que tengo que hacer: Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, ganas 1 punto de Leyenda (Lore).",
  IM_LATE:
    "¡Voy tarde!: Agotar, paga 1 tinta - El personaje elegido gana Prisa (Rush) este turno. (Pueden desafiar el turno en el que se juega).",
  IMPROVISE:
    "Improvisar: Cuando juegas este personaje, cada oponente pierde 1 puntos de Leyenda (Lore).",
  INSIDIOUS_PLOT:
    "Trama insidiosa: Cuando juegas este personaje, el personaje contrario elegido obtiene -5 de fuerza este turno.",
  INTO_THE_SHADOWS:
    "En las sombras: Siempre que uno de tus otros personajes sea desterrado en un desafío, puedes devolver esa carta a tu mano.",
  IS_THERE_A_DOWNSIDE_TO_THIS:
    "¿Hay alguna desventaja en esto?: Cuando juegas este personaje, puedes poner al personaje contrario elegido en el tintero de su jugador boca abajo.",
  I_SUMMON_THEE: "Yo te invoco: Agotar - Roba una carta",
  ITS_TOO_EASY:
    "¡Es demasiado fácil!: Cuando juegas este personaje, cada oponente pierde 1 punto de Leyenda (Lore). Puedes robar una carta por cada 1 punto de Leyenda (Lore) perdido de esta manera.",
  I_WILL_BE_WITH_YOU:
    "Estaré contigo: Cuando este personaje sea desterrado, puedes poner esta carta en tu tintero boca abajo y agotado.",
  LEAD_BY_EXAMPLE:
    "Predicar con el ejemplo: Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, tus otros personajes obtienen +1 punto de Leyenda (Lore) este turno.",
  LOOK_AT_THIS_STUFF:
    "Mira estas cosas: Cada vez que juegas un Objeto (Item), puedes preparar este personaje.",
  LOSE_SOMETHING:
    "¿Perder algo?: Cuando este personaje sea desafiado y desterrado, destierra al personaje desafiante.",
  LOVING_HEART:
    "Corazón amoroso: Cuando juegues este personaje, si tienes un personaje llamado Elsa en juego, elige un personaje contrario. El personaje elegido no se prepara al comienzo de su siguiente turno.",
  LOYAL:
    "Leal: Si tienes un personaje llamado Gastón en juego, pagas 1 tinta menos para jugar a este personaje.",
  LOYAL_AND_DEVOTED:
    "Leal y devoto: Tus personajes llamados Peter Pan obtienen Retador (Challenger) +1. (Obtienen +1 de Fuerza mientras desafían).",
  MISDIRECTION:
    "Mal dirección: Cada vez que juegas una Acción (Action), el personaje contrario elegido no puede explorar (Quest) durante su siguiente turno.",
  MUSICAL_DEBUT:
    "Debut musical: Cuando juegues este personaje, mira las 4 cartas superiores de tu mazo. Puedes revelar una carta Canción (Action - Song) y ponerla en tu mano. Coloca el resto en el fondo de tu mazo en cualquier orden.",
  NO_TOUCHY:
    "¡No susceptible!: Cuando este personaje es desafiado y desterrado, puedes desterrar al personaje desafiante.",
  NOW_SING:
    "¡Ahora canta!: Cada vez que juegues una Canción (Action - Song), podrás pagar 1 tinta para robar una carta.",
  OHANA:
    "Ohana: Cuando juegas este personaje, si tienes 2 o más personajes en juego, puedes robar 2 cartas.",
  QUICK_SHOT:
    "Tiro rápido: Agotar, paga 2 tintas - Inflige 1 de Daño al personaje elegido.",
  PEER_INTO_THE_DEPTHS:
    "Mirar hacia las profundidades: Agotar - Mira las 2 primeras cartas de tu mazo. Coloca una en la parte superior de tu mazo y la otro en la parte inferior.",
  PHENOMENAL_COSMIC_POWER:
    "¡Poder cósmico fenomenal!: Siempre que este personaje explore (Quest), puedes realizar una Acción (Action) con un coste 5 o menos de forma gratuita.",
  PICK_YOUR_FIGHTS:
    "Elige tus peleas: Cuando juegas este personaje y cada vez que explora (Quest), el personaje contrario elegido obtiene Temerario (Reckless) durante su próximo turno. (No puede explorar (Quest) y debe desafiar si puede).",
  PIXIE_DUST:
    "Polvo de hadas: Cuando juegas este personaje, el personaje elegido gana Escurridizo (Evasive) este turno.",
  POUNCE:
    "Saltar: Durante tu turno, este personaje gana Escurridizo (Evasive). (Pueden desafiar a los personajes con Escurridizo).",
  PROTECTIVE_EMBRACE:
    "Abrazo protector: Tus otros personajes ganan Guarda (Ward). (Los oponentes no pueden elegirlos excepto para desafiarlos).",
  PUPPYNAPPING:
    "Cría de cachorros: Siempre que este personaje explore (Quest), el personaje contrario elegido no podrá explorar (Quest) durante su próximo turno.",
  READ_A_BOOK_MY_FAVOURITE_PART:
    "<1>Lee un libro:</1> Durante tu turno, puedes poner una carta adicional de tu mano en tu tintero boca abajo. <2/><1>Mi parte favorita!:</1> Mientras tengas 10 o más cartas en tu tintero, este personaje obtiene +4 puntos de Leyenda (Lore).",
  ROAR: "Rugido: Cuando juegues este personaje, agota todos los personajes dañados del oponente.",
  ROCK_THE_BOAT_PUNY_PIRATE:
    "<1>Mover el bote:</1> Cuando juegues este personaje, inflige 1 de daño a cada personaje contrario<2/><1>¡Pirata insignificante!:</1> Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, puedes causar 2 de daño al personaje contrario elegido.",
  ROUSING_SPEECH:
    "Discurso conmovedor: Cuando juegues este personaje, prepara tus personajes con un coste 3 o menos. No pueden explorar (Quest) durante el resto de este turno.",
  SEE_THE_FUTURE:
    "Mira el futuro: Agotar - El personaje elegido obtiene +1 punto de Leyenda (Lore) este turno.",
  SEEK_THE_HEART:
    "Busca el corazón: Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, ganas 2 puntos de Leyenda (Lore).",
  SHOW_ME:
    "Muéstrame: Agotar, paga 3 tintas - Si no tienes cartas en tu mano, roba una carta.",
  SINISTER_PLOT:
    "Trama siniestra: Este personaje obtiene +1 punto de Leyenda (Lore) por cada otro personaje Villano que tengas en juego.",
  SINISTER_SLITHER:
    "Deslizamiento siniestro: Tus personajes llamados Flotsam obtienen Escurridizo (Evasive).",
  SKIP_THE_DRAMA_STAY_WITH_MAMA:
    "Deja el drama, quédate con mamá: Mientras este personaje esté agotado, el personaje contrario no puede explorar (Quest).",
  SLASH:
    "Slash: Agotar - El personaje elegido obtiene +1 de Fuerza este turno. Si se elige un personaje llamado Aladdin, obtiene +2 de Fuerza en su lugar.",
  SPEAK: "¡Hablar!: Agotar, paga 4 tintas - Roba una carta",
  STAGE_LITTLE_ACCIDENT:
    "Pequeño accidente de escenario: Siempre que este personaje explore (Quest), puedes infligir 1 daño al personaje elegido.",
  STAY_ALERT:
    "¡Manténgase alerta!: Durante tu turno, tus personajes Mosqueteros obtienen Escurridizo (Evasive). (Pueden desafiar a los personajes con Escurridizo).",
  STOLEN_DUST:
    "Polvo robado: Los personajes con coste 3 o menos no pueden desafiar a este personaje.",
  STRAIGHTEN_HAIR:
    "Alisar el cabello: Agotar - Remueve hasta 1 de daño al personaje elegido.",
  SWEEP:
    "Barrer: Cuando juegas este personaje, puedes barajar una carta de cualquier descarte en el mazo de su jugador.",
  TANGLE_ABILITIES:
    "Enredo: Cuando juegas este personaje, cada oponente pierde 1 punto de Leyenda (Lore).",
  TAKE_A_BITE:
    "Toma un mordisco...: Paga 1 tinta - Desterrar este Objeto (Item) - Agota al personaje elegido. Si se elige un personaje Princesa, destierrala en su lugar.",
  TASTES_LIKE_CHICKEN:
    "Sabe a pollo: Cuando juegas este personaje, puedes remover hasta 1 de daño al personaje elegido.",
  TEA_PARTY:
    "Fiesta del té: Siempre que este personaje sea desafiado, puedes robar una carta.",
  THATS_NO_BLIZZARD:
    "Eso no es una tormenta de nieve: Siempre que juegues a un personaje llamado Anna, prepara este personaje. Este personaje no puede explorar (Quest) durante el resto de este turno.",
  THE_CARDS_WILL_TELL:
    "Las cartas lo dirán: Agotar - Pagas 1 menos de tinta por la siguiente Acción (Action) que juegues este turno.",
  TINKER:
    "Gitano: Cada vez que este personaje explore (Quest), pagas 1 tinta menos por el siguiente Objeto (Item) que juegues en este turno.",
  THIS_IS_NOT_WHO_YOU_ARE:
    "Esto no es lo que eres: Cuando juegas con este personaje, puedes desterrar al personaje elegido llamado Te Ka.",
  UNDER_ARREST:
    "Bajo arresto: Los personajes con coste 2 o menos no pueden desafiar a tus personajes.",
  VOICELESS:
    "Sin voz: Este personaje no puede agotarse para cantar Canciones (Action - Song).",
  WE_CAN_FIX_IT:
    "Lo podemos arreglar: Siempre que este personaje explore (Quest), puedes preparar a tus otros personajes de Princesa. No pueden explorar (Quest) durante el resto de este turno.",
  WELL_OF_SOULS:
    "Pozo de almas: Cuando juegues este personaje, devuelve a tu mano una carta de personaje de tu descarte.",
  WHAT_HAVE_WE_HERE_GLAM:
    "<1>¿Qué tenemos aquí?:</1> Cuando juegas este personaje y cada vez que explore (Quest), puedes devolver una carta Objeto (Item) de tu descarte a tu mano. <2/><1>Glamour:</1> Este personaje obtiene +1 punto de Leyenda (Lore) por cada Objeto (Item) que tengas en juego.",
  WONDER_BOY:
    "Chico maravilla: Cuando juegas este personaje, el personaje elegido obtiene +2 de Fuerza este turno.",
  YOU_COWARD:
    "¡Cobarde!: Mientras este personaje esté agotado, los personajes oponentes con Escurridizo (Evasive) obtienen Temerario (Reckless). (No pueden explorar (Quest) y deben desafiar si pueden).",
  YOU_GOT_A_PROBLEM:
    "Tienes un problema: Agotar - El personaje elegido gana Temerario (Reckless) durante su próximo turno. (No pueden explorar (Quest) y deben desafiar si pueden).",
  YOULL_BE_SORRY:
    "Te vas a arrepentir: Cuando este personaje es desafiado y desterrado, puedes devolver el personaje elegido a la mano de su jugador.",
  YOURE_EXCUSED:
    "Estas excusado: Siempre que este personaje explore (Quest), mira la carta superior de tu mazo. Colócalo en la parte superior o inferior de tu mazo.",

  // BODY TEXT set 1    _ACTION_BODY_TEXT: "",             _ACTION_SONG_BODY_TEXT: "",
  A_WHOLE_NEW_WORLD_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 5 o más puede agotarse para cantar esta canción de forma gratuita.) Cada jugador descarta su mano y roba 7 cartas.",
  BEFUDDLE_ACTION_BODY_TEXT:
    "Devuelve un personaje o un objeto (item) con coste 2 o menos a la mano de su jugador.",
  BE_PREPARED_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 7 o más puede agotarse para cantar esta canción de forma gratuita.) Destierra a todos los personajes.",
  BE_OUR_GUEST_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 2 o más puede agotarse para cantar esta canción de forma gratuita.) Mira las 4 cartas superiores de tu mazo. Puedes revelar una carta de personaje y ponerla en tu mano. Pon el resto en la parte inferior de tu mazo en el orden que desees.",
  BREAK_ACTION_BODY_TEXT: "Destierra el objeto elegido.",
  CONTROL_YOUR_TEMPER_ACTION_BODY_TEXT:
    "El personaje elegido obtiene -2 de fuerza en este turno.",
  CUT_TO_THE_CHASE_ACTION_BODY_TEXT:
    "El personaje elegido obtiene Prisa (Rush) en este turno. (Puede desafiar en el turno en el que se juega.)",
  DEVELOP_YOUR_BRAIN_ACTION_BODY_TEXT:
    "Mira las 2 cartas superiores de tu mazo. Pon una en tu mano y la otra en la parte inferior del mazo.",
  DO_IT_AGAIN_ACTION_BODY_TEXT:
    "Devuelve una carta de acción (action) de tu descarte a tu mano.",
  DRAGON_FIRE_ACTION_BODY_TEXT: "Destierra al personaje elegido.",
  FAN_THE_FLAMES_ACTION_BODY_TEXT:
    "Prepara al personaje elegido. No puede explorar (Quest) durante el resto de este turno.",
  FIRE_THE_CANNONS_ACTION_BODY_TEXT: "Inflige 2 de daño al personaje elegido.",
  FREEZE_ACTION_BODY_TEXT: "Agota al personaje oponente elegido.",
  FRIENDS_ON_THE_OTHER_SIDE_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 3 o más puede agotarse para cantar esta canción de forma gratuita.) Roba 2 cartas.",
  GRAB_YOUR_SWORD_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 5 o más puede agotarse para cantar esta canción de forma gratuita.) Inflige 2 de daño a cada personaje oponente.",
  HAKUNA_MATATA_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 4 o más puede agotarse para cantar esta canción de forma gratuita.) Remueve hasta 3 puntos de daño de cada uno de tus personajes.",
  HEALING_GLOW_ACTION_BODY_TEXT:
    "Elimina hasta 2 puntos de daño del personaje elegido.",
  HES_GOT_A_SWORD_ACTION_BODY_TEXT:
    "El personaje elegido obtiene +2 de fuerza en este turno.",
  IF_ITS_NOT_BAROQUE_ACTION_BODY_TEXT:
    "Devuelve una carta de objeto (item) de tu descarte a tu mano.",
  JUST_IN_TIME_ACTION_BODY_TEXT:
    "Puedes jugar un personaje con coste 5 o menos de forma gratuita.",
  LET_IT_GO_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 5 o más puede agotarse para cantar esta canción de forma gratuita.) Poner al personaje elegido en el tintero de su jugador boca abajo y agotado.",
  MOTHER_KNOWS_BEST_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 3 o más puede agotarse para cantar esta canción de forma gratuita.) Devuelve al personaje elegido a la mano de su jugador.",
  ONE_JUMP_AHEAD_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 2 o más puede agotarse para cantar esta canción de forma gratuita.) Pon la carta superior de tu mazo en tu tintero boca abajo y agotado.",
  PART_OF_YOUR_WORLD_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 3 o más puede agotarse para cantar esta canción de forma gratuita.) Devuelve una carta de personaje de tu descarte a tu mano.",
  RANSACK_ACTION_BODY_TEXT: "Roba 2 cartas, luego elige y descarta 2 cartas.",
  REFLECTION_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 2 o más puede agotarse para cantar esta canción de forma gratuita.) Mira las 3 cartas superiores de tu mazo. Colócalas de nuevo en la parte superior de tu mazo en el orden que desees.",
  SMASH_ACTION_BODY_TEXT: "Inflige 3 puntos de daño al personaje elegido.",
  STAMPEDE_ACTION_BODY_TEXT:
    "Inflige 2 puntos de daño al personaje dañado elegido.",
  STEAL_FROM_THE_RICH_ACTION_BODY_TEXT:
    "Cada vez que uno de tus personajes explore (Quest) en este turno, cada oponente pierde 1 punto de Leyenda (Lore).",
  SUDDEN_CHILL_ACTION_SONG_BODY_TEXT:
    "(El personaje con coste 2 o más puede agotarse para cantar esta canción de forma gratuita.) Cada oponente elige y descarta una carta.",
  TANGLE_ACTION_BODY_TEXT: "Cada oponente pierde 1 punto de Leyenda (Lore).",
  THE_BEAST_IS_MINE_ACTION_BODY_TEXT:
    "El personaje elegido obtiene Temerario (Reckless) durante su próximo turno. (No puede explorar (Quest) y debe desafiar si puede).",
  VICIOUS_BETRAYAL_ACTION_BODY_TEXT:
    "El personaje elegido obtiene +2 de fuerza en este turno. Si se elige un personaje Villano, obtienen +3 de fuerza en su lugar.",
  YOU_HAVE_FORGOTTEN_ME_ACTION_BODY_TEXT:
    "Cada oponente elige y descarta 2 cartas.",
  WORK_TOGETHER_ACTION_BODY_TEXT:
    "El personaje elegido obtiene Apoyo (Support) en este turno. (Cuando explore (Quest), puedes agregar su fuerza a la fuerza de otro personaje elegido en este turno).",

  // FLAVOR set 1   “”    TEXT _FLAVOR_TEXT: "",     _ITEM_FLAVOR_TEXT: "",       _ACTION_FLAVOR_TEXT: "",     _ACTION_SONG_FLAVOR_TEXT: "",
  ABU_MISCHIEVOUS_MONKEY_FLAVOR_TEXT:
    "“Algún día, Abu, las cosas van a cambiar. Seremos ricos, viviremos en un palacio y nunca tendremos ningún problema en absoluto.” -Aladdín",
  ALADDIN_CORNERED_SWORDSMAN_FLAVOR_TEXT:
    "“Oh, ho! ¡Así que el chico de la calle encontró una espada y una columna vertebral!” -Razoul",
  ALADDIN_PRINCE_ALI_FLAVOR_TEXT:
    "Fabulosamente rico. Prácticamente intocable. Genuinamente inauténtico.",
  ALADDIN_STREET_RAT_FLAVOR_TEXT:
    "Puede ser difícil distinguir entre un diamante en bruto y alguien que es simplemente, bueno, tosco.",
  ANNA_HEIR_TO_ARENDELLE_FLAVOR_TEXT: "“Dos hermanas, una mente.”",
  ARCHIMEDES_HIGHLY_EDUCATED_OWL_FLAVOR_TEXT:
    "“Volar no es simplemente un proceso mecánico. Es un arte delicado. Estética pura. Poesía en movimiento. Y la mejor manera de aprenderlo es hacerlo.”",
  ARIEL_ON_HUMAN_LEGS_FLAVOR_TEXT: "“. . .”",
  ARIEL_WHOSEIT_COLLECTOR_FLAVOR_TEXT: "“¿Quieres cachivaches? Tengo veinte.”",
  AURORA_BRIAR_ROSE_FLAVOR_TEXT:
    "“Había algo extraño en esa voz. Demasiado hermosa para ser real . . . ” -Príncipe Felipe",
  AURORA_DREAMING_GUARDIAN_FLAVOR_TEXT:
    "Mientras la princesa dormía, su poder despertaba.",
  AURORA_REGAL_PRINCESS_FLAVOR_TEXT:
    "“Dicen que si sueñas algo más de una vez, seguro se cumplirá.”",
  A_WHOLE_NEW_WORLD_ACTION_SONG_FLAVOR_TEXT:
    "Brillante, reluciente, espléndido . . .",
  BEAST_HARDHEADED_FLAVOR_TEXT:
    "“Ella nunca me verá como algo más que un monstruo.”",
  BEAST_MIRROR_FLAVOR_TEXT:
    "Avergonzado de su forma monstruosa, la Bestia se ocultó dentro de su castillo, con un espejo mágico como su única ventana al mundo exterior.",
  BEAST_WOLFSBANE_FLAVOR_TEXT:
    "“¡Me enfrentaré a todos ustedes si es necesario!”",
  BEFUDDLE_ACTION_FLAVOR_TEXT:
    "Nunca temas que tu mente se desconcierte de vez en cuando.",
  BELLE_INVENTIVE_ENGINEER_FLAVOR_TEXT:
    "Un poco de ingenio y mucho corazón te llevarán lejos en este mundo.",
  BELLE_STRANGE_BUT_SPECIAL_FLAVOR_TEXT:
    "“Lugares lejanos, duelos de espadas audaces, hechizos mágicos, un príncipe disfrazado . . .”",
  BE_PREPARED_ACTION_SONG_FLAVOR_TEXT:
    "Nuestros dientes y ambiciones están al descubierto.",
  BREAK_ACTION_FLAVOR_TEXT: "Nadie lanza un berrinche como una bestia.",
  CAPTAIN_COLONELS_LIEUTENANT_FLAVOR_TEXT:
    "“Señal de ladridos. Es una alerta. ¡Informe al Coronel de inmediato!”",
  CAPTAIN_HOOK_FORCEFUL_DUELIST_FLAVOR_TEXT:
    "Le encanta burlarse de la situación de un enemigo.",
  CAPTAIN_HOOK_CAPTAIN_OF_THE_JOLLY_ROGER_FLAVOR_TEXT:
    "“Una vista hermosa, Sr. Smee. Los atraparemos como patos sentados.”",
  CAPTAIN_HOOK_RUTHLESS_PIRATE_FLAVOR_TEXT:
    "“No te atreverías a pelear contra el viejo Hook de hombre a hombre.”",
  CERBERUS_THREE_HEADED_DOG_FLAVOR_TEXT:
    "Siempre vigilante. Siempre feroz. Siempre peleando por la pelota.",
  CHESHIRE_CAT_NOT_ALL_THERE_FLAVOR_TEXT:
    "“Puede que hayas notado que no estoy del todo aquí.”",
  CHIEF_TUI_RESPECTED_LEADER_FLAVOR_TEXT:
    "“Siempre puedes contar con la fuerza de quienes te aman.”",
  COCONUT_BASKET_ITEM_FLAVOR_TEXT:
    "El coco es un regalo versátil de los dioses, utilizado para hacer casi todo, incluyendo cestas para llevar más cocos.",
  CRUELLA_DE_VIL_MISERABLE_AS_USUAL_FLAVOR_TEXT:
    "Cuando ella aparece, la miseria es su compañía.",
  CUT_TO_THE_CHASE_ACTION_FLAVOR_TEXT: "“¡Sorpresa!”",
  DEVELOP_YOUR_BRAIN_ACTION_FLAVOR_TEXT:
    "“El conocimiento, la sabiduría, ahí está el verdadero poder.” -Merlín",
  DINGLEHOPPER_ITEM_FLAVOR_TEXT:
    "Disfruta de los mejores estilos de cabello humano.",
  DO_IT_AGAIN_ACTION_FLAVOR_TEXT:
    "“. . . Luego lava la terraza, barre los pasillos y las escaleras, limpia las chimeneas. Y, por supuesto, está la reparación, la costura y la lavandería . . . ” -Lady Tremaine",
  DONALD_DUCK_BOISTEROUS_FOWL_FLAVOR_TEXT: "“¿A quién llamas ruidoso, pillo?”",
  DONALD_DUCK_STRUTTING_HIS_STUFF_FLAVOR_TEXT:
    "Camina con inteligencia, no con fuerza.",
  DRAGON_FIRE_ACTION_FLAVOR_TEXT:
    "Raro es el héroe que puede resistir la ira de un dragón.",
  DR_FACILIERS_CARDS_ITEM_FLAVOR_TEXT:
    "“¡Toma un pequeño viaje a tu futuro conmigo!” -Dr. Facilier",
  DR_FACILIER_CHARLATAN_FLAVOR_TEXT: "“Encantado. Un saludo del Dr. Facilier.”",
  DUKE_OF_WESELTON_PPORTUNISTIC_OFFICIAL_FLAVOR_TEXT:
    "“¡Hechicería! Sabía que algo dudoso estaba pasando aquí.”",
  ELSA_ICE_SURFER_FLAVOR_TEXT:
    "“Mi hermana siempre ha estado ahí para mí. Tengo que estar ahí para ella.”",
  ELSA_QUEEN_REGENT_FLAVOR_TEXT: "“Nunca supe de lo que era capaz.”",
  ELSA_SNOW_QUEEN_FLAVOR_TEXT:
    "Recreada por tinta mágica, Elsa se encontró en un nuevo mundo desconocido. Afortunadamente, el hielo funciona de la misma manera en todas partes.",
  ELSA_SPIRIT_OF_WINTER_FLAVOR_TEXT:
    "“El hielo es más fuerte de lo que puedas pensar.”",
  EYE_OF_THE_FATES_ITEM_FLAVOR_TEXT:
    "Puedes cambiar el futuro una vez que sepas lo que estás buscando.",
  FAN_THE_FLAMES_ACTION_FLAVOR_TEXT:
    "Las palabras bonitas pueden mover a una multitud, pero también pueden hacerlo las feas.",
  FIRE_THE_CANNONS_ACTION_FLAVOR_TEXT:
    "Capitán Hook: “¡Duplica la pólvora y acorta la mecha!.“ Sr. Smee: ¡Acorte la pólvora y duplique la mecha!”",
  FISHBONE_QUILL_ITEM_FLAVOR_TEXT:
    "“Si quieres cruzar el puente, mi dulce, debes pagar el peaje.” - Úrsula",
  FLOTSAM_URSULAS_SPY_FLAVOR_TEXT:
    "“Sabemos de alguien que puede ayudarte... por un precio.”",
  FLOUNDER_VOICE_OF_REASON_FLAVOR_TEXT:
    "“Emoción... aventura... peligro acechando en cada esqui- ¡AAAAGGH!”",
  FLYNN_RIDER_CHARMING_ROGUE_FLAVOR_TEXT:
    "“No quería tener que hacer esto, pero no me dejas otra opción...”",
  FREEZE_ACTION_FLAVOR_TEXT: "Es hora de que te enfríes.",
  FRIENDS_ON_THE_OTHER_SIDE_ACTION_SONG_FLAVOR_TEXT:
    "Las cartas, las cartas, las cartas lo dirán...",
  FRYING_PAN_ITEM_FLAVOR_TEXT:
    "Es una pieza de utensilio de cocina excelente, pero como arma es verdaderamente impresionante.",
  GANTU_GALACTIC_FEDERATION_CAPTAIN_FLAVOR_TEXT:
    "“Relájate, disfruta del viaje... ¡y no se te ocurra tener ideas!”",
  GASTON_ARROGANT_HUNTER_FLAVOR_TEXT:
    "No es arrogancia cuando realmente eres el mejor.",
  GENIE_ON_THE_JOB_FLAVOR_TEXT:
    "“¿Pueden tus amigos decir '¡Abracadabra, déjalo salir!' y luego hacer que el tonto desaparezca?”",
  GENIE_THE_EVER_IMPRESSIVE_FLAVOR_TEXT:
    "“¡Puedes desear casi cualquier cosa! ¿Quieres la versión corta o debería darte toda la canción y baile?”",
  GOOFY_DAREDEVIL_FLAVOR_TEXT: "A veces tienes que darle un salto y ¡juaj!",
  GOOFY_MUSKETEER_FLAVOR_TEXT: "“¡En gawrsh!”",
  GOONS_MALEFICENTS_UNDERLINGS_FLAVOR_TEXT:
    "Pueden parecer inútiles, pero vinieron con el castillo.",
  GRAB_YOUR_SWORD_ACTION_SONG_FLAVOR_TEXT:
    "No nos gusta lo que no entendemos. De hecho, nos asusta.",
  GRAMMA_TALA_STORYTELLER_FLAVOR_TEXT:
    "Moana: “¿Hay algo que quieras decirme?” Gramma Tala: “¿Hay algo que quieras oír?”",
  HADES_INFERNAL_SCHEMER_FLAVOR_TEXT:
    "“Él debe tener una debilidad, porque todo el mundo tiene una debilidad.”",
  HADES_KING_OF_OLYMPUS_FLAVOR_TEXT:
    "Oh, hola, voy a necesitar nuevas tarjetas de presentación.",
  HADES_LORD_OF_THE_UNDERWORLD_FLAVOR_TEXT:
    "“La producción está en alza, los costos están bajando, los ríos están llenos. Es hora de hablar de expansión.”",
  HAKUNA_MATATA_ACTION_SONG_FLAVOR_TEXT: "¡Qué frase tan maravillosa!",
  HANS_SCHEMING_PRINCE_FLAVOR_TEXT:
    "“Las reglas son como hermanos mayores. Todo lo que hacen es ponerse en el camino.”",
  HANS_THIRTEENTH_IN_LINE_FLAVOR_TEXT:
    "Cansado de estar al final, decidió saltarse la fila.",
  HEALING_GLOW_ACTION_FLAVOR_TEXT: "“¡No te asustes!” - Rapunzel",
  HEIHEI_BOAT_SNACK_FLAVOR_TEXT:
    "“A veces, nuestras fortalezas yacen bajo la superficie. Muy por debajo, en algunos casos...” - Moana",
  HERCULES_TRUE_HERO_FLAVOR_TEXT:
    "“Tienes que admitir que fue bastante heroico.”",
  HES_GOT_A_SWORD_ACTION_FLAVOR_TEXT: "“¡Todos tenemos espadas!” - Razoul",
  HORACE_NO_GOOD_SCOUNDREL_FLAVOR_TEXT: "“Bueno, no están aquí, Jasper.”",
  IF_ITS_NOT_BAROQUE_ACTION_FLAVOR_TEXT: "“... No lo arregles.”",
  JAFAR_KEEPER_OF_SECRETS_FLAVOR_TEXT:
    "Hay más de una forma de enterrar secretos.",
  JAFAR_WICKED_SORCERER_FLAVOR_TEXT:
    "“Basta de andar escondiéndose. Es hora de mostrarle a ese sultán lloriqueante lo que un hechicero puede hacer.”",
  JASMINE_DISGUISED_FLAVOR_TEXT:
    "“Trata de entenderlo. Nunca he hecho nada por mi cuenta. Nunca he tenido amigos de verdad... ni siquiera he salido de los muros del palacio.”",
  JASPER_COMMON_CROOK_FLAVOR_TEXT:
    "“Ahora mira aquí, Horacio, te advertí sobre pensar demasiado.”",
  JETSAM_URSULAS_SPY_FLAVOR_TEXT:
    "“Podemos ayudarte a conseguir cualquier cosa que desees...”",
  JOHN_SILVER_ALIEN_PIRATE_FLAVOR_TEXT:
    "“No te sientas muy intimidado por este... pedazo de maquinaria.”",
  JUST_IN_TIME_ACTION_FLAVOR_TEXT:
    "Los mejores héroes siempre llegan en el momento perfecto, ya sea que lo sepan o no.",
  KRISTOFF_OFFICIAL_ICE_MASTER_FLAVOR_TEXT:
    "Kristoff: “¿Quieres hablar de un problema de oferta y demanda? Vendo hielo para vivir.” Anna: “Oh, eso es un negocio difícil en este momento. Quiero decir, eso es realmente... ah, mm. Es desafortunado.”",
  KRONK_RIGHT_HAND_MAN_FLAVOR_TEXT:
    "“Oh, sí. Todo está encajando perfectamente.”",
  KUZCO_TEMPERAMENTAL_EMPEROR_FLAVOR_TEXT:
    "“Pedí esmeralda y eso es claramente jade. ¿Qué les pasa a ustedes, gente?”",
  LADY_TREMAINE_WICKED_STEPMOTHER_FLAVOR_TEXT:
    "“Si tus quehaceres están hechos, entonces claramente tú...”",
  LANTERN_ITEM_FLAVOR_TEXT:
    "Las linternas llenan el cielo en una noche especial, faros de esperanza y amor.",
  LEFOU_BUMBLER_FLAVOR_TEXT:
    "Necesitas un buen adulador para ser un malvado apropiado.",
  LEFOU_INSTIGATOR_FLAVOR_TEXT:
    "Todo lo que necesita una turba es un empujón en la dirección equivocada.",
  LET_IT_GO_ACTION_SONG_FLAVOR_TEXT:
    "Es hora de ver lo que puedo hacer. De poner a prueba los límites y romper barreras.",
  LILO_GALACTIC_HERO_FLAVOR_TEXT:
    "“El espacio. Ahí es de donde vienen los extraterrestres. ¡Y también los turistas!”",
  LILO_MAKING_A_WISH_FLAVOR_TEXT:
    "“Una estrella fugaz... ¡tengo que pedir un deseo!”",
  MAD_HATTER_GRACIOUS_HOST_FLAVOR_TEXT:
    "Sombrerero Loco: “¿Te gustaría un poco más de té?” Alicia: “Todavía no he tomado nada, así que no puedo tomar más.”",
  MAGIC_BROOM_BUCKET_BRIGADE_FLAVOR_TEXT:
    "En la inmensa forja de historias conocida como la Gran Iluminaria, siempre hay trabajo por hacer.",
  MAGIC_GOLDEN_FLOWER_ITEM_FLAVOR_TEXT:
    "“Había una vez, una sola gota de luz solar cayó del cielo...” - Flynn Rider",
  MAGIC_MIRROR_ITEM_FLAVOR_TEXT: "“¿Qué deseas saber, mi Reina?”",
  MALEFICENT_BIDING_HER_TIME_FLAVOR_TEXT:
    "“No se debe apresurar estas cosas, o el mejor plan podría quedar en nada.”",
  MALEFICENT_MONSTROUS_DRAGON_FLAVOR_TEXT:
    "La novena Regla de la Villanía: Cuando todo lo demás falla, conviértete en un dragón.",
  MALEFICENT_SINISTER_VISITOR_FLAVOR_TEXT:
    "“La princesa ciertamente crecerá en gracia y belleza, amada por todos los que la conozcan. Pero antes de que el sol se ponga en su decimosexto cumpleaños, se pinchará el dedo con la huso de una rueca...”",
  MALEFICENT_SORCERESS_FLAVOR_TEXT:
    "“¿Te atreves a desafiarme? Tonto, mi magia es más poderosa de lo que podrías imaginar.”",
  MALEFICENT_UNINVITED_FLAVOR_TEXT:
    "No tenía invitación y no necesitaba presentación.",
  MARSHMALLOW_PERSISTENT_GUARDIAN_FLAVOR_TEXT:
    "“¡Oye! Estábamos hablando de ti. Todas cosas buenas, todas cosas buenas.” - Olaf",
  MAUI_DEMIGOD_FLAVOR_TEXT:
    "Cuando los dioses te regalan un barco, lo tomas. El dueño del barco es opcional.",
  MAUI_HERO_TO_ALL_FLAVOR_TEXT:
    "“Lo que creo que estabas tratando de decir es 'Gracias'.”",
  MAXIMUS_RELENTLESS_PURSUER_FLAVOR_TEXT:
    "Persigue a su presa con valentía, disciplina y un toque de clase.",
  MEGARA_PULLING_THE_STRINGS_FLAVOR_TEXT:
    "“Un trato es un trato. Pero enamorarse nunca debió ser parte de él.”",
  MICKEY_MOUSE_ARTFUL_ROGUE_FLAVOR_TEXT:
    "Silencioso como un... bueno, ya sabes.",
  MICKEY_MOUSE_BRAVE_LITTLE_TAILOR_FLAVOR_TEXT:
    "Cuando la derrota se avecina y la victoria pende de un hilo, un héroe corre al rescate, arreglando las cosas a través de una determinación pura.",
  MICKEY_MOUSE_DETECTIVE_FLAVOR_TEXT:
    "De donde sea que viniera el alga marina, Mickey estaba seguro de una cosa: algo sospechoso estaba ocurriendo.",
  MICKEY_MOUSE_STEAMBOAT_PILOT_FLAVOR_TEXT:
    "En los ríos de todo el País de Tinta, el silbato del pequeño barco de vapor responde a las alegres melodías de su piloto.",
  MICKEY_MOUSE_TRUE_FRIEND_FLAVOR_TEXT:
    "Mientras él esté cerca, los recién llegados a la Gran Iluminaria siempre recibirán una cálida bienvenida.",
  MICKEY_MOUSE_WAYWARD_SORCERER_FLAVOR_TEXT:
    "Siempre va por la victoria completa.",
  MINNIE_MOUSE_ALWAYS_CLASSY_FLAVOR_TEXT:
    "Su sentido de la moda siempre está impecable.",
  MINNIE_MOUSE_BELOVED_PRINCESS_FLAVOR_TEXT:
    "Dondequiera que vaya la princesa, sus mosqueteros están... bueno, están por ahí en algún lugar, probablemente.",
  MOANA_CHOSEN_BY_THE_OCEAN_FLAVOR_TEXT: "“Sabes quién eres.”",
  MOANA_OF_MOTUNUI_FLAVOR_TEXT:
    "“Soy Moana de Motunui. Subirás a mi barco, navegarás por el mar, y restaurarás el corazón de Te Fiti.”",
  MOTHER_GOTHEL_SELFISH_MANIPULATOR_FLAVOR_TEXT: "“Genial. Ahora soy el malo.”",
  MOTHER_KNOWS_BEST_ACTION_SONG_FLAVOR_TEXT:
    "De una forma u otra, algo saldrá mal, lo juro.",
  MR_SMEE_LOYAL_FIRST_MATE_FLAVOR_TEXT:
    "“El Sr. Smee es un alma amable y gentil que vive para llevar consuelo y ayuda a un retorcido viejo villano. Ahora, ¿de qué sirve la amabilidad como esa?” - Peter Pan",
  MUFASA_KING_OF_THE_PRIDE_LANDS_FLAVOR_TEXT:
    "“Un rey debe cuidar a todas las criaturas de su reino, sin importar su tamaño.”",
  MUSKETEER_TABARD_ITEM_FLAVOR_TEXT:
    "No existe tal cosa como un mosquetero solitario.",
  OLAF_FRIENDLY_SNOWMANFLAVOR_TEXT:
    "“¡Soy Olaf y me gustan los abrazos cálidos!”",
  ONE_JUMP_AHEAD_ACTION_SONG_FLAVOR_TEXT:
    "Tengo que comer para vivir, tengo que robar para comer - Te lo contaré todo cuando tenga tiempo.",
  PART_OF_YOUR_WORLD_ACTION_SONG_FLAVOR_TEXT:
    "¿Qué daría yo si pudiera vivir fuera de estas aguas?",
  PASCAL_RAPUNZELS_COMPANION_FLAVOR_TEXT:
    "Un verdadero amigo siempre está ahí para ti, ya sea que puedas verlos o no.",
  PETER_PAN_FEARLESS_FIGHTER_FLAVOR_TEXT:
    "“¡Nadie llama cobarde a Pan y vive! Te pelearé de hombre a hombre, con una mano detrás de mi espalda.”",
  PETER_PAN_NEVER_LANDING_FLAVOR_TEXT: "“¿Qué pasa, Hook? ¿No puedes volar?”",
  PHILOCTETES_TRAINER_OF_HEROES_FLAVOR_TEXT:
    "“Tienes que ser el mejor para entrenar a los mejores. ¡Y yo entreno a los mejores!”",
  PLASMA_BLASTER_ITEM_FLAVOR_TEXT:
    "“No tienes que decir 'pew pew' cuando lo uses, pero no duele.” - Lilo, heroína galáctica",
  POISONED_APPLE_ITEM_FLAVOR_TEXT:
    "“Un solo bocado de la manzana envenenada, y los ojos de la víctima se cerrarán para siempre...” - La Reina",
  PRINCE_ERIC_DASHING_AND_BRAVE_FLAVOR_TEXT:
    "“¡La perdí una vez! ¡No la volveré a perder!”",
  PRINCE_PHILLIP_DRAGONSLAYER_FLAVOR_TEXT:
    "“El camino hacia el amor verdadero puede estar bloqueado por muchos peligros más, que solo tú tendrás que enfrentar.” - Flora",
  PUMBAA_FRIENDLY_WARTHOG_FLAVOR_TEXT: "“Tienes que dejar atrás tu pasado.”",
  RAFIKI_MISCHIEVOUS_MONKEY_FLAVOR_TEXT:
    "“El pasado puede herir. Pero la forma en que lo veo, puedes correr de él o aprender de él.”",
  RANSACK_ACTION_FLAVOR_TEXT: "¿Quién tiene tiempo para leer etiquetas?",
  RAPUNZEL_LETTING_DOWN_HER_HAIR_FLAVOR_TEXT:
    "“¿Quién eres? ¿Y cómo me encontraste?”",
  REFLECTION_ACTION_SONG_FLAVOR_TEXT:
    "¿Cuándo mostrará mi reflejo quién soy por dentro?",
  ROBIN_HOOD_UNRIVALED_ARCHER_FLAVOR_TEXT:
    "“Nunca robamos. Solo tomamos prestado un poco de aquellos que pueden pagarlo.”",
  SCAR_FIERY_USURPER_FLAVOR_TEXT: "Consumido por las llamas de la ambición.",
  SCAR_MASTERMIND_FLAVOR_TEXT:
    "“Los mejores planes involucran un poco de peligro. Solo que no para mí.”",
  SCAR_SHAMELESS_FIREBRAND_FLAVOR_TEXT:
    "“Ha llegado el momento de tomar lo que es nuestro.”",
  SEBASTIAN_COURT_COMPOSER_FLAVOR_TEXT:
    "“Debería estar escribiendo sinfonías, no siguiendo a una adolescente testaruda.”",
  SERGEANT_TIBBS_COURAGEOUS_CAT_FLAVOR_TEXT:
    "“Sí, señor. Enseguida, señor. De inmediato, señor...”",
  SHIELD_OF_VIRTUE_ITEM_FLAVOR_TEXT:
    "“Equípate con este Escudo encantado de Virtud y esta poderosa Espada de la Verdad, porque estas armas de rectitud triunfarán sobre el mal.” - Flora",
  SIMBA_FUTURE_KING_FLAVOR_TEXT:
    "“Voy a ser el mejor rey que las Tierras del Orgullo hayan visto.”",
  SIMBA_PROTECTIVE_CUB_FLAVOR_TEXT: "El valor viene en todas las tallas.",
  SIMBA_RETURNED_KING_FLAVOR_TEXT:
    "“Haré lo que sea necesario para salvar mi reino.”",
  SIMBA_RIGHTFUL_HEIR_FLAVOR_TEXT:
    "“Ya no puedo esconderme más. Es hora de aceptar mi destino.”",
  SMASH_ACTION_FLAVOR_TEXT: "“¡Vete!”",
  STAMPEDE_ACTION_FLAVOR_TEXT:
    "Una estampida de ñus es como un río furioso: mejor experimentado desde la distancia.",
  STARKEY_HOOKS_HENCHMAN_FLAVOR_TEXT:
    "Un pirata debe ser duro, leal y fuerte. 'Inteligente' ni siquiera está en la lista.",
  STEAL_FROM_THE_RICH_ACTION_FLAVOR_TEXT:
    "“Me pregunto cuánto gastó el príncipe Juan en todas esas cerraduras elegantes.” - Little John",
  STITCH_ABOMINATION_FLAVOR_TEXT:
    "“Su programación destructiva está surtiendo efecto. Será irresistiblemente atraído hacia las grandes ciudades, donde obstruirá alcantarillas, cambiará las señales de tráfico y robará el zapato izquierdo de todos.” - Jumba Jookiba",
  STITCH_CAREFREE_SURFER_FLAVOR_TEXT:
    "“¿Así que eres de otro espacio, ¿eh? Escuché que el surf es excelente.” - David",
  STITCH_NEW_DOG_FLAVOR_TEXT:
    "Lilo: “¡David! ¡Conseguí un nuevo perro!” David: “¡Auwe!... ¿Estás segura de que es un perro?” Lilo: “Sí. Solía ser un collie antes de ser atropellado.”",
  STITCH_ROCK_STAR_FLAVOR_TEXT:
    "La mejor parte de un concierto junto a la playa es que siempre hay espacio para uno más.",
  STOLEN_DUST_ITEM_FLAVOR_TEXT:
    "A veces, tienes que tomar lo que puedes conseguir.",
  SUDDEN_CHILL_ACTION_SONG_FLAVOR_TEXT:
    "Cruella De Vil, Cruella De Vil, si ella no te asusta, nada malvado lo hará.",
  SVEN_OFFICIAL_ICE_DELIVERER_FLAVOR_TEXT:
    "“¡Renos abriéndose paso!” - Kristoff",
  SWORD_OF_TRUTH_ITEM_FLAVOR_TEXT:
    "Casi tan poderosa como el beso del Amor Verdadero.",
  TAMATOA_DRAB_LITTLE_CRAB_FLAVOR_TEXT:
    "“Algún día, creceré para ser el cangrejo más fabuloso que el mundo haya visto.”",
  TAMATOA_SO_SHINY_FLAVOR_TEXT:
    "“¡Mírame deslumbrar como un diamante en bruto!”",
  TANGLE_ACTION_FLAVOR_TEXT:
    "“¡Quédate aquí mismo! Quiero decir, supongo que no tienes elección. ¡Pero aún así! ¡No te muevas!” - Rapunzel",
  TE_KA_HEARTLESS_FLAVOR_TEXT:
    "Maui: “¿Alguna vez has derrotado a un monstruo de lava?” Moana: “No. ¿Y tú?”",
  TE_KA_THE_BURNING_ONE_FLAVOR_TEXT: "Ella arde por lo que le fue robado.",
  THE_BEAST_IS_MINE_ACTION_FLAVOR_TEXT:
    "“Es justo que el mejor cazador obtenga la bestia más feroz.” - Gaston",
  THE_QUEEN_WICKED_AND_VAIN_FLAVOR_TEXT:
    "Sublime belleza acompañada de astucia sin igual. ¿Hay alguna duda sobre quién es la más bella?",
  THE_WARDROBE_BELLES_CONFIDANT_FLAVOR_TEXT:
    "“Cuando simplemente debes tener la alta costura más elegante.”",
  TIGGER_WONDERFUL_THING_FLAVOR_TEXT:
    "“¡Soy el saltarín más saltarín que jamás haya saltado!”",
  TIMON_GRUB_RUSTLER_FLAVOR_TEXT:
    "“Hay toda clase de delicias sabrosas en el mundo, solo tienes que saber dónde buscar.”",
  TINKER_BELL_TINY_TACTICIAN_FLAVOR_TEXT:
    "A veces, todo lo que necesitas es un poco de genialidad táctica.",
  TRITON_THE_SEA_KING_FLAVOR_TEXT:
    "“¿No es 'Porque lo dije' suficiente razón?”",
  YOU_HAVE_FORGOTTEN_ME_ACTION_FLAVOR_TEXT:
    "“Eres más de lo que has llegado a ser.” - Mufasa",
  URSULAS_CAULDRON_ITEM_FLAVOR_TEXT:
    "Perfecta para mezclar pociones y robar voces.",
  URSULA_POWER_HUNGRY_FLAVOR_TEXT:
    "La primera Regla de la Villanía: si vas a ser malvado, debes tener estilo.",
  URSULAS_SHELL_NECKLACE_ITEM_FLAVOR_TEXT:
    "“Cantar es un pasatiempo encantador... si tienes la voz para ello.” - Úrsula",
  VICIOUS_BETRAYAL_ACTION_FLAVOR_TEXT:
    "“Un verdadero rey toma las riendas con sus propias garras.” - Scar",
  WHITE_RABBITS_POCKET_WATCH_ITEM_FLAVOR_TEXT:
    "“No es de extrañar que llegues tarde. Este reloj está exactamente dos días atrasado.” - El Sombrerero Loco",
  WORK_TOGETHER_ACTION_FLAVOR_TEXT:
    "Pacha: “¡Ponle todo tu esfuerzo!” Kuzco: “¡Esto es todo mi esfuerzo!”",
  YZMA_ALCHEMIST_FLAVOR_TEXT: "“Cuando quiera tu opinión, te la daré.”",
  ZEUS_GOD_OF_LIGHTNING_FLAVOR_TEXT:
    "“Un poco de rayos resuelve muchos problemas.”",

  // KEYWORDS TEXT set 1    CHALLENGER_X, SHIFT_X, SINGER_X
  ALADDIN_HEROIC_OUTLAW_SHIFT_TEXT:
    "5 (Shift) (Puedes pagar 5 tintas para jugar esto encima de uno de tus personajes llamado Aladdin).",
  ARIEL_SPECTACULAR_SINGER_SINGER_TEXT:
    "5 (Singer) (Este personaje cuenta como costo 5 para cantar Canciones (Action - Song)).",
  AURORA_DREAMING_GUARDIAN_SHIFT_TEXT:
    "3 (Shift) (Puedes pagar 3 tintas para jugar esto encima de uno de tus personajes llamado Aurora).",
  CAPTAIN_HOOK_FORCEFUL_DUELIST_CHALLENGER_TEXT:
    "+2 (Challenger) (Mientras desafía, este personaje obtiene +2 de fuerza).",
  CAPTAIN_HOOK_THINKING_A_HAPPY_THOUGHT_CHALLENGER_TEXT:
    "+3 (Challenger) (Mientras desafía, este personaje obtiene +3 de fuerza).",
  CAPTAIN_HOOK_THINKING_A_HAPPY_THOUGHT_SHIFT_TEXT:
    "3 (Shift) (Puedes pagar 3 tintas para jugar esto encima de uno de tus personajes llamado Capitán Garfio).",
  CINDERELLA_GENTLE_AND_KIND_SINGER_TEXT:
    "5 (Singer) (Este personaje cuenta como costo 5 para cantar Canciones (Action - Song)).",
  DR_FACILIER_AGENT_PROVOCATEUR_SHIFT_TEXT:
    "5 (Shift) (Puedes pagar 5 tintas para jugar esto encima de uno de tus personajes llamado Dr. Facilier).",
  DR_FACILIER_CHARLATAN_CHALLENGER_TEXT:
    "+2 (Challenger) (Mientras desafía, este personaje obtiene +2 de fuerza).",
  ELSA_SPIRIT_OF_WINTER_SHIFT_TEXT:
    "6 (Shift) (Puedes pagar 6 tintas para jugar esto encima de uno de tus personajes llamado Elsa).",
  GENIE_POWER_UNLEASHED_SHIFT_TEXT:
    "6 (Shift) (Puedes pagar 6 tintas para jugar esto encima de uno de tus personajes llamado Genio).",
  HADES_KING_OF_OLYMPUS_SHIFT_TEXT:
    "6 (Shift) (Puedes pagar 6 tintas para jugar esto encima de uno de tus personajes llamado Hades).",
  JAFAR_WICKED_SORCERER_CHALLENGER_TEXT:
    "+3 (Challenger) (Mientras desafía, este personaje obtiene +3 de fuerza).",
  MICKEY_MOUSE_ARTFUL_ROGUE_SHIFT_TEXT:
    "5 (Shift) (Puedes pagar 5 tintas para jugar esto encima de uno de tus personajes llamado Mickey Mouse).",
  PRINCE_ERIC_DASHING_AND_BRAVE_CHALLENGER_TEXT:
    "+2 (Challenger) (Mientras desafía, este personaje obtiene +2 de fuerza).",
  SCAR_SHAMELESS_FIREBRAND_SHIFT_TEXT:
    "6 (Shift) (Puedes pagar 6 tintas para jugar esto encima de uno de tus personajes llamado Scar).",
  SEBASTIAN_COURT_COMPOSER_SINGER_TEXT:
    "4 (Singer) (Este personaje cuenta como costo 4 para cantar Canciones (Action - Song)).",
  SIMBA_RETURNED_KING_CHALLENGER_TEXT:
    "+4 (Challenger) (Mientras desafía, este personaje obtiene +4 de fuerza).",
  STITCH_ROCK_STAR_SHIFT_TEXT:
    "4 (Shift) (Puedes pagar 4 tintas para jugar esto encima de uno de tus personajes llamado Stitch).",
  TINKER_BELL_GIANT_FAIRY_SHIFT_TEXT:
    "4 (Shift) (Puedes pagar 4 tintas para jugar esto encima de uno de tus personajes llamado Campanita).",
  ZEUS_GOD_OF_LIGHTNING_CHALLENGER_TEXT:
    "+4 (Challenger) (Mientras desafía, este personaje obtiene +4 de fuerza).",

  // SET 2
  // ITEM CARD set 2
  BINDING_CONTRACT: "Contrato de Compromiso",
  CROQUET_MALLET: "Mazo de Croquet",
  DINNER_BELL: "Campana de cena",
  DRAGON_GEM: "Gema del Dragón",
  PERPLEXING_SIGNPOSTS: "Señales Perplejas",
  PETER_PANS_DAGGER: "Daga de Peter Pan",
  RATIGANS_MARVELOUS_TRAP: "La Maravillosa Trampa de Ratigan",
  SLEEPYS_FLUTE: "Flauta de Dormilón",
  SWORD_IN_THE_STONE: "Espada en la Piedra",
  THE_SORCERERS_SPELLBOOK: "El Libro de Hechizos del Hechicero",
  FANG_CROSSBOW: "Ballesta de Colmillo",
  GUMBO_POT: "Olla de Gumbo",
  MAURICES_WORKSHOP: "El Taller de Maurice",
  PAWPSCICLE: "Paletas de Pata",
  SARDINE_CAN: "Lata de Sardina",
  LAST_CANNON: "Último Cañón",
  MOUSE_ARMOR: "Armadura de Ratón",
  WEIGHT_SET: "Juego de Pesas",

  // ACTION CARD set 2
  BOUNCE: "Rebotar",
  FALLING_DOWN_THE_RABBIT_HOLE: "Cayendo por el Agujero del Conejo",
  LAST_STAND: "Última Resistencia",
  LAUNCH: "Lanzamiento",
  HOLD_STILL: "Quédate Quieto",
  HYPNOTIZE: "Hipnotizar",
  IM_STUCK: "¡Estoy atrapado!",
  IMPROVISE: "Improvisar",
  NOTHING_TO_HIDE: "Nada que Ocultar",
  PACK_TACTICS: "Tácticas de Grupo",
  RING_THE_BELL: "Tocar la Campana",
  WHAT_DID_YOU_CALL_ME: "¿Cómo me llamaste?",
  CHARGE: "Carga",
  PICK_A_FIGHT: "Buscar Pelea",

  // ACTION SONG CARD set 2
  BIBBIDI_BOBBIDI_BOO: "Bibbidi Bobbidi Boo",
  FOUR_DOZEN_EGGS: "Cuatro Docenas de Huevos",
  GO_THE_DISTANCE: "Ve la Distancia",
  GRUESOME_AND_GRIM: "Espantoso y Siniestro",
  LEGEND_OF_THE_SWORD_IN_THE_STONE: "La Leyenda de la Espada en la Piedra",
  TEETH_AND_AMBITIONS: "Dientes y Ambiciones",
  THE_MOST_DIABOLICAL_SCHEME: "El Plan más Diabólico",
  PAINTING_THE_ROSES_RED: "Pintando las Rosas de Rojo",
  WORLDS_GREATEST_CRIMINAL_MIND: "La Mente Criminal Más Grande del Mundo",
  YOU_CAN_FLY: "You Can Fly",
  ZERO_TO_HERO: "Puedes Volar",
  LET_THE_STORM_RAGE_ON: "Deja que la Tormenta Siga",
  STRENGTH_OF_A_RAGING_FIRE: "Fuerza de un Fuego Furioso",

  // VERSION NAME set 2
  S2_001_VERSION_NAME: "Romántico Desesperado",
  S2_002_VERSION_NAME: "Aventurero",
  S2_003_VERSION_NAME: "Sensación del Salón de Baile",
  S2_004_VERSION_NAME: "Simplemente un Trabajador Social",
  S2_005_VERSION_NAME: "Líder de los Siete Enanitos",
  S2_006_VERSION_NAME: "Siempre Juguetón",
  S2_007_VERSION_NAME: "Costurera Experimentada",
  S2_008_VERSION_NAME: "Bullicioso Barítono",
  S2_009_VERSION_NAME: "Consejero del Rey",
  S2_010_VERSION_NAME: "Malhumorado",
  S2_011_VERSION_NAME: "De Buen Corazón",
  S2_012_VERSION_NAME: "VIP de la Jungla",
  S2_013_VERSION_NAME: "Cara Amigable",
  S2_014_VERSION_NAME: "Líder Traicionado",
  S2_015_VERSION_NAME: "Espíritu Libre",
  S2_016_VERSION_NAME: "Reflexionando",
  S2_017_VERSION_NAME: "Mascota Querida de la Familia",
  S2_018_VERSION_NAME: "Animal Muy Pequeño",
  S2_019_VERSION_NAME: "Artista Talentoso",
  S2_020_VERSION_NAME: "Rayo de Sol",
  S2_021_VERSION_NAME: "Asintiendo con la Cabeza",
  S2_022_VERSION_NAME: "Muy Alérgico",
  S2_023_VERSION_NAME: "Perdido en el Bosque",
  S2_024_VERSION_NAME: "Visitante Inesperado en la Casa",
  S2_025_VERSION_NAME: "Bienqueriente",
  S2_026_VERSION_NAME: "Presencia Imponente",
  S2_027_VERSION_NAME: "Monarca Regio",
  S2_035_VERSION_NAME: "Aprendiz de Mago",
  S2_036_VERSION_NAME: "Recompensando Buenas Acciones",
  S2_037_VERSION_NAME: "Alma Gentil",
  S2_038_VERSION_NAME: "Oportunista Astuto",
  S2_039_VERSION_NAME: "Sin Guantes",
  S2_040_VERSION_NAME: "Aquí para Ayudar",
  S2_041_VERSION_NAME: "Armero Místico",
  S2_042_VERSION_NAME: "Corazón Puro",
  S2_043_VERSION_NAME: "Presencia Persistente",
  S2_044_VERSION_NAME: "Conciencia de Pinocho",
  S2_045_VERSION_NAME: "Llama Buscada",
  S2_046_VERSION_NAME: "Zorro",
  S2_047_VERSION_NAME: "Dragón Morado",
  S2_048_VERSION_NAME: "Rival de Merlín",
  S2_049_VERSION_NAME: "Serpiente",
  S2_050_VERSION_NAME: "Cangrejo",
  S2_051_VERSION_NAME: "Cabrito",
  S2_052_VERSION_NAME: "Conejo",
  S2_053_VERSION_NAME: "Cambiante",
  S2_054_VERSION_NAME: "Ardilla",
  S2_055_VERSION_NAME: "No Cosido",
  S2_056_VERSION_NAME: "Atracción Estelar",
  S2_057_VERSION_NAME: "En Fuga",
  S2_058_VERSION_NAME: "Marioneta Habladora",
  S2_059_VERSION_NAME: "Mago de la Miel",
  S2_060_VERSION_NAME: "Atemorizante Más Allá de Toda Razón",
  S2_061_VERSION_NAME: "Sin Dormir por la Belleza",
  S2_069_VERSION_NAME: "Espadachín Entrenado",
  S2_070_VERSION_NAME: "Implacable",
  S2_071_VERSION_NAME: "Ratón de Biblioteca",
  S2_072_VERSION_NAME: "Arquero Oculto",
  S2_073_VERSION_NAME: "Maestro de Siseo de Ardilla",
  S2_074_VERSION_NAME: "Siempre Sonriendo",
  S2_075_VERSION_NAME: "Desde las Sombras",
  S2_076_VERSION_NAME: "Agente Secreto",
  S2_077_VERSION_NAME: "Caballero Perfecto",
  S2_078_VERSION_NAME: "Sonámbulo",
  S2_079_VERSION_NAME: "Adivino",
  S2_080_VERSION_NAME: "Juez Inesperado",
  S2_081_VERSION_NAME: "Vagabundo Confidente",
  S2_082_VERSION_NAME: "Su Propio Mayor Admirador",
  S2_083_VERSION_NAME: "Pretendiente Maquinador",
  S2_084_VERSION_NAME: "Amigo Leal",
  S2_085_VERSION_NAME: "Gato Astuto",
  S2_086_VERSION_NAME: "Imp del Inframundo",
  S2_087_VERSION_NAME: "Imp del Inframundo",
  S2_088_VERSION_NAME: "Villano",
  S2_089_VERSION_NAME: "El Más Avaro de Todos",
  S2_090_VERSION_NAME: "De Temperamento Rápido",
  S2_091_VERSION_NAME: "Mente Criminal",
  S2_092_VERSION_NAME: "Luciérnaga Tranquila",
  S2_093_VERSION_NAME: "Comerciante Disfrazado",
  S2_094_VERSION_NAME: "Verdadera Princesa",
  S2_095_VERSION_NAME: "Jefe de Colmillo",
  S2_103_VERSION_NAME: "Oso Amante de la Diversión",
  S2_104_VERSION_NAME: "Emprendedor Precóz",
  S2_105_VERSION_NAME: "Baraja Completa",
  S2_106_VERSION_NAME: "¡Otra Vez No!",
  S2_107_VERSION_NAME: "Siempre Hambriento",
  S2_108_VERSION_NAME: "Secuaz de Ratigan",
  S2_109_VERSION_NAME: "No Tan Honesto",
  S2_110_VERSION_NAME: "Reina Imperiosa",
  S2_111_VERSION_NAME: "Matriarca Dominante",
  S2_112_VERSION_NAME: "Candelabro Impulsivo",
  S2_113_VERSION_NAME: "Surfista con Estilo",
  S2_114_VERSION_NAME: "Buceador de ojos abiertos",
  S2_115_VERSION_NAME: "Zumbando por Ahí",
  S2_116_VERSION_NAME: "Marchito y Malvado",
  S2_117_VERSION_NAME: "Soldado en Entrenamiento",
  S2_118_VERSION_NAME: "Némesis",
  S2_119_VERSION_NAME: "Gobernante Impulsivo",
  S2_120_VERSION_NAME: "Detectando Debilidad",
  S2_121_VERSION_NAME: "Ratón Muy Grande",
  S2_122_VERSION_NAME: "Testarudo",
  S2_123_VERSION_NAME: "Líder del Corazón",
  S2_124_VERSION_NAME: "Guerrera de Kumandra",
  S2_125_VERSION_NAME: "Tramposo Malicioso",
  S2_126_VERSION_NAME: "Depredador Amenazador",
  S2_127_VERSION_NAME: "Único en su Clase",
  S2_128_VERSION_NAME: "Bola de Demolición",
  S2_137_VERSION_NAME: "Chica en Crecimiento",
  S2_138_VERSION_NAME: "Gran Detective de Ratones",
  S2_139_VERSION_NAME: "De la Calle Baker",
  S2_140_VERSION_NAME: "Investigador Perspicaz",
  S2_141_VERSION_NAME: "Calmado y Sereno",
  S2_142_VERSION_NAME: "Reloj Abuelo",
  S2_143_VERSION_NAME: "Reloj Parlante",
  S2_144_VERSION_NAME: "Crucerista Elegante",
  S2_145_VERSION_NAME: "Perfectamente Despreciable",
  S2_146_VERSION_NAME: "Ladrón de Poca Monta",
  S2_147_VERSION_NAME: "Potencia Intelectual",
  S2_148_VERSION_NAME: "Más Viejo y Sabio",
  S2_149_VERSION_NAME: "Fabricante de Juguetes",
  S2_150_VERSION_NAME: "Modelo a Seguir",
  S2_151_VERSION_NAME: "Heredero de Agrabah",
  S2_152_VERSION_NAME: "Oficial Optimista",
  S2_153_VERSION_NAME: "Ama de Llaves",
  S2_154_VERSION_NAME: "Astuto Zorro",
  S2_155_VERSION_NAME: "Ladrón Huérfano",
  S2_156_VERSION_NAME: "Conferenciante Lógico",
  S2_157_VERSION_NAME: "Heredero del Trono",
  S2_158_VERSION_NAME: "Anfitrión Reacio",
  S2_159_VERSION_NAME: "Dragón de Agua Divino",
  S2_160_VERSION_NAME: "Espíritu del Agua",
  S2_161_VERSION_NAME: "Pensando",
  S2_171_VERSION_NAME: "Ermitaño Prohibido",
  S2_172_VERSION_NAME: "Protector Desinteresado",
  S2_173_VERSION_NAME: "Héroe Trágico",
  S2_174_VERSION_NAME: "Guardián de la Gema del Dragón",
  S2_175_VERSION_NAME: "Oficial Respetado",
  S2_176_VERSION_NAME: "Caballero en Entrenamiento",
  S2_177_VERSION_NAME: "Corazón Valiente",
  S2_178_VERSION_NAME: "Buzo de Aguas Profundas",
  S2_179_VERSION_NAME: "Gran Papá",
  S2_180_VERSION_NAME: "Caballero por un Día",
  S2_181_VERSION_NAME: "Héroe Divino",
  S2_182_VERSION_NAME: "Héroe en Entrenamiento",
  S2_183_VERSION_NAME: "Acorazado",
  S2_184_VERSION_NAME: "Visir Real",
  S2_185_VERSION_NAME: "Ardilla Joven",
  S2_186_VERSION_NAME: "Sirviente Celoso",
  S2_187_VERSION_NAME: "Instructor de Tiro con Arco",
  S2_188_VERSION_NAME: "Modelo Industrial",
  S2_189_VERSION_NAME: "Niebla Matutina",
  S2_190_VERSION_NAME: "Líder de la Aldea",
  S2_191_VERSION_NAME: "Real Sin Dinero",
  S2_192_VERSION_NAME: "Monarca Caprichoso",
  S2_193_VERSION_NAME: "Luchador Capaz",
  S2_194_VERSION_NAME: "Ejecutor Reacio",
  S2_195_VERSION_NAME: "Nunca se Rinde",
  S2_196_VERSION_NAME: "Princesa Celebrante",
  S2_197_VERSION_NAME: "Camarera Diligente",

  // ABILITIES set 2
  S2_001_ABILITY:
    "¡OH, CARAMBA!: Este personaje no puede explorar (Quest) a menos que tengas otro personaje de los Siete Enanitos en juego.",
  S2_002_ABILITY:
    "SIEMPRE ESTAREMOS JUNTOS: Siempre que prepares a este personaje, si tienes 2 o más personajes adicionales en juego, gana 2 puntos de Leyenda (Lore).",
  S2_005_ABILITY:
    "COMPARTIR Y COMPARTIR POR IGUAL: Siempre que este personaje explore (Quest), pagas 1 tinta menos por el próximo personaje que juegues este turno.",
  S2_006_ABILITY:
    "EL RARO: Cuando este personaje es desterrado, tus otros personajes de los Siete Enanitos obtienen +2 de fuerza hasta el comienzo de tu próximo turno.",
  S2_009_ABILITY:
    "SÍ, MAJESTAD: Tus personajes Príncipe, Princesa, Rey y Reina obtienen +1 de fuerza.",
  S2_010_ABILITY:
    "SE ACERCA EL PROBLEMA: Tus otros personajes de los Siete Enanitos obtienen +1 de fuerza.",
  S2_012_ABILITY:
    "DECIR LAS COSAS COMO SON: Siempre que otro personaje sea desterrado, puedes quitar hasta 2 de daño de este personaje.",
  S2_013_ABILITY:
    "¡QUÉ ALEGRÍA VERTE!: Siempre que este personaje explore (Quest), pagas 3 tintas menos por el próximo personaje que juegues este turno.",
  S2_014_ABILITY:
    "EL SOL SE OCULTARÁ: Cuando este personaje es desterrado, puedes revelar la carta superior de tu mazo. Si es una carta de personaje, puedes jugar ese Personaje gratis y entra en juego agotado. De lo contrario, ponla en la parte superior de tu mazo.",
  S2_016_ABILITY:
    "HONOR A LOS ANTEPASADOS: Siempre que este personaje explore (Quest), puedes revelar la carta superior de tu mazo. Si es una carta de Canción, puedes jugarla gratis. De lo contrario, ponla en la parte superior de tu mazo.",
  S2_017_ABILITY:
    "ENFERMERA: Siempre que juegues un personaje Nacido de la Inundación (Floodborn), puedes quitar todo el daño del personaje elegido.",
  S2_019_ABILITY:
    "DEJA QUE BRILLE TU PODER: Siempre que quites 1 o más de daño de uno de tus personajes, puedes robar una carta.",
  S2_020_ABILITY:
    "PELO MÁGICO: Agotar - Remueve hasta 2 de daño del personaje elegido.",
  S2_021_ABILITY: "¡BOSTEZAR!: Este personaje entra en juego agotado.",
  S2_022_ABILITY:
    "AH-CHOO!: Siempre que juegues a este personaje u otro personaje de los Siete Enanitos, puedes darle -1 de fuerza al personaje elegido este turno.",
  S2_023_ABILITY:
    "NO TE HARÉ DAÑO: Cuando juegas a este personaje, puedes remover hasta 2 de daño del personaje elegido.",
  S2_024_ABILITY:
    "¿CÓMO ESTÁS?: Pagas 1 tinta menos para jugar personajes de los Siete Enanitos.",
  S2_025_ABILITY:
    "LOS DESEOS SE HACEN REALIDAD: Siempre que este personaje explore (Quest), puedes devolver una carta de personaje de tu descarte a tu mano.",
  S2_026_ABILITY:
    "¿QUIÉN ES LA MÁS BELLA?: Siempre que este personaje explore (Quest), el personaje oponente elegido recibe -4 de fuerza este turno y el personaje elegido recibe +4 de fuerza este turno.",
  S2_033_ABILITY:
    "DEVOLVER A LA VIDA: Agotar, pagar 3 tintas - Devuelve una carta de personaje con Apoyo (Support) de tu descarte a tu mano.",
  S2_034_ABILITY:
    "UNA CANCIÓN TONTA: Agotar - Si jugaste una canción este turno, gana 1 punto de Leyenda (Lore).",
  S2_035_ABILITY:
    "ESTUDIANTE: Siempre que este personaje explore (Quest), puedes devolver otro personaje elegido tuyo a tu mano para ganar 2 puntos de Leyenda (Lore).",
  S2_036_ABILITY:
    "RESPLANDOR ETÉREO: Siempre que juegues un personaje Nacido de la Inundación (Floodborn), puedes robar una carta.",
  S2_041_ABILITY:
    "OLVÍDATE DEL ENTRENADOR, AQUÍ TIENES UNA ESPADA: Siempre que este personaje explore (Quest), tus personajes obtienen Retador (Challenger) +3 y 'Cuando este personaje es desterrado en un desafío, devuelve esta carta a tu mano' este turno. (Obtienen +3 de fuerza mientras desafían.)",
  S2_042_ABILITY:
    "DEJA TODO EN MIS MANOS: Siempre que juegues un personaje llamado Cenicienta, puedes agotar al personaje elegido.",
  S2_043_ABILITY:
    "¡ESTÁ DE VUELTA!: Cuando este personaje es desterrado en un desafío, devuelve esta carta a tu mano.",
  S2_044_ABILITY:
    "ESA VOZ QUIETA Y PEQUEÑA: Cuando juegas a este personaje, si tienes un personaje llamado Pinocho en juego, puedes robar una carta.",
  S2_045_ABILITY:
    "OK, ¿DÓNDE ESTOY?: Cuando este personaje es desterrado, puedes robar una carta.",
  S2_046_ABILITY:
    "SIGUIENDO AL CONEJO: Cuando juegas a este personaje, destiérralo o devuelve otro personaje elegido tuyo a tu mano.",
  S2_047_ABILITY:
    "¡YO GANO, YO GANO!: Cuando juegas a este personaje, destiérralo o devuelve otros 2 personajes elegidos tuyos a tu mano.",
  S2_048_ABILITY:
    "ESPANTOSO Y SOMBRÍO: Agotar - Juega un personaje con un coste de 4 o menos gratis. Obtienen Prisa (Rush). Al final del turno, destiérralos. Pueden desafiar el turno en que se juegan.",
  S2_049_ABILITY:
    "SOLO ESPERA: Cuando juegas a este personaje, destiérralo o devuelve otro personaje elegido tuyo a tu mano.",
  S2_050_ABILITY:
    "¿LISTO O NO?: Cuando juegas a este personaje y cuando deja el juego, el personaje elegido obtiene Retador (Challenger) +3 este turno. (Obtienen +3 de fuerza mientras desafían.)",
  S2_051_ABILITY:
    "¡AQUÍ VOY!: Cuando juegas a este personaje y cuando deja el juego, gana 1 punto de Leyenda (Lore).",
  S2_052_ABILITY:
    "¡HOPPITY HIP!: Cuando juegas a este personaje y cuando deja el juego, puedes robar una carta.",
  S2_053_ABILITY:
    "BATALLA DE INTELIGENCIA: Siempre que uno de tus otros personajes sea devuelto a tu mano desde el juego, este personaje obtiene +1 punto de Leyenda (Lore) este turno.",
  S2_054_ABILITY:
    "MIRA ANTES DE SALTAR: Cuando juegas a este personaje y cuando deja el juego, mira la carta superior de tu mazo. Ponla en la parte superior o inferior de tu mazo.",
  S2_055_ABILITY:
    "SIGILOSO: Tus otros personajes con Prisa (Rush) obtienen Escurridizo (Evasive).",
  S2_057_ABILITY:
    "ESCUCHE SU CONCIENCIA: Cuando juegas a este personaje, puedes devolver un personaje o un objeto (item) elegido con un coste de 3 o menos a la mano de su jugador.",
  S2_058_ABILITY:
    "CONTANDO MENTIRAS: Cuando juegas a este personaje, puedes agotar al personaje oponente elegido.",
  S2_060_ABILITY:
    "IRONÍA CRUEL: Cuando juegas a este personaje, baraja otra carta de personaje elegida en el mazo de su jugador. Ese jugador roba 2 cartas.",
  S2_065_ABILITY:
    "POR TODA LA ETERNIDAD: Agotar, agotar uno de tus personajes - Agota al personaje elegido.",
  S2_066_ABILITY:
    "ERIZO VELOZ: Destierra este objeto (item) - El personaje elegido gana Prisa (Rush) este turno. (Pueden desafiar el turno en que se juegan.)",
  S2_067_ABILITY:
    "HACIA EL PAÍS DE LAS MARAVILLAS: Destierra este objeto (item) - Devuelve el personaje elegido tuyo a tu mano.",
  S2_068_ABILITY:
    "CONOCIMIENTO: Agotar, pagar 1 tinta - Obtén 1 punto de Leyenda (Lore).",
  S2_070_ABILITY:
    "SEGUNDO ALIENTO: Siempre que un personaje oponente reciba daño, puedes preparar a este personaje.",
  S2_071_ABILITY:
    "USA TU IMAGINACIÓN: Mientras un oponente no tenga cartas en su mano, este personaje obtiene +2 puntos de Leyenda (Lore).",
  S2_072_ABILITY:
    "FLECHAS ESPINOSAS: Siempre que este personaje sea desafiado, el jugador del personaje que desafía descarta todas las cartas de su mano.",
  S2_073_ABILITY:
    "CHILLIDO: Siempre que juegues a un personaje Nacido de la Inundación (Floodborn), cada oponente elige y descarta una carta.",
  S2_075_ABILITY:
    "SONRISA MALVADA: agotar - Destierra al personaje dañado elegido.",
  S2_076_ABILITY:
    "FRUSTRAR: Siempre que este personaje rexplore (Quest), cada oponente elige y descarta una carta.",
  S2_077_ABILITY:
    "PERMÍTAME: Al comienzo de tu turno, cada jugador puede robar una carta.",
  S2_078_ABILITY:
    "DESPERTADO DE GOLPE: Siempre que juegues una acción (action), este personaje obtiene +2 de fuerza este turno.",
  S2_079_ABILITY:
    "ESTÁS EN MI MUNDO: Siempre que este personaje explore (Quest), el personaje oponente elegido no puede explorar (Quest) durante su próximo turno.",
  S2_080_ABILITY:
    "VERDADERA FORMA: Mientras es desafiado, este personaje obtiene +2 de fuerza.",
  S2_082_ABILITY:
    "UN ÚLTIMO, GRAN GOLPE: Este personaje obtiene -1 punto de Leyenda (Lore) por cada carta en las manos de tus oponentes.",
  S2_083_ABILITY:
    "SÍ, IMPONGO RESPETO: Mientras uno o más oponentes no tengan cartas en sus manos, este personaje obtiene +3 de fuerza.",
  S2_085_ABILITY:
    "CAZADOR DE RATONES: Cuando juegas a este personaje, cada oponente elige y descarta 2 cartas o 1 carta de acción (action).",
  S2_086_ABILITY:
    "LLEGANDO, SU MÁS LÚGUBRE MAJESTAD: Mientras este personaje tenga 5 de fuerza o más, obtiene +2 puntos de Leyenda (Lore).",
  S2_087_ABILITY:
    "PUEDO MANEJARLO: Cuando juegas a este personaje, el personaje elegido obtiene +2 de fuerza este turno. Si el personaje elegido se llama Pena (Pain), obtiene +4 de fuerza en su lugar.",
  S2_088_ABILITY:
    "<1>¡TOMA ESO!:</1> Siempre que juegues una acción (action), este personaje obtiene +2 de fuerza este turno. <2/><1>¿QUIÉN SIGUE?:</1> Mientras este personaje tenga 7 de fuerza o más, obtiene +2 puntos de Leyenda (Lore).",
  S2_089_ABILITY:
    "TE SENTENCIO: Siempre que tu oponente descarte 1 o más cartas, puedes robar una carta por cada carta descartada.",
  S2_090_ABILITY:
    "RABIA REAL: Cuando juegas a este personaje, inflige 1 daño al personaje oponente dañado elegido.",
  S2_093_ABILITY:
    "UN DISFRAZ PERFECTO: agotar, Elegir y descartar una carta de personaje - Gana puntos de Leyenda (Lore) igual a los puntos de Leyenda (Lore) del personaje descartado.",
  S2_102_ABILITY:
    "SNAP! BOOM! TWANG!: Destierra este objeto (item) - Cada oponente pierde 2 puntos de Leyenda (Lore).",
  S2_106_ABILITY:
    "PHOOEY!: Este personaje obtiene +1 punto de Leyenda (Lore) por cada 1 de daño que tenga.",
  S2_109_ABILITY:
    "CALLE FÁCIL: Siempre que juegues un personaje Nacido de la Inundación (Floodborn), cada oponente pierde 1 punto de Leyenda (Lore).",
  S2_110_ABILITY:
    "PODER PARA GOBERNAR AL FIN: Cuando juegas a este personaje, cada oponente elige y destierra uno de sus personajes.",
  S2_111_ABILITY:
    "NO PARA TI: Cuando juegas a este personaje, cada oponente con más puntos de Leyenda (Lore) que tú pierde 1 punto de Leyenda (Lore).",
  S2_114_ABILITY:
    "AVENTURA SUBMARINA: Siempre que juegues una segunda acción (action) en un turno, este personaje obtiene +2 puntos de Leyenda (Lore) este turno.",
  S2_116_ABILITY:
    "¿QUÉ HAS HECHO?!: Este personaje entra en juego con 3 de daño.",
  S2_118_ABILITY:
    "ESTO NO DEBERÍA TOMAR MUCHO TIEMPO: agotar, Destierra este personaje - Destierra al personaje elegido.",
  S2_120_ABILITY:
    "QUE EMPIECE EL JUEGO: Siempre que uno de tus personajes desafíe a otro personaje, puedes robar una carta.",
  S2_121_ABILITY:
    "ESTE ES MI REINO: Cuando juegas a este personaje, agota al personaje oponente elegido con 3 de fuerza o menos. Elige a uno de tus personajes y prepáralo. No pueden explorar (Quest) durante el resto de este turno.",
  S2_122_ABILITY:
    "NOTA PARA MÍ, NO MUERAS: Durante tu turno, siempre que este personaje destierre a otro personaje en un desafío, puedes preparar a este personaje. No puede explorar (Quest) durante el resto de este turno.",
  S2_123_ABILITY:
    "CAMPEÓN DE KUMANDRA: Siempre que este personaje desafíe a un personaje dañado, no sufre daño del desafío.",
  S2_125_ABILITY:
    "PAPÁ NO ESTÁ AQUÍ PARA SALVARTE: Durante tu turno, siempre que este personaje destierre a otro personaje en un desafío, puedes preparar a este personaje. No puede explorar (Quest) durante el resto de este turno.",
  S2_126_ABILITY:
    "NO INSULTES MI INTELIGENCIA: Cuando uno de tus personajes desafíe a otro personaje, gana 1 punto de Leyenda (Lore).",
  S2_127_ABILITY:
    "ENÉRGICO: Siempre que juegues una acción (action), este personaje obtiene +2 de fuerza este turno.",
  S2_134_ABILITY:
    "SABES LO QUE SUCEDE: agotar, 2 tintas - Roba cartas igual al daño en el personaje elegido tuyo, luego destiérralo.",
  S2_137_ABILITY:
    "<1>BUEN CONSEJO:</1> Tus otros personajes obtienen Apoyo (Support). (Cuando exploren (Quest), puedes sumar su fuerza a la fuerza de otro personaje elegido este turno.) <2/><1>¿QUÉ HICE?:</1> Mientras este personaje tenga 10 de fuerza o más, obtiene +4 puntos de Leyenda (Lore).",
  S2_138_ABILITY:
    "SIEMPRE HAY UNA OPORTUNIDAD: Si usaste Cambio (Shift) para jugar a este personaje, puedes robar 2 cartas cuando entre en juego.",
  S2_142_ABILITY:
    "RELÁJATE: Tus otros personajes obtienen Resistencia (Resist) +1 (El daño que se les inflige se reduce en 1.)",
  S2_143_ABILITY:
    "ESPERA UN MINUTO: Tu personaje con Temerario (Reckless) obtiene “agotar - Obtén 1 punto de Leyenda (Lore).",
  S2_144_ABILITY:
    "AHORA, ¡VETE!: Durante tu turno, este personaje obtiene Escurridizo (Evasive). (Pueden desafiar a personajes con Escurridizo.)",
  S2_145_ABILITY:
    "¡OH, NO LO HARÁS!: Siempre que este personaje explore (Quest), el personaje oponente elegido obtiene -2 de fuerza este turno.",
  S2_147_ABILITY:
    "CEREBRO DESARROLLADO: Cuando juegas a este personaje, mira las 3 cartas superiores de tu mazo. Puedes poner una en tu mano. Pon el resto en la parte inferior de tu mazo en cualquier orden.",
  S2_148_ABILITY:
    "VISIÓN ANTIGUA: Siempre que retires 1 o más daños de uno de tus personajes, obtén 2 puntos de Leyenda (Lore).",
  S2_149_ABILITY:
    "ARTESANO: Cuando juegas a este personaje y siempre que explore (Quest), puedes desterrar uno de tus objetos (items) para robar 2 cartas.",
  S2_150_ABILITY:
    "NUNCA, JAMÁS PIERDAS DE VISTA: Cuando este personaje es desterrado, puedes poner esta carta en tu tintero boca abajo y agotada.",
  S2_151_ABILITY:
    "APRENDO RÁPIDO: Cuando juegas a este personaje, puedes remover hasta 1 de daño del personaje elegido tuyo.",
  S2_152_ABILITY:
    "NO ME LLAMES LINDO: Cuando juegas a este personaje, puedes desterrar el objeto (item) elegido. Su jugador roba una carta.",
  S2_153_ABILITY:
    "ORDEN: Siempre que juegues a un personaje Nacido de la Inundación (Floodborn), puedes poner la carta superior de tu mazo en tu tintero boca abajo y agotada.",
  S2_154_ABILITY:
    "SE LLAMA TRABAJO: Cuando juegas a este personaje, puedes devolver una carta de objeto (item) llamada Palo de Hielo (Pawpsicle) de tu descarte a tu mano.",
  S2_155_ABILITY:
    "ESCONDERSE Y BUSCAR: Mientras tengas un objeto (item) en juego, este personaje obtiene Resistencia (Resist) +1 y Guarda (Ward). (El daño que se inflige a este personaje se reduce en 1. Los oponentes no pueden elegir este personaje excepto para desafiar.)",
  S2_159_ABILITY:
    "CONFÍO EN TI: Cada vez que este personaje explore (Quest), mira las 2 cartas superiores de tu mazo. Puedes poner una en tu mano. Pon el resto en la parte inferior de tu mazo en cualquier orden.",
  S2_161_ABILITY:
    "POTE DE MIEL: Cada vez que este personaje explore (Quest), puedes poner una carta de tu mano en tu tintero boca abajo.",
  S2_166_ABILITY:
    "<1>APUNTE CUIDADOSO:</1> agotar, 2 tinta - El personaje elegido recibe -2 de fuerza este turno. <2/><1>¡RETROCEDE!:</1> agotar, Destierra este objeto (item) - Destierra el personaje Dragón elegido.",
  S2_167_ABILITY:
    "LO MEJOR QUE HE PROBADO: agotar - Remueve 1 de daño a cada uno de hasta 2 personajes elegidos.",
  S2_168_ABILITY:
    "¿BUSCANDO ESTO?: Cada vez que juegues otro objeto (item), puedes pagar 1 tinta para robar una carta.",
  S2_169_ABILITY:
    "<1>POPCORN GRANDE:</1> Cuando juegues este objeto (item), puedes robar una carta. <2/><1>ESO ES MADERA DE SECUOYA:</1> Destierra este objeto - Remueve hasta 2 de daño del personaje elegido.",
  S2_170_ABILITY:
    "CABINA DE VUELO: Tus personajes agotados obtienen Guarda (Ward). (Los oponentes no pueden elegirlos excepto para desafiarlos.)",
  S2_171_ABILITY:
    "NO ERES BIENVENIDO AQUÍ: Cuando juegas este personaje, puedes infligir 1 de daño al personaje elegido.",
  S2_172_ABILITY:
    "ESCUDAR A OTRO: Siempre que uno de tus otros personajes reciba daño, pon esa cantidad de contadores de daño en este personaje en su lugar.",
  S2_173_ABILITY:
    "ES MEJOR ASÍ: Al comienzo de tu turno, si este personaje no tiene daño, roba una carta. De lo contrario, obtiene +4 de fuerza este turno.",
  S2_174_ABILITY:
    "TENEMOS UNA ELECCIÓN: Cuando juegas este personaje, puedes desterrar el objeto (item) elegido.",
  S2_175_ABILITY:
    "¡INSUBORDINACIÓN!: Cada vez que juegas un personaje Nacido de la Inundación (Floodborn), inflige 1 de daño a cada personaje oponente.",
  S2_176_ABILITY:
    "TEN VALOR: Cuando juegas este personaje, puedes robar una carta, luego elegir y descartar una carta.",
  S2_177_ABILITY:
    "LA ESPADA CANTORA: Cada vez que juegas una canción (action - song), este personaje puede desafiar a personajes preparados este turno.",
  S2_183_ABILITY:
    "¿DÓNDE NOS QUEDAMOS?: Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, puedes robar una carta.",
  S2_184_ABILITY:
    "NO CONFÍO EN ÉL, SIRE: Durante tu turno, este personaje obtiene Escurridizo (Evasive). (Pueden desafiar a personajes con Escurridizo (Evasive).)",
  S2_185_ABILITY:
    "LÍDER DE ESCUADRA: Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, puedes infligir 2 de daño al personaje elegido.",
  S2_186_ABILITY:
    "REVANCHA: Mientras este personaje no tenga daño, obtiene +4 de fuerza.",
  S2_187_ABILITY:
    "LECCIÓN DE TIRO CON ARCO: Cada vez que este personaje explore (Quest), tus personajes obtienen Escurridizo (Evasive) este turno. (Pueden desafiar a personajes con Escurridizo (Evasive).)",
  S2_188_ABILITY:
    "HAZLO BRILLAR: Cuando juegas este personaje, el personaje elegido obtiene Resistencia (Resist) +1 hasta el comienzo de tu próximo turno. (El daño infligido a ellos se reduce en 1.)",
  S2_189_ABILITY:
    "HOJAS: Este personaje puede desafiar a personajes preparados.",
  S2_192_ABILITY:
    "¡FUERA CON SUS CABEZAS!: Cada vez que un personaje oponente es desterrado, puedes preparar este personaje.",
  S2_193_ABILITY:
    "ESCARAMUZA: agotar - Inflige 1 de daño al personaje elegido.",
  S2_194_ABILITY:
    "CAMBIO DE CORAZÓN: Cada vez que este personaje explore (Quest), puedes robar una carta, luego elegir y descartar una carta.",
  S2_196_ABILITY:
    "LO QUE DAS ES LO QUE OBTIENES: Mientras este personaje esté agotado y no tengas cartas en tu mano, los oponentes no pueden jugar acciones (actions).",
  S2_202_ABILITY:
    "ARMATE: 1 tinta, Destierra este objeto (item) - El personaje elegido obtiene Retador (Challenger) +3 este turno. (Obtienen +3 de fuerza al desafiar.)",
  S2_203_ABILITY:
    "PROTECCIÓN: agotar - El personaje elegido obtiene Resistencia (Resist) +1 hasta el comienzo de tu próximo turno. (El daño infligido a ellos se reduce en 1.)",
  S2_204_ABILITY:
    "ENTRENAMIENTO: Cada vez que juegas un personaje con 4 de fuerza o más, puedes pagar 1 tinta para robar una carta.",

  // BODY set 2
  S2_028_BODY: "Quita hasta 4 puntos de daño del personaje elegido.",
  S2_029_BODY: "Destierra al personaje elegido que fue desafiado este turno.",
  S2_030_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) Hasta 2 personajes elegidos obtienen -1 de fuerza este turno. Roba una carta.",
  S2_031_BODY:
    "(Un personaje con un coste de 3 o más puede agotarse para cantar esta canción gratis.) Destierra al personaje elegido con 5 o más de fuerza.",
  S2_032_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) Cuenta el número de personajes que tienes en juego. Paga esa cantidad de tinta menos por el siguiente personaje que juegues este turno.",
  S2_062_BODY:
    "(Un personaje con un coste de 3 o más puede agotarse para cantar esta canción gratis.) Juega un personaje con un coste de 4 o menos gratis. Obtienen Prisa (Rush). Al final del turno, destiérralos. (Pueden desafiar el turno en que se juegan.)",
  S2_063_BODY:
    "El personaje agotado elegido no puede prepararse al principio de su próximo turno.",
  S2_064_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) El personaje elegido obtiene Retador (Challenger) +3 este turno. (Obtienen +3 de fuerza al desafiar.)",
  S2_096_BODY:
    "(Un personaje con un coste de 3 o más puede agotarse para cantar esta canción gratis.) Devuelve el personaje elegido tuyo a tu mano para jugar otro personaje con el mismo coste o menos gratis.",
  S2_097_BODY:
    "Devuelve el personaje elegido tuyo a tu mano para devolver a otro personaje elegido a la mano de su jugador.",
  S2_098_BODY: "Cada oponente elige y descarta una carta. Roba una carta.",
  S2_099_BODY:
    "El personaje elegido obtiene +1 de fuerza este turno. Roba una carta.",
  S2_100_BODY:
    "Gana 1 punto de Leyenda (Lore) por cada personaje dañado que tengan los oponentes en juego.",
  S2_101_BODY: "Destierra al personaje dañado elegido.",
  S2_129_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) Prepara al personaje dañado elegido tuyo. No pueden explorar (Quest) durante el resto de este turno. Roba una carta.",
  S2_130_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) Inflige 2 puntos de daño al personaje elegido tuyo para infligir 2 puntos de daño a otro personaje elegido.",
  S2_131_BODY:
    "(Un personaje con un coste de 3 o más puede agotarse para cantar esta canción gratis.) Destierra al Villano (Villain) elegido tuyo para desterrar al personaje elegido.",
  S2_132_BODY: "El personaje dañado elegido obtiene +3 de fuerza este turno.",
  S2_133_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) El personaje elegido obtiene Escurridizo (Evasive) hasta el comienzo de tu próximo turno. (Solo los personajes con Escurridizo pueden desafiarlos.)",
  S2_135_BODY:
    "Tus personajes con Escurridizo (Evasive) obtienen +1 de fuerza.",
  S2_136_BODY:
    "agotar, 2 tintas - El personaje elegido obtiene +1 de fuerza este turno por cada 1 punto de daño en ellos.",
  S2_162_BODY:
    "Cada jugador elige uno de sus personajes y los pone boca abajo en su tintero y agotado.",
  S2_163_BODY:
    "(Un personaje con un coste de 4 o más puede agotarse para cantar esta canción gratis.) Tus personajes obtienen Resistencia (Resist) +2 hasta el comienzo de tu próximo turno. (El daño infligido a ellos se reduce en 2.)",
  S2_164_BODY:
    "Destierra el objeto (item) elegido tuyo para infligir 5 puntos de daño al personaje elegido.",
  S2_165_BODY: "Cada oponente revela su mano. Roba una carta.",
  S2_198_BODY:
    "El personaje elegido obtiene Retador (Challenger) +2 y Resistencia (Resist) +2 este turno. (Obtienen +2 de fuerza al desafiar. El daño infligido a ellos se reduce en 2.)",
  S2_199_BODY:
    "(Un personaje con un coste de 3 o más puede agotarse para cantar esta canción gratis.) Inflige 2 puntos de daño al personaje elegido. Roba una carta.",
  S2_200_BODY:
    "El personaje elegido puede desafiar a los personajes preparados este turno.",
  S2_201_BODY:
    "(Un personaje con un coste de 3 o más puede agotarse para cantar esta canción gratis.) Inflige daño al personaje elegido igual al número de personajes que tienes en juego.",

  // FLAVOR
  S2_001_FLAVOR: "La vida es más dulce con amigos.",
  S2_002_FLAVOR:
    "“Mira, Pooh. ¿Alguna vez has visto algo tan grandioso?” -Christopher Robin",
  S2_003_FLAVOR:
    "Con un vestido mágico y una canción en su corazón, deslumbró a todos en el baile.",
  S2_004_FLAVOR:
    "“Déjame entender esto. ¿Tu libro de hechizos ‘mágicos’ fue arrastrado por una inundación de tinta ‘mágica’?”",
  S2_005_FLAVOR: "Él está a cargo de este atuendo.",
  S2_006_FLAVOR: "Es una verdadera joya.",
  S2_007_FLAVOR: "El amor mantiene unido todo.",
  S2_008_FLAVOR: "Nadie . . . canta como Gastón.",
  S2_009_FLAVOR: "Él lleva tener opiniones a un nivel superior.",
  S2_010_FLAVOR: "¡Agrio como una grosella espinosa verde!",
  S2_011_FLAVOR: "No podrías elegir un mejor amigo.",
  S2_012_FLAVOR: "“Tranquilo, chico. Relájate.”",
  S2_013_FLAVOR: "“Vamos-hay mucho por explorar.”",
  S2_015_FLAVOR: "Todo se ve mejor cuando eres libre de ser tú mismo.",
  S2_017_FLAVOR: "Los niños son los mejores amigos de un perro.",
  S2_018_FLAVOR: "Para un animal muy pequeño, tiene un corazón muy grande.",
  S2_020_FLAVOR:
    "“Todos podemos hacer el mundo un poco más brillante a nuestra manera.”",
  S2_021_FLAVOR: "Nunca se cansa de las siestas.",
  S2_022_FLAVOR: "¡Cuidado! ¡Va a explotar!",
  S2_023_FLAVOR: "“¿Por qué estás solo, al igual que yo?”",
  S2_024_FLAVOR: "“Nada dice ‘hola’ mejor que una tarta recién horneada.”",
  S2_027_FLAVOR:
    "No la cuestiones. No dudes de ella. Y sobre todo, no la desobedezcas.",
  S2_028_FLAVOR: "“Esto puede picar un poco.”",
  S2_029_FLAVOR: "“Vamos a terminar con esto, binturi.” -Namaari",
  S2_033_FLAVOR: "La esperanza brilla incluso en las situaciones más oscuras.",
  S2_035_FLAVOR: "“Hmm . . ¿qué hechizo debería intentar a continuación?”",
  S2_036_FLAVOR:
    "“Para hacer realidad el deseo de Geppetto dependerá enteramente de ti.” -Hada Azul",
  S2_037_FLAVOR: "“¿Quieres verme hacer un truco?”",
  S2_039_FLAVOR:
    "El poder del hielo puede no detener la inundación, pero ayudará a proteger a Lorcana.",
  S2_040_FLAVOR:
    "“Usa un hechizo aburrido y terminarás con magia aburrida. ¡Me gusta que mi magia tenga algo especial!”",
  S2_042_FLAVOR:
    "“Tendremos que apresurarnos, porque incluso los milagros tardan un poco de tiempo.”",
  S2_043_FLAVOR: "Poder. Belleza. HeiHei.",
  S2_044_FLAVOR: "“Dicho así, suena bastante bien.”",
  S2_045_FLAVOR:
    "“Así que ahí estaba. Perfectamente bajo control de la situación.”",
  S2_047_FLAVOR: "“¿Dije que no a los dragones morados? ¿Lo dije?”",
  S2_049_FLAVOR: "“¡Te tengo nervioso ahora!”",
  S2_050_FLAVOR: "¡Él saldrá de esto en un instante!",
  S2_051_FLAVOR: "“Siempre fue un viejo terco.” -Madam Mim",
  S2_052_FLAVOR: "Estaba resultando ser un mal día de la liebre.",
  S2_053_FLAVOR: "“Oh, demonios-no puedo decidirme.”",
  S2_054_FLAVOR: "“No siempre puedes confiar en la suerte, muchacho.”",
  S2_056_FLAVOR:
    "“Con esa personalidad, ese perfil, esa complexión . . . Por qué, puedo ver tu nombre en luces, luces de seis pies de altura.” -Honest John",
  S2_057_FLAVOR: "Corrió hacia las Tierras de Tinta sin pensarlo.",
  S2_058_FLAVOR:
    "“Una mentira sigue creciendo y creciendo hasta que es tan evidente como la nariz en tu cara.” -Hada Azul",
  S2_059_FLAVOR:
    "Siempre había sentido un parentesco con la miel. Ambos eran dorados y dulces, y probablemente terminarían en situaciones pegajosas.",
  S2_061_FLAVOR:
    "Yzma: “¡Llamas! ¡Todo lo que veo cuando cierro los ojos son llamas!” Kronk: “Extraño. Yo acabo de ver una en la inundación. Pobrecito.”",
  S2_063_FLAVOR: "“Oh, qué fastidio-otra vez.”",
  S2_064_FLAVOR:
    "Una leyenda se canta cuando Inglaterra era joven y los caballeros eran valientes y audaces",
  S2_065_FLAVOR: "Solo un formulario estándar, nada de qué preocuparse.",
  S2_067_FLAVOR:
    "Alicia: “Solo quería preguntarte qué camino debería tomar.” Gato de Cheshire: “Bueno, eso depende de a dónde quieras llegar.”",
  S2_068_FLAVOR:
    "Los Iluminadores buscan el poder del conocimiento, pero deben ser conscientes del precio.",
  S2_069_FLAVOR:
    "“No es solo caballos elegantes y blandir una espada, ¿sabes? Un verdadero maestro debe usar su cerebro así como su espada.” -Merlín",
  S2_071_FLAVOR: "No hay nada más tentador que una pila de libros no leídos.",
  S2_072_FLAVOR: "Se desliza entre los árboles tan fácilmente como la sombra.",
  S2_073_FLAVOR: "“Hay mucho matiz en ser ardilla.” -Kronk",
  S2_074_FLAVOR:
    "Alicia se sintió bastante confundida. “Pero aquí no veo mucha tinta en absoluto. ¿Cómo la inundación puede seguir cambiando las Tierras de Tinta?” “Las cosas siempre están cambiando, ¿sabes?” dijo el Gato. “Sería un gran cambio si no lo hicieran.”",
  S2_078_FLAVOR: "¡Se dirige hacia un despertar grosero!",
  S2_080_FLAVOR: "Las apariencias pueden ser engañosas.",
  S2_081_FLAVOR:
    "“¡Me encanta un buen club de fans, pero podrían al menos intentar hacer bien la nariz!”",
  S2_083_FLAVOR: "“¿No merezco lo mejor?”",
  S2_084_FLAVOR:
    "“¿Cuál es la prisa, Rob? ¡Relájate! Hay mucho tiempo para buscar fragmentos de Leyenda (Lore) perdido en esa loca tinta.”",
  S2_085_FLAVOR: "“Debe haber algo bueno en él.” -Cenicienta",
  S2_086_FLAVOR:
    "“¡Apúrate! Soy un dios ocupado, mucho que hacer-reuniones, maldiciones, un poco de intriga ligera.” -Hades",
  S2_087_FLAVOR:
    "“¿Quién dice que es difícil encontrar buenos ayudantes en estos días? Oh, sí . . . ¡YO!” -Hades",
  S2_089_FLAVOR: "“¡Impuestos! ¡Impuestos! ¡Impuestos hermosos, encantadores!”",
  S2_090_FLAVOR:
    "“Sabes, podríamos enfurecerla de verdad. ¿Deberíamos intentarlo?” -Gato de Cheshire",
  S2_091_FLAVOR:
    "“¡Me he superado a mí misma esta vez! Pronto tendré todo lo que merezco. ¡Riquezas . . . poder . . . un reino entero a mis pies!”",
  S2_092_FLAVOR: "Puede ser difícil seguirlo, pero su corazón no lo es.",
  S2_093_FLAVOR: "“Esta no es una manzana ordinaria . . .”",
  S2_094_FLAVOR: "Encontrar tu verdadero yo hará brillar tu corazón.",
  S2_095_FLAVOR:
    "“Debo tomar decisiones difíciles para proteger a mi hija, especialmente en este mundo desconocido.”",
  S2_096_FLAVOR: "Hará magia, lo creas o no",
  S2_097_FLAVOR: "“¿Estás listo para saltar un poco?” -Tigger",
  S2_098_FLAVOR: "“Mírame a los ojos cuando te estoy hablando.”",
  S2_099_FLAVOR:
    "Shan-Yu: “Parece que se te acabaron las ideas.” Mulán: “¡Todavía no del todo!”",
  S2_100_FLAVOR:
    "Pacha: “¿Quieres sobrevivir en la jungla? Empieza a pensar como si pertenecieras aquí.” Kuzco: “No hay problema . . . Grrr, mírame, soy un jaguar.”",
  S2_101_FLAVOR: "“Me temo que te has molestado conmigo.” - Ratigan",
  S2_102_FLAVOR: "Simple en propósito, elaborado en ejecución-como Ratigan.",
  S2_103_FLAVOR:
    "“¡Las abejas zumban en el árbol para hacer un poco de miel solo para mí!”",
  S2_104_FLAVOR: "“Si ves caras hambrientas, mándalas hacia mí.”",
  S2_105_FLAVOR: "Tendrás que lidiar con ellos tarde o temprano.",
  S2_107_FLAVOR:
    "“Este no es el juego de gato y ratón como la mayoría, ¿verdad, Dr. Dawson?” -Basil",
  S2_108_FLAVOR: "Cuando un secuaz normal simplemente no es suficiente.",
  S2_109_FLAVOR: "“¡Una cosa así debería valer una fortuna para alguien!”",
  S2_110_FLAVOR:
    "La duodécima Regla de Villanía: Si no tienes un trono, toma uno.",
  S2_111_FLAVOR:
    "“Que no quepa duda: ¡esta vez me aseguraré de que la llave permanezca segura!”",
  S2_112_FLAVOR: "Cuando las cosas se calientan, nadie puede superarlo.",
  S2_113_FLAVOR: "“¡Esto entra en mi top diez de cosas más divertidas!”",
  S2_115_FLAVOR: "¡De cero a diversión en menos de tres segundos!",
  S2_116_FLAVOR: "Sus sentimientos están escritos en su rostro.",
  S2_117_FLAVOR: "“¡Tengo que hacer algo!”",
  S2_118_FLAVOR:
    "“No necesito espadas para vencerte. ¡Solo hacen que sea más divertido!”",
  S2_119_FLAVOR:
    "El brillo del fragmento de Leyenda (Lore) en la inundación atrapó su mirada. “¡El libro de hechizos!” gritó. “¡No dejes que se escape!”",
  S2_121_FLAVOR:
    "“¡Esta vez, nada, ni siquiera Basil, puede interponerse en mi camino!”",
  S2_122_FLAVOR:
    "Dos partes de valentía, una parte de ingenio, y mucha determinación.",
  S2_124_FLAVOR:
    "“Mi ba sueña con un Kumandra unido. Lucho para honrar ese sueño.”",
  S2_126_FLAVOR:
    "La sexta Regla de Villanía: Mantén tu mente aguda y tus garras más afiladas.",
  S2_127_FLAVOR: "¡Saltando para salvar el día!",
  S2_128_FLAVOR: "Un buen amigo siempre está listo para rodar.",
  S2_130_FLAVOR:
    "Por supuesto, quid pro quo, se espera que asumas ciertos deberes.",
  S2_131_FLAVOR:
    "Ahora llega la verdadera obra maestra, astuta y malvada, por supuesto.",
  S2_132_FLAVOR:
    "“Nadie puede tener una opinión más alta de ti que yo, y pienso que eres una rata de alcantarilla despreciable y asquerosa.” -Basil",
  S2_134_FLAVOR: "El delicado sonido de la perdición inminente.",
  S2_135_FLAVOR:
    "Como muchos otros fragmentos de Leyenda (Lore), la daga de Peter Pan estaba segura en el Gran Iluminario hasta la inundación.",
  S2_136_FLAVOR:
    "“Quien saque esta espada de esta piedra y yunque será rey legítimo nacido de Inglaterra.”",
  S2_138_FLAVOR: "Siempre se presenta una solución.",
  S2_139_FLAVOR:
    "“¡Qué ingenioso aparato! Si su luz se refracta a través de estos, entonces sus imágenes deben resolverse en algún lugar debajo.”",
  S2_140_FLAVOR: "“No hay duda: algo está sucediendo en el Gran Iluminario.”",
  S2_141_FLAVOR: "“Mantén tu ritmo.”",
  S2_143_FLAVOR:
    "“Esto ha ido lo suficientemente lejos. Yo estoy a cargo aquí.”",
  S2_144_FLAVOR:
    "“No es simplemente precioso, cariño? Y tan elegante como yo.”",
  S2_145_FLAVOR: "“¡Es alta costura de tinta, cariño. ¡Solo uso lo mejor!”",
  S2_146_FLAVOR: "“Es Wee-sel-ton.”",
  S2_148_FLAVOR: "Cuando habla, incluso el cielo escucha.",
  S2_149_FLAVOR:
    "Sus creaciones son aún más maravillosas con los recursos del Iluminario a su alcance.",
  S2_151_FLAVOR:
    "Puede ser joven, pero tiene el espíritu de un verdadero líder.",
  S2_152_FLAVOR:
    "“Llegaré al fondo de lo que sucedió con ese libro de hechizos cerrado. ¡Puedes contar conmigo!”",
  S2_153_FLAVOR:
    "“Sé exactamente lo que necesitas. Déjame traerte una tetera y algunos de mis frescos bocadillos de queso.“",
  S2_154_FLAVOR: "“¡Es criminal lo bien que saben estas cosas!”",
  S2_156_FLAVOR:
    "“Por ejemplo, basándome en la calidad de la luz y el sutil cambio en la dirección del viento, puedo decir con seguridad que es hora del té.”",
  S2_157_FLAVOR:
    "Había buscado en las Tierras de Tinta a la joven que le había robado el corazón en el baile, solo para encontrar más misterios.",
  S2_158_FLAVOR:
    "Pooh: “¿No hay nadie aquí en absoluto?” Conejo: “¡Nadie!” Pooh: “Alguien está allí . . . porque alguien debe haber dicho ‘Nadie’”",
  S2_159_FLAVOR: "No importa su forma, no puedes equivocarte con su corazón.",
  S2_160_FLAVOR: "Tan escurridizo como el agua.",
  S2_161_FLAVOR:
    "Cuando pensaba, pensaba de la manera más reflexiva que podía pensar.",
  S2_162_FLAVOR:
    "Abajo, abajo, abajo fue, flotando en un remolino de tinta. ¡Qué curioso!",
  S2_164_FLAVOR: "Preparado . . . apunta . . . ¿coco?",
  S2_165_FLAVOR: "Te ayuda a evitar sorpresas desagradables.",
  S2_167_FLAVOR: "“Un regalo tan especial tiene que ser compartido.” -James",
  S2_168_FLAVOR:
    "La solución que necesitas podría estar a solo unos ajustes de distancia.",
  S2_170_FLAVOR:
    "“¡Vuelo 3759 abordando ahora! ¡Vamos a conseguir ese fragmento de Leyenda (Lore)!” -Orville",
  S2_171_FLAVOR: "¡Ay de aquel que atraiga su mirada!",
  S2_172_FLAVOR: "“Tendrás que pasar por encima de mí primero.”",
  S2_173_FLAVOR:
    "“Debe ser mi destino, permanecer en una bestia para siempre.”",
  S2_174_FLAVOR: "“No confundas el espíritu con la habilidad.”",
  S2_175_FLAVOR:
    "“Podemos confirmar que la inundación de tinta fue causada por una explosión. Lo tenemos bajo control-ahora despejen el área.”",
  S2_176_FLAVOR:
    "Siempre ha tenido el corazón de un campeón, ¡ahora también tendrá las habilidades!",
  S2_178_FLAVOR:
    "“Ve adelante, Minnie! Voy a ver si hay algún fragmento de Leyenda (Lore) por aquí.”",
  S2_179_FLAVOR:
    "“¿No suponen que podrían preparar algunos beignets mágicos con esos lanzadores de tinta?”",
  S2_180_FLAVOR:
    "Es un día de banderías para Sir Goofy, que está decidido a demostrar su valía contra cualquiera que busque problemas-por si acaso.",
  S2_181_FLAVOR:
    "Un buen tipo para tener cerca cuando algo arruina tus obras de tinta.",
  S2_182_FLAVOR: "¡No es necesario llamar al IX-I-I!",
  S2_184_FLAVOR: "“¡Pronto aprenderás quién tiene el verdadero poder!”",
  S2_186_FLAVOR: "En este nuevo mundo, finalmente la corona podría ser suya.",
  S2_187_FLAVOR: "“Aprende qué hacer, luego aprende a hacerlo sin pensar.”",
  S2_188_FLAVOR:
    "Incluso con un peso de carga de “seriamente, mucho”, solo puede hacer tanto en una inundación mágica.",
  S2_190_FLAVOR:
    "“No te dejes engañar por el aspecto campesino de la gente.” -Kuzco",
  S2_191_FLAVOR: "Todo lo que tiene es su encanto.",
  S2_192_FLAVOR:
    "La cuarta Regla de Villanía: Haz lo que sea necesario para salir adelante.",
  S2_193_FLAVOR: "“¿Capaz? No conoces ni la mitad.” -Pequeño Juan",
  S2_194_FLAVOR: "“¡Huye, escóndete! ¡En el bosque, en cualquier lugar!”",
  S2_197_FLAVOR:
    "“Mi lugar va a ser especial, con comida deliciosa para llenar los vientres de la gente y jazz caliente para alimentar sus almas.”",
  S2_198_FLAVOR:
    "A veces se requiere sutileza. Este no es uno de esos momentos.",
  S2_199_FLAVOR: "El frío nunca me molestó de todos modos.",
  S2_200_FLAVOR: "“¡Voy a destruirlo!”",
  S2_201_FLAVOR: "Tranquilo como un bosque, pero ardiendo por dentro.",
  S2_202_FLAVOR: "Un solo disparo puede cambiarlo todo.",
  S2_203_FLAVOR:
    "Construido por las manos más pequeñas para los corazones más valientes.",
  S2_204_FLAVOR: "¡Personalmente respaldado por Hércules mismo!",

  // KEYWORDS TEXT CHARACTERS: SHIFT_X set 2
  S2_016_SHIFT_TEXT:
    "2 (Puedes pagar 2 de tinta para jugar esto encima de uno de tus personajes llamado Mulan.)",
  S2_019_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Rapunzel.)",
  S2_025_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Blancanieves (Snow White).)",
  S2_026_SHIFT_TEXT:
    "2 (Puedes pagar 2 de tinta para jugar esto encima de uno de tus personajes llamado La Reina (The Queen).)",
  S2_041_SHIFT_TEXT:
    "2 (Puedes pagar 2 de tinta para jugar esto encima de uno de tus personajes llamado Hada Madrina (Fairy Godmother).)",
  S2_048_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Madam Mim.)",
  S2_057_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Pinocho (Pinocchio).)",
  S2_060_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Yzma.)",
  S2_072_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Bella (Belle).)",
  S2_075_SHIFT_TEXT:
    "5 (Puedes pagar 5 de tinta para jugar esto encima de uno de tus personajes llamado Gato de Cheshire (Cheshire Cat).)",
  S2_077_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Pato Donald (Donald Duck).)",
  S2_082_SHIFT_TEXT:
    "2 (Puedes pagar 2 de tinta para jugar esto encima de uno de tus personajes llamado Flynn Rider.)",
  S2_110_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Lady Tremaine.)",
  S2_114_SHIFT_TEXT:
    "2 (Puedes pagar 2 de tinta para jugar esto encima de uno de tus personajes llamado Minnie Mouse.)",
  S2_120_SHIFT_TEXT:
    "2 (Puedes pagar 2 de tinta para jugar esto encima de uno de tus personajes llamado Reina de Corazones (Queen of Hearts).)",
  S2_123_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Raya.)",
  S2_138_SHIFT_TEXT:
    "5 (Puedes pagar 5 de tinta para jugar esto encima de uno de tus personajes llamado Basil.)",
  S2_142_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Din Don (Cogsworth).)",
  S2_145_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Cruella De Vil.)",
  S2_147_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Gastón.)",
  S2_173_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Bestia (Beast).)",
  S2_177_SHIFT_TEXT:
    "5 (Puedes pagar 5 de tinta para jugar esto encima de uno de tus personajes llamado Cenicienta (Cinderella).)",
  S2_181_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Hércules.)",
  S2_183_SHIFT_TEXT:
    "2 (Puedes pagar 2 de tinta para jugar esto encima de uno de tus personajes llamado Jafar.)",
  S2_206_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Blancanieves (Snow White).)",
  S2_211_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Lady Tremaine.)",
  S2_215_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Hércules.)",

  // SET 3

  // ITEM CARD set 3
  CLEANSING_RAINWATER: "Agua de Lluvia Purificadora",
  HEART_OF_ATLANTIS: "Corazón de Atlantis",
  WILDCATS_WRENCH: "Llave Inglesa del Gato Salvaje",
  THE_LAMP: "La Lámpara",
  THE_SORCERERS_HAT: "El Sombrero del Hechicero",
  AIRFOIL: "Ala de Avión",
  ROBINS_BOW: "Arco de Robin",
  STARLIGHT_VIAL: "Frasco de Luz Estelar",
  MAUIS_FISH_HOOK: "Gancho de Pescar de Maui",
  SUMERIAN_TALISMAN: "Talismán Sumerio",
  AURELIAN_GYROSENSOR: "Giroscopio Aureliano",
  HEART_OF_TE_FITI: "Corazón de Te Fiti",
  LUCKY_DIME: "Moneda de la Suerte",
  SCROOGES_TOP_HAT: "Sombrero de Copa de Rico McPato",
  VAULT_DOOR: "Puerta de la Cámara Acorazada",
  CAPTAIN_HOOKS_RAPIER: "Espada de Capitán Garfio",
  GIZMOSUIT: "Traje de Gadget",
  MAP_OF_TREASURE_PLANET: "Mapa del Planeta del Tesoro",

  // ACTION CARD set 3
  PUPPIES_99: "99 Cachorros",
  BOSSS_ORDERS: "Órdenes del Jefe",
  QUICK_PATCH: "Parche Rápido",
  BESTOW_A_GIFT: "Conferir un Regalo",
  LAST_DITCH_EFFORT: "Esfuerzo Final",
  DIVEBOMB: "Bomba en Picado",
  ON_YOUR_FEET_NOW: "¡De Pie! ¡Ahora!",
  VOYAGE: "Viaje",
  DISTRACT: "Distraer",
  REPAIR: "Reparar",
  BA_BOOM: "¡Bum-Bum!",
  OLYMPUS_WOULD_BE_THAT_WAY: "El Olimpo Sería Así",
  RISE_OF_THE_TITANS: "El Ascenso de los Titanes",

  // ACTION SONG CARD set 3
  HEAL_WHAT_HAS_BEEN_HURT: "Curar lo que ha sido Herido",
  THE_BARE_NECESSITIES: "Las Necesidades Básicas",
  IT_CALLS_ME: "Me Llama",
  THE_BOSS_IS_ON_A_ROLL: "El Jefe Está en Racha",
  I_WILL_FIND_MY_WAY: "Encontraré Mi Camino",
  STRIKE_A_GOOD_MATCH: "Hacer una Buena Combinación",
  I_HAVE_GOT_A_DREAM: "Tengo un Sueño",
  FRIEND_LIKE_ME: "Amigo Como Yo",
  HOW_FAR_ILL_GO: "Cuán lejos voy",
  AND_THEN_ALONG_CAME_ZEUS: "Y Luego Apareció Zeus",
  FOUR_DOZEN_EGGS: "Cuatro Docenas de Huevos",

  // LOCATION CARD set 3
  NEVER_LAND: "Tierra de Nunca Jamás",
  PRIDE_LANDS: "Tierras del Orgullo",
  TIANAS_PALACE: "Palacio de Tiana",
  FORBIDDEN_MOUNTAIN: "Montaña Prohibida",
  THE_QUEENS_CASTLE: "El Castillo de la Reina",
  THE_SORCERERS_TOWER: "La Torre del Hechicero",
  DE_VIL_MANOR: "Mansión De Vil",
  FANG: "Colmillo",
  KUZCOS_PALACE: "EL Palacio de Kuzco",
  AGRABAH: "Agrabah",
  JOLLY_ROGER: "Jolly Roger",
  RLS_LEGACY: "Legado RLS",
  BELLES_HOUSE: "Casa de Bella",
  MCDUCK_MANOR: "Mansión McDuck",
  MOTUNUI: "Motunui",
  MAUIS_PLACE_OF_EXILE: "Lugar de Exilio de Maui",
  NOTTINGHAM: "Nottingham",

  // VERSION NAME set 3
  S3_001_VERSION_NAME: "von Bruinwald XIII",
  S3_002_VERSION_NAME: "Agente Nuevo",
  S3_003_VERSION_NAME: "Malvado",
  S3_004_A_VERSION_NAME: "Meneador de Cola",
  S3_005_VERSION_NAME: "El Doctor",
  S3_006_VERSION_NAME: "Atlante",
  S3_007_VERSION_NAME: "Protector de Atlantis",
  S3_008_VERSION_NAME: "El 15º Cachorro",
  S3_009_VERSION_NAME: "Artista Musical",
  S3_010_VERSION_NAME: "Agente de la Sociedad de Rescate",
  S3_011_VERSION_NAME: "Empresario Inepto",
  S3_012_VERSION_NAME: "Hermana Protectora",
  S3_013_VERSION_NAME: "Piloto As",
  S3_014_VERSION_NAME: "Cachorro Intimidante",
  S3_015_VERSION_NAME: "Madre Devota",
  S3_016_VERSION_NAME: "Capitán Pirata Pooh",
  S3_017_VERSION_NAME: "Defensor Determinado",
  S3_018_VERSION_NAME: "Cachorro Amigable",
  S3_019_VERSION_NAME: "Padre Determinado",
  S3_020_VERSION_NAME: "Emperatriz de Wonderland",
  S3_021_VERSION_NAME: "Cachorro Hambriento",
  S3_022_VERSION_NAME: "Hada Generosa",
  S3_023_VERSION_NAME: "Marinero Talentoso",
  S3_032_VERSION_NAME: "Laguna de Sirenas",
  S3_033_VERSION_NAME: "Roca del Orgullo",
  S3_034_VERSION_NAME: "Restaurante de Jazz",
  S3_035_VERSION_NAME: "Alquimista del Té",
  S3_036_VERSION_NAME: "Criaturas Malévolas",
  S3_037_VERSION_NAME: "Mascota Fiel",
  S3_038_VERSION_NAME: "Amigo de Apoyo",
  S3_039_VERSION_NAME: "Titán de Hielo",
  S3_040_VERSION_NAME: "Bonita Polly",
  S3_041_VERSION_NAME: "Ladrón de Lámparas",
  S3_042_VERSION_NAME: "Ilusionista Impactante",
  S3_043_VERSION_NAME: "Adolescente Rebelde",
  S3_044_VERSION_NAME: "Plumero Bailarín",
  S3_045_VERSION_NAME: "Limpiador Veloz",
  S3_046_VERSION_NAME: "El Gran Barredor",
  S3_047_VERSION_NAME: "Alfombra Voladora",
  S3_048_VERSION_NAME: "Bruja Ambiciosa",
  S3_049_VERSION_NAME: "El Toque de Midas",
  S3_050_VERSION_NAME: "Hechicera Ladrona",
  S3_051_VERSION_NAME: "Amo de Todos los Malvados",
  S3_052_VERSION_NAME: "Voz de la Sabiduría",
  S3_053_VERSION_NAME: "Amigo Barrigón",
  S3_054_VERSION_NAME: "Luchador Místico",
  S3_055_VERSION_NAME: "Titán del Tornado",
  S3_056_VERSION_NAME: "Fuerza de la Destrucción",
  S3_057_VERSION_NAME: "Rival Odioso",
  S3_058_VERSION_NAME: "Protector de la Cueva",
  S3_059_VERSION_NAME: "Bruja del Mar",
  S3_066_VERSION_NAME: "Castillo de Maléfica",
  S3_067_VERSION_NAME: "Cámara de los Espejos",
  S3_068_VERSION_NAME: "Espacio de Trabajo Maravilloso",
  S3_069_VERSION_NAME: "Niño Perdido Poderoso",
  S3_070_VERSION_NAME: "Obra de Ursula",
  S3_071_VERSION_NAME: "Príncipe de los Piratas",
  S3_072_VERSION_NAME: "Rufián",
  S3_073_VERSION_NAME: "Sacerdote de Nottingham",
  S3_074_VERSION_NAME: "Mujer Fatal",
  S3_075_VERSION_NAME: "Socio Vengativo",
  S3_076_VERSION_NAME: "Rufián",
  S3_077_VERSION_NAME: "Tipo Duro",
  S3_078_VERSION_NAME: "Mercenario Astuto",
  S3_079_VERSION_NAME: "Cartógrafo Ingenioso",
  S3_080_VERSION_NAME: "Rey de Atlantis",
  S3_081_VERSION_NAME: "Goo Espacial",
  S3_082_VERSION_NAME: "Líder de los Niños Perdidos",
  S3_083_VERSION_NAME: "Rey Falso",
  S3_084_VERSION_NAME: "Soñador",
  S3_085_VERSION_NAME: "Líder de la Manada de Hienas",
  S3_086_VERSION_NAME: "Áspid Irritante",
  S3_087_VERSION_NAME: "Conejo Energético",
  S3_088_VERSION_NAME: "Pirata Astuto",
  S3_089_VERSION_NAME: "Agente Encubierto",
  S3_090_VERSION_NAME: "Engañador",
  S3_091_VERSION_NAME: "Engañador de Todos",
  S3_092_VERSION_NAME: "Mecánico",
  S3_093_VERSION_NAME: "Guardián de las Tierras del Orgullo",
  S3_100_VERSION_NAME: "Mansión de Cruella",
  S3_101_VERSION_NAME: "Ciudad del Río",
  S3_102_VERSION_NAME: "Hogar del Emperador",
  S3_103_VERSION_NAME: "Coleccionista Aventurero",
  S3_104_VERSION_NAME: "Guardián del Mapa",
  S3_105_VERSION_NAME: "Esgrimista Maestro",
  S3_106_VERSION_NAME: "Madre Imparable",
  S3_107_VERSION_NAME: "Explorador Accidental",
  S3_108_VERSION_NAME: "Serpiente Mortal",
  S3_109_VERSION_NAME: "Viajero Espacial",
  S3_110_VERSION_NAME: "Buscador de Emociones",
  S3_111_VERSION_NAME: "Marinero Amenazante",
  S3_112_VERSION_NAME: "El Jefe",
  S3_113_VERSION_NAME: "Semidiós Volador",
  S3_114_VERSION_NAME: "Ballena",
  S3_115_VERSION_NAME: "Erudito Espiritado",
  S3_116_VERSION_NAME: "Líder Nato",
  S3_117_VERSION_NAME: "Viajero Sin Temor",
  S3_118_VERSION_NAME: "Sicario de Buitres",
  S3_119_VERSION_NAME: "Héroe de Nunca Jamás",
  S3_120_VERSION_NAME: "Azote de Piratas",
  S3_121_VERSION_NAME: "Timonel Experto",
  S3_122_VERSION_NAME: "Traidor",
  S3_123_VERSION_NAME: "Cachorro Luchador",
  S3_124_VERSION_NAME: "Niño Perdido",
  S3_125_VERSION_NAME: "Cohete Pequeño",
  S3_126_VERSION_NAME: "Tirador No Tan Agudo",
  S3_127_VERSION_NAME: "Pato Entusiasta",
  S3_134_VERSION_NAME: "Mercado",
  S3_135_VERSION_NAME: "Barco de Garfio",
  S3_136_VERSION_NAME: "Galeón Solar",
  S3_137_VERSION_NAME: "El Ingeniero",
  S3_138_VERSION_NAME: "El Primero al Mando",
  S3_139_VERSION_NAME: "Sobrino Llamativo",
  S3_140_VERSION_NAME: "Tramposo Solitario",
  S3_141_VERSION_NAME: "Apretado en la Lámpara",
  S3_142_VERSION_NAME: "Guardián de Historias Antiguas",
  S3_143_VERSION_NAME: "Espíritu del Océano",
  S3_144_VERSION_NAME: "Genio de los Gadgets",
  S3_145_VERSION_NAME: "Sobrino Astuto",
  S3_146_VERSION_NAME: "Director de Banda",
  S3_147_VERSION_NAME: "Navegante",
  S3_148_VERSION_NAME: "Cachorro de Oso Dinámico",
  S3_149_VERSION_NAME: "Sobrino Tranquilo",
  S3_150_VERSION_NAME: "Soñador Encantador",
  S3_151_VERSION_NAME: "Mago Misterioso",
  S3_152_VERSION_NAME: "Amigo Ingenioso de Mickey",
  S3_153_VERSION_NAME: "Gato del Orfanato",
  S3_154_VERSION_NAME: "Pato Más Rico del Mundo",
  S3_155_VERSION_NAME: "Tío Gorriones",
  S3_156_VERSION_NAME: "Buscador de Espejos",
  S3_157_VERSION_NAME: "Hada Muy Ingeniosa",
  S3_158_VERSION_NAME: "Autoridad en Peter Pan",
  S3_168_VERSION_NAME: "Taller de Maurice",
  S3_169_VERSION_NAME: "Mansión de Tío McPato",
  S3_170_VERSION_NAME: "Paraíso Isla",
  S3_171_VERSION_NAME: "Orgulloso de Motunui",
  S3_172_VERSION_NAME: "Burro Demasiado Lleno",
  S3_173_VERSION_NAME: "Terror del Reino",
  S3_174_VERSION_NAME: "Gobernante Impetuoso",
  S3_175_VERSION_NAME: "Mujer de Mano Derecha",
  S3_176_VERSION_NAME: "Buscador de Tesoros Codicioso",
  S3_177_VERSION_NAME: "Guerrero Real",
  S3_178_VERSION_NAME: "Forajido Ingenioso",
  S3_179_VERSION_NAME: "Amigo de Robin",
  S3_180_VERSION_NAME: "Titán de Roca",
  S3_181_VERSION_NAME: "Explorador Valiente",
  S3_182_VERSION_NAME: "Trompetista",
  S3_183_VERSION_NAME: "Espeleólogo Alegre",
  S3_184_VERSION_NAME: "Compañero Torpe",
  S3_185_VERSION_NAME: "Campeón de las Tierras del Orgullo",
  S3_186_VERSION_NAME: "Amigo Feroz",
  S3_187_VERSION_NAME: "Titán de Lava",
  S3_188_VERSION_NAME: "Guardia del Palacio",
  S3_189_VERSION_NAME: "Forajido Querido",
  S3_190_VERSION_NAME: "Campeón de Sherwood",
  S3_191_VERSION_NAME: "Oficial Corrupto",
  S3_192_VERSION_NAME: "Príncipe Luchador",
  S3_193_VERSION_NAME: "Rey Legítimo",
  S3_194_VERSION_NAME: "Líder Metálico",
  S3_202_VERSION_NAME: "Isla Oculta",
  S3_203_VERSION_NAME: "Castillo del Príncipe Juan",
  S3_204_VERSION_NAME: "Pantano Misterioso",

  // ABILITIES set 3
  S3_001_ABILITY:
    "¡HAGAMOS COMO UN ÁRBOL: Cuando este personaje sea desterrado, gana 2 puntos de Leyenda (Lore).",
  S3_002_ABILITY:
    "VOY A ECHAR UN VISTAZO: Al final de tu turno, si este personaje está agotado, puedes preparar otro personaje elegido tuyo.",
  S3_003_ABILITY:
    "EL PODER DEL MAL: Cuando juegas este personaje, pagas 1 tinta menos por cada carta de personaje en tu descarte. INVOCAR A LOS ESPÍRITUS: Cuando juegas este personaje, baraja todas las cartas de personaje de tu descarte en tu mazo.",
  S3_004_A_ABILITY:
    "¿DE DÓNDE SALIERON TODOS?: Puedes tener hasta 99 copias de Cachorro Dálmata - Meneador de Cola (Dalmatian Puppy - Tail Wagger) en tu mazo.",
  S3_007_ABILITY:
    "TAL VEZ PODAMOS SALVAR NUESTRO FUTURO: Cuando juegas este personaje, todos los personajes obtienen -3 de fuerza hasta el comienzo de tu próximo turno.",
  S3_008_ABILITY:
    "COMO NUEVO: Agotar - Revela las 3 cartas superiores de tu mazo. Puedes poner cada carta de personaje con un coste de 2 o menos en tu mano. Pon el resto en la parte inferior de tu mazo en cualquier orden. AMOR DE CACHORRO: Siempre que este personaje explore (Quest), si tienes 4 o más personajes en juego, tus otros personajes obtienen +1 punto de Leyenda (Lore) este turno.",
  S3_009_ABILITY:
    "SÉQUITO: Siempre que juegues un personaje con Guardaespaldas (Bodyguard), puedes eliminar hasta 2 de daño de un personaje elegido.",
  S3_014_ABILITY:
    "LADRIDO: Agotar - El personaje elegido obtiene -2 de fuerza hasta el comienzo de tu próximo turno.",
  S3_015_ABILITY:
    "VENID, NIÑOS: Cuando juegas este personaje y siempre que ella explore (Quest), puedes jugar un personaje con un coste de 2 o menos de tu descarte gratis.",
  S3_016_ABILITY:
    "¡Y YO SOY EL CAPITÁN!: Mientras tengas 2 o más personajes en juego, este personaje obtiene +2 puntos de Leyenda (Lore).",
  S3_017_ABILITY:
    "PERRO GUARDIÁN: Al comienzo de tu turno, elimina hasta 3 de daño de este personaje.",
  S3_018_ABILITY:
    "BUEN PERRO: Agotar - Pagas 1 tinta menos por el próximo personaje que juegues este turno.",
  S3_019_ABILITY:
    "LADRIDO DEL CREPÚSCULO: Una vez por turno, puedes pagar 2 tintas para revelar la carta superior de tu mazo. Si es una carta de personaje, ponla en tu mano. De lo contrario, ponla en la parte inferior de tu mazo.",
  S3_020_ABILITY:
    "TODOS LOS CAMINOS AQUÍ SON MIS CAMINOS: Siempre que este personaje explore (Quest), tus otros personajes Villanos obtienen +1 punto de Leyenda (Lore) este turno.",
  S3_022_ABILITY:
    "HACER UN NUEVO AMIGO: Cuando juegas este personaje, mira las 4 cartas superiores de tu mazo. Puedes revelar una carta de personaje y ponerla en tu mano. Coloca el resto en la parte inferior de tu mazo en cualquier orden.",
  S3_029_ABILITY:
    "PODER ANTIGUO: Destierra este objeto (Item) - Elimina hasta 2 de daño de cada uno de tus personajes.",
  S3_030_ABILITY:
    "DADOR DE VIDA: Agotar - Pagas 2 tintas menos por el próximo personaje que juegues este turno.",
  S3_031_ABILITY:
    "RECONSTRUIR: Agotar - Elimina hasta 2 de daño de la ubicación (Location) elegida.",
  S3_033_ABILITY:
    "TODOS ESTAMOS CONECTADOS: Los personajes obtienen +2 de voluntad (Willpower) mientras estén aquí. HOGAR DEL LEÓN: Si tienes un personaje Príncipe (Prince) o Rey (King) aquí, pagas 1 tinta menos para jugar personajes.",
  S3_034_ABILITY:
    "SALIDA NOCTURNA: Los personajes no pueden ser desafiados mientras estén aquí.",
  S3_035_ABILITY:
    "MÁS CURIOSO Y CURIOSO: Agotar - Agota al personaje oponente elegido y a todos los demás personajes oponentes con el mismo nombre.",
  S3_036_ABILITY:
    "ALMAS INQUIETAS: Siempre que este personaje explore (Quest), puedes desterrar este personaje para robar una carta.",
  S3_037_ABILITY:
    "EN BUSCA DE AURORA: Siempre que juegues un personaje llamado Maléfica, puedes mirar la carta superior de tu mazo. Ponla en la parte superior o inferior de tu mazo.",
  S3_038_ABILITY:
    "TRES DESEOS: Siempre que este personaje explore (Quest), puedes barajar esta carta en tu mazo para robar 3 cartas.",
  S3_039_ABILITY: "VENTISCA: Agotar - Agota al personaje elegido.",
  S3_041_ABILITY:
    "AHORA SOY TU MAESTRO: Cuando juegas este personaje, mira las 2 cartas superiores de tu mazo. Pon una en la parte superior de tu mazo y la otra en la parte inferior.",
  S3_042_ABILITY:
    "PODER MÁS ALLÁ DE LA MEDIDA: Durante tu turno, mientras este personaje esté agotado, cada vez que robes una carta, gana 1 punto de Leyenda (Lore).",
  S3_044_ABILITY:
    "LIMPIEZA DE PODER: Cuando juegas este personaje, si tienes un personaje Hechicero (Sorcerer) en juego, puedes agotar al personaje oponente elegido. No pueden prepararse al comienzo de su próximo turno.",
  S3_045_ABILITY:
    "LIMPIA ESTO, LIMPIA AQUELLO: Cuando juegas este personaje, puedes barajar todas las cartas Escoba (Broom) de tu descarte en tu mazo.",
  S3_046_ABILITY:
    "BARRIDO LIMPIO: Mientras este personaje esté en una ubicación (Location), obtiene +2 de fuerza.",
  S3_047_ABILITY:
    "ENCONTRAR EL CAMINO: Agotar - Mueve un personaje tuyo a una ubicación (Location) gratis.",
  S3_049_ABILITY:
    "TODO MÍO: Siempre que este personaje explore (Quest), gana puntos de Leyenda (Lore) igual al coste de uno de tus objetos (Items) en juego.",
  S3_050_ABILITY:
    "TELEQUINESIS: Agotar - Devuelve el objeto (Item) elegido con un coste igual o menor que la fuerza de este personaje a la mano de su jugador.",
  S3_051_ABILITY:
    "<1>CONOCIMIENTO OSCURO:</1> Siempre que este personaje explore (Quest), puedes robar una carta. <2/><1>ADIVINACIÓN:</1> Durante tu turno, cada vez que robes una carta, puedes mover 1 contador de daño de un personaje elegido a un personaje oponente elegido.",
  S3_052_ABILITY:
    "ESCUCHE A SU MAMÁ AHORA: Siempre que este personaje explore (Quest), puedes mover hasta 2 contadores de daño del personaje elegido al personaje oponente elegido.",
  S3_053_ABILITY:
    "SIEMPRE PRESENTE: Cuando este personaje sea desterrado, puedes barajar esta carta en tu mazo.",
  S3_054_ABILITY:
    "HABILIDADES ANTIGUAS: Siempre que desafíe a un personaje Hiena (Hyena), este personaje no recibe daño del desafío.",
  S3_055_ABILITY:
    "CICLÓN: Agotar - Gana puntos de Leyenda (Lore) igual al número de personajes Titán que tengas en juego.",
  S3_058_ABILITY:
    "¿QUIÉN INTERFIERE EN MI SUEÑO?: Este personaje no puede desafiar ni explorar (Quest) a menos que esté en una ubicación (Location).",
  S3_059_ABILITY:
    "LLEGASTE TARDE: Siempre que este personaje explore (Quest), el personaje oponente elegido no puede prepararse al comienzo de su próximo turno.",
  S3_064_ABILITY:
    "BUENO O MALO: Destierra este objeto (Item) - Si tienes un personaje llamado Jafar en juego, roba 2 cartas. Si tienes un personaje llamado Genio (Genie) en juego, devuelve un personaje elegido con coste de 4 o menos a la mano de su jugador.",
  S3_065_ABILITY:
    "ENERGÍA INCREÍBLE: agotar, 1 tinta - Nombra una carta, luego revela la carta superior de tu mazo. Si es la carta nombrada, pon esa carta en tu mano. De lo contrario, ponla en la parte superior de tu mazo.",
  S3_067_ABILITY:
    "USANDO EL ESPEJO: Al comienzo de tu turno, por cada personaje que tengas aquí, puedes robar una carta.",
  S3_068_ABILITY:
    "<1>ARMARIO DE ESCOBA:</1> Tus personajes llamados Escoba Mágica (Magic Broom) pueden moverse aquí gratis. <2/><1>PODER MÁGICO:</1> Los personajes obtienen +1 punto de Leyenda (Lore) mientras estén aquí.",
  S3_069_ABILITY:
    "EL OSO: Siempre que este personaje se mueva a una ubicación (Location), obtiene +3 de fuerza este turno.",
  S3_070_ABILITY:
    "ALMAS DESDICHADAS: Siempre que este personaje sea desafiado, cada oponente elige y descarta una carta.",
  S3_072_ABILITY:
    "DUETO SINIESTRO: Tus personajes llamados Jetsam obtienen +3 de fuerza.",
  S3_073_ABILITY:
    "LADRÓN DESALMADO: Cuando juegas este personaje, el jugador o los jugadores con más cartas en su mano eligen y descartan una carta.",
  S3_074_ABILITY:
    "ESTO CAMBIA TODO: Siempre que este personaje explore (Quest), puedes infligir 3 de daño al personaje dañado elegido.",
  S3_075_ABILITY:
    "NADA PERSONAL: Cuando este personaje es desafiado y desterrado, destierra al personaje que desafió.",
  S3_076_ABILITY:
    "DUETO SINIESTRO: Tus personajes llamados Flotsam obtienen Guarda (Ward).",
  S3_077_ABILITY:
    "SURFEANDO EN EL CIELO: Cuando juegas este personaje, puedes devolver el personaje oponente elegido con 2 de fuerza o menos a la mano de su jugador.",
  S3_078_ABILITY:
    "<1>BUENO, AHORA LO SABES:</1> Cuando juegas este personaje, el personaje oponente elegido gana Temerario (Reckless) durante su próximo turno. (No pueden explorar (Quest) y deben desafiar si pueden). <2/><1>GRACIAS POR VOLUNTARIARTE:</1> Siempre que uno de tus otros personajes sea desterrado, cada oponente pierde 1 punto de Leyenda (Lore).",
  S3_080_ABILITY:
    "SOPRESA: Cuando este personaje es desterrado, devuelve todos los personajes oponentes a las manos de sus jugadores.",
  S3_081_ABILITY:
    "MIMETISMO: Puedes jugar cualquier personaje con Cambio (Shift) en este personaje como si este personaje tuviera cualquier nombre.",
  S3_082_ABILITY:
    "VINE A ESCUCHAR LAS HISTORIAS: Una vez por turno, cuando este personaje se mueve a una ubicación (Location), gana puntos de Leyenda (Lore) igual a los punto de Leyenda (Lore) de esa ubicación.",
  S3_083_ABILITY:
    "COBRAR IMPUESTOS: Siempre que este personaje explore (Quest), cada oponente con más puntos de Leyenda (Lore) que tú pierde 2 puntos de Leyenda (Lore).",
  S3_085_ABILITY:
    "<1>LO HARÉ:</1> Cuando este personaje esté en una ubicación (Location), obtiene +3 de fuerza. <2/><1>¿CUÁL ES LA PRISA?:</1> Cuando este personaje esté en una ubicación, cuando desafíe a otro personaje, puedes robar una carta.",
  S3_089_ABILITY:
    "ESCONDERSE: Mientras este personaje esté en una ubicación (Location), obtiene Guarda (Ward). (Los oponentes no pueden elegirlos excepto para desafiarlos.)",
  S3_090_ABILITY:
    "NI SIQUIERA LO EXTRAÑARÁS: Cuando juegas este personaje, el oponente elegido revela su mano y descarta una carta de canción (Action - Song) de tu elección.",
  S3_091_ABILITY:
    "QUÉ OFERTA: Siempre que este personaje cante una canción (Action - Song), puedes jugar esa canción nuevamente desde tu descarte gratis, luego ponla en la parte inferior de tu mazo.",
  S3_092_ABILITY: "DESARMAR: Agotar - Destierra el objeto (Item) elegido.",
  S3_093_ABILITY:
    "¡ES HORA DE IRSE!: Mientras este personaje esté en una ubicación (Location), obtiene +1 punto de Leyenda (Lore).",
  S3_097_ABILITY:
    "ME TENGO QUE IR: Agotar - Si has jugado 2 o más acciones (Actions) este turno, roba una carta.",
  S3_098_ABILITY:
    "<1>REGALO DEL BOSQUE:</1> Agotar - Inflige 1 de daño al personaje o ubicación (Location) dañados elegidos. <2/><1>UN POCO DE TRAVESURA:</1> Siempre que un personaje tuyo llamado Robin Hood explore (Quest), puedes preparar este objeto (Item).",
  S3_099_ABILITY:
    "<1>ENERGÍA EFICIENTE:</1> Agotar - Puedes pagar 2 tintas menos por la siguiente acción (Action) que juegues este turno. <2/><1>TRAMPA:</1> 2 tintas, Destierra este objeto (Item) - Roba 2 cartas, luego elige y descarta una carta.",
  S3_101_ABILITY:
    "RODEADO DE AGUA: Los personajes obtienen Guarda (Ward) y Escurridizo (Evasive) mientras estén aquí. (Los oponentes no pueden elegirlos excepto para desafiarlos. Solo los personajes con Escurridizo pueden desafiarlos.)",
  S3_102_ABILITY:
    "MURALLAS DE LA CIUDAD: Siempre que un personaje sea desafiado y desterrado mientras esté aquí, destierra al personaje que desafió.",
  S3_103_ABILITY:
    "VOZ INSPIRADORA: Siempre que juegues una canción (Action - Song), el personaje tuyo elegido obtiene Escurridizo (Evasive) hasta el comienzo de tu próximo turno.",
  S3_105_ABILITY:
    "<1>NÉMESIS:</1> Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, prepara este personaje. No puede explorar (Quest) durante el resto de este turno. <2/><1>CARA A CARA:</1> Los personajes llamados Peter Pan pierden Escurridizo (Evasive) y no pueden obtener Escurridizo.",
  S3_107_ABILITY:
    "DEAMBULANDO SIN PENSAMIENTOS: Una vez por turno, cuando este personaje se mueve a una ubicación (Location), cada oponente pierde 1 punto de Leyenda (Lore).",
  S3_108_ABILITY:
    "CUIDADO CON LOS DIENTES: Cuando este personaje recibe daño, inflige la misma cantidad de daño a un personaje oponente elegido.",
  S3_109_ABILITY:
    "<1>¡ESTO ES!:</1> Cuando juegas este personaje, puedes jugar una ubicación (Location) con un coste de 4 o menos gratis. <2/><1>TOMA EL TIMÓN:</1> Siempre que juegues una ubicación, este personaje puede moverse allí gratis.",
  S3_111_ABILITY:
    "SAQUEO: Cuando juegas este personaje, cada oponente pierde 1 punto de Leyenda (Lore).",
  S3_112_ABILITY:
    "ESA MUJER TAN TERRIBLE: Cuando juegas este personaje, destierra al personaje oponente elegido con 3 de fuerza o menos.",
  S3_113_ABILITY:
    "EN MI ESTÓMAGO: Siempre que uno de tus personajes llamados Heihei explore (Quest), este personaje obtiene +1 punto de Leyenda (Lore) y pierde Temerario (Reckless) durante este turno.",
  S3_114_ABILITY:
    "<1>ESTA MISIÓN ESTÁ MALDITA:</1> Este personaje no puede prepararse al comienzo de tu turno. <2/><1>YO TE APOYO:</1> 2 tintas - Prepara este personaje. No puede explorar (Quest) durante el resto de este turno.",
  S3_115_ABILITY:
    "¡SOY TU HOMBRE!: Mientras este personaje esté en una ubicación, obtiene +2 fuerza.",
  S3_116_ABILITY:
    "BIENVENIDO A MI BARCO: Siempre que este personaje explore (Quest) estando en una ubicación (Location), prepara a todos los demás personajes aquí. No pueden explorar (Quest) durante el resto de este turno.",
  S3_119_ABILITY:
    "AQUÍ, TINK: Mientras tengas un personaje llamado Campanita (Tinker Bell) en juego, este personaje obtiene +2 de fuerza.",
  S3_120_ABILITY:
    "¡TE TOCA!: Siempre que desafíe a un personaje Pirata, este personaje no recibe daño del desafío.",
  S3_121_ABILITY:
    "MANIOBRA SORPRESA: Cuando este personaje es desterrado, puedes desterrar al personaje elegido.",
  S3_122_ABILITY:
    "BESTIA: Mientras este personaje tenga daño, obtiene +3 de fuerza.",
  S3_124_ABILITY:
    "EL ZORRO: Si tienes un personaje llamado Peter Pan en juego, pagas 1 tinta menos para jugar este personaje.",
  S3_126_ABILITY:
    "VIEJA BETSY: Tus personajes llamados Lelo (Nutsy) obtienen +1 punto de Leyenda (Lore).",
  S3_132_ABILITY:
    "<1>¡ES HORA DE MAUI!:</1> Si tienes un personaje llamado Maui en juego, puedes usar la habilidad de Cambio de forma (Shapeshift) de este objeto (Item) gratis. <2/><1>CAMBIO DE FORMA:</1> Agotar, 2 tintas - Elige uno: · El personaje elegido obtiene Escurridizo (Evasive) hasta el comienzo de tu próximo turno. (Solo los personajes con Escurridizo pueden desafiarlos.) · El personaje elegido obtiene +3 de fuerza este turno.",
  S3_133_ABILITY:
    "FUENTE DE MAGIA: Durante tu turno, cada vez que uno de tus personajes sea desterrado en un desafío, puedes robar una carta.",
  S3_135_ABILITY:
    "<1>¡ALERTA, MARIPOSAS DE MAR!:</1> Los personajes obtienen Prisa (Rush) mientras estén aquí. (Pueden desafiar el turno en que se juegan). <2/><1>¡TODOS A CUBIERTA!:</1> Tus personajes Pirata pueden moverse aquí gratis.",
  S3_136_ABILITY:
    "<1>ESTE ES NUESTRO BARCO:</1> Los personajes obtienen Escurridizo (Evasive) mientras estén aquí. (Solo los personajes con Escurridizo pueden desafiarlos.) <2/><1>REMÁOS JUNTOS:</1> Si tienes un personaje aquí, pagas 2 tintas menos para mover un personaje tuyo aquí.",
  S3_137_ABILITY:
    "REPUESTOS: Siempre que este personaje explore (Quest), prepara uno de tus objetos (Items).",
  S3_138_ABILITY:
    "DISCIPLINA: Durante tu turno, este personaje obtiene Escurridizo (Evasive). (Pueden desafiar a personajes con Escurridizo).",
  S3_140_ABILITY:
    "¡NUNCA LO VERÁN VENIR!: Durante tu turno, este personaje obtiene Escurridizo (Evasive). (Pueden desafiar a personajes con Escurridizo).",
  S3_142_ABILITY:
    "SOLO HABÍA OCÉANO: Cuando juegas este personaje, mira las 2 cartas superiores de tu mazo. Puedes añadir una a tu mano. Pon el resto en la parte inferior de tu mazo en cualquier orden.",
  S3_143_ABILITY:
    "¿SABES QUIÉN ERES?: Siempre que una carta se ponga en tu tintero, gana 1 punto de Leyenda (Lore).",
  S3_144_ABILITY:
    "AHORA INTENTA SEGUIR EL RITMO: Agotar — Pon una carta de objeto (Item) de tu descarte en la parte superior de tu mazo.",
  S3_145_ABILITY:
    "TRES SOBRINOS: Siempre que este personaje explore (Quest), si tienes personajes llamados Dewey (Paco) y Louie (Luis) en juego, puedes robar 3 cartas.",
  S3_150_ABILITY:
    "DAMA DE ALTA CUNA: Cuando juegas este personaje, el personaje elegido obtiene -2 de fuerza este turno.",
  S3_151_ABILITY:
    "ESTO VA A ESTAR BUENO: Siempre que juegues una canción (Action - Song), puedes poner la carta superior de tu mazo en tu tintero boca abajo y agotada.",
  S3_153_ABILITY:
    "DEMASIADO VIEJO PARA ANDAR PERSIGUIENDO RATONES: Cuando este personaje es desterrado, puedes poner esta carta en tu tintero boca abajo y agotada.",
  S3_154_ABILITY:
    "<1>¡ME VOY A CASA!:</1> Durante tu turno, este personaje gana Escurridizo (Evasive). (Puede desafiar a personajes con Escurridizo.) <2/><1>¡NO ME HICE RICO SIENDO ESTÚPIDO!:</1> Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, puedes jugar un objeto (Item) gratis.",
  S3_155_ABILITY:
    "BUSCADOR DE TESOROS: Cuando este personaje explore (Quest), pagas 1 tinta menos por el próximo objeto (Item) que juegues este turno.",
  S3_156_ABILITY:
    "CALCULADOR Y VANIDOSO: Cuando este personaje explore (Quest), puedes mirar las 3 primeras cartas de tu mazo y ponerlas de vuelta en cualquier orden.",
  S3_157_ABILITY:
    "PUEDO USAR ESO: Cuando uno de tus objetos (Item) es desterrado, puedes poner esa carta boca abajo y agotada en tu tintero.",
  S3_163_ABILITY:
    "BUSCANDO CONOCIMIENTO: Cuando uno de tus personajes explore (Quest), puedes mirar la carta superior de tu mazo. Ponla en la parte superior o inferior de tu mazo.",
  S3_164_ABILITY:
    "CREAR VIDA: Agotar, 2 tinta - Pon la carta superior de tu mazo boca abajo y agotada en tu tintero.",
  S3_165_ABILITY:
    "NÚMERO UNO: Agotar, 2 tinta - Elige uno de tus personajes y gana puntos de Leyenda (Lore) igual a sus puntos de Leyenda (Lore).",
  S3_166_ABILITY:
    "EXPERTO EN NEGOCIOS: Aagotar - Pagas 1 tinta menos por el próximo objeto (Item) que juegues este turno.",
  S3_167_ABILITY:
    "SELLADO: Tus ubicaciones (Location) y personajes en ubicaciones ganan Resistir +1 (Resist). (El daño infligido a ellos se reduce en 1.)",
  S3_168_ABILITY:
    "LABORATORIO: Si tienes un personaje aquí, pagas 1 tinta menos para jugar objetos (Item).",
  S3_159_BODY:
    "El personaje elegido obtiene -2 de fuerza este turno. Roba una carta.",
  S3_170_ABILITY:
    "REENCARNACIÓN: Cuando un personaje es desterrado mientras esté aquí, puedes poner esa carta boca abajo y agotada en tu tintero.",
  S3_173_ABILITY:
    "<1>TODO ATADO:</1> Este personaje entra en juego agotado y no puede prepararse al inicio de tu turno. <2/><1>LIBÉRATE:</1> Durante tu turno, cada vez que uno de tus otros personajes destierre a otro personaje en un desafío, puedes preparar este personaje.",
  S3_174_ABILITY:
    "LLAMANDO A LOS TITANES: Agotar - Prepara a tus personajes Titán.",
  S3_176_ABILITY:
    "MARCA TU PROPIO RUMBO: Por cada ubicación (Location) que tengas en juego, este personaje gana Resistir +1 (Resist) y obtiene +1 punto de Leyenda (Lore). (El daño infligido a ellos se reduce en 1.)",
  S3_178_ABILITY:
    "DE ACUERDO, GRAN TIRO: Mientras este personaje esté agotado, tus personajes con Guardaespaldas (Bodyguard) ganan Resistir +1 (Resist) y obtiene +1 punto de Leyenda (Lore). (El daño infligido a ellos se reduce en 1.)",
  S3_179_ABILITY:
    "DISFRAZADO: Durante tu turno, este personaje gana Escurridizo (Evasive). (Puede desafiar a personajes con Escurridizo.)",
  S3_180_ABILITY:
    "PIEL DE PIEDRA: Agotar - El personaje elegido gana Resistir +2 (Resist) este turno.",
  S3_181_ABILITY:
    "ECHEMOS UN VISTAZO: Este personaje obtiene +1 de fuerza por cada ubicación (Location) que tengas en juego.",
  S3_182_ABILITY:
    "LLAMADA DE TROMPETA: Agotar, 2 tintas - Juega un personaje gratis.",
  S3_183_ABILITY:
    "VIAJE: Mientras este personaje esté en una ubicación (Location), obtiene +2 de fuerza.",
  S3_184_ABILITY:
    "OH QUERIDO, QUERIDO, QUERIDO: Al final de tu turno, si este personaje está agotado y no tienes un personaje Capitán en juego, inflige 1 punto de daño a este personaje.",
  S3_187_ABILITY:
    "ERUPCIÓN: Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, puedes preparar el personaje elegido.",
  S3_188_ABILITY:
    "MIRA AQUÍ: Mientras este personaje no tenga daño, obtiene +2 de fuerza.",
  S3_190_ABILITY:
    "<1>COMBATIENTE EXPERTO:</1> Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, obtén 2 puntos de Leyenda (Lore). <2/><1>POR EL BIEN DE OTROS:</1> Cuando este personaje es desterrado en un desafío, puedes robar una carta.",
  S3_191_ABILITY:
    "LOS IMPUESTOS DEBERÍAN DOLER: Cuando descartes una carta, puedes infligir 1 punto de daño al personaje oponente elegido.",
  S3_192_ABILITY:
    "<1>SOMETERSE O PELEAR:</1> Cuando juegas este personaje y cada vez que este personaje destierre a otro personaje en un desafío durante tu turno, elige uno: · Puedes robar 2 cartas, luego elige y descarta 2 cartas. · Puedes infligir 2 puntos de daño al personaje elegido.",
  S3_193_ABILITY:
    "POSTURA TRIUNFANTE: Durante tu turno, cada vez que este personaje destierre a otro personaje en un desafío, el personaje oponente elegido no puede desafiar durante su próximo turno.",
  S3_194_ABILITY:
    "MIS DIENTES SON MÁS AFILADOS: Cuando este personaje explore (Quest) estando en una ubicación (Location), puedes infligir 1 punto de daño al personaje elegido.",
  S3_199_ABILITY:
    "<1>¡AGARRA A ESOS MOCOSOS PIRATAS!:</1> Durante tu turno, cada vez que uno de tus personajes destierre a otro personaje en un desafío, puedes pagar 1 tinta para robar una carta. <2/><1>¡VAMOS A POR ELLOS!:</1> Tus personajes llamados Capitán Garfio (Captain Hook) ganan Retador (Challenger) +1. (Obtienen +1 de fuerza al desafiar.)",
  S3_200_ABILITY:
    "ARMADURA CIBERNÉTICA: Destierra este objeto (Item) - El personaje elegido gana Resistencia (Resist) +2 hasta el inicio de tu próximo turno. (El daño infligido a ellos se reduce en 2.)",
  S3_201_ABILITY:
    "<1>LLAVE DEL PORTAL:</1> Agotar - Pagas 1 tinta menos por la próxima ubicación (Location) que juegues este turno. <2/><1>MUESTRA EL CAMINO:</1> Pagas 1 tinta menos para mover tus personajes a una ubicación.",
  S3_202_ABILITY:
    "AISLADO: Los personajes ganan Resistencia (Resist) +1 mientras estén aquí. (El daño infligido a ellos se reduce en 1.)",
  S3_204_ABILITY:
    "MUÉSTRAME EL CAMINO: Cuando un personaje explore (Quest) estando aquí, puedes robar una carta, luego elige y descarta una carta.",

  // BODY set 3
  S3_024_BODY:
    "Siempre que uno de tus personajes explore (Quest) este turno, gana 1 punto de Leyenda (Lore).",
  S3_025_BODY:
    "El personaje elegido obtiene Apoyo (Support) este turno. (Cuando explore (Quest), puedes sumar su fuerza a la fuerza de otro personaje elegido este turno.)",
  S3_026_BODY:
    "(Un personaje con un coste de 3 o más puede agotarse para cantar esta canción gratis.) Elimina hasta 3 de daño del personaje elegido. Roba una carta.",
  S3_027_BODY: "Elimina hasta 3 de daño de la ubicación (Location) elegida.",
  S3_028_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) El oponente elegido revela su mano y descarta una carta que no sea de personaje de tu elección.",
  S3_060_BODY:
    "Mueve 1 contador de daño del personaje elegido al personaje opuesto elegido.",
  S3_061_BODY:
    "(Un personaje con un coste de 1 o más puede agotarse para cantar esta canción gratis.) Roba una carta. Baraja hasta 3 cartas del descarte de tu oponente en el mazo de tu oponente.",
  S3_062_BODY:
    "Agota al personaje oponente elegido. Luego, el personaje elegido tuyo obtiene Retador (Challenger) +2 este turno. (Obtiene +2 de fuerza al desafiar.)",
  S3_063_BODY:
    "(Un personaje con un coste de 3 o más puede agotarse para cantar esta canción gratis.) Mira las 5 cartas superiores de tu mazo. Pon cualquiera de ellas en la parte superior o inferior de tu mazo en cualquier orden. Gana 1 punto de Leyenda (Lore).",
  S3_094_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) Destierra el objeto (Item) elegido.",
  S3_095_BODY:
    "(Un personaje con un coste de 1 o más puede agotarse para cantar esta canción gratis.) El personaje elegido tuyo obtiene +2 de fuerza este turno. Puede moverse a una ubicación (Location) gratis.",
  S3_096_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) Roba 2 cartas, luego elige y descarta una carta.",
  S3_128_BODY:
    "Destierra uno de tus personajes con Temerario (Reckless) para desterrar al personaje elegido con menos fuerza que ese personaje.",
  S3_129_BODY:
    "(Un personaje con un coste de 2 o más puede agotarse para cantar esta canción gratis.) Prepara al personaje elegido tuyo en una ubicación (Location). No pueden explorar (Quest) por el resto de este turno. Gana puntos de Leyenda (Lore) igual a los puntos de Leyenda de esa ubicación.",
  S3_130_BODY:
    "Prepara todos tus personajes e inflige 1 punto de daño a cada uno de ellos. No pueden explorar (Quest) por el resto de este turno.",
  S3_131_BODY:
    "Mueve hasta 2 de tus personajes a la misma ubicación (Location) gratis.",
  S3_160_BODY:
    "(Un personaje con un coste de 5 o más puede agotarse para cantar esta canción gratis.) Cada jugador pone las 3 cartas superiores de su mazo en su tintero boca abajo y agotadas.",
  S3_161_BODY:
    "(Un personaje con un coste de 4 o más puede agotarse para cantar esta canción gratis.) Mira las 2 cartas superiores de tu mazo. Pon una en tu mano y la otra en tu tintero boca abajo y agotada.",
  S3_162_BODY:
    "Elimina hasta 3 puntos de daño de una de tus ubicaciones (Location) o personajes.",
  S3_195_BODY:
    "(Un personaje con un coste de 4 o más puede agotarse para cantar esta canción gratis.) Inflige 5 puntos de daño al personaje o ubicación (Location) elegida.",
  S3_196_BODY:
    "Inflige 2 puntos de daño al personaje o ubicación (Location) elegida.",
  S3_197_BODY:
    "Tus personajes obtienen +3 de fuerza este turno al desafiar una ubicación (Location).",
  S3_198_BODY: "Destierra la ubicación (Location) u objeto (Item) elegido.",

  // FLAVOR TEXT set 3
  S3_002_FLAVOR: "“Quédate allí. Yo buscaré la Lore disperso.”",
  S3_003_FLAVOR: "La oscuridad llama a todos sus súbditos.",
  S3_004_A_FLAVOR: "Primero te roban el corazón. Luego te roban la silla.",
  S3_005_FLAVOR:
    "“¿Dirigiéndote a las Tierras de Tinta? ¡Vuelve si necesitas reparaciones!”",
  S3_006_FLAVOR: "Bienvenido a las Tierras de Tinta. (En lenguaje atlante)",
  S3_007_FLAVOR: "“Ella ha sido elegida.” -Rey Kashekim Nedakh",
  S3_009_FLAVOR: "¡Sus talentos musicales están fuera de serie!",
  S3_010_FLAVOR:
    "“Nuestra sociedad nunca ha fallado en responder a un llamado de ayuda.” -Sr. Presidente",
  S3_011_FLAVOR:
    "“¡Es simple, Snoops! ¡Quiero ese Lore! ¡El que brilla como un diamante!” -Madame Medusa",
  S3_012_FLAVOR: "“Déjame encargarme de esto, Lilo”",
  S3_013_FLAVOR:
    "¡Mira la velocidad de estas máquinas! ¿Cómo se llaman de nuevo? ¿Corredores de Tinta? ¡Ojalá pudiera volar así!",
  S3_014_FLAVOR:
    "Rápido como un rayo, y listo para enfrentar a cualquier villano.",
  S3_015_FLAVOR: "Sus cachorros la seguirán a cualquier parte.",
  S3_016_FLAVOR: "“¡Ahoy! ¡Hay Lore por ahí, y yo lo encontraré!”",
  S3_018_FLAVOR: "¡No puede esperar a que lleguen nuevos amigos!",
  S3_019_FLAVOR: "“Encontraremos nuestro camino. ¡Lo sé!”",
  S3_020_FLAVOR: "Cuanto más reclama de las Tierras de Tinta, más quiere.",
  S3_021_FLAVOR: "“Tengo hambre, Mamá. En serio que sí.”",
  S3_022_FLAVOR: "Abundantes bayas, ¡y amistad para repartir!",
  S3_023_FLAVOR:
    "Con las mejoras de Campanita en su lugar, Wendy los llevó a los cielos para encontrar a la sirena perdida.",
  S3_024_FLAVOR:
    "“Dos, cuatro, seis, y tres son nueve, más dos son once . . .” -Roger",
  S3_025_FLAVOR:
    "“¡Snoops! ¡Sé que puedes buscar más! ¡Encuéntrame ese Lore!” -Madame Medusa",
  S3_026_FLAVOR: "Deja brillar tu poder Haz que el reloj retroceda . . .",
  S3_027_FLAVOR: "“¡Como nuevo! Bueno, casi.”",
  S3_028_FLAVOR: "Olvídate de tus preocupaciones y tus problemas . . .",
  S3_029_FLAVOR:
    "La lluvia cae mientras la piedra se derrite y los dragones vuelan de nuevo.",
  S3_030_FLAVOR:
    "“¡Es lo que te mantiene a todos -a toda Atlantis- con vida!” -Milo Thatch",
  S3_031_FLAVOR: "La herramienta adecuada marca la diferencia.",
  S3_032_FLAVOR:
    "Las sirenas le dijeron a Peter que habían visto algunos objetos flotando varios días antes, pero estaban más preocupadas porque una de sus hermanas había desaparecido.",
  S3_034_FLAVOR: "En Nueva Orleans, los sueños pueden hacerse realidad.",
  S3_035_FLAVOR:
    "Con las hojas de té adecuadas y un poco de magia, ella crea la taza perfecta para cualquier invitado a la fiesta.",
  S3_036_FLAVOR: "Que reine el caos.",
  S3_037_FLAVOR:
    "Con un poco de magia potenciada por tinta, nada escapa a su vista.",
  S3_038_FLAVOR:
    "“Aquí mismo, directamente de la lámpara, ¡aquí mismo para tu más sincero cumplimiento de deseos!”",
  S3_039_FLAVOR: "“¡Congela, Zeus!”",
  S3_040_FLAVOR:
    "“Tu majestad ciertamente tiene una forma especial con los animales tontos.” -Jafar",
  S3_041_FLAVOR: "“Reinaré hasta donde alcance la vista.”",
  S3_043_FLAVOR: "Nacido de las sombras. Salvado por la amistad.",
  S3_044_FLAVOR: "Tidiness súper suave.",
  S3_045_FLAVOR: "Tidiness súper veloz.",
  S3_046_FLAVOR: "Tidiness súper grande.",
  S3_047_FLAVOR:
    "“¡Carpet y yo podemos zambullirnos, agarrar el conocimiento y estar de vuelta antes de que alguien lo note!” -Aladdín",
  S3_048_FLAVOR:
    "“Alimenta mi poder, Eclipse Oscuro. Libera mi forma del abismo. Magia dormida, desátala ahora, ¡que la Reina de las Sombras esté completa de nuevo!”",
  S3_049_FLAVOR:
    "“Puedo sentir que mis poderes crecen cuanto más me acerco al querido centavo de Scrooge!”",
  S3_050_FLAVOR: "“¿Sabes cómo llamo a esto? ¡Un buen comienzo!”",
  S3_052_FLAVOR: "“Así se hace para darle lo que necesita, ¡Juju!”",
  S3_053_FLAVOR: "Siempre listo para echar una mano . . . eh, hocico.",
  S3_054_FLAVOR: "¡Cuidado con el palo!",
  S3_055_FLAVOR: "“¡Vuela, Zeus!”",
  S3_056_FLAVOR:
    "Ruge por el bosque, alas extendidas con llamas, la primavera canta su antiguo coro y el verde renueva su reclamo",
  S3_057_FLAVOR:
    "“Un hilo de telaraña para mantenerla atrapada, un ojo de águila para verla bien, arena del tiempo para robarle su pasado, ¡así lanzo mi hechizo mágico!”",
  S3_058_FLAVOR: "“Solo uno puede entrar aquí.”",
  S3_059_FLAVOR: "El contrato secreto ya ha sido escrito.",
  S3_060_FLAVOR:
    "“De la tinta mágica llamo a este regalo. ¡Vuela, mi esbirro, que tus alas sean veloces!” -Maléfica",
  S3_061_FLAVOR: "Soy todo lo que he aprendido y más",
  S3_062_FLAVOR: "“Estoy aquí para apoyarte”",
  S3_063_FLAVOR: "¡Adelante! ¡Haz tu elección!",
  S3_065_FLAVOR:
    "Minnie se acercó con cautela. Quienquiera que lo haya colocado aquí podría haber preparado trampas.",
  S3_066_FLAVOR:
    "Un silencio misterioso rodea el castillo, pero cuidado con el peligroso ocupante que hay dentro.",
  S3_067_FLAVOR:
    "Quienes visitan el espejo pueden elegir su pregunta, pero no la respuesta.",
  S3_068_FLAVOR: "Todo lo que necesitas para hacer un poco de magia.",
  S3_069_FLAVOR:
    "Con Peter deteniendo a Garfio, Cubby hizo lo que mejor sabe hacer: romper cosas.",
  S3_070_FLAVOR:
    "“¡Ahora me ha pasado una o dos veces, que alguien no pudo pagar el precio . . .” -Úrsula",
  S3_071_FLAVOR:
    "“Nunca pensaste que me encontrarías aquí, ¿verdad? Para ser honesto, yo tampoco.”",
  S3_072_FLAVOR: "“Úrsula necesita Lore para completar su plan. . . .”",
  S3_073_FLAVOR: "“¡Sal de mi iglesia! ¡Fuera! ¡Fuera! ¡Fuera!”",
  S3_075_FLAVOR: "“¡Dijiste que estábamos juntos en esto!”",
  S3_076_FLAVOR: "“Deben haber Lore poderoso aquí, muy poderosa.”",
  S3_077_FLAVOR: "“Ha sido divertido, chicos, pero tengo que irme. ¡Yahoooo!”",
  S3_079_FLAVOR:
    "“¡Créeme, estudiar disparates realmente puede llevarte a lugares!“",
  S3_080_FLAVOR: "“No sabes con qué estás jugando.“",
  S3_081_FLAVOR: "“¡Cabeza hueca, bola de travesura!” -John Silver",
  S3_082_FLAVOR:
    "Los Illumineers necesitaban a alguien que encontrara un libro de hechizos perdido, y Peter fue el primero en ofrecerse como voluntario.",
  S3_083_FLAVOR:
    "“Increíble como es inepto, siempre que los libros de historia se conserven…” -Sheriff de Nottingham",
  S3_084_FLAVOR: "“Algunos días, es agradable dejar que el río siga su curso.”",
  S3_085_FLAVOR: "“¿Por qué no te quedas a cenar?”",
  S3_086_FLAVOR: "¡El Príncipe Juan no es el único que cobra “impuestos”!",
  S3_087_FLAVOR: "Incluso el forajido más valiente sabe cuándo esconderse.",
  S3_088_FLAVOR:
    "Apúntalo en la dirección correcta, ¡y él se encargará del resto!",
  S3_089_FLAVOR: "Para ser un vestidor tan llamativo, ¡es difícil de detectar!",
  S3_091_FLAVOR: "“No quiero mucho, ¡solo el Lore más poderoso!”",
  S3_092_FLAVOR: "“Puedo desarmar cualquier cosa en menos de cinco minutos!”",
  S3_093_FLAVOR:
    "“Estaría mintiendo si dijera que estoy a cargo aquí, pero ¡las Tierras del Orgullo aún te dan la bienvenida!”",
  S3_094_FLAVOR:
    "No es muy inteligente, y sin embargo, ha tocado mi pequeño corazón de cuero de vaca.",
  S3_095_FLAVOR: "Iría a casi cualquier lugar Para sentir que pertenezco",
  S3_096_FLAVOR: "Por favor, tráenos honor Por favor, tráenos a todos honor",
  S3_097_FLAVOR: "Descubierto en el perdido Sea Duck, parecía como nuevo.",
  S3_098_FLAVOR:
    "“El bosque siempre provee justo lo que necesitas.” -Robin Hood",
  S3_099_FLAVOR:
    "En manos equivocadas, este frasco de magia podría ser desastroso.",
  S3_100_FLAVOR:
    "“Dicen que el viejo lugar está embrujado, o hechizado, o algo así sin sentido.” -Coronel",
  S3_101_FLAVOR:
    "Una nación protegida por feroces asesinos y sus aún más feroces gatos.",
  S3_102_FLAVOR:
    "“Seguro que es un poco pequeño, ¡pero tampoco TIENE UNA PISCINA!” -Kuzco",
  S3_103_FLAVOR:
    "Los naufragios son excelentes lugares para encontrar Lore, ¡y esconderse de los tiburones!",
  S3_104_FLAVOR:
    "“Él está tras de mí cofre, ese ciborg endemoniado y su banda de despiadados.”",
  S3_106_FLAVOR:
    "“Sobreviví a la luna. ¿Qué tan difícil podrían ser las Tierras de Tinta inundadas?”",
  S3_107_FLAVOR: "Considerando el coco.",
  S3_108_FLAVOR: "Más cabezas son mejores que una.",
  S3_109_FLAVOR: "“Vas a hacer temblar las estrellas, lo harás.” -John Silver",
  S3_110_FLAVOR:
    "Si quieres una recompensa más grande, ¡tienes que correr mayores riesgos!",
  S3_111_FLAVOR: "Hay un montón más de donde vino.",
  S3_112_FLAVOR:
    "“Encontrar Lore será tan fácil como quitarle dulces a los niños.”",
  S3_113_FLAVOR: "“¿Todavía tienes algo de pollo por aquí?”",
  S3_114_FLAVOR: "Siempre listo para causar sensación.",
  S3_115_FLAVOR: "“¡Mi abuelo nunca me habló de este lugar!”",
  S3_117_FLAVOR: "Ningún fuego o tormenta la detendrá en su objetivo.",
  S3_118_FLAVOR: "“Nutsy, cierra tu pico.” -Trigger",
  S3_119_FLAVOR:
    "Con Peter, los planes a menudo significaban lanzarse de cabeza hacia el peligro.",
  S3_121_FLAVOR:
    "La tormenta apareció de la nada, obligando a Eric a regresar antes de alcanzar la misteriosa estructura en el borde de Lorcana.",
  S3_122_FLAVOR:
    "Siempre mantenlo delante de ti. Y a favor del viento, si es posible.",
  S3_123_FLAVOR:
    "Se unirá a la caza de Lore después de haber tenido un bocado rápido.",
  S3_124_FLAVOR: "“Los piratas seguramente son fáciles de engañar!”",
  S3_125_FLAVOR:
    "“¿Quién pensó que darle una armadura de poder sería una buena idea?!” -Nani",
  S3_126_FLAVOR:
    "“¡Por todos los demonios, Trigger! Apunta esa escopeta en otra dirección.” -Sheriff de Nottingham",
  S3_127_FLAVOR:
    "“Encontrar Lore es mucho más fácil cuando tienes un gancho de agarre!”",
  S3_129_FLAVOR:
    "A Tor le gustaría renunciar y ser florista. Gunther se dedica al diseño de interiores.",
  S3_130_FLAVOR: "“¡Atrápalos! ¡Antes de que escapen!”",
  S3_131_FLAVOR: "“¡Éramos navegantes! ¿Por qué paramos?” -Moana",
  S3_133_FLAVOR:
    "“Espíritu convocado de la oscuridad, ¡muéstrate ante este arco!” -Lena Sabrewing",
  S3_134_FLAVOR:
    "“Bienvenidos a Agrabah, ciudad de misterio, de encantamiento y la mejor mercancía de este lado del río. -Comerciante”",
  S3_137_FLAVOR: "“¿Cómo rompiste esto, de todos modos?”",
  S3_138_FLAVOR:
    "“He avistado una extraña estructura en el horizonte. ¡A babor, timonel!”",
  S3_139_FLAVOR: "“¡Lo tengo! ¡Sabía que podía hacerlo! Paco gana!”",
  S3_140_FLAVOR: "“¡Nadie le dice a Flintheart Glomgold qué hacer!”",
  S3_141_FLAVOR: "¡Diez mil años te dejarán un dolor de cuello!",
  S3_142_FLAVOR: "Escucha con todo tu corazón, y los ancestros te guiarán.",
  S3_143_FLAVOR:
    "Ella siempre estará contigo, no importa lo lejos que te lleve tu viaje.",
  S3_144_FLAVOR:
    "“Tal vez la tecnología de bombillas sea menos malvada esta vez...”",
  S3_145_FLAVOR: "“¡Esto parece ser parte de un cristal más grande!”",
  S3_146_FLAVOR:
    "“Espera un momento, primo. ¿Por qué no te relajas y disfrutas de algunos ritmos agradables?”",
  S3_148_FLAVOR:
    "Emocionado de tener su aerodeslizador de vuelta, se lanzó hacia las Tierras de la Tinta.",
  S3_149_FLAVOR: "“¡Encontré el último!”",
  S3_150_FLAVOR: "Ella embellece cada espacio.",
  S3_151_FLAVOR:
    "“Cava un poco más profundo, encontrarás todo lo que necesitas.”",
  S3_152_FLAVOR:
    "El trabajo de Pluto era mantener la atención de Gustav para que Minnie pudiera llegar a la cueva.",
  S3_153_FLAVOR: "Fe y un pequeño pájaro azul, que ves a lo lejos.",
  S3_154_FLAVOR: "Él puede ganar dinero en cualquier lugar.",
  S3_155_FLAVOR:
    "Un mundo nuevo y extraño es el lugar perfecto para hacer una fortuna nueva y extraña.",
  S3_156_FLAVOR:
    "Ella tiene solo una pregunta, y mejor le gustará la respuesta.",
  S3_157_FLAVOR: "Ella vive en un mundo de posibilidades.",
  S3_162_FLAVOR: "“Estoy pensando en abrir una tienda aquí. ¿Qué piensas tú?”",
  S3_163_FLAVOR:
    "“Puede señalar hacia el Lore perdido, pero si no tienes cuidado, te llevará a un acantilado.” -Venturo, un Iluminado",
  S3_164_FLAVOR:
    "Se necesita un corazón puro para calmar la tormenta furiosa dentro.",
  S3_165_FLAVOR: "Esta simple moneda cambió la vida de Scrooge para siempre.",
  S3_166_FLAVOR: "Justo lo necesario para rematar otro trato brillante.",
  S3_167_FLAVOR:
    "“Solo Scrooge sabe sobre esta bóveda. Y planea mantenerlo así.”",
  S3_168_FLAVOR:
    "Algunas de las herramientas más importantes de Lorcana se elaboran aquí.",
  S3_169_FLAVOR:
    "“¡Es solo el hogar más genial de Lorcana!” -Webby Vanderquack",
  S3_171_FLAVOR: "“¡Mira nuestro hogar! No hay un lugar mejor para estar.”",
  S3_172_FLAVOR: "“No es mucho obstáculo, pero supongo que servirá.”",
  S3_174_FLAVOR:
    "“¡Hermanos! ¡Titanes! Miren, en su miserable prisión. ¿Quién los puso ahí abajo?”",
  S3_175_FLAVOR: "“Esa fue una orden, no una sugerencia. ¡Vamos!”",
  S3_176_FLAVOR: "“Nunca fui bueno en los juegos. Siempre odié perder.”",
  S3_177_FLAVOR: "Ella ha visto cosas extrañas antes, pero nada como esto.",
  S3_180_FLAVOR: "“¡Aplasta a Zeus!”",
  S3_181_FLAVOR:
    "Mickey sabía exactamente dónde empezar a buscar el Sombrero del Hechicero, pero no esperaba que estuviera custodiado.",
  S3_183_FLAVOR: "Nunca se derrumbará bajo presión.",
  S3_184_FLAVOR: "“¡Atrapa a esos pequeños! ¡AY!”",
  S3_185_FLAVOR: "Incluso su nombre hace temblar de miedo a los enemigos.",
  S3_186_FLAVOR: "“Te volví a atrapar.”",
  S3_187_FLAVOR: "“¡Derrite a Zeus!”",
  S3_188_FLAVOR:
    "“No hay princesa aquí para salvarte esta vez, rata de alcantarilla.”",
  S3_189_FLAVOR:
    "“¿Llamas a esto una emboscada? Tsk, tsk. Apenas es un ardid de arbustos.”",
  S3_191_FLAVOR:
    "La decimotercera Regla de la Villanía: Toma todo lo que puedas, pero hazlo con una sonrisa.",
  S3_192_FLAVOR:
    "Está listo para restaurar su antigua gloria en las Tierras del Orgullo.",
  S3_193_FLAVOR:
    "Con peligros multiplicándose por todas partes, la manada necesita a su guardián.",
  S3_194_FLAVOR: "“Pronto las campanas sonarán el sonido de tu perdición.”",
  S3_195_FLAVOR:
    "Lanzó su rayo, lo cerró Encerró a esos chupas en un cofre Están atrapados Y por su cuenta detuvo el caos en su camino",
  S3_196_FLAVOR: "¡Más grande que tu explosión promedio!",
  S3_197_FLAVOR:
    "“Ahora que te he liberado, ¿cuál es la primera cosa que vas a hacer?” -Hades",
  S3_198_FLAVOR: "“Oh, estamos en problemas, ¡grandes problemas!” -Hermes",
  S3_200_FLAVOR:
    "Está en la Sala de Lorcana, esperando a que alguien pronuncie las palabras secretas.",
  S3_201_FLAVOR:
    "“Caballeros, esto debe mantenerse bajo llave.” -Capitana Amelia",
  S3_202_FLAVOR: "Nada más que rocas y arena, fácil de pasar por alto.",
  S3_203_FLAVOR:
    "Sir Hiss: “Digo, sssseñor, el castillo de su madre sería el lugar perfecto para dar vida a nuestro plan.” Príncipe John: “¡Mami!”",
  S3_204_FLAVOR:
    "Un lugar para encontrar lo que necesitas, no solo lo que quieres.",

  // KEYWORDS TEXT CHARACTERS: SHIFT_X set 3
  S3_007_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Kida.)",
  S3_017_SHIFT_TEXT:
    "5 (Puedes pagar 5 de tinta para jugar esto encima de uno de tus personajes llamado Pluto.)",
  S3_042_SHIFT_TEXT:
    "5 (Puedes pagar 5 de tinta para jugar esto encima de uno de tus personajes llamado Jafar.)",
  S3_049_SHIFT_TEXT:
    "5 (Puedes pagar 5 de tinta para jugar esto encima de uno de tus personajes llamado Mágica de Hechizo.)",
  S3_074_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Sinclair.)",
  S3_080_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Milo Thatch.)",
  S3_116_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Moana.)",
  S3_120_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Peter Pan.)",
  S3_143_SHIFT_TEXT:
    "5 (Puedes pagar 5 de tinta para jugar esto encima de uno de tus personajes llamado Abuela Tala.)",
  S3_147_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Kit Nubarrón.)",
  S3_178_SHIFT_TEXT:
    "4 (Puedes pagar 4 de tinta para jugar esto encima de uno de tus personajes llamado Pequeño Juan.)",
  S3_190_SHIFT_TEXT:
    "3 (Puedes pagar 3 de tinta para jugar esto encima de uno de tus personajes llamado Robin Hood.)",

  // SET 4

  // ITEM CARD set 4
  IMPERIAL_PROCLAMATION: "Proclamación imperial",
  MYSTICAL_ROSE: "Rosa mística",
  RECORD_PLAYER: "Tocadiscos",
  SIGNED_CONTRACT: "Contrato firmado",
  TRITONS_TRIDENT: "Tridente de Tritón",
  VISION_SLAB: "Losa de visión",

  // ACTION CARD set 4
  BRAWL: "Pelearse",
  BRUNOS_RETURN: "El regreso de Bruno",
  SIGN_THE_SCROLL: "Firma el pergamino",

  // ACTION SONG CARD set 4
  A_PIRATES_LIFE: "La vida de un pirata",
  BE_KING_UNDISPUTED: "Sé rey indiscutible",
  DIG_A_LITTLE_DEEPER: "Cavar un poco más profundo",
  LOOK_AT_THIS_FAMILY: "Mira a esta familia",
  LOST_IN_THE_WOODS: "Perdido en el bosque",
  SECOND_STAR_TO_THE_RIGHT: "Segunda estrella a la derecha",
  SELDOM_ALL_THEY_SEEM: "Rara vez todo lo que parecen",
  THE_MOB_SONG: "La canción de la mafia",
  TREASURES_UNTOLD: "Tesoros no contados",
  UNDER_THE_SEA: "Bajo el mar",
  WE_DONT_TALK_ABOUT_BRUNO: "No se habla de Bruno",

  // LOCATION CARD set 4
  CASA_MADRIGAL: "Casa Madrigal",
  SNUGGLY_DUCKLING: "El patito modosito",
  THE_WALL: "La muralla",
  URSULAS_GARDEN: "El jardín de Úrsula",
  URSULAS_LAIR: "La guarida de Úrsula",

  // VERSION NAME set 4 (desordenados para subirlos mientras las revelan)
  S4_006_VERSION_NAME: "Lovely Lady",
  S4_009_VERSION_NAME: "Fun-Loving Family Man",
  S4_013_VERSION_NAME: "Excellent Cook",
  S4_018_VERSION_NAME: "Gift of the Family",
  S4_038_VERSION_NAME: "Out of the Shadows",
  S4_041_VERSION_NAME: "Easy Listener",
  S4_047_VERSION_NAME: "Magically Strong One",
  S4_058_VERSION_NAME: "Sea Witch Queen",
  S4_107_VERSION_NAME: "Super Goof",
  S4_137_VERSION_NAME: "Braving the Storm",
  S4_146_VERSION_NAME: "Noble Scoundrel",
  S4_153_VERSION_NAME: "Appreciative Artist",
  S4_175_VERSION_NAME: "Sonic Warrior",
  S4_223_VERSION_NAME: "Powerful Sorcerer",
  S4_224_VERSION_NAME: "Elite Archer",
  S4_225_VERSION_NAME: "Playful Sorcerer",
  S4_084_VERSION_NAME: "Gift for Hercules",
  S4_184_VERSION_NAME: "Rock of the Family",
  S4_115_VERSION_NAME: "Enemy of Entanglement",
  S4_105_VERSION_NAME: "Mulan's Father",
  S4_144_VERSION_NAME: "Collector's Companion",
  S4_102_VERSION_NAME: "Full of the Unfortunate",
  S4_185_VERSION_NAME: "Aerial Cleaner",
  S4_108_VERSION_NAME: "Clumsy Kid",
  S4_090_VERSION_NAME: "Proclaimers of Heroes",
  S4_135_VERSION_NAME: "Disreputable Pub",
  S4_072_VERSION_NAME: "Interior Designer",
  S4_091_VERSION_NAME: "Florist",
  S4_024_VERSION_NAME: "Eric's Bride",
  S4_044_VERSION_NAME: "Entangling Eels",
  S4_070_VERSION_NAME: "Devoted Herald",
  S4_112_VERSION_NAME: "Valorous General",
  S4_149_VERSION_NAME: "Carrot Enthusiast",
  S4_171_VERSION_NAME: "Brave Rescuer",
  S4_003_VERSION_NAME: "Singing Mermaid",
  S4_124_VERSION_NAME: "Emboldened Warrior",
  S4_192_VERSION_NAME: "Royal Protector",
  S4_077_VERSION_NAME: "Connoisseur of Climbing",
  S4_110_VERSION_NAME: "Beloved Steed",
  S4_056_VERSION_NAME: "Ever-Present Pursuer",
  S4_069_VERSION_NAME: "Lucky Cricket",
  S4_068_VERSION_NAME: "Eye of the Storm",
  S4_042_VERSION_NAME: "Storm Chaser",
  S4_177_VERSION_NAME: "Imperial Advisor",
  S4_178_VERSION_NAME: "Imperial Soldier",
  S4_183_VERSION_NAME: "Imperial Soldier",
  S4_203_VERSION_NAME: "Border Fortress",
  S4_053_VERSION_NAME: "Weather Maker",
  S4_040_VERSION_NAME: "Prankster",
  S4_045_VERSION_NAME: "Golden Child",
  S4_067_VERSION_NAME: "Casita",
  S4_104_VERSION_NAME: "Brave Mediator",

  // ABILITIES set 4 “”     //  para doble habilidad: "<1>¡AGARRA A ESOS MOCOSOS PIRATAS!:</1> Durante tu turno... <2/><1>¡VAMOS A POR ELLOS!:</1> Tus personajes llamados...)",
  S4_013_ABILITY: "SIGNATURE RECIPE: When you play this character, you may remove up to 2 damage from chosen character. If you removed damage this way, you may draw a card.",
  S4_018_ABILITY: "SAVING THE MIRACLE: Whenever this character quests, your other Madrigal characters get +1 lore this turn.",
  S4_038_ABILITY: "IT WAS YOUR VISION: When you play this character, chosen character gains “When this character is banished in a challenge, you may return this card to your hand” this turn.",
  S4_041_ABILITY: "MAGICAL INFORMANT: When you play this character, if an opponent has an exerted character in play, you may draw a card.",
  S4_058_ABILITY: "<1>NOW I AM THE RULER!:<1> Whenever this character quests, exert chosen character. <2/><1>YOU'LL LISTEN TO ME!:</1> Other characters can't exert to sing songs.",
  S4_066_ABILITY: "SYMBOL OF POWER: Banish this item — Chosen character gets +1 strength this turn for each card in your hand.",
  S4_107_ABILITY: "SUPER PEANUT POWERS: Whenever this character challenges another character, gain 2 lore.",
  S4_137_ABILITY: "I WAS BORN READY: If you have another Hero character in play, this character gets +1 lore.",
  S4_146_ABILITY: "ROYAL SCHEMES: When you play this characer, if a Princess or Queen character is in play, gain 1 lore.",
  S4_153_ABILITY: "PERCEPTIVE PARTNER: While you have a character named Pascal in play, this character gains Ward. (Opponents can't chose them except to challenge.)",
  S4_175_ABILITY: "AMPLIFIED VOICE: Whenever you play a song, you may pay ink to deal 3 daamge to chosen character.",
  S4_223_ABILITY: "<1>TIMELY INTERVENTION:</1> When you play this character, if you have a character named Magic Broom in play, you may draw a card. <2/><1>ARCANE STUDY:</1> While you have 2 or more Broom characters in play, this character gets +2 lore.",
  S4_224_ABILITY: "<1>STRAIGHT SHOOTER:</1> When you play this character, if you used Shift to play her, she gets +3 strength this turn.<2/><1> TRIPPLE SHOT:</1> During your turn, whenever this character deals damage to another character in a challenge, deal the same amount of damage to up to 2 other chosen characters.",
  S4_225_ABILITY: "SWEEP AWAY: When you play this character, deal damage to chosen character equal to the number of Broom characters you have in play.",
  S4_032_ABILITY: "<1>LOOK AT THIS!:</1> Whenever you play a song, chosen character gets -2 strength until the start of your next turn. <2/><1>HIT PARADE:</1> Your characters named Stitch count as having +1 cost to sing songs.",
  S4_184_ABILITY: "I'M THE STRONG ONE: While you have another character in play, this character gets +2 strength.",
  S4_115_ABILITY: "TIME TO SHINE: Whenever you play an action, this character gets +2 strength this turn.",
  S4_105_ABILITY: "<1>WAR WOUND:</1> This character cannot challenge. <2/><1>HEAD OF FAMILY:</1> Exert - Ready chosen character named Mulan. They can't quest for the rest of the turn.",
  S4_144_ABILITY: "I'M NOT A GUPPY: If you have a character named Ariel in play, you pay 1 ink less to play this character.",
  S4_099_ABILITY: "FINE PRINT: Whenever an opponent plays a song, you may draw a card.",
  S4_102_ABILITY: "ABANDON HOPE: While you have an exerted character here, opposing characters get -1 lore.",
  S4_064_ABILITY: "DISPEL THE ENTANGLEMENT: Banish this item - Chosen character named Beast gets +2 lore this turn. If you have a character named Belle in play, move up to 3 damage counters from chosen character to chosen opposing character.",
  S4_185_ABILITY: "WINGED FOR A DAY: During your turn, this character gains Evasive. (They can challenge characters with Evasive.)",
  S4_090_ABILITY: "THE GOSPEL TRUTH: Whenever you play a song, you may return chosen character with 2 strength or less to their player's hand.",
  S4_135_ABILITY: "ROUTINE RUCKUS: Whenever a character with 3 strength or more challenges another character while here, gain 1 lore. If the challenging character has 6 strength or more, gain 3 lore instead.",
  S4_072_ABILITY: "SAD-EYED PUPPY: When this character is challenged and banished, each opponent chooses one of their characters and returns that card to their hand.",
  S4_024_ABILITY: "VANESSA'S DESIGN: Whenever this character quests, chosen opponent reveals their hand and discards a non-character card of your choice.",
  S4_070_ABILITY: "CIRCLE FAR AND WIDE: During each opponent's turn, whenever they draw a card while this character is exerted, you may draw a card.",
  S4_112_ABILITY: "LEAD THE CHARGE: Your characters with 4 strength or more get +1 lore.",
  S4_149_ABILITY: "CARROTS ALL AROUND!: Whenever he quests, each of your other characters gets +strength equal to this character's strength this turn.",
  S4_171_ABILITY: "CRASHING THROUGH: Whenever this character quests, you may banish chosen item.",
  S4_124_ABILITY: "SURGE OF POWER : This character gets +1 strength for each card in opponent's hands.",
  S4_192_ABILITY: "STEADY GAZE: While you have no cards in your hand, characters with cost 4 or less can't challenge this character.",
  S4_077_ABILITY: "SNEAKY IDEA: When you play this character, chosen opposing character gains Reckless during their next turn. (They can't quest and must challenge if able.)",
  S4_069_ABILITY: "SPREADING GOOD FORTUNE: When you play this character, your other characters get +3 strength this turn.",
  S4_068_ABILITY: "<1>SLIPPERY HALLS:</1> Whenever a characters is banished in a challenge while here, you may return them to your hand. <2/><1>SEAT OF POWER:</1> Characters named Ursula get +1 lore while here.",
  S4_042_ABILITY: "TEMPEST: Exert- Chosen character gains Challenger +2 and Rush this turn. (They get +2 strength while challenging. They can challenge the turn they're played.)",
  S4_131_ABILITY: "CALL TO THE FRONT: Whenever one of your characters challenges another character, you pay 1 ink less for the next character you play this turn.",
  S4_177_ABILITY: "OVERLY CAUTIOUS: While this character has no damage, he gets +2 lore.",
  S4_183_ABILITY: "FULL OF SPIRIT: Your Hero characters get +1 strength.",
  S4_203_ABILITY: "PROTECT THE REALM: While you have an exerted character here, your other locations can't be challenged.",
  S4_100_ABILITY: "<1>DANGER REVEALED:</1> At the start of your turn, if an opposing character has damage, gain 1 lore. TRAPPED!:</1> Damage counters can't be removed.",
  S4_053_ABILITY: "IT LOOKS LIKE RAIN: When you play this character, you may exert chosen opposing character. That character can't ready at the start of their next turn unless you're at a location.",
  S4_040_ABILITY: "<1>MANY FORMS: At the start of your turn, you may chose one: • This character gets +1 lore this turn. • This character gain Challenger +2 this turn. (While challenging, this character gets +2 strength.)",
  S4_045_ABILITY: "<1>LADIES FIRST:</1> During your turn, if no other character has quested this turn, this character gets +3 lore. <2/><1>LEAVE IT TO ME:</1> Whenever this character quests, your other characters can't quest for the rest of this turn.",
  S4_067_ABILITY: "OUR HOME: At the start of your turn, if you have a character here, gain 1 lore.",

  // BODY set 4
  S4_097_BODY: "(A character with cost 5 or more can exert to sing this song for free.) Return chosen character to their player's hand, then that player discards a card at random.",
  S4_030_BODY: "Each opponent may chose and discard a chard. For each opponent who doesn't, you gain 2 lore.",
  S4_130_BODY: "Banish chosen character with 2 strength or less.",
  S4_129_BODY: "(A character with cost 4 or more can exert to sing this song for free.) Each opponent chooses and banishes one of their characters.",
  S4_029_BODY: "(A character with cost 4 or more can exert to sing this song for free.) All opposing characters get -2 strength until the start of your next turn.",
  S4_164_BODY: "(A character with cost 2 or more can exert to sing this song for free.) Chosen character gets -3 strength this turn.",
  S4_165_BODY: "(A character with cost 6 or more can exert to sing this song for free.) Return up to 2 item cards from your discard into your hand.",
  S4_044_BODY: "(This character counts as being named both Flotsam and Jetsam)",
  S4_028_BODY: "Look at the top 5 cards of your deck. You may reveal up to 2 character cards and put them into your hand. Put the rest on the bottom of your deck in any order.",
  S4_061_BODY: "Chosen player draws 5 cards.",
  S4_095_BODY: "Put all opposing characters with 2 strength or less on the bottom of their players' decks in any order.",
  S4_128_BODY: "Each opponent loses 2 lore. You gain 2 lore.",
  S4_162_BODY: "Look at the top 7 cards of your deck. Put 2 into your hand. Put the rest on teh bottom of your deck in any order.",
  S4_198_BODY: "Deal 3 damage to up to 3 chosen characters and/or locations.",
  S4_026_BODY: "Return a character card from your discard to your hand. Then remove up to 2 damage from chosen character.",

  // FLAVOR TEXT set 4
  S4_006_FLAVOR: "Sweet Dasiy, the fairest duck I ever met Each flaxen lock a rush of flowing gold Her bill the color of summer sunset Exquisite plumes, a wonder to behold",
  S4_009_FLAVOR: "Who needs a gift when you're having this much fun?",
  S4_013_FLAVOR: "“Eat this, mi amor.”",
  S4_047_FLAVOR: "“This rock? No problem. Go get that prophecy, hermana!”",
  S4_066_FLAVOR: "“Just imagine all this power in the wrong hands...” — Ursula",
  S4_107_FLAVOR: "Never underestimate the power of a Goof.",
  S4_137_FLAVOR: "After talking to Olaf, Anna marched into the unexpected storm to save Kristoff.",
  S4_146_FLAVOR: "Hans was confident he could bring Anna to Ursula — all he needed was something of Kristoff's to lure her in.",
  S4_153_FLAVOR: "“Pascal! A new flower for the wall!”",
  S4_084_FLAVOR: "“His name is Pegasus. And he's all yours....” — Zeus",
  S4_097_FLAVOR: "Your fate is sealed when your prophecy is read!",
  S4_184_FLAVOR: "There's no way Ursula's creatures are getting to that donkey.",
  S4_115_FLAVOR: "Ursula's messengers fled, leaving behind tendrils of dark ink.",
  S4_105_FLAVOR: "“I am ready to serve the Emperor.”",
  S4_144_FLAVOR: "“Ariel, Ariel! You won't believe what I found!”",
  S4_099_FLAVOR: "“I Would love to help you, of course, but there's the little matter of the contract...” -Ursula",
  S4_064_FLAVOR: "Ink surrounded Belle's last hope to heal the Beast. With no other choice, she reached out for it . . .",
  S4_185_FLAVOR: "It spends its days keeping the treasured glimmers in the Hall of Lorcana sparkling clean.",
  S4_108_FLAVOR: "“Nice Catch, Jerkules.” -Village boy",
  S4_130_FLAVOR: "There are two ways to leave the Snuggly Duckling - the door or the window.",
  S4_072_FLAVOR: "“I hate to cover this trap door. It really pulls the room together!”",
  S4_091_FLAVOR: "They say that his arrangements are exquisite, His composites and bouquets are so divine. But when the crowds try to come and visit, There's always quite a fight to form a line.",
  S4_129_FLAVOR: "Respected, saluted",
  S4_029_FLAVOR: "I'm left behind, wondering if I should follow",
  S4_164_FLAVOR: "I know you I walked with you once upon a dream",
  S4_165_FLAVOR: "How many wonders can one cavern hold?",
  S4_003_FLAVOR: "“Watch and you'll see-some day l'Il be part of your world!”",
  S4_124_FLAVOR: "Sometimes the only way to fight the unimaginable is with the incredible.",
  S4_192_FLAVOR: "As regal as his namesake and just as powerful.",
  S4_077_FLAVOR: "Teamwork makes the cheese work.",
  S4_110_FLAVOR: "As silent as a shadow and faster than the wind: brave Khan, the mightiest stallion.",
  S4_056_FLAVOR: "“That cursed beast liked the teaste of me so well he's followed me ever since...” - Captian Hook",
  S4_069_FLAVOR: "Everyone feels better just knowing he's around.",
  S4_061_FLAVOR: "Lead us to the land we dream of",
  S4_095_FLAVOR: "Such wonderful things surround you",
  S4_128_FLAVOR: "Give me a career As a buccaneer",
  S4_042_FLAVOR: "After Elsa dispersed Ursula's storm, Anna was nowhere to be found.",
  S4_131_FLAVOR: "“By order of the Emperor, one man from every family must server in the Imperial Army” -Chi Fu",
  S4_177_FLAVOR: "“You , there! Keep that fighting away from here! It is imperative that I write to the Emperor.”",
  S4_183_FLAVOR: "A good friend is handy in a fight.",
  S4_100_FLAVOR: "“Tío Bruno! What's happening to him? We have to help!” -Mirabel",
  S4_026_FLAVOR: "“I feel like I missed something important”",
  S4_104_FLAVOR: "“We must work together to heal the entanglements.”",

  // KEYWORDS TEXT CHARACTERS: SHIFT_X set 4
  S4_058_SHIFT_TEXT: "Shift 5 (You may pay 5 ink to play this on top of one of your characters named Ursula.)",
  S4_175_SHIFT_TEXT: "Shift 4 (You may pay 4 ink to play this on top of one of your characters named Ariel.)",
  S4_224_SHIFT_TEXT: "Shift 5 (You may pay 5 ink to play this on top of one of your characters named Mulan.)",
  S4_187_SHIFT_TEXT: "Shift 3 (You may pay 3 ink to play this on top of one of your characters named Mickey Mouse)",
  S4_024_SHIFT_TEXT: "Discard a song card (You may discard a song card to play this on top of one of your characters named Ursula.)",
  S4_044_SHIFT_TEXT: "Discard 2 cards (You may discard 2 cards to play this on top of one of your characters named Flotsam or Jetsam.)",
  S4_070_SHIFT_TEXT: "Discard an action card (You may discard an action card to play this on top of one of your characters named Diablo.)",
  S4_112_SHIFT_TEXT: "Discard a character card (You may discard a character card to play this on top of one of your characters named Li Shang.)",
  S4_149_SHIFT_TEXT: "Discard an item card (You may discard an item card to play this on top of one of your characters named Olaf.)",
  S4_171_SHIFT_TEXT: "Discard a location card (You may discard a location card to play this on top of one of your characters named Aladdin.)",
};

export default TRANSLATIONS_ES;
