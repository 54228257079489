import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Form, Input, Button } from "antd";
import { authApi } from "../apiCalls";

const initialState = {
  incorrectCredentials: "",
  requiredFieldEmail: false,
  requiredFieldPassword: false,
  emailText: "",
  passwordText: "",
};

const LoginForm = () => {
  const { Item } = Form;
  const token = localStorage.getItem("token");
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [
    {
      incorrectCredentials,
      requiredFieldPassword,
      requiredFieldEmail,
      emailText,
      passwordText,
    },
    setError,
  ] = useState(initialState);

  async function sendCredentials(user) {
    const response = await authApi.login(user);
    if (response?.token) {
      const { token, email, username } = response;
      localStorage.setItem("token", token);
      localStorage.setItem("email", email);
      localStorage.setItem("username", username);
      navigate("/cards", { replace: true });
    } else {
      setError(response);
    }
  }

  const onFinish = () => {
    let user = {};
    user.email = email.toLowerCase();
    user.password = password;
    sendCredentials(user);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <Card id="loginCard" variant="outlined">
        <Form onFinish={onFinish}>
          <Item
            label="Email" //{/*TODO: agregar translation */}
            validateStatus={requiredFieldEmail ? "error" : ""}
            help={emailText}
          >
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  emailText: "",
                  requiredFieldEmail: false,
                  incorrectCredentials: "",
                }));
              }}
            />
          </Item>
          <Item
            label="Password" //{/*TODO: agregar translation */}
            validateStatus={requiredFieldPassword ? "error" : ""}
            help={passwordText}
          >
            <Input.Password
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  passwordText: "",
                  requiredFieldPassword: false,
                  incorrectCredentials: "",
                }));
              }}
            />
          </Item>
          <div>
            {/* <a href="/passwordRecovery">Recover Password</a> */}
            <Button type="primary" htmlType="submit">
              Iniciar sesion {/*TODO: agregar translation */}
            </Button>
            <div id="loginErrorMsg">{incorrectCredentials}</div>
          </div>
        </Form>
      </Card>
      {token && (
        <Button
          type="error"
          onClick={() => {
            authApi.logout();
            navigate("/", { replace: true });
          }}
        >
          Cerrar sesión {/*TODO: agregar translation */}
        </Button>
      )}
    </div>
  );
};

export default LoginForm;
