import "./locales/i18n";
import { useTranslation } from "react-i18next";
import { ConfigProvider, Layout, Typography, theme } from "antd";
import CardsViewList from "./pages/CardsView";
import CardIndividual from "./pages/CardIndividual";
import MainMenu from "./components/MainMenu";
import MainHeader from "./components/MainHeader";
import CardCompare from "./pages/CardCompare";
import QuickRules from "./pages/QuickRules";
import NewsProv from "./pages/NewsProv";
import LorcanaStory from "./pages/LorcanaStory";
import "./css/style.css";
import {
  Navigate,
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import SelectedContextProvider from "./context/SelectedContext";
import { InfoCircleOutlined } from "@ant-design/icons";
import LoginForm from "./pages/Login";
const { Footer } = Layout;
const { Text } = Typography;

function App() {
  const { t } = useTranslation();

  const customizeRenderEmpty = () => (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <InfoCircleOutlined
        style={{
          fontSize: 15,
          color: "#bfbfbf",
        }}
      />
      <Text style={{ color: "#bfbfbf", margin: "3px" }} type="secondary">
        {t("EMPTY_DATA")}
      </Text>
    </div>
  );

  return (
    <SelectedContextProvider>
      <ConfigProvider
        renderEmpty={customizeRenderEmpty}
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            fontFamily: "Poppins",
            colorPrimary: "#FFEB39ff",
          },
        }}
      >
        <Layout style={{ minHeight: "100vh" }}>
          <Router>
            <MainMenu />
            <Layout >
              <MainHeader />
              <div className="layoutBack">
                <Routes>
                  <Route exact path="/" element={<NewsProv />} />
                  <Route exact path="/cards" element={<CardsViewList />} />
                  <Route
                    path="/card-detail/:cardNumber"
                    element={<CardIndividual />}
                  />
                  <Route path="/cards-compare" element={<CardCompare />} />
                  <Route
                    exact
                    path="/lorcana-story"
                    element={<LorcanaStory />}
                  />
                  <Route exact path="/quickrules" element={<QuickRules />} />
                  <Route exact path="/login" element={<LoginForm />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <Footer className="footer">
                  <Text
                    type="secondary"
                    style={{
                      color: "#bfbfbf",
                      fontSize: "8px",
                      lineHeight: "1",
                    }}
                  >
                    {t("LORCANA_COPY")}
                  </Text>
                </Footer>
              </div>
            </Layout>
          </Router>
        </Layout>
      </ConfigProvider>
    </SelectedContextProvider>
  );
}

export default App;
