import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { cardsApi } from "../apiCalls";
import { Trans, useTranslation } from "react-i18next";
import {
  Divider,
  Image,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import TextInfo from "../components/TextInfo";
import { SelectOutlined } from "@ant-design/icons";
import "../css/style.css";
import { Content } from "antd/es/layout/layout";
const { Text } = Typography;

const cardBack =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/back_small.png";

const CardCompare = () => {
  const { t } = useTranslation();
  const [cardOne, setCardOne] = useState([]);
  const [cardTwo, setCardTwo] = useState([]);
  const [filteredCardListOne, setFilteredCardListOne] = useState([]);
  const [filteredCardListTwo, setFilteredCardListTwo] = useState([]);

  const handleSearchCardOne = async (value) => {
    if (value.length > 2) {
      const cartasCoincidentes = await getAllCardsByName(value);
      setFilteredCardListOne(cartasCoincidentes);
    }
  };

  const handleChangeCardOne = async (value) => {
    const selectedCardNumber = value;
    const cardByNumber = await getCardsByCardNumber(selectedCardNumber);
    setCardOne(cardByNumber[0]);
  };

  const handleSearchCardTwo = async (value) => {
    if (value.length > 2) {
      const cartasCoincidentes = await getAllCardsByName(value);
      setFilteredCardListTwo(cartasCoincidentes);
    }
  };

  const handleChangeCardTwo = async (value) => {
    const selectedCardNumber = value;
    const cardByNumber = await getCardsByCardNumber(selectedCardNumber);
    setCardTwo(cardByNumber[0]);
  };

  const getAllCardsByName = async (name) => {
    const cardsAndCount = await cardsApi.getCards(undefined, name);
    const cards = cardsAndCount.cards;
    if (cards && cards.length > 0) {
      return cards;
    }
  };

  const getCardsByCardNumber = async (selectedCardNumber) => {
    const cards = await cardsApi.getCardNumber(selectedCardNumber);
    const cardByNumber = cards;
    if (cardByNumber && cardByNumber.length > 0) {
      return cardByNumber;
    }
  };

  const filterOptionOne = (input, option) => true;
  const filterOptionTwo = (input, option) => true;

  const columns = [
    {
      title: t("COMPARE"),
      dataIndex: "name",
      align: "center",
      width: "10vw",
      render: (text) => (
        <div >
          <strong>{text}</strong>
        </div>
      ),
    },
    {
      title: (
        <>
          <TextInfo
            labelText="COMPARE_WITH"
            tooltipText={t("SEARCH_BY_NAME")}
          />
          <br />
          <Select
            showSearch
            placeholder={t("ENTER_NAME")}
            optionLabelProp="name"
            style={{ minWidth: "20vw", width: "20vw" }}
            optionFilterProp="children"
            onChange={handleChangeCardOne}
            onSearch={handleSearchCardOne}
            filterOption={filterOptionOne}
            options={filteredCardListOne?.map((option) => ({
              value: option.cardNumber,
              label: (
                <div className="select-label">
                  <img src={option.imageUrls.small} style={{ width: "50px" }} />
                  <Space.Compact direction="vertical">
                    <Text type="secondary" style={{ fontSize: "0.8125em" }}>
                      {t(option.name)}
                    </Text>
                    <Text type="secondary" style={{ fontSize: "0.8125em" }}>
                      {t(option.versionName)}
                    </Text>
                  </Space.Compact>
                </div>
              ),
              name: t(option.name),
            }))}
          />
          <br />
          <Divider />
          <Image
            style={{ maxWidth: "20vw" }}
            src={cardOne.length === 0 ? cardBack : cardOne.imageUrls?.medium}
          />
        </>
      ),
      dataIndex: "card1",
      width: "20vw",
    },
    {
      title: (
        <>
          <TextInfo
            labelText="COMPARE_WITH"
            tooltipText={t("SEARCH_BY_NAME")}
          />
          <br />
          <Select
            showSearch
            placeholder={t("ENTER_NAME")}
            optionLabelProp="name"
            style={{ minWidth: "20vw", width: "20vw" }}
            optionFilterProp="children"
            onChange={handleChangeCardTwo}
            onSearch={handleSearchCardTwo}
            filterOption={filterOptionTwo}
            options={filteredCardListTwo?.map((option) => ({
              value: option.cardNumber,
              label: (
                <div className="select-label">
                  <img src={option.imageUrls.small} style={{ width: "50px" }} />
                  <Space.Compact direction="vertical">
                    <Text type="secondary" style={{ fontSize: "0.8125em" }}>
                      {t(option.name)}
                    </Text>
                    <Text type="secondary" style={{ fontSize: "0.8125em" }}>
                      {t(option.versionName)}
                    </Text>
                  </Space.Compact>
                </div>
              ),
              name: t(option.name),
            }))}
          />
          <br />
          <Divider />
          <Image
            style={{ maxWidth: "20vw" }}
            src={cardTwo.length === 0 ? cardBack : cardTwo.imageUrls?.medium}
          />
        </>
      ),
      dataIndex: "card2",
      width: "20vw",
    },
  ];

  const data = [
    {
      key: "1",
      name: t("NAME"),
      card1: t(cardOne?.name),
      card2: t(cardTwo?.name),
    },
    {
      key: "2",
      name: t("VERSION_NAME"),
      card1: t(cardOne?.versionName),
      card2: t(cardTwo?.versionName),
    },
    {
      key: "3",
      name: t("TYPE"),
      card1: t(cardOne?.type),
      card2: t(cardTwo?.type),
    },
    {
      key: "4",
      name: t("CLASSIFICATIONS"),
      card1: (
        <>
          {cardOne?.Classifications?.map((item) => {
            return <p>{"- " + t(item.type)}</p>;
          })}
        </>
      ),
      card2: (
        <>
          {cardTwo?.Classifications?.map((item) => {
            return <p>{"- " + t(item.type)}</p>;
          })}
        </>
      ),
    },
    {
      key: "5",
      name: t("INK_COST"),
      card1: t(cardOne?.inkCost),
      card2: t(cardTwo?.inkCost),
    },
    {
      key: "6",
      name: t("INKWELL"),
      card1: (
        <>
          {cardOne?.inkwell && t("YES")}{" "}
          {cardOne?.inkwell === false && t("NOT")}
        </>
      ),
      card2: (
        <>
          {cardTwo?.inkwell && t("YES")}{" "}
          {cardTwo?.inkwell === false && t("NOT")}
        </>
      ),
    },
    {
      key: "7",
      name: t("INK"),
      card1: (
        <>
          {cardOne?.Inks?.map((item) => {
            return (
              <>
                {t(item?.ink) + " "}
                {/* <img src={item.inkImage} width={20} /> */}
              </>
            );
          })}
        </>
      ),
      card2: (
        <>
          {cardTwo?.Inks?.map((item) => {
            return (
              <>
                {t(item?.ink) + " "}
                {/* <img src={item.inkImage} width={20} /> */}
              </>
            );
          })}
        </>
      ),
    },
    {
      key: "8",
      name: t("KEYWORDS"),
      card1: (
        <>
          {cardOne?.Keywords?.map((item) => {
            return (
              <p>
                {"- "} <Text strong>{t(item.keyword)}</Text>
                {": "}
                {item.keywordText === null ? (
                  <>
                    {(cardOne?.keywordTextX).split(", ").map((valor) => {
                      return <>{valor.includes(item.keyword) && t(valor)}</>;
                    })}
                  </>
                ) : (
                  t(item.keywordText)
                )}
              </p>
            );
          })}
        </>
      ),
      card2: (
        <>
          {cardTwo?.Keywords?.map((item) => {
            return (
              <p>
                {"- "} <Text strong>{t(item.keyword)}</Text>
                {": "}
                {item.keywordText === null ? (
                  <>
                    {(cardTwo?.keywordTextX).split(", ").map((valor) => {
                      return <>{valor.includes(item.keyword) && t(valor)}</>;
                    })}
                  </>
                ) : (
                  t(item.keywordText)
                )}
              </p>
            );
          })}
        </>
      ),
    },
    {
      key: "9",
      name: t("ABILITY"),
      card1: cardOne.ability && (
        <Trans
          i18nKey={cardOne?.ability}
          components={{ 1: <strong />, 2: <br /> }}
        />
      ),
      card2: cardTwo.ability && (
        <Trans
          i18nKey={cardTwo?.ability}
          components={{ 1: <strong />, 2: <br /> }}
        />
      ),
    },
    {
      key: "10",
      name: t("BODY_TEXT"),
      card1: t(cardOne?.bodyText),
      card2: t(cardTwo?.bodyText),
    },
    {
      key: "11",
      name: t("FLAVOR_TEXT"),
      card1: t(cardOne?.flavorText),
      card2: t(cardTwo?.flavorText),
    },
    {
      key: "12",
      name: t("STRENGTH"),
      card1: t(cardOne?.strength),
      card2: t(cardTwo?.strength),
    },
    {
      key: "13",
      name: t("WILLPOWER"),
      card1: t(cardOne?.willpower),
      card2: t(cardTwo?.willpower),
    },
    {
      key: "14",
      name: t("LORE"),
      card1: t(cardOne?.loreValue),
      card2: t(cardTwo?.loreValue),
    },
    {
      key: "15",
      name: t("MOVE_COST"),
      card1: t(cardOne?.moveCost),
      card2: t(cardTwo?.moveCost),
    },
    {
      key: "16",
      name: t("RARITY"),
      card1: t(cardOne?.rarity),
      card2: t(cardTwo?.rarity),
    },
    {
      key: "17",
      name: t("ARTIST"),
      card1: cardOne?.artist,
      card2: cardTwo?.artist,
    },
    {
      key: "18",
      name: t("CARD_NUMBER"),
      card1: t(cardOne?.cardNumber?.split("_")[1]),
      card2: t(cardTwo?.cardNumber?.split("_")[1]),
    },
    {
      key: "19",
      name: t("SET"),
      card1: t(cardOne?.set),
      card2: t(cardTwo?.set),
    },
    {
      key: "20",
      name: t("RELEASE_DATE"),
      card1: t(cardOne?.releaseDate),
      card2: t(cardTwo?.releaseDate),
    },
    {
      key: "21",
      name: t("FRANCHISE"),
      card1: t(cardOne?.franchise),
      card2: t(cardTwo?.franchise),
    },
    {
      key: "22",
      name: t("OTHER_EDITIONS"),
      card1: (
        <>
          {cardOne?.otherEditions?.map((item) => {
            return (
              <Link to={`/card-detail/${item.cardNumber}`}>
                <Tooltip title={t("GO")}>
                  <p>
                    {"-"}
                    {item?.edition?.map((valor) => {
                      return <>{t(valor) + " "}</>;
                    })}
                    <SelectOutlined />
                  </p>
                </Tooltip>
              </Link>
            );
          })}
        </>
      ),
      card2: (
        <>
          {cardTwo?.otherEditions?.map((item) => {
            return (
              <Link to={`/card-detail/${item.cardNumber}`}>
                <Tooltip title={t("GO")}>
                  <p>
                    {"-"}
                    {item?.edition?.map((valor) => {
                      return <>{t(valor) + " "}</>;
                    })}
                    <SelectOutlined />
                  </p>
                </Tooltip>
              </Link>
            );
          })}
        </>
      ),
    },
    {
      key: "23",
      name: t("PROMO_INFO"),
      card1: t(cardOne?.promoInfo),
      card2: t(cardTwo?.promoInfo),
    },
    {
      key: "24",
      name: t("OVERSIZED"),
      card1: (
        <>
          {cardOne?.oversized && t("YES")}{" "}
          {cardOne?.oversized === false && t("NOT")}
        </>
      ),
      card2: (
        <>
          {cardTwo?.oversized && t("YES")}{" "}
          {cardTwo?.oversized === false && t("NOT")}
        </>
      ),
    },
    {
      key: "25",
      name: t("FOIL"),
      card1: (
        <>
          {cardOne?.foil && t("YES")} {cardOne?.foil === false && t("NOT")}
        </>
      ),
      card2: (
        <>
          {cardTwo?.foil && t("YES")} {cardOne?.foil === false && t("NOT")}
        </>
      ),
    },
    {
      key: "26",
      name: t("IN_PRODUCT"),
      card1: (
        <>
          {cardOne?.Products?.map((item) => {
            return (
              <p>
                {"- " +
                  t(item.product) +
                  t("AMOUNT") +
                  item.cardProduct.quantity}
              </p>
            );
          })}
        </>
      ),
      card2: (
        <>
          {cardTwo?.Products?.map((item) => {
            return (
              <p>
                {"- " +
                  t(item.product) +
                  t("AMOUNT") +
                  item.cardProduct.quantity}
              </p>
            );
          })}
        </>
      ),
    },
  ];

  return (
    <Content className="container" >
      <Text className="titles">{t("COMPARE_CARDS")}</Text>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        size="small"
        footer={() => ""}
        pagination={false}
      />
    </Content>
  );
};
export default CardCompare;
