import React, { useState } from "react";
export const SelectedContext = React.createContext({});

export default function SelectedContextProvider({ children }) {
  const [allCards, setAllCards] = useState([]);
  const [loadingCards, setLoadingCards] = useState(true);
  const [cardsCount, setCardsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingButton, setLoadingButton] = useState(false);

  return (
    <SelectedContext.Provider
      value={{
        allCards,
        setAllCards,
        loadingCards,
        setLoadingCards,
        cardsCount,
        setCardsCount,
        page,
        setPage,
        loadingButton,
        setLoadingButton,
      }}
    >
      {children}
    </SelectedContext.Provider>
  );
}
