import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HomeOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  UserOutlined,
  BlockOutlined,
  SearchOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import { Button, Divider, Layout, Menu, Select, Space, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { cardsApi } from "../apiCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
const { SubMenu } = Menu;
const { Sider } = Layout;
const { Text } = Typography;

const MainMenu = () => {
  const username = localStorage.getItem("username");
  const { t, i18n } = useTranslation();
  const [filteredCardListOne, setFilteredCardListOne] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("es");
  const navigate = useNavigate();

  const handleSearchCardOne = async (value) => {
    if (value.length > 2) {
      const cartasCoincidentes = await getAllCardsByName(value);
      setFilteredCardListOne(cartasCoincidentes);
    }
  };

  const handleChangeCardOne = async (value) => {
    navigate(`/card-detail/${value}`);
    setFilteredCardListOne([]);
  };

  const getAllCardsByName = async (name) => {
    const cardsAndCount = await cardsApi.getCards(undefined, name);
    const cards = cardsAndCount.cards;
    if (cards && cards.length > 0) {
      return cards;
    }
  };

  const filterOptionOne = (input, option) => true;

  const menuItems = [
    {
      icon: <HomeOutlined />,
      route: "/",
      label: t("HOME"),
    },
    {
      icon: <ContainerOutlined />,
      route: "/cards",
      label: t("CARDS"),
    },
    {
      icon: <BlockOutlined />,
      route: "/cards-compare",
      label: t("COMPARE_CARDS"),
    },
    {
      icon: <ReadOutlined />,
      label: t("LORCANA"),
      subMenuItems: [
        {
          route: "/lorcana-story",
          label: t("STORY"),
        },
        {
          route: "/quickrules",
          label: t("QUICK_RULES"),
        },
        {
          route: "/",
          label: t("PLAY"),
          disabled: true,
        },
        {
          route: "/",
          label: t("PRODUCTS"),
          disabled: true,
        },
        {
          route: "/",
          label: t("WIKI"),
          disabled: true,
        },
      ],
    },
    {
      icon: <DatabaseOutlined />,
      route: "/",
      label: t("DECKS"),
      disabled: true,
    },
    {
      icon: <DatabaseOutlined />,
      route: "/",
      label: t("CREATE_DECKS"),
      disabled: true,
    },
    {
      icon: <DatabaseOutlined />,
      route: "/",
      label: t("MY_COLLECTION"),
      disabled: true,
    },
    {
      icon: <DatabaseOutlined />,
      route: "/",
      label: t("WISHLIST"),
      disabled: true,
    },
    {
      icon: <UserOutlined />,
      route: "/",
      label: username || t("USER"),
      disabled: true,
    },
  ];

  const handleClickLanguage = () => {
    const newLanguage = currentLanguage === "es" ? "en" : "es";
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  return (
    <Sider breakpoint="lg" collapsedWidth="0">
      <Menu mode="inline" style={{ height: "100%" }}>
        <div style={{ margin: "5px", marginTop: "20px", marginBottom: "20px"  }}>
          <SearchOutlined style={{ color: "#bfbfbf", margin: "8px" }} />
          <Text style={{ color: "#bfbfbf" }} type="secondary">
            {t("SEARCH_ALL")}
          </Text>
          <Select
            showSearch
            placeholder={t("ENTER_SEARCH")}
            optionLabelProp="name"
            style={{ minWidth: "190px" }}
            optionFilterProp="children"
            onChange={handleChangeCardOne}
            onSearch={handleSearchCardOne}
            filterOption={filterOptionOne}
            options={filteredCardListOne?.map((option) => ({
              value: option.cardNumber,
              label: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "normal",
                  }}
                >
                  <img
                    src={option.imageUrls.small}
                    style={{ width: "50px", marginRight: "8px" }}
                  />
                  <Space.Compact direction="vertical">
                    <Text type="secondary" style={{ fontSize: "0.8125em" }}>
                      {t(option.name)}
                    </Text>
                    <Text type="secondary" style={{ fontSize: "0.8125em" }}>
                      {t(option.versionName)}
                    </Text>
                  </Space.Compact>
                </div>
              ),
              name: t(option.name),
            }))}
          />
        </div>
        {menuItems.map((menuItem, index) =>
          menuItem.subMenuItems ? (
            <SubMenu key={index} icon={menuItem.icon} title={menuItem.label}>
              {menuItem.subMenuItems.map((subMenuItem, subIndex) => (
                <Menu.Item
                  key={`${index}-${subIndex}`}
                  disabled={subMenuItem.disabled}
                >
                  <Link to={subMenuItem.route}>{subMenuItem.label}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={index}
              icon={menuItem.icon}
              disabled={menuItem.disabled}
            >
              <Link to={menuItem.route}>{menuItem.label}</Link>
            </Menu.Item>
          )
        )}
        <div style={{ textAlign: "center", marginTop: "auto" }}>
          <Divider />
          <div>
            <Button
              icon={<FontAwesomeIcon icon={faLanguage} size="xl" />}
              onClick={handleClickLanguage}
            />
          </div>
          <Text
            style={{ color: "#bfbfbf", marginLeft: "3px" }}
            type="secondary"
          >
            {t("CHANGE_LANGUAGE")}
          </Text>
        </div>
        <br />
      </Menu>
    </Sider>
  );
};

export default MainMenu;
