import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SelectedContext } from "../context/SelectedContext";
import {
  Alert,
  Button,
  Card,
  Col,
  Image,
  Row,
  Segmented,
  Space,
  Tooltip,
  Typography,
} from "antd";
import TextInfo from "./TextInfo";
import {
  InfoCircleOutlined,
  LeftCircleOutlined,
  EyeTwoTone,
  EyeInvisibleTwoTone,
  RightCircleOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

const { Meta } = Card;
const { Text } = Typography;

const cardBack =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/back_small.png";

const LorcanaCard = () => {
  const { t } = useTranslation();
  const [showImg, setShowImg] = useState(false);
  const [isShownId, setIsShownId] = useState(null);
  const [showAllInfo, setShowAllInfo] = useState(false);
  const assignSelected = useContext(SelectedContext);

  const handleClickImg = (id) => {
    setIsShownId(id);
  };
  const handleClickButton = () => {
    assignSelected.setPage(assignSelected.page + 1);
    assignSelected.setLoadingButton(true);
  };

  return (
    <>
      <Space size={[8, 16]} wrap style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "1em" }}>
        <Alert
          className="alertStyle"
          message={
            <Text style={{ color: "white" }}>
              {t("TOTAL") + assignSelected.cardsCount}
            </Text>
          }
        />
        <Alert
          className="alertStyle"
          message={
            <>
              <TextInfo
                labelText="SHOW_IMG"
                tooltipText="show illustration"
              />
              <Segmented
                size="small"
                style={{ marginLeft: 5 }}
                defaultValue={false}
                onChange={(value) => setShowImg(value)}
                options={[
                  {
                    label: t("YES"),
                    value: true,
                    icon: (
                      <EyeTwoTone twoToneColor="#eb2f96" size="small" />
                    ),
                  },
                  {
                    label: t("NOT"),
                    value: false,
                    icon: (
                      <EyeInvisibleTwoTone
                        twoToneColor="#eb2f96"
                        size="small"
                      />
                    ),
                  },
                ]}
              />
            </>
          }
        />
        <Alert
          className="alertStyle"
          message={
            <>
              <TextInfo
                labelText="SHOW_IMG"
                tooltipText="show illustration"
              />
              <Segmented
                size="small"
                style={{ marginLeft: 5 }}
                defaultValue={false}
                onChange={(value) => setShowImg(value)}
                options={[
                  {
                    label: t("YES"),
                    value: true,
                    icon: (
                      <EyeTwoTone twoToneColor="#eb2f96" size="small" />
                    ),
                  },
                  {
                    label: t("NOT"),
                    value: false,
                    icon: (
                      <EyeInvisibleTwoTone
                        twoToneColor="#eb2f96"
                        size="small"
                      />
                    ),
                  },
                ]}
              />
            </>
          }
        />
      </Space>
      <Row gutter={[4, 4]} justify="center" align="middle">
        <>
          {assignSelected.loadingCards && assignSelected.page === 1 ? (
            <>
              <Col xs={12} sm={8} md={8} lg={6} xl={4}>
                <Card loading={true}></Card>
              </Col>
              <Col xs={12} sm={8} md={8} lg={6} xl={4}>
                <Card loading={true}></Card>
              </Col>
              <Col xs={12} sm={8} md={8} lg={6} xl={4}>
                <Card loading={true}></Card>
              </Col>
            </>
          ) : (
            <>
              {assignSelected.allCards &&
                assignSelected.allCards.length === 0 ? (
                <Alert message={t("NOT_CARDS")} type="info" showIcon />
              ) : (
                <>
                  {assignSelected?.allCards?.map((card) => {
                    return (
                      <>
                        <Col
                          xs={isShownId === card.id || showAllInfo ? 24 : 12}
                          sm={isShownId === card.id || showAllInfo ? 16 : 8}
                          md={isShownId === card.id || showAllInfo ? 16 : 8}
                          lg={isShownId === card.id || showAllInfo ? 12 : 6}
                          xl={isShownId === card.id || showAllInfo ? 8 : 4}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Card
                              bordered={false}
                              hoverable
                              color="#FFEB39ff"
                              key={card.id}
                              size="small"
                              style={{
                                cursor: "default",
                              }}
                              title={
                                <Text
                                  type="secondary"
                                  style={{
                                    whiteSpace: "normal",
                                    fontSize: "0.8125em",
                                  }}
                                >
                                  {t(card.name)}
                                </Text>
                              }
                              actions={[
                                <Link to={`/card-detail/${card.cardNumber}`}>
                                  <Tooltip title={t("GO")}>
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </Link>,
                                <Tooltip title={t("ADD_TO_COLLECTION")}>
                                  <FileAddOutlined key="add" />
                                </Tooltip>,
                                <>
                                  {showAllInfo === false && (
                                    <>
                                      {isShownId === card.id ? (
                                        <>
                                          <LeftCircleOutlined
                                            style={{ marginLeft: 3 }}
                                            onClick={() => handleClickImg("")}
                                          />
                                        </>
                                      ) : (
                                        <Tooltip title={t("PREVIEW")}>
                                          <RightCircleOutlined
                                            style={{ marginLeft: 3 }}
                                            onClick={() =>
                                              handleClickImg(card.id)
                                            }
                                          />
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </>,
                              ]}
                            >
                              <Meta
                                description={
                                  <>
                                    <Row
                                      gutter={8}
                                      justify="center"
                                      align="middle"
                                    >
                                      <Col
                                        span={
                                          isShownId === card.id || showAllInfo
                                            ? 12
                                            : 24
                                        }
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Image
                                            src={
                                              showImg
                                                ? card.imageUrls?.small
                                                  ? card.imageUrls?.small
                                                  : cardBack
                                                : cardBack
                                            }
                                            preview={{
                                              src: card.imageUrls?.medium,
                                            }}
                                          />
                                        </div>
                                      </Col>
                                      {(isShownId === card.id ||
                                        showAllInfo) && (
                                          <Col span={12}>
                                            <Text
                                              type="secondary"
                                              style={{
                                                fontSize: "0.8125em",
                                              }}
                                            >
                                              <br />
                                              {t(card.versionName)}
                                              <br />
                                              {card.Classifications.map(
                                                (item) => {
                                                  return (
                                                    <>{t(item.type) + ", "}</>
                                                  );
                                                }
                                              )}
                                              {card.Keywords === null ? (
                                                <>
                                                  <Text
                                                    strong
                                                    style={{ fontSize: "1em" }}
                                                  >
                                                    {t("KEYWORDS") + ": "}
                                                  </Text>
                                                  {card.Keywords.map(
                                                    (keyword) => {
                                                      return (
                                                        <>{t(keyword.keyword)}</>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}

                                              <br />
                                              {/* <Text
                                              strong
                                              style={{ fontSize: "1em" }}
                                            >
                                              {t("ABILITY") + ": "}
                                            </Text>
                                            {card.Abilities.map((ability) => {
                                              return <>{t(ability.ability)}</>;
                                            })}
                                            <br /> */}
                                              <Text
                                                strong
                                                style={{ fontSize: "1em" }}
                                              >
                                                {t("INK_COST") + ": "}
                                              </Text>
                                              {t(card.inkCost)}
                                              <br />
                                              <Text
                                                strong
                                                style={{ fontSize: "1em" }}
                                              >
                                                {t("STRENGTH") + ": "}
                                              </Text>
                                              {t(card.strength)}
                                              <br />
                                              <Text
                                                strong
                                                style={{ fontSize: "1em" }}
                                              >
                                                {t("WILLPOWER") + ": "}
                                              </Text>
                                              {t(card.willpower)}
                                              <br />
                                              <Text
                                                strong
                                                style={{ fontSize: "1em" }}
                                              >
                                                {t("LORE") + ": "}
                                              </Text>
                                              {t(card.loreValue)}
                                              <br />
                                              <Text
                                                strong
                                                style={{ fontSize: "1em" }}
                                              >
                                                {t("INK") + ": "}
                                              </Text>
                                              {card.Inks.map((item) => {
                                                return <>{t(item?.ink) + " "}</>;
                                              })}
                                            </Text>
                                          </Col>
                                        )}
                                    </Row>
                                  </>
                                }
                              />
                            </Card>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </>
              )}
            </>
          )}
        </>
      </Row>
      <Row justify="center" align="middle">
        <Col span={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px",
            }}
          >
            {assignSelected.cardsCount === assignSelected.allCards.length ? (
              <></>
            ) : (
              <Button
                type="primary"
                style={{ margin: "1em" }}
                loading={assignSelected.loadingButton}
                onClick={handleClickButton}
              >
                {t("LOAD_MORE")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <br />
    </>
  );
};
export default LorcanaCard;
