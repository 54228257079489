import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Col, Collapse, Image, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
const { Text, Title } = Typography;

const quickRulesImgSmall1 =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/rules/quick/quickrules01_small.png";
const quickRulesImgLarge1 =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/rules/quick/quickrules01_large.png";
const quickRulesImgSmall2 =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/rules/quick/quickrules02_small.png";
const quickRulesImgLarge2 =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/rules/quick/quickrules02_large.png";
const quickRulesImgSmall3 =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/rules/quick/quickrules03_small.png";
const quickRulesImgLarge3 =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/rules/quick/quickrules03_large.png";
const quickRulesImgSmall4 =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/rules/quick/quickrules04_small.png";
const quickRulesImgLarge4 =
  "https://reinodetinta.s3.us-east-2.amazonaws.com/img/rules/quick/quickrules04_large.png";

const items = [
  {
    key: "1",
    label: (
      <ul>
        <li>REGLAS DE INICIO RÁPIDO</li>
        <li>LAS CARTAS</li>
      </ul>
    ),
    children: (
      <>
        <Image
          src={quickRulesImgSmall1}
          preview={{
            src: quickRulesImgLarge1,
          }}
        />
        <Title level={4}>DISNEY LORCANA</Title>
        <Text>Juego de Cartas Intercambiables</Text>
        <Title mark level={5}>
          REGLAS DE INICIO RÁPIDO
        </Title>
        <Text>
          Como un Illumineer en el maravilloso reino de Lorcana, usarás tinta
          mágica para invocar nuevas versiones de personajes y objetos de
          Disney, que se llaman destellos. Estos destellos, algunos familiares y
          otros fantásticos, te ayudarán mientras corres por Lorcana para
          encontrar y recolectar piezas perdidas de la historia. ¡Innumerables
          misiones te esperan!
        </Text>
        <br />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong style={{ fontSize: "16px" }}>
                <center>¿Qué tipo de juego es este?</center>
              </Text>
              <Text>
                Disney Lorcana es un juego de cartas estratégico en el que cada
                jugador crea su propio mazo antes de que comience el juego, y
                los mazos no se mezclan. Puedes jugar con uno de los mazos
                listos para jugar disponibles, hacer cambios en uno de esos
                mazos o construir un mazo completamente propio.
              </Text>
              <br />
              <Text>
                En un juego como este, las cartas pueden permitirte hacer cosas
                que normalmente no son parte de cada turno. Si el texto de una
                carta contradice alguna regla del juego, sigue lo que dice la
                carta.
              </Text>
            </>
          }
        />
        <Title mark level={5}>
          LAS CARTAS
        </Title>
        <Text>
          Tu mazo de Lorcana puede tener una combinación de tres tipos de
          cartas: personajes, acciones y objetos.
        </Text>
        <br />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong>Personajes: </Text>
              <Text>
                Enviarás destellos de personajes a misiones y desafíos. Algunos
                tienen habilidades especiales.
              </Text>
            </>
          }
        />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong>Objetos: </Text>
              <Text>
                Los destellos de objetos permanecen en juego cuando los juegas y
                te otorgan habilidades especiales durante la partida.
              </Text>
            </>
          }
        />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong>Acciones: </Text>
              <Text>
                Las acciones te brindan una ventaja de un solo uso y luego se
                descartan.
              </Text>
            </>
          }
        />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong>Canciones: </Text>
              <Text>
                Las canciones son un tipo de acción, y puedes jugar una de la
                misma manera que cualquier otra acción: pagando su coste y
                resolviendo su efecto. Pero esa no es tu única opción - algunos
                personajes pueden cantar la canción por ti, ¡así que no tienes
                que gastar la tinta!
              </Text>
            </>
          }
        />
      </>
    ),
  },
  {
    key: "2",
    label: (
      <ul>
        <li>RESUMEN DEL JUEGO</li>
        <li>PARTES DE UNA CARTA</li>
        <li>PREPARACIÓN</li>
      </ul>
    ),
    children: (
      <>
        <Image
          src={quickRulesImgSmall2}
          preview={{
            src: quickRulesImgLarge2,
          }}
        />
        <Title mark level={5}>
          RESUMEN DEL JUEGO
        </Title>
        <Title level={5}>Sobre el Juego</Title>
        <Text>
          En este juego, compites por encontrar piezas de historia (lore)
          dispersas por Lorcana y recolectarlos para guardarlas de manera
          segura. Invoca destellos de personajes y objetos de Disney en el
          camino para ayudar en misiones, obstaculizar a tus oponentes y
          desafiar a personajes opuestos. Con la estrategia adecuada y un poco
          de suerte, puedes preservar tu lore recolectado contra futuras
          amenazas!
        </Text>
        <Title level={5}>Aprender a Jugar</Title>
        <Text>
          Existen varias formas de aprender Lorcana! La mejor, y más divertida,
          forma de aprender es tener un amigo que te enseñe. También puedes
          preguntar acerca de eventos de Lorcana en una tienda de juegos cercana
          y descargar la aplicación de Disney Lorcana Trading Card Game
          Companion para un tutorial interactivo.
        </Text>
        <Title level={5}>Ganar</Title>
        <Text>
          Tu objetivo es ser el primer jugador en obtener 20 o más puntos de
          historia (lore). Algunas habilidades de cartas te dan lore, pero la
          forma más común de obtenerlo es jugando personajes y enviándolos a
          misiones.
        </Text>
        <br />
        <Title mark level={5}>
          PARTES DE UNA CARTA
        </Title>
        <Title level={5}>En Todas las Cartas</Title>
        <br />
        <Text strong>Costo: </Text>
        <Text>Cuánta tinta (el ícono de hexágono) cuesta jugar la carta.</Text>
        <br />
        <Text strong>Ícono de Tintero: </Text>
        <Text>
          Las cartas con el ícono de tintero alrededor del hexágono de costo
          pueden ser colocadas en tu tintero para usar como tinta.
        </Text>
        <br />
        <Text strong>Nombre: </Text>
        <Text>
          Los personajes también tienen un nombre de versión listado debajo del
          nombre del personaje.
        </Text>
        <br />
        <Text strong>Clasificaciones: </Text>
        <Text>
          Categorías a veces mencionadas en las reglas de las cartas (como
          "Escoba" en este ejemplo).
        </Text>
        <br />
        <Text strong>Tinta: </Text>
        <Text>
          Este símbolo y la banda de color detrás del nombre de la carta indican
          el tipo de tinta de la carta.
        </Text>
        <br />
        <Text strong>Habilidades y Efectos: </Text>
        <Text>
          Las reglas especiales de la carta. En acciones (Actions), se llaman
          efectos. En personajes (Characters) y objetos (Items), la mayoría de
          las habilidades tienen un nombre basado en la historia. Las
          habilidades especialmente comunes usan palabras clave en negrita (como
          "Prisa" (Rush) en el ejemplo de abajo).
        </Text>
        <Text italic>
          Prisa (Rush) (Este personaje puede desafiar el turno en el que se
          juega.)
        </Text>
        <br />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong style={{ fontSize: "16px" }}>
                <center>Tipos de Tinta</center>
              </Text>
              <Text>
                <ul>
                  <li>Ámbar (Amber)</li>
                  <li>Amatista (Amethyst)</li>
                  <li>Esmeralda (Emerald)</li>
                  <li>Rubí (Ruby)</li>
                  <li>Zafiro (Sapphire)</li>
                  <li>Acero (Steel)</li>
                </ul>
              </Text>
            </>
          }
        />
        <Title level={5}>Solo en Personajes</Title>
        <Text strong>Fuerza: </Text>
        <Text>Cuánto daño inflige el personaje durante un desafío.</Text>
        <br />
        <Text strong>Fuerza de voluntad: </Text>
        <Text>
          Cuánto daño se necesita para desterrar al personaje del juego.
        </Text>
        <br />
        <Text strong>Valor de Lore: </Text>
        <Text>Cuánto lore ganas cuando el personaje realiza una misión.</Text>
        <br /> <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong style={{ fontSize: "16px" }}>
                <center>Tipos de Rareza</center>
              </Text>
              <Text>
                <ul>
                  <li>Común (Common)</li>
                  <li> Poco Común (Uncommon)</li>
                  <li>Rara (Rare)</li>
                  <li>Super Rara (Super Rare)</li>
                  <li>Legendaria(Legendary)</li>
                </ul>
              </Text>
            </>
          }
        />
        <Title mark level={5}>
          PREPARACIÓN
        </Title>
        <Text>
          Cada jugador necesita su propio mazo. También necesitarás contadores
          de daño y una forma de llevar un registro de cuánto lore tienes. Ambos
          vienen con tu mazo inicial. 1. Baraja tu mazo. 2. Coloca tu marcador
          de lore en 0. 3. Roba 7 cartas para tu mano inicial. Puedes mirar las
          cartas en tu mano, ¡pero no la de ningún otro jugador! 4. Modifica tu
          mano inicial si lo deseas (ver más abajo). Omite este paso en tu
          primera partida. 5. Determina quién será el primer jugador lanzando
          una moneda, tirando un dado, o utilizando alguna otra forma similar.
        </Text>
        <br />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong style={{ fontSize: "16px" }}>
                <center>Modificando tu Mano Inicial</center>
              </Text>
              <Text>
                Antes de que comience el juego, cada jugador puede modificar su
                mano inicial una vez, comenzando con el primer jugador. (Por lo
                general, un jugador hace esto si no tiene muchas cartas con
                íconos de tintero o si tiene demasiadas cartas con un costo
                alto). Para hacerlo, coloca cualquier cantidad de cartas de tu
                mano en la parte inferior de tu mazo sin revelarlas, luego roba
                cartas hasta que tu mano vuelva a tener 7 cartas. Finalmente,
                baraja tu mazo nuevamente.
              </Text>
            </>
          }
        />
      </>
    ),
  },
  {
    key: "3",
    label: (
      <ul>
        <li>JUGANDO</li>
      </ul>
    ),
    children: (
      <>
        <Image
          src={quickRulesImgSmall3}
          preview={{
            src: quickRulesImgLarge3,
          }}
        />
        <Title mark level={5}>
          JUGANDO
        </Title>
        <Text>
          Un turno se divide en dos partes. Cada jugador completa su turno antes
          de pasar el turno al siguiente jugador. En tu turno, sigue estos pasos
          en orden.
        </Text>
        <br />
        <br />
        <Text strong>Fase de Inicio</Text>
        <br />
        <Text strong>1. PREPARAR - </Text>
        <Text>Prepara tus cartas agotadas girándolas hacia arriba.</Text>
        <br />
        <Text strong>2. AJUSTAR - </Text>
        <Text>
          Verifica los efectos que ocurren al comienzo de tu turno y sigue sus
          instrucciones.
        </Text>
        <br />
        <Text strong>3. ROBAR - </Text>
        <Text>
          Roba una carta de la parte superior de tu mazo. El primer jugador se
          salta este paso en su primer turno.
        </Text>
        <br />
        <br />
        <Text strong>Fase Principal</Text>
        <br />
        <Text>
          Una vez por turno, puedes poner una carta boca abajo en tu tintero en
          cualquier momento.
        </Text>
        <br />
        <Text>
          Además, puedes realizar cualquiera de las acciones listadas abajo,
          cualquier número de veces, en el orden que desees. Esto te permite
          aprovechar al máximo lo que pueden hacer tus cartas en combinación con
          otros efectos.
        </Text>
        <Text>
          <ul>
            <li>Jugar una carta.</li>
            <li>Usar una habilidad de personaje que no requiera que se agote.</li>
            <li>Usar una habilidad de objeto (Item).</li>
            <li>
              Realizar una acción con un personaje que haya estado en juego desde
              el comienzo de tu turno. Esto incluye:
            </li>
            <ul>
              <li>Misión.</li>
              <li>Desafiar a un personaje agotado de un oponente.</li>
              <li>Usar una habilidad que requiera que se agote.</li>
            </ul>
          </ul>
        </Text>
        <br /> <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong style={{ fontSize: "16px" }}>
                <center>
                  ¿Qué son las cartas preparadas (ready) y las cartas agotadas
                  (exerted)?
                </center>
              </Text>
              <Text>
                Algunas reglas del juego y efectos de cartas requieren que
                agotes o muestra el ícono de girar, una carta en juego. Para
                agotar una carta (o ícono de girar), gírala de lado.
              </Text>
              <br />
              <Text>
                Una vez que una carta ha sido agotada, no puedes volver a agotar
                hasta que haya sido preparada por una regla del juego o un
                efecto de carta. Para preparar una carta, simplemente gírala de
                nuevo hacia arriba. Recuerda preparar todas tus cartas agotadas
                al comienzo de tu turno.
              </Text>
            </>
          }
        />
        <Title level={5}>Tu Tintero</Title>
        <Text>
          Tu tintero es donde pondrás tus cartas de tinta, boca abajo. Usarás
          las cartas en tu tintero para pagar los costos de las cartas que
          juegas desde tu mano.
        </Text>
        <br />
        <br />
        <Text>
          Puedes poner una carta de tu mano en tu tintero una vez en cada turno.
          La carta que elijas debe tener el ícono de tintero alrededor de su
          costo en la esquina superior izquierda. Cuantas más cartas de tinta
          tengas, más podrás hacer.
        </Text>
        <br />
        <br />
        <Text>
          Para poner una carta en tu tintero, muestra la carta a tus oponentes y
          luego colócala boca abajo en tu tintero como tinta. Cada carta en tu
          tintero representa 1 costo, sin importar lo que haya en el frente.
          ¡Elige sabiamente! Las cartas colocadas en tu tintero permanecen allí
          durante el resto del juego. Una vez que pongas una carta en tu
          tintero, nada en el frente importa, incluyendo su costo y tipo de
          tinta. Es simplemente tinta.
        </Text>
        <Title level={5}>Jugando una Carta</Title>
        <Text>
          Jugar una carta simplemente significa tomarla de tu mano y ponerla
          boca arriba en la mesa. Cada carta tiene un costo de tinta en un
          hexágono en la esquina superior izquierda. Para jugar la carta, debes
          agotar esa cantidad de cartas en tu tintero. Ej: Girarla (agotarla) =
          1 costo.
        </Text>
        <br />
        <br />
        <Text>
          Cuando juegas una carta de personaje, colócala en la mesa encima de tu
          tintero. Los personajes entran en juego en la posición de preparados,
          pero no puedes hacer nada con ellos hasta tu próximo turno. ¡Necesitas
          esperar a que su tinta se seque!
        </Text>
        <br />
        <br />
        <Text>
          Cuando juegas una carta de objeto (item), colócala en la mesa encima
          de tu tintero. A diferencia de los personajes, puedes usar objetos de
          inmediato.
        </Text>
        <br />
        <br />
        <Text>
          Cuando juegas una carta de acción (action), haz lo que la carta te
          indique, luego coloca la carta en tu pila de descarte. Siempre coloca
          las cartas en tu pila de descarte boca arriba para que todos puedan
          verlas.
        </Text>
        <br />
        <br />
        <Text>
          Las canciones (song) son un tipo de carta de acción (action), pero hay
          una regla especial que te da otra forma de pagar por ellas. Cada
          canción dice "(Un personaje con un costo de X o más puede agotarse
          para cantar esta canción de forma gratuita)." Si tienes un personaje
          con el costo listado o superior, puedes agotar ese personaje para
          jugar la carta de canción en lugar de agotar cartas en tu tintero para
          hacerlo. Utilizar este enfoque aún cuenta como jugar la carta. Las
          reglas para cuándo puedes agotar un personaje aún se aplican, por
          supuesto, por lo que los personajes no pueden cantar canciones el
          mismo turno en que entran en juego.
        </Text>
        <Title level={5}>Misiones</Title>
        <Text>
          Para hacer una misión con uno de tus personajes, agótalos y gana lore
          igual a su valor de lore. Recuerda que no puedes hacer una misión con
          un personaje el mismo turno en que lo juegas.
        </Text>
        <Title level={5}>Desafiar</Title>
        <Text>
          Las misiones es cómo ganas el juego, pero a veces necesitas frenar a
          tus oponentes. Aquí es donde entra el desafío. Primero, agota a uno de
          tus personajes para enviarlo al desafío. Luego, elige a un personaje
          agotado de un oponente para desafiar. ¡No puedes desafiar a un
          personaje preparado! Ambos personajes en un desafío infligen daño.
          Mira la Fuerza de cada personaje y coloca esa cantidad de contadores
          de daño en el otro personaje.
        </Text>
        <br />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong style={{ fontSize: "16px" }}>
                <center>Daño</center>
              </Text>
              <Text>
                Los personajes se dañan entre ellos en los desafíos, y algunos
                efectos de cartas también infligen daño. Sea cual sea la fuente,
                los contadores de daño permanecen en un personaje hasta que un
                efecto los elimine o el personaje sea desterrado.
              </Text>
              <Text>
                Un personaje es desterrado cuando tiene contadores de daño que
                alcanzan o superan su Fuerza de Voluntad (willpower). Coloca la
                carta del personaje en la pila de descarte de su jugador.
              </Text>
            </>
          }
        />
        <br />
        <Text strong italic>
          Ejemplo: Desafío
        </Text>
        <br />
        <Text strong italic>
          El Capitán Garfio - El capitán del Jolly Roger, está desafiando a Pato
          Donald - El Pato Alborotado.
        </Text>
        <br /> <br />
        <Text italic>
          El Capitán Garfio tiene 3 de fuerza y Pato Donald tiene 2 de fuerza.
          Al mismo tiempo, el Capitán Garfio inflige 3 puntos de daño al Pato
          Donald, quien recibe 3 contadores de daño. Donald inflige 2 puntos de
          daño al Capitán Garfio, quien recibe 2 contadores de daño. Con solo 3
          de fuerza de voluntad, Donald está en problemas. Debido a que su daño
          es igual o mayor que su fuerza de voluntad, es desterrado y se va a la
          pila de descarte de su jugador. ¡El Capitán Garfio gana el desafío!
          Sin embargo, debe tener cuidado. Su propia fuerza de voluntad es 4,
          por lo que si recibe 2 puntos de daño extras más adelante, él también
          será desterrado.
        </Text>
      </>
    ),
  },
  {
    key: "4",
    label: (
      <ul>
        <li>JUGANDO</li>
        <li>FINALIZACIÓN DEL JUEGO</li>
        <li>JUEGOS MULTIJUGADOR</li>
        <li>CONSTRUYENDO UN MAZO</li>
        <li>ORDEN DE TURNOS EN UN VISTAZO</li>
      </ul>
    ),
    children: (
      <>
        <Image
          src={quickRulesImgSmall4}
          preview={{
            src: quickRulesImgLarge4,
          }}
        />
        <Title level={5}>Uso de Habilidades </Title>
        <Text>
          Muchos objetos y personajes tienen habilidades que puedes utilizar
          durante tu turno (y solo durante tu turno). Normalmente, estas
          habilidades afectan a otras cartas en juego. Las cartas en el mazo, el
          descarte, la mano, o el tintero de un jugador no están "en juego", por
          lo que no se ven afectadas por otras cartas a menos que la carta diga
          lo contrario.
        </Text>
        <br />
        <br />
        <Text>
          Si una habilidad de una carta tiene un costo, este aparece antes de su
          efecto, con un guión separándolos. El costo puede incluir un costo de
          agotamiento, un costo de tinta, texto que explique el costo o una
          combinación de cualquiera de estos. Debes pagar cada parte del costo
          de una habilidad para poder utilizarla.
        </Text>
        <br />
        <br />
        <Text>
          Recuerda que no puedes usar las habilidades de un personaje que hayas
          jugado en este turno.
        </Text>
        <br />
        <br />
        <Text italic>
          Ejemplo: El Espejo de la Bestia tiene la habilidad "Muéstrame", que
          dice "Agotar, paga 3 de costo - Si no tienes cartas en tu mano, roba
          una carta." En tu turno, puedes usar esta habilidad agotando la carta
          y pagando 3 de costo (agotando 3 cartas de tu tintero). Como el Espejo
          es un objeto, ¡incluso puedes usar su habilidad en el mismo turno en
          que jugaste la carta!
        </Text>
        <br />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong style={{ fontSize: "16px" }}>
                <center>FINALIZACIÓN DEL JUEGO</center>
              </Text>
              <Text>
                El primer jugador en alcanzar 20 lore o más gana. Si tu mazo se
                queda sin cartas, pierdes en el próximo momento en el que
                tendrías que robar una carta.
              </Text>
            </>
          }
        />
        <br />
        <Title mark level={5}>
          JUEGOS MULTIJUGADOR
        </Title>
        <Text>
          Lorcana es un juego divertido para 2 jugadores, ¡pero no hay nada que
          te impida jugar partidas de 3 o más jugadores!
        </Text>
        <br />
        <br />
        <Text>
          Las reglas del juego siguen siendo las mismas, excepto que al final
          del turno de un jugador, el jugador a su izquierda toma el siguiente
          turno.
        </Text>
        <br />
        <br />
        <Text>
          Cuando una habilidad requiera que más de un jugador haga algo al mismo
          tiempo, comienza con el jugador cuyo turno es, y luego continúa hacia
          la izquierda hasta que cada jugador afectado haya realizado su acción.
        </Text>
        <br />
        <br />

        <Title mark level={5}>
          CONSTRUYENDO UN MAZO
        </Title>
        <Text>
          ¡Crear tu propio mazo es parte de la diversión en Lorcana! Puedes
          elegir qué personajes quieres, qué habilidades incluir, y las
          estrategias que deseas utilizar.
        </Text>
        <br />
        <br />
        <Text>
          Existen dos enfoques para construir un mazo. El más sencillo es
          reemplazar cartas en un mazo existente, como uno de los mazos listos
          para jugar. También puedes construir un mazo nuevo desde cero
          utilizando las cartas de tu colección. Este método requiere más
          trabajo, ¡pero puede ser muy divertido!
        </Text>
        <br />
        <br />
        <Text strong>Cada mazo de Lorcana debe seguir estas reglas:</Text>
        <Text>
          <ul>
            <li>Tu mazo debe tener al menos 60 cartas.</li>
            <li>
              Tu mazo no puede contener más de 4 copias de una sola carta.
              Ejemplo: No puedes tener más de 4 copias de Elsa - Reina de las
              Nieves en tu mazo. Las versiones diferentes de un personaje se
              cuentan como cartas diferentes, por lo que tener 4 copias de Elsa -
              Reina de las Nieves en tu mazo no te impide agregar hasta 4 copias
              de Elsa - Reina Regente.
            </li>
            <li>Tu mazo solo puede contener cartas de 1 o 2 tintas.</li>
          </ul>
        </Text>
        <Text>
          Asegúrate de visitar disneylorcana.com para ver videos con consejos
          sobre construcción de mazos y estrategia.
        </Text>
        <br />
        <br />
        <Title mark level={5}>
          ORDEN DE TURNOS EN UN VISTAZO
        </Title>
        <br />
        <br />
        <Alert
          className="alertBoxStyle"
          message={
            <>
              <Text strong style={{ fontSize: "16px" }}>
                Fase de Inicio
              </Text>
              <br />
              <Text>1. LISTO: Preparar todas tus cartas.</Text>
              <br />
              <Text>2. AJUSTAR: Ocurren los efectos de inicio de turno.</Text>
              <br />
              <Text>
                3. ROBAR: Robar una carta. (Salta esto en el primer turno.)
              </Text>
              <br />
              <br />
              <Text strong style={{ fontSize: "16px" }}>
                Fase Principal
              </Text>
              <br />
              <Text>
                Elige tantas acciones como desees (excepto cuando se indique lo
                contrario), en cualquier orden:
              </Text>
              <Text>
                <ul>
                  <li>Una vez por turno, añade una carta a tu tintero.</li>
                  <li>Juega una carta.</li>
                  <li>Activa un objeto.</li>
                  <li>
                    Juega una habilidad de personaje que no requiera agotarlo.
                  </li>
                  <li>
                    Con un personaje que estuvo en juego durante el paso de
                    Ajustar:
                  </li>
                  <ul>
                    <li>Hacer una misión -o-</li>
                    <li>Desafiar a un personaje agotado -o-</li>
                    <li>Usar una habilidad que requiera agotarlo.</li>
                  </ul>
                </ul>
              </Text>
              <Text>Costo, Tinta, Fuerza, Fuerza de voluntad, Lore</Text>
            </>
          }
        />
      </>
    ),
  },
];
const QuickRules = () => {
  const { t } = useTranslation();

  return (
    <Content className="container" >
      <Alert
        className="alertBoxStyle"
        message={
          <>
            <Text strong>
              <center>{t("OFFICIAL_RULES")}</center>
            </Text>
            <Text><center>{t("OFFICIAL_RULES_INTRO")}</center></Text>
          </>
        }
      />
      <Collapse style={{ margin: "10px" }} items={items} />
    </Content>
  );
};
export default QuickRules;
