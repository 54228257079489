import axios from "axios";

export const cardsApi = {
  getCards: async function (
    cardNumber = undefined,
    name = undefined,
    sets = undefined,
    inks = undefined,
    types = undefined,
    keywords = undefined,
    classifications = undefined,
    rarities = undefined,
    inkwell = undefined,
    franchises = undefined,
    artists = undefined,
    products = undefined,
    strengthMin = undefined,
    strengthMax = undefined,
    willpowerMin = undefined,
    willpowerMax = undefined,
    inkCostMin = undefined,
    inkCostMax = undefined,
    loreValueMin = undefined,
    loreValueMax = undefined,
    moveCostMin = undefined,
    moveCostMax = undefined,
    page = 1
  ) {
    try {
      let options = {
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/cards/cards`,
        params: {
          cardNumber,
          name,
          sets,
          inks,
          types,
          keywords,
          classifications,
          rarities,
          inkwell,
          franchises,
          artists,
          products,
          strengthMin,
          strengthMax,
          willpowerMin,
          willpowerMax,
          inkCostMin,
          inkCostMax,
          loreValueMin,
          loreValueMax,
          moveCostMin,
          moveCostMax,
          page,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      };
      const response = await axios(options);
      const allInfo = response.data;
      return allInfo;
    } catch (error) {
      console.error("Error getting cards: " + error);
    }
  },
  getCatalogos: async function () {
    try {
      let options = {
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/catalogos`,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      };
      const response = await axios(options);
      return response.data;
    } catch (error) {
      console.error("Error getting catalogos: " + error);
    }
  },
  getCardNumber: async function (cardNumber = undefined) {
    try {
      let options = {
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/card`,
        params: {
          cardNumber,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      };
      const response = await axios(options);
      const allInfo = response.data;
      return allInfo;
    } catch (error) {
      console.error("Error getting card number: " + error);
    }
  },
};
