const TRANSLATIONS_EN = {
  NEW_CLIENT_MODAL_TITLE: "Create new client",
  // OTROS
  TITLE_CARDS: "Disney Lorcana Card Collection",
  SHOW_IMG: "Show img",
  SHOW_ALL_INFO: "Show all info",
  CARDS: "Cards",
  SET: "Set",
  INK: "Ink",
  RARITY: "Rarity",
  TYPE: "Type",
  CLASSIFICATIONS: "Classifications",
  KEYWORDS: "Keywords",
  FRANCHISE: "Franchise",
  NAME: "Name",
  STRENGTH: "Strength",
  WILLPOWER: "Willpower",
  LORE_VALUE: "Lore",
  MOVE_COST: "Move Cost",
  INKWELL: "Inkwell",
  INKWELL_YES: "Inkwell",
  INKWELL_NO: "No Inkwell",
  YES: "Yes",
  NOT: "No",
  INK_COST: "Ink cost",
  ARTIST: "Artist",
  PRODUCT: "Product",
  EXTRAS: "Extras",
  SEARCH_NAME: "Search by name",
  SEARCH_ALL: "Search",
  OPEN: "Open",
  CLOSE: "Close",
  PREVIEW: "Preview",
  // INFO: "more info",
  GO: "Go to card",
  ABILITY: "Ability",
  LORE: "Lore",
  BODY_TEXT: "Body text",
  VERSION_NAME: "Version name",
  FLAVOR_TEXT: "Flavor text",
  CARD_NUMBER: "Card number",
  RELEASE_DATE: "Release date",
  PROMO_INFO: "Promo type",
  VARIANT: "Variante",

  // MENU
  HOME: "Home",
  LORCANA: "Lorcana",
  COMPARE_CARDS: "Compare Cards",
  DECKS: "Decks",
  CREATE_DECKS: "Create Deck",
  USER: "User",
  PROFILE: "Profile",
  STORY: "Story",
  PLAY: "How to play",
  PRODUCTS: "Products",
  QUICK_RULES: "Quick Rules",
  WIKI: "Wiki",
  MY_COLLECTION: "My collection",
  WISHLIST: "Wishlist",
  CHANGE_LANGUAGE: "Cambiar Idioma",
  LORCANA_COPY:
    "“This website uses trademarks and/or copyrights associated with Disney Lorcana TCG, used under Ravensburger’s Community Code Policy (https://cdn.ravensburger.com/lorcana/community-code-en). We are expressly prohibited from charging you to use or access this content. This website is not published, endorsed, or specifically approved by Disney or Ravensburger. For more information about Disney Lorcana TCG, visit https://www.disneylorcana.com/en-US/.”",

  // VISTA HOME(noticias)
  NEWS: "BREAKING NEWS",
  NEWS_TEXT:
    "Find out everything that is being said about Disney Lorcana right now!",
  CARD_CATALOG: "CARD CATALOG",
  TOTAL: "Total cards found: ",
  NOT_CARDS: "No results were found that match your search.",
  LOAD_MORE: "Load more",
  MORE_FILTERS: "More filters",
  // VISTA COMPARE CARDS
  OVERSIZED: "Oversized?",
  FOIL: "Foil?",
  BACK: "Back to Cards",
  COMPARE: "Compare",
  COMPARE_WITH: "Compare with:",
  ENTER_NAME: "Enter character...",
  ENTER_SEARCH: "Enter...",
  SEARCH_BY_NAME: "search by name",
  EMPTY_DATA: "Empty search",
  // VISTA CARD DETAILS
  ADD_TO_COLLECTION: "Add to collection (coming soon)",
  ADD_TO_WISHLIST: "Add to wishlist (coming soon)",
  ADD_TO_DECK: "Add to deck (coming soon)",
  IN_PRODUCT: "Part of the product",
  AMOUNT: ". Amount: ",
  OTHER_EDITIONS: "Other editions",
  ALT_ART: "Alternative art",
  STANDARD: "Standard",
  // VISTA QUICK RULES:
  OFFICIAL_RULES: "OFFICIAL RULES",
  OFFICIAL_RULES_INTRO:
    "In this section you will find the quick rules guide in Spanish, along with the original manual.",

  // SET (todos)
  THE_FIRST_CHAPTER: "Set 1 - The First Chapter",
  RISE_OF_THE_FLOODBORN: "Set 2 - Rise of the Floodborn",
  INTO_THE_INKLANDS: "Set 3 - Into the Inklands",
  URSULAS_RETURN: "Set 4 - Ursula's Return",

  // PRODUCT decks and boxes (todos)
  TFC_SD_SS: "Sapphire/Steel Starter Deck (Set 1)",
  TFC_SD_SS_TEXT:
    "The First Chapter, Starter Deck, inks Sapphire and Steel. Aurora and Simba",
  TFC_SD_ER: "Emerald/Ruby Starter Deck (Set 1)",
  TFC_SD_ER_TEXT:
    "The First Chapter, Starter Deck, inks Emerald and Ruby. Cruella De Vil and Aladdin",
  TFC_SD_AA: "Amber/Amethyst Starter Deck (Set 1)",
  TFC_SD_AA_TEXT:
    "The First Chapter, Starter Deck, inks Amber and Amethyst. Mickey Mouse and Moana",
  // TFC_GS: "The First Chapter, Gift Set",
  D100CE: "Disney 100 Collector's Edition",
  D100CE_TEXT: "Disney 100 Collector's Edition",
  S2_STARTER_DECK_AMBER_SAPPHIRE: "Amber/Sapphire Starter Deck (Set 2)",
  S2_STARTER_DECK_AMETHYST_STEEL: "Amethyst/Steel Starter Deck (Set 2)",
  S2_STARTER_DECK_AMBER_SAPPHIRE_TEXT:
    "Rise of the Floodborn, Starter Deck, inks Amber and Sapphire. The Evil Queen and Gaston",
  S2_STARTER_DECK_AMETHYST_STEEL_TEXT:
    "Rise of the Floodborn, Starter Deck, inks Amethyst and Steel. Merlin and Tiana",
  S3_STARTER_DECK_AMBER_EMERALD: "Amber/Emerald Starter Deck (Set 3)",
  S3_STARTER_DECK_RUBY_SAPPHIRE: "Ruby/Sapphire Starter Deck (Set 3)",
  S3_STARTER_DECK_AMBER_EMERALD_TEXT:
    "Into the Inklands, Starter Deck, inks Amber and Emerald. 101 Dalmatians and Peter Pan",
  S3_STARTER_DECK_RUBY_SAPPHIRE_TEXT:
    "Into the Inklands, Starter Deck, inks Ruby and Sapphire. Moana and Scrooge McDuck",
  S4_STARTER_DECK_AMBER_AMETHYST: "Amber/Amethyst Starter Deck (Set 4)",
  S4_STARTER_DECK_SAPPHIRE_STEEL: "Sapphire/Steel Starter Deck (Set 4)",
  S4_STARTER_DECK_AMBER_AMETHYST_TEXT:
    "Ursula's Return, Starter Deck, inks Amber and Amethyst. Mirabel and Bruno",
  S4_STARTER_DECK_SAPPHIRE_STEEL_TEXT:
    "Ursula's Return, Starter Deck, inks Sapphire and Steel. Anna and Hercules",
  ILLUMINEERS_QUEST_DEEP_TROUBLE: "Illumineer's Quest Deep Trouble",

  // TYPE CARD
  CHARACTER: "Character",
  //ACTION
  ACTION_SONG: "Action - Song",
  ITEM_CARD: "Item",
  LOCATION: "Location",

  // CHARACTER CARD (todos)
  ABU: "Abu",
  ALADDIN: "Aladdin",
  ALICE: "Alice",
  ANNA: "Anna",
  ARCHIMEDES: "Archimedes",
  ARIEL: "Ariel",
  ARTHUR: "Arthur",
  AUDREY_RAMIREZ: "Audrey Ramirez",
  AURORA: "Aurora",
  BALOO: "Baloo",
  BASHFUL: "Bashful",
  BASIL: "Basil",
  BEAST: "Beast",
  BELLE: "Belle",
  BENJA: "Benja",
  BERNARD: "Bernard",
  BILLY_BONES: "Billy Bones",
  BLUE_FAIRY: "Blue Fairy",
  BOUND: "Bound",
  BRAWL: "Brawl",
  BRUNO_MADRIGAL: "Bruno Madrigal",
  BUCKY: "Bucky",
  CAMILO_MADRIGAL: "Camilo Madrigal",
  // CAPTAIN
  CAPTAIN_AMELIA: "Captain Amelia",
  CAPTAIN_HOOK: "Captain Hook",
  CARD_SOLDIERS: "Card Soldiers",
  CATERPILLAR: "Caterpillar",
  CERBERUS: "Cerberus",
  CHERNABOG: "Chernabog",
  CHERNABOGS_FOLLOWERS: "Chernabog's Followers",
  CHESHIRE_CAT: "Cheshire Cat",
  CHIEF_BOGO: "Chief Bogo",
  CHIEF_TUI: "Chief Tui",
  CHIEN_PO: "Chien-Po",
  CHI_FU: "Chi-Fu",
  CHIP_THE_TEACUP: "Chip the Teacup",
  CHRISTOPHER_ROBIN: "Christopher Robin",
  CINDERELLA: "Cinderella",
  COBRA_BUBBLES: "Cobra Bubbles",
  COGSWORTH: "Cogsworth",
  CRI_KEE: "Cri-Kee",
  CRUELLA_DE_VIL: "Cruella de Vil",
  CUBBY: "Cubby",
  CURSED_MERFOLK: "Cursed Merfolk",
  DAISY_DUCK: "Daisy Duck",
  DALMATIAN_PUPPY: "Dalmatian Puppy",
  DELLA_DUCK: "Della Duck",
  DEWEY: "Dewey",
  DIABLO: "Diablo",
  DOC: "Doc",
  DOLORES_MADRIGAL: "Dolores Madrigal",
  DON_KARNAGE: "Don Karnage",
  DONALD_DUCK: "Donald Duck",
  DOPEY: "Dopey",
  DR_FACILIER: "Dr. Facilier",
  DUKE_OF_WESELTON: "Duke of Weselton",
  DUKE_WEASELTON: "Duke Weaselton",
  EEYOTE: "Eeyore",
  ELI_LA_BOUFF: "Eli La Bouff",
  ELSA: "Elsa",
  ENCHANTRESS: "Enchantress",
  EUDORA: "Eudora",
  FAIRY_GODMOTHER: "Fairy Godmother",
  FA_ZHOU: "Fa Zhou",
  FELICIA: "Felicia",
  FELIX_MADRIGAL: "Félix Madrigal",
  FIDGET: "Fidget",
  FLINTHEART_GLOMGOLD: "Flintheart Glomgold",
  FLOTSAM: "Flotsam",
  FLOTSAM_JETSAM: "Flotsam & Jetsam",
  FLOUNDER: "Flounder",
  FLYNN_RIDER: "Flynn Rider",
  FRIAR_TUCK: "Friar Tuck",
  GANTU: "Gantu",
  GASTON: "Gaston",
  GENIE: "Genie",
  GOOFY: "Goofy",
  GOONS: "Goons",
  GRAMMA_TALA: "Gramma Tala",
  GRAND_DUKE: "Grand Duke",
  GRAND_PABBIE: "Grand Pabbie",
  GRUMPY: "Grumpy",
  GUNTHER: "Gunther",
  GUSTAV_THE_GIANT: "Gustav the Giant",
  GYRO_GEARLOOSE: "Gyro Gearloose",
  HADES: "Hades",
  HANS: "Hans",
  HAPPY: "Happy",
  HEIHEI: "Heihei",
  HELGA_SINCLAIR: "Helga Sinclair",
  HERCULES: "Hercules",
  HIRAM_FLAVERSHAM: "Hiram Flaversham",
  HONEST_JOHN: "Honest John",
  HORACE: "Horace",
  HUEY: "Huey",
  HYDRA: "Hydra",
  HYDROS: "Hydros",
  IAGO: "Iago",
  ISABELA_MADRIGAL: "Isabela Madrigal",
  JAFAR: "Jafar",
  JAQ: "Jaq",
  JAMES: "James",
  JASMINE: "Jasmine",
  JASPER: "Jasper",
  JETSAM: "Jetsam",
  JIM_HAWKINS: "Jim Hawkins",
  JIMINY_CRICKET: "Jiminy Cricket",
  JOHN_SILVER: "John Silver",
  JOSHUA_SWEET: "Joshua Sweet",
  JUDY_HOPPS: "Judy Hopps",
  JULIETA_MADRIGAL: "Julieta Madrigal",
  JUMBA_JOOKIBA: "Jumba Jookiba",
  KAKAMORA: "Kakamora",
  KHAN: "Khan",
  KIDA: "Kida",
  KING_LOUIE: "King Louie",
  KIT_CLOUDKICKER: "Kit Cloudkicker",
  KRISTOFF: "Kristoff",
  KRONK: "Kronk",
  KUZCO: "Kuzco",
  LADY_TREMAINE: "Lady Tremaine",
  LAWRENCE: "Lawrence",
  LEFOU: "LeFou",
  LENA_SABREWING: "Lena Sabrewing",
  LING: "Ling",
  LI_SHANG: "Li Shang",
  LILO: "Lilo",
  LITTLE_JOHN: "Little John",
  LOUIE: "Louie",
  LUCIFER: "Lucifer",
  LUCKY: "Lucky",
  LUISA_MADRIGAL: "Luisa Madrigal",
  LUMIERE: "Lumiere",
  LYLE_TIBERIUS_ROURKE: "Lyle Tiberius Rourke",
  LYTHOS: "Lythos",
  MAD_HATTER: "Mad Hatter",
  MADAM_MIM: "Madam Mim",
  MADAME_MEDUSA: "Madame Medusa",
  MAID_MARIAN: "Maid Marian",
  MAGIC_BROOM: "Magic Broom",
  MAGIC_CARPET: "Magic Carpet",
  MAGICA_DE_SPELL: "Magica De Spell",
  MALEFICENT: "Maleficent",
  MAMA_ODIE: "Mama Odie",
  MAUI: "Maui",
  MAURICE: "Maurice",
  MARSHMALLOW: "Marshmallow",
  MAXIMUS: "Maximus",
  MERLIN: "Merlin",
  MICKEY_MOUSE: "Mickey Mouse",
  MILO_THATCH: "Milo Thatch",
  MINNIE_MOUSE: "Minnie Mouse",
  MIRABEL_MADRIGAL: "Mirabel Madrigal",
  MISS_BIANCA: "Miss Bianca",
  MEGARA: "Megara",
  MOANA: "Moana",
  MORPH: "Morph",
  MOTHER_GOTHEL: "",
  MRS_JUDSON: "Mrs. Judson",
  MR_SMEE: "Mr. Smee",
  MR_SNOOPS: "Mr. Snoops",
  MUFASA: "Mufasa",
  MULAN: "Mulan",
  NALA: "Nala",
  NAMAARI: "Namaari",
  NANA: "Nana",
  NANI: "Nani",
  NICK_WILDE: "Nick Wilde",
  NOI: "Noi",
  NUTSY: "Nutsy",
  OLAF: "Olaf",
  ORVILLE: "Orville",
  OWL: "Owl",
  PACHA: "Pacha",
  PAIN: "Pain",
  PANIC: "Panic",
  PASCAL: "Pascal",
  PATCH: "Patch",
  PEGASUS: "Pegasus",
  PEPA_MADRIGAL: "Pepa Madrigal",
  PERDITA: "Perdita",
  PETE: "Pete",
  PETER_PAN: "Peter Pan",
  PETER_PANS_SHADOW: "Peter Pan's Shadow",
  PHILOCTETES: "Philoctetes",
  PIGLET: "Piglet",
  PINOCCHIO: "Pinocchio",
  PLUTO: "Pluto",
  PONGO: "Pongo",
  PRINCE_CHARMING: "Prince Charming",
  PRINCE_ERIC: "Prince Eric",
  PRINCE_JOHN: "Prince John",
  PRINCE_NAVEEN: "Prince Naveen",
  PRINCE_PHILLIP: "Prince Phillip",
  PUA: "Pua",
  PUMBAA: "Pumbaa",
  PYROS: "Pyros",
  QUEEN_OF_HEARTS: "Queen of Hearts",
  RABBIT: "Rabbit",
  RAFIKI: "Rafiki",
  RAJAH: "Rajah",
  RAPUNZEL: "Rapunzel",
  RATIGAN: "Ratigan",
  RAY: "Ray",
  RAYA: "Raya",
  RAZOUL: "Razoul",
  ROBIN_HOOD: "Robin Hood",
  ROLLY: "Rolly",
  RUFUS: "Rufus",
  SCAR: "Scar",
  SCROOP: "Scroop",
  SCROOGE_MCDUCK: "Scrooge McDuck",
  SEBASTIAN: "Sebastian",
  SERGEANT_TIBBS: "Sergeant Tibbs",
  SHENZI: "Shenzi",
  SHERE_KHAN: "Shere Khan",
  SHERIFF_OF_NOTTINGHAM: "Sheriff of Nottingham",
  SIMBA: "Simba",
  SIR_HISS: "Sir Hiss",
  SISU: "Sisu",
  SKIPPY: "Skippy",
  SLEEPY: "Sleepy",
  SLIGHTLY: "Slightly",
  SNEEZY: "Sneezy",
  SNOW_WHITE: "Snow White",
  STARKEY: "Starkey",
  STITCH: "Stitch",
  STRATOS: "Stratos",
  SVEN: "Sven",
  TAMATOA: "Tamatoa",
  TE_KA: "Te Ka",
  TIANA: "Tiana",
  TICK_TOCK: "Tick-Tock",
  TIGGER_CHAR: "Tigger Char",
  TIMON: "Timon",
  TINKER_BELL: "Tinker Bell",
  THADDEUS_E_KLANG: "Thaddeus E. Klang",
  THE_FIREBIRD: "The Firebird",
  THE_HUNTSMAN: "The Huntsman",
  THE_MUSES: "The Muses",
  THE_NOKK: "The Nokk",
  THE_PRINCE: "The Prince",
  THE_QUEEN: "The Queen",
  THE_WARDROBE: "The Wardrobe",
  TOR: "Tor",
  TREASURE_GUARDIAN: "Treasure Guardian",
  TRIGGER: "Trigger",
  TRITON: "Triton",
  TUK_TUK: "Tuk Tuk",
  URSULA: "Ursula",
  VIRANA: "Virana",
  WEBBY_VANDERQUACK: "Webby Vanderquack",
  WENDY_DARLING: "Wendy Darling",
  WILDCAT: "Wildcat",
  WINNIE_THE_POOH: "Winnie the Pooh",
  YEN_SID: "Yen Sid",
  YZMA: "Yzma",
  ZAZU: "Zazu",
  ZEUS: "Zeus",

  // CLASSIFICATIONS (todos)
  ACTION: "Action",
  ALIEN: "Alien",
  ALLY: "Ally",
  BROOM: "Broom",
  CAPTAIN: "Captain",
  DEITY: "Deity",
  DETECTIVE: "Detective",
  DRAGON: "Dragon",
  DREAMBORN: "Dreamborn",
  FAIRY: "Fairy",
  FLOODBORN: "Floodborn",
  HERO: "Hero",
  INVENTOR: "Inventor",
  ITEM: "Item",
  KING: "King",
  KNIGHT: "Knight",
  MADRIGAL: "Madrigal",
  MENTOR: "Mentor",
  MUSKETEER: "Musketeer",
  PIRATE: "Pirate",
  PRINCE: "Prince",
  PRINCESS: "Princess",
  PUPPY: "Puppy",
  QUEEN: "Queen",
  SEVEN_DWARFS: "Seven Dwarfs",
  SONG: "Song",
  SORCERER: "Sorcerer",
  STORYBORN: "Storyborn",
  TIGGER: "Tigger",
  TITAN: "Titan",
  VILLAIN: "Villain",

  // INK
  AMBER: "Amber",
  AMETHYST: "Amethyst",
  EMERALD: "Emerald",
  RUBY: "Ruby",
  SAPPHIRE: "Sapphire",
  STEEL: "Steel",

  // INK COLOR
  YELLOW: "Yellow",
  PURPLE: "Purple",
  GREEN: "Green",
  RED: "Red",
  BLUE: "Blue",
  GREY: "Grey",

  // RARITY
  COMMON: "Common",
  UNCOMMON: "Uncommon",
  RARE: "Rare",
  SUPER_RARE: "Super Rare",
  LEGENDARY: "Legendary",
  ENCHANTED: "Enchanted",
  PROMO: "Promo",

  // KEYWORDS
  BODYGUARD: "Bodyguard",
  CHALLENGER: "Challenger X",
  EVASIVE: "Evasive",
  RECKLESS: "Reckless",
  RESIST: "Resist X",
  RUSH: "Rush",
  SHIFT: "Shift X",
  SINGER: "Singer X",
  SING_TOGETHER: "Sing Together X",
  SUPPORT: "Support",
  WARD: "Ward",

  // KEYWORDS TEXT (todos)
  BODYGUARD_TEXT:
    "This character may enter play exerted. An opposing character who challenges one of your characters must choose one with Bodyguard if able.",
  CHALLENGER_X_TEXT: "When challenging, this character get +X strength.",
  EVASIVE_TEXT: "Only characters with Evasive can challenge this character.",
  RECKLESS_TEXT: "This character can't quest and must challenge if able.",
  RESIST_X_TEXT: "Damage dealt to this character is reduced by X.",
  RUSH_TEXT: "This character can challenge the turn they're played.",
  SHIFT_X_TEXT:
    "You may pay X Ink to play this on top of one of your Y characters.",
  SINGER_X_TEXT: "This character counts as cost X to sing songs.",
  SING_TOGETHER_X_TEXT: "Sing Together X (Any number of your of your teammates' characters with total cost X or more may exert to sing this song for free.)",
  SUPPORT_TEXT:
    "Whenever this character quests, you may add their strength to another chose character's strength this turn.",
  WARD_TEXT: "Opponents can't choose them except to challenge.",

  // KEYWORDS TEXT (todos)    CHALLENGER_X, SINGER_X, RESIST_X
  KEYWORDS_CHALLENGER_2:
    "+2 (While challenging, this character gets +2 strength.)",
  KEYWORDS_CHALLENGER_3:
    "+3 (While challenging, this character gets +3 strength.)",
  KEYWORDS_CHALLENGER_4:
    "+4 (While challenging, this character gets +4 strength.)",

  KEYWORDS_RESIST_1: "+1 (Damage dealt to this character is reduced by 1.)",
  KEYWORDS_RESIST_2: "+2 (Damage dealt to this character is reduced by 2.)",

  KEYWORDS_SINGER_3: "3 (This character counts as cost 3 to sing songs.)",
  KEYWORDS_SINGER_4: "4 (This character counts as cost 4 to sing songs.)",
  KEYWORDS_SINGER_5: "5 (This character counts as cost 5 to sing songs.)",
  KEYWORDS_SINGER_7: "7 (This character counts as cost 7 to sing songs.)",

  KEYWORDS_SING_TOGETHER_6: "6 (Any number of your of your teammates' characters with total cost 6 or more may exert to sing this song for free.)",
  KEYWORDS_SING_TOGETHER_7: "7 (Any number of your of your teammates' characters with total cost 7 or more may exert to sing this song for free.)",
  KEYWORDS_SING_TOGETHER_8: "8 (Any number of your of your teammates' characters with total cost 8 or more may exert to sing this song for free.)",
  KEYWORDS_SING_TOGETHER_10: "10 (Any number of your of your teammates' characters with total cost 10 or more may exert to sing this song for free.)",

  // FRANCHISE (todos)
  // ALADDIN
  ALICE_IN_WONDERLAND: "Alice in wonderland",
  ATLANTIS_THE_LOST_EMPIRE: "Atlantis: The Lost Empire",
  BEAUTY_AND_THE_BEAST: "Beauty and the beast",
  // CINDERELLA
  DALMATIANS_101: "101 Dalmatians",
  DUCKTALES: "DuckTales",
  ENCANTO: "Encanto",
  FANTASIA: "Fantasia",
  FROZEN: "Frozen",
  //HERCULES
  LILO_AND_STITCH: "Lilo and Stitch",
  // LORCANA
  MICKEY_AND_FRIENDS: "Mickey and friends",
  // MOANA
  // MULAN
  // PETER_PAN
  // PINOCCHIO
  RAYA_AND_THE_LAST_DRAGON: "Raya and the Last Dragon",
  // ROBIN_HOOD
  SLEEPING_BEAUTY: "Sleeping Beauty",
  SNOW_WHITE_AND_THE_SEVEN_DWARFS: "Snow White and the Seven Dwarfs",
  TALESPIN: "TaleSpin",
  TANGLED: "Tangled",
  THE_EMPERORS_NEW_GROOVE: "The Emperor's New Groove",
  THE_GREAT_MOUSE_DETECTIVE: "The Great Mouse Detective",
  THE_JUNGLE_BOOK: "The Jungle Book",
  THE_LION_KING: "The Lion King",
  THE_LITTLE_MERMAID: "The Little Mermaid",
  THE_PRINCESS_AND_THE_FROG: "The Princess and the Frog",
  THE_RESCUERS: "The Rescuers",
  THE_SWORD_IN_THE_STONE: "The Sword in the Stone",
  TREASURE_PLANET: "Treasure Planet",
  // WINNIE_THE_POOH: "Winnie the Pooh",
  WRECK_IT_RALPH: "Wreck-It Ralph",
  ZOOTOPIA: "Zootopia",

  // SET 1
  // ITEM CARD set 1
  BEAST_MIRROR: "Beast mirror",
  COCONUT_BASKET: "Coconut basket",
  DINGLEHOPPER: "Dinglehopper",
  DR_FACILIERS_CARDS: "Dr. Facilier's Cards",
  EYE_OF_THE_FATES: "Eye of the fates",
  FISHBONE_QUILL: "Fishbone quill",
  FRYING_PAN: "Frying pan",
  LANTERN: "Lantern",
  MAGIC_GOLDEN_FLOWER: "Magic golden flower",
  MAGIC_MIRROR: "Magic mirror",
  MUSKETEER_TABARD: "Musketeer tabard",
  PLASMA_BLASTER: "Plasma blaster",
  POISONED_APPLE: "Poisoned apple",
  SCEPTER_OF_ARENDELLE: "Scepter of Arendelle",
  SHIELD_OF_VIRTUE: "Shield of virtue",
  STOLEN_SCIMITAR: "Stolen scimitar",
  SWORD_OF_TRUTH: "Sword of truth",
  URSULAS_CAULDRON: "Ursula's cauldron",
  URSULAS_SHELL_NECKLACE: "Ursula's shell necklace",
  WHITE_RABBITS_POCKET_WATCH: "White rabbit's pocket watch",

  // ACTION CARD set 1
  BEFUDDLE: "Befuddle",
  BREAK: "Breack",
  CONTROL_YOUR_TEMPER: "Control your temper",
  CUT_TO_THE_CHASE: "Cut to the chase",
  DEVELOP_YOUR_BRAIN: "Develop your brain",
  DO_IT_AGAIN: "Do it again",
  DRAGON_FIRE: "Dragon fire",
  FAN_THE_FLAMES: "Fan the flames",
  FIRE_THE_CANNONS: "Fire the cannons",
  FREEZE: "Freeze",
  HEALING_GLOW: "Healing glow",
  HES_GOT_A_SWORD: "He's got a sword",
  IF_ITS_NOT_BAROQUE: "If it's not baroque",
  JUST_IN_TIME: "Just in time",
  RANSACK: "Ransack",
  SMASH: "Smash",
  STAMPEDE: "Stampede",
  STEAL_FROM_THE_RICH: "Steal from the rich",
  TANGLE: "Tangle",
  THE_BEAST_IS_MINE: "The beast is mine",
  VICIOUS_BETRAYAL: "Vicious betrayal",
  YOU_HAVE_FORGOTTEN_ME: "You have forgotten me",
  WORK_TOGETHER: "Work together",

  // ACTION SONG CARD set 1
  A_WHOLE_NEW_WORLD: "A whole new world",
  BE_OUR_GUEST: "Be our guest",
  BE_PREPARED: "Be prepared",
  FRIENDS_ON_THE_OTHER_SIDE: "Friends on the other side",
  GRAB_YOUR_SWORD: "Grab your sword",
  HAKUNA_MATATA: "Hakuna Matata",
  LET_IT_GO: "Let it go",
  MOTHER_KNOWS_BEST: "Mother knows best",
  ONE_JUMP_AHEAD: "One jump ahead",
  PART_OF_YOUR_WORLD: "Part of your world",
  REFLECTION: "Reflection",
  SUDDEN_CHILL: "Sudden chill",

  // VERSION NAME set 1
  ABOMINATION: "Abomination",
  AGENT_PROVOCATEUR: "Agent Provocateur",
  ALCHEMIST: "Alquimista",
  ALIEN_PIRATE: "Alien Pirate",
  ALWAYS_CLASSY: "Always Classy",
  ARROGANT_HUNTER: "Arrogant Hunter",
  ARTFUL_ROGUE: "Artful Rogue",
  BELLES_CONFIDANT: "Belle's Confidant",
  BELOVED_PRINCESS: "Beloved Princess",
  BIDING_HER_TIME: "Biding Her Time",
  BOAT_SNACK: "Boat Snack",
  BOISTEROUS_FOWL: "Boisterous Fowl",
  BRAVE_LITTLE_TAILOR: "Brave Little Tailor",
  BRIAR_ROSE: "Briar Rose",
  BUCKET_BRIGADE: "Bucket Brigade",
  BUMBLER: "Bumbler",
  CAPTAIN_OF_THE_JOLLY_ROGER: "Captain of the Jolly Roger",
  CAREFREE_SURFER: "Carefree Surfer",
  CHARLATAN: "Charlatan",
  CHARMING_ROGUE: "Charming Rogue",
  CHOSEN_BY_THE_OCEAN: "Chosen by the Ocean",
  COLONELS_LIEUTENANT: "Colonel's Lieutenant",
  COMMON_CROOK: "Common Crook",
  CORNERED_SWORDSMAN: "Cornered Swordsman",
  COURAGEOUS_CAT: "Courageous Cat",
  COURT_COMPOSER: "Court Composer",
  DAREDEVIL: "Daredevil",
  DASHING_AND_BRAVE: "Dashing and Brave",
  DEMIGOD: "Demigod",
  // DETECTIVE
  DISGUISED: "Disguised",
  DRAB_LITTLE_CRAB: "Drab Little Crab",
  DRAGONSLAYER: "Dragonslayer",
  DREAMING_GUARDIAN: "Dreaming Guardian",
  FEARLESS_FIGHTER: "Fearless Fighter",
  FIERY_USURPER: "Fiery Usurper",
  FORCEFUL_DUELIST: "Forceful Duelist",
  FRIENDLY_SNOWMAN: "Friendly Snowman",
  FRIENDLY_WARTHOG: "Friendly Warthog",
  FUTURE_KING: "Future King",
  GALACTIC_FEDERATION_CAPTAIN: "Galactic Federation Captain",
  GALACTIC_HERO: "Galactic Hero",
  GENTLE_AND_KIND: "Gentle and Kind",
  GIANT_FAIRY: "Giant Fairy",
  GIFTED_WITH_HEALING: "Gifted with Healing",
  GOD_OF_LIGHTNING: "God of Lightning",
  GRACIOUS_HOST: "Gracious Host",
  GRUB_RUSTLER: "Grub Rustler",
  HARDHEADED: "Hardheaded",
  HEARTLESS: "Heartless",
  HEIR_TO_ARENDELLE: "Heir to Arendelle",
  HERO_TO_ALL: "Hero to AlL",
  HEROIC_OUTLAW: "Heroic Outlaw",
  HIGHLY_EDUCATED_OWL: "Highly Educated Owl",
  HOOKS_HENCHMAN: "Hook's Henchman",
  ICE_SURFER: "Ice Surfer",
  IMPERIAL_SOLDIER: "Imperial Soldier",
  INFERNAL_SCHEMER: "Infernal Schemer",
  INSTIGATOR: "Instigator",
  INVENTIVE_ENGINEER: "Inventive Engineer",
  KEEPER_OF_SECRETS: "Keeper of Secrets",
  KING_OF_OLYMPUS: "King of Olympus",
  KING_OF_THE_PRIDE_LANDS: "King of the Pride Lands",
  LETTING_DOWN_HER_HAIR: "Letting Down Her Hair",
  LORD_OF_THE_UNDERWORLD: "Lord of the Underworld",
  LOUD_MOUTHED_PARROT: "Loud-Mouthed Parrot",
  LOYAL_FIRST_MATE: "Loyal First Mate",
  MAKING_A_WISH: "Making a Wish",
  MALEFICENTS_UNDERLINGS: "Maleficent's Underlings",
  MASTERMIND: "Mastermind",
  MISCHIEVOUS_MONKEY: "Mischievous Monkey",
  MISERABLE_AS_USUAL: "Miserable As Usual",
  MONSTROUS_DRAGON: "Monstrous Dragon",
  MOST_HELPFUL: "Most Helpful",
  // MUSKETEER
  MYSTERIOUS_SAGE: "Mysterious Sage",
  NEVER_LANDING: "Never Landing",
  NEW_DOG: "New Dog",
  NO_GOOD_SCOUNDREL: "No-Good Scoundrel",
  NOT_ALL_THERE: "Not All There",
  OFFICIAL_ICE_DELIVERER: "Official Ice Deliverer",
  OFFICIAL_ICE_MASTER: "Official Ice Master",
  OF_MOTUNUI: "Of Motunui",
  OL_RASCAL: "Ol' Rascal",
  ON_HUMAN_LEGS: "On Human Legs",
  ON_THE_JOB: "On the Job",
  OPPORTUNISTIC_OFFICIAL: "Opportunistic Official",
  PALACE_HORSE: "Palace Horse",
  PERSISTENT_GUARDIAN: "Persistent Guardian",
  PETER_PANS_ALLY: "Peter Pan's Ally",
  POWER_HUNGRY: "Power Hungry",
  POWER_UNLEASHED: "Powers Unleashed",
  PRINCE_ALI: "Prince Ali",
  PROTECTIVE_CUB: "Protective Cub",
  PULLING_THE_STRINGS: "Pulling the Strings",
  QUEEN_OF_AGRABAH: "Queen of Agrabah",
  QUEEN_REGENT: "Queen Regent",
  RAPUNZELS_COMPANION: "Rapunzel's Companion",
  REGAL_PRINCESS: "Regal Princess",
  RELENTLESS_PURSUER: "Relentless Pursuer",
  REMARKABLE_GENTLEMAN: "Remarkable Gentleman",
  RENEGADE_SCIENTIST: "Renegade Scientist",
  RESPECTED_LEADER: "Respected Leader",
  RETURNED_KING: "Returned King",
  RIGHTFUL_HEIR: "Rightful Heir",
  RIGHT_HAND_MAN: "Right-Hand Man",
  ROCK_STAR: "Rock Star",
  RUTHLESS_PIRATE: "Ruthless Pirate",
  SCHEMING_PRINCE: "Scheming Prince",
  SELF_APPOINTED_MENTOR: "Self-Appointed Mentor",
  SELFISH_MANIPULATOR: "Selfish Manipulator",
  SHAMELESS_FIREBRAND: "Shameless Firebrand",
  SINISTER_VISITOR: "Sinister Visitor",
  SNOW_QUEEN: "Snow Queen",
  SORCERESS: "Sorceress",
  SO_SHINY: "So Shiny!",
  SPECTACULAR_SINGER: "Spectacular Singer",
  SPIRIT_OF_WINTER: "Spirit of Winter",
  STEAMBOAT_PILOT: "Steamboat Pilot",
  STORYTELLER: "Storyteller",
  STRANGE_BUT_SPECIAL: "Strange but Special",
  STREET_RAT: "Street Rat",
  STRUTTING_HIS_STUFF: "Strutting His Stuff",
  TEMPERAMENTAL_EMPEROR: "Temperamental Emperor",
  THE_BURNING_ONE: "The Burning One",
  THE_EVER_IMPRESSIVE: "The Ever Impressive",
  THE_SEA_KING: "The Sea King",
  THINKING_A_HAPPY_THOUGHT: "Thinking a Happy Thought",
  THREE_HEADED_DOG: "Three-Headed Dog",
  THIRTEENTH_IN_LINE: "Thirteenth in Line",
  TINY_TACTICIAN: "Tiny Tactician",
  TRAINER_OF_HEROES: "Trainer of Heroes",
  TRUE_FRIEND: "True Friend",
  TRUE_HERO: "True Hero",
  UNINVITED: "Uninvited",
  UNRIVALED_ARCHER: "Unrivaled Archer",
  URSULAS_SPY: "Ursula's Spy",
  VOICE_OF_REASON: "Voice of Reason",
  WAYWARD_SORCERER: "Wayward Sorcerer",
  WICKED_AND_VAIN: "Wicked and Vain",
  WICKED_SORCERER: "Wicked Sorcerer",
  WICKED_STEPMOTHER: "Wicked Stepmother",
  WHOSEIT_COLLECTOR: "Whoseit Collector",
  WOLFSBANE: "Wolfsbane",
  WONDERFUL_THING: "Wonderful Thing",
  WORLD_FAMOUS_INVENTOR: "World-Famous Inventor",

  // ABILITIES set 1
  ADORING_FANS:
    "Adoring fans: Whenever you play a character with cost 2 or less, you may exert them to draw a card.",
  ALL_FOR_ONE: "All for one: Your other Musketeer characters get +1 strength.",
  ALL_FOR_ONE_AND_ONE_FOR_ALL:
    "All for one and one for all: Whenever one of your characters with Bodyguard is banished, you may draw a card.",
  AND_TWO_FOR_TEA:
    "And two for tea!: When you play this character, you may remove up to 2 damage from each of your Musketeer characters.",
  ANIMATE_BROOM_CEASELESS_WORKER:
    "<1>Animate broom:</1> You pay 1 Ink less to play Broom characters. <2/><1>Ceaseless Worker:</1> Whenever one of your Broom characters is banished in a challenge, you may return that card to your hand.",
  // Ceaseless Worker
  AYE_AYE_CAPTAIN:
    "Aye aye, captain: While you have a Captain character in play, this character gets +1 Lore.",
  A_WONDERFUL_DREAM:
    "A wonderful dream: Exert - Remove up to 3 damage from chosen Princess character.",
  BATTLE_PLANS:
    "Battle plans: Exert - Draw a card, then choose and discard a card.",
  BIRTHDAY_LIGHTS:
    "Birthday lights: Exert - You pay 1 ink less for the next character you play this turn.",
  BREAK_ABILITIES:
    "Break: When you play this character, you may banish chosen item card.",
  CAMOUFLAGE:
    "Camouflage: While you have another character in play, this character gains Evasive. (Only characters with Evasive can challenge them.)",
  CARETAKER:
    "Caretaker: When you play this character and whenever she quests, you may remove up to 2 damage from each of your characters.",
  CAST_MY_SPELL:
    "Cast my spell!: When you play this character, you may draw a card.",
  CLANG:
    "Clang: Banish this item - Chosen character can't challenge during their next turn.",
  COMMAND:
    "Command: Exert - Chosen character gains Support this turn. (Whenever they quest, you may add their strength to another chosen character's Strength this turn.)",
  CONSIDER_THE_COCONUT:
    "Consider the coconut: Whenever you play a character, you may remove up to 2 damage from chosen character.",
  DARING_EXPLOIT:
    "Daring exploit: During your turn, whenever this character banishes another character in a challenge, you gain 2 lore and each opponent loses 2 lore.",
  DEEP_FREEZE:
    "Deep freeze: When you play this character, exert up to 2 chosen characters. They can't ready at the start of their next turn.",
  DEXTEROUS_LUNGE: "Dexterous lunge: Your characters named Jetsam gain Rush.",
  DISAPPEAR:
    "Disappear: When you play this character, you may return chosen character to their player's hand.",
  DISTURBING_BEAUTY:
    "Disturbing beauty: When you play this character, chosen character gets -2 strength for the rest of the turn.",
  DO_IT_AGAIN_ABILITIES:
    "Do it again: When you play this character, you may return an action card from your discard to your hand",
  // DO_IT_AGAIN
  DOUBLE_THE_POWDER:
    "Double the powder!: When you play this character, you may return an action card named Fire the Cannons! from your discard to your hand.",
  DRAGON_FIRE_ABILITIES:
    "Dragon fire: When you play this character, you may banish chosen character.",
  DREAMS_MADE_REAL:
    "Dreams made real: Whenever you play a song, you may look at the top 2 cards of your deck. Put one on the top of your deck and the other on the bottom.",
  DURABLE:
    "Durable: When this character is banished in a challenge, you may return this card to your hand.",
  FAN_THE_FLAMES_ABILITIES:
    "Fan the flames: When you play this character, ready chosen character. They can't quest for the rest of this turn.",
  // FAN_THE_FLAMES
  FEED_THE_POOR_GOOD_SHOT:
    "Feed the poor: When you play this character, if an opponent has more cards in their hand than you, draw a card. Good shot: During your turn, this character gains Evasive. (They can challenge characters with Evasive.)",
  // GOOD_SHOT: "Good shot",
  FINAL_ENCHANTMENT:
    "Final enchantment: Banish this item - Banish chosen Villain character.",
  FIREPROOF:
    "Fireproof: Exert, pay 3 inks - Ready chosen character. They can't quest for the rest of this turn.",
  FREEZE_ABILITIES: "Freeze: Exert - Exert chosen opposing character.",
  GET_A_CLUE:
    "Get a clue: When you play this character, you may put the top card of your deck into your inkwell facedown and exerted.",
  // GIVE_IT_A_TRY: "Get it a try",
  GIVE_IT_A_TRY_IT_WORKS:
    "<1>Get it a try:</1> Whenever this character quests, you pay 2 ink less for the next item you play this turn. <2/><1>It works!:</1> Whenever you play an item, you may draw a card.",
  // IT_WORKS: "It works!",
  GLEAM_AND_GLOW:
    "Gleam and glow: When you play this character, remove up to 3 damage from one of your characters. Draw a card for each 1 damage removed this way.",
  GO_AHEAD_AND_SIGN:
    "Go ahead and sign: Exert - Put any card from your hand into your inkwell facedown.",
  GUESS_WHAT:
    "Guess what?: When you play this character, you may draw a card, then choose and discard a card.",
  HEALING_POLLEN:
    "Healing pollen: Banish this item - Remove up to 3 damage from chosen character.",
  HERE_COMES_THE_SMOLDER:
    "Here comes the smolder: Whenever this character is challenged, the challenging player chooses and discards a card.",
  HEROISM:
    "Heroism: When this character challenges and is banished, you may banish the challenged character.",
  HIDDEN_WONDERS:
    "Hidden wonders: This character gets +1 strength for each card in your hand.",
  HORSE_KICK:
    "Horse kick: When you play this character, chosen character gets -2 strength this turn.",
  I_KNOW_WHAT_I_HAVE_TO_DO:
    "I know what I have to do: During your turn, whenever this character banishes another character in a challenge, you gain 1 lore.",
  IM_LATE:
    "I'm late!: Exert, pay 1 ink - Chosen character gains Rush this turn. (They can challenge the turn they're played.)",
  IMPROVISE:
    "Improvise: When you play this character each opponent loses 1 lore.",
  INSIDIOUS_PLOT:
    "Insidious plot: When you play this character, chosen opposing character gets -5 strength this turn.",
  INTO_THE_SHADOWS:
    "Into the shadows: Whenever one of your other characters is banished in a challenge, you may return that card to your hand.",
  IS_THERE_A_DOWNSIDE_TO_THIS:
    "Is there a downside to this?: When you play this character, you may put chosen opposing character into their player's inkwell facedown.",
  I_SUMMON_THEE: "I summon thee: Exert - Draw a card.",
  ITS_TOO_EASY:
    "It's too easy!: When you play this character, each opponent loses 1 lore. You may draw a card for each 1 lore lost this way.",
  I_WILL_BE_WITH_YOU:
    "I will be with you: When this character is banished, you may put this card into your inkwell facedown and exerted.",
  LEAD_BY_EXAMPLE:
    "Lead by example: During your turn, whenever this character banishes another character in a challenge, your other characters get +1 lore this turn.",
  LOOK_AT_THIS_STUFF:
    "Look at this stuff: Whenever you play an item, you may ready this character.",
  LOSE_SOMETHING:
    "Lose something?: When this character is challenged and banished, banish the challenging character.",
  LOVING_HEART:
    "Loving heart: When you play this character, if you have a character named Elsa in play, choose an opposing character. The chosen character doesn't ready at the start of their next turn.",
  LOYAL:
    "Loyal: If you have a character named Gaston in play, you pay 1 ink less to play this character.",
  LOYAL_AND_DEVOTED:
    "Loyal and devoted: Your characters named Peter Pan gain Challenger +1. (They get +1 strength while challenging.)",
  MISDIRECTION:
    "Misdirection: Whenever you play an action, chosen opposing character can't quest during their next turn.",
  MUSICAL_DEBUT:
    "Musical debut: When you play this character, look at the top 4 cards of your deck. You may reveal a song card and put it into your hand. Put the rest on the bottom of your deck in any order.",
  NO_TOUCHY:
    "No touchy!: When this character is challenged and banished, you may banish the challenging character.",
  NOW_SING:
    "Now, sing!: Whenever you play a song, you may pay 1 ink to draw a card.",
  OHANA:
    "Ohana: When you play this character, if you have 2 or more other characters in play, you may draw 2 cards.",
  QUICK_SHOT:
    "Quick shot: Exert, pay 2 inks - Deal 1 damage to chosen character.",
  PEER_INTO_THE_DEPTHS:
    "Peer into the depths: Exert - Look at the top 2 cards of your deck. Put one on the top of your deck and the other on the bottom.",
  PHENOMENAL_COSMIC_POWER:
    "Phenomenal cosmic power!: Whenever this character quests, you may play an action with cost 5 or less for free.",
  PICK_YOUR_FIGHTS:
    "Pick your fights: When you play this character and whenever he quests, chosen opposing character gains Reckless during their next turn. (They can't quest and must challenge if able.)",
  PIXIE_DUST:
    "Pixie dust: When you play this character, chosen character gains Evasive this turn.",
  POUNCE:
    "Pounce: During your turn, this character gains Evasive. (They can challenge characters with Evasive.)",
  PROTECTIVE_EMBRACE:
    "Protective embrace: Your other characters gain Ward. (Opponents can't choose them except to challenge.)",
  PUPPYNAPPING:
    "Puppynapping: Whenever this character quests, chosen opposing character can't quest during their next turn.",
  READ_A_BOOK_MY_FAVOURITE_PART:
    "<1>Read a book:</1> During your turn, you may put an additional card from your hand into your inkwell facedown. <2/><1>My favourite part!:</1> While you have 10 or more cards in your inkwell, this character gets +4 lore.",
  // MY_FAVOURITE_PART: "My favourite part!",
  ROAR: "Roar: When you play this character, exert all opposing damaged characters.",
  ROCK_THE_BOAT_PUNY_PIRATE:
    "<1>Rock the boat:</1> When you play this character, deal 1 damage to each opposing character. <2/><1>Puny Pirate:</1> During your turn, whenever this character banishes another character in a challenge, you may deal 2 damage to chosen opposing character.",
  // PUNY_PIRATE: "Puny Pirate",
  ROUSING_SPEECH:
    "Rousing speech: When you play this character, ready your characters with cost 3 or less. They can't quest for the rest of this turn.",
  SEE_THE_FUTURE:
    "See the future: Exert - Chosen character gets +1 lore this turn.",
  SEEK_THE_HEART:
    "Seek the heart: During your turn, whenever this character banishes another character in a challenge, you gain 2 lore.",
  SHOW_ME:
    "Show me: Exert, pay 3 inks - If you have no cards in your hand, draw a card.",
  SINISTER_PLOT:
    "Sinister plot: This character gets +1 Lore for every other Villain character you have in play.",
  SINISTER_SLITHER:
    "Sinister slither: Your characters named Flotsam gain Evasive.",
  SKIP_THE_DRAMA_STAY_WITH_MAMA:
    "Skip the drama, stay with mama: While this character is exerted, opposing character can't quest.",
  SLASH:
    "Slash: Exert - Chosen character get +1 strength this turn. If a character named Aladdin is chosen, he gets +2 strength instead.",
  SPEAK: "Speak!: Exert, pay 4 inks - Draw a card.",
  STAGE_LITTLE_ACCIDENT:
    "Stage little accident: Whenever this character quests, you may deal 1 damage to chosen character.",
  STAY_ALERT:
    "Stay alert!: During your turn, your Musketeer characters gain Evasive. (They can challenge characters with Evasive.)",
  STOLEN_DUST:
    "Stolen dust: Characters with cost 3 or less can't challenge this character.",
  STRAIGHTEN_HAIR:
    "Straighten hair: Exert - Remove up to 1 damage from chosen character.",
  SWEEP:
    "Sweep: When you play this character, you may shuffle a card from any discard into its player's deck.",
  TANGLE_ABILITIES:
    "Tangle: When you play this character, each opponent loses 1 lore",
  TAKE_A_BITE:
    "Take a bite...: Pay 1 ink - Banish this item - Exert chosen character. If a Princess character is chosen, banish her instead.",
  TASTES_LIKE_CHICKEN:
    "Tastes like chicken: When you play this character, you may remove up to 1 damage from chosen character.",
  TEA_PARTY:
    "Tea party: Whenever this character is challenged, you may draw a card.",
  THATS_NO_BLIZZARD:
    "That's no blizzard: Whenever you play a character named Anna, ready this character. This character can't quest for the rest of this turn.",
  THE_CARDS_WILL_TELL:
    "The cards will tell: Exert - You pay 1 ink less for the next action you play this turn.",
  TINKER:
    "Tinker: Whenever this character quests, you pay 1 ink less for the next item you play this turn.",
  THIS_IS_NOT_WHO_YOU_ARE:
    "This is not who you are: When you play this character, you may banish chosen character named Te Ka.",
  UNDER_ARREST:
    "Under arrest: Characters with cost 2 or less can't challenge your characters.",
  VOICELESS: "Voiceless: This character can't exert to sing songs.",
  WE_CAN_FIX_IT:
    "We can fix it: Whenever this character quests, you may ready your other Princess characters. They can't quest for the rest of this turn.",
  WELL_OF_SOULS:
    "Well of souls: When you play this character, return a character card from your discard to your hand.",
  WHAT_HAVE_WE_HERE_GLAM:
    "<1>What have we here?:</1> When you play this character and whenever he quests, you may return an item card from your discard to your hand. <2/><1>Glam:</1> This character gets +1 Lore for each item you have in play",
  // GLAM: "Glam",
  WONDER_BOY:
    "Wonder boy: When you play this character, chosen character gets +2 strength this turn.",
  YOU_COWARD:
    "You coward!: While this character is exerted, opposing characters with Evasive gain Reckless. (They can't quest and must challenge if able.)",
  YOU_GOT_A_PROBLEM:
    "You got a problem: Exert - Chosen character gains Reckless during their next turn. (They can't quest and must challenge if able.)",
  YOULL_BE_SORRY:
    "You'll Be Sorry: When this character is challenged and banished, you may return chosen character to their player's hand.",
  YOURE_EXCUSED:
    "You're excused: Whenever this character quests, look at the top card of your deck. Put it on either the top or the bottom of your deck.",

  // BODY TEXT set 1    _ACTION_BODY_TEXT: "",             _ACTION_SONG_BODY_TEXT: "",
  A_WHOLE_NEW_WORLD_ACTION_SONG_BODY_TEXT:
    "(A character with cost 5 or more can exert to sing this song for free.) Each player discards their hand and draws 7 cards.",
  BEFUDDLE_ACTION_BODY_TEXT:
    "Return a character or item with cost 2 or less to their player's hand.",
  BE_PREPARED_ACTION_SONG_BODY_TEXT:
    "(A character with cost 7 or more can exert to sing this song for free.) Banish all characters.",
  BE_OUR_GUEST_ACTION_SONG_BODY_TEXT:
    "(A character with cost 2 or more can exert to sing this song for free.) Look at the top 4 cards of your deck. You may reveal a character card and put it into your hand. Put the rest on the bottom of your deck in any order.",
  BREAK_ACTION_BODY_TEXT: "Banish chosen item.",
  CONTROL_YOUR_TEMPER_ACTION_BODY_TEXT:
    "Chosen character gets -2 strength this turn.",
  CUT_TO_THE_CHASE_ACTION_BODY_TEXT:
    "Chosen character gains Rush this turn. (They can challenge the turn they're played.)",
  DEVELOP_YOUR_BRAIN_ACTION_BODY_TEXT:
    "Look at the top 2 cards of your deck. Put one into your hand and the other on the bottom of the deck.",
  DO_IT_AGAIN_ACTION_BODY_TEXT:
    "Return an action card from your discard to your hand.",
  DRAGON_FIRE_ACTION_BODY_TEXT: "Banish chosen character.",
  FAN_THE_FLAMES_ACTION_BODY_TEXT:
    "Ready chosen character. They can't quest for the rest of this turn.",
  FIRE_THE_CANNONS_ACTION_BODY_TEXT: "Deal 2 damage to chosen character.",
  FREEZE_ACTION_BODY_TEXT: "Exert chosen opposing character.",
  FRIENDS_ON_THE_OTHER_SIDE_ACTION_SONG_BODY_TEXT:
    "(A character with cost 3 or more can exert to sing this song for free.) Draw 2 cards.",
  GRAB_YOUR_SWORD_ACTION_SONG_BODY_TEXT:
    "(A character with cost 5 or more can exert to sing this song for free.) Deal 2 damage to each opposing character.",
  HAKUNA_MATATA_ACTION_SONG_BODY_TEXT:
    "(A character with cost 4 or more can exert to sing this song for free.) Remove up to 3 damage from each of your characters.",
  HEALING_GLOW_ACTION_BODY_TEXT: "Remove up to 2 damage from chosen character.",
  HES_GOT_A_SWORD_ACTION_BODY_TEXT:
    "Chosen character gets +2 strength this turn.",
  IF_ITS_NOT_BAROQUE_ACTION_BODY_TEXT:
    "Return an item card from your discard to your hand.",
  JUST_IN_TIME_ACTION_BODY_TEXT:
    "You may play a character with cost 5 or less for free.",
  LET_IT_GO_ACTION_SONG_BODY_TEXT:
    "(A character with cost 5 or more can exert to sing this song for free.) Put chosen character into their player's inkwell facedown and exerted.",
  MOTHER_KNOWS_BEST_ACTION_SONG_BODY_TEXT:
    "(A character with cost 3 or more can exert to sing this song for free.) Return chosen character to their player's hand.",
  ONE_JUMP_AHEAD_ACTION_SONG_BODY_TEXT:
    "(A character with cost 2 or more can exert to sing this song for free.) Put the top card of your deck into your inkwell facedown and exerted.",
  PART_OF_YOUR_WORLD_ACTION_SONG_BODY_TEXT:
    "(A character with cost 3 or more can exert to sing this song for free.) Return a character card from your discard to your hand.",
  RANSACK_ACTION_BODY_TEXT: "Draw 2 cards, then choose and discard 2 cards.",
  REFLECTION_ACTION_SONG_BODY_TEXT:
    "(A character with cost 2 or more can exert to sing this song for free.) Look at the top 3 cards of your deck. Put them back on the top of your deck in any order.",
  SMASH_ACTION_BODY_TEXT: "Deal 3 damage to the chosen character.",
  STAMPEDE_ACTION_BODY_TEXT: "Deal 2 damage to chosen damaged character.",
  STEAL_FROM_THE_RICH_ACTION_BODY_TEXT:
    "Whenever one of your characters quests this turn, each opponent loses 1 lore.",
  SUDDEN_CHILL_ACTION_SONG_BODY_TEXT:
    "(A character with cost 2 or more can exert to sing this song for free.) Each opponent chooses and discards a card.",
  TANGLE_ACTION_BODY_TEXT: "Each opponent loses 1 lore.",
  THE_BEAST_IS_MINE_ACTION_BODY_TEXT:
    "“Chosen character gains Reckless during their next turn. (They can't quest and must challenge if able.)",
  VICIOUS_BETRAYAL_ACTION_BODY_TEXT:
    "Chosen character gets +2 strength this turn. If a Villain character is chosen, they get +3 strength instead.",
  YOU_HAVE_FORGOTTEN_ME_ACTION_BODY_TEXT:
    "Each opponent chooses and discards 2 cards.",
  WORK_TOGETHER_ACTION_BODY_TEXT:
    "Chosen character gains Support this turn. (Whenever they quest, you may add their strength to another chosen character's strength this turn.)",

  // FLAVOR set 1   “”    TEXT _FLAVOR_TEXT: "",     _ITEM_FLAVOR_TEXT: "",       _ACTION_FLAVOR_TEXT: "",     _ACTION_SONG_FLAVOR_TEXT: "",
  ABU_MISCHIEVOUS_MONKEY_FLAVOR_TEXT:
    "“Someday, Abu, things are gonna change. We'll be rich, live in a palace, and never have any problems at all.” -Aladdin",
  ALADDIN_CORNERED_SWORDSMAN_FLAVOR_TEXT:
    "“Oh ho! So the street rat found a sword and a backbone!” -Razoul",
  ALADDIN_PRINCE_ALI_FLAVOR_TEXT:
    "Fabulously wealthy. Practically untouchable. Genuinely inauthentic.",
  ALADDIN_STREET_RAT_FLAVOR_TEXT:
    "It can be hard to tell the difference between a diamond in the rough and someone who's just, well, rough.",
  ANNA_HEIR_TO_ARENDELLE_FLAVOR_TEXT: "“Two sisters, one mind.”",
  ARCHIMEDES_HIGHLY_EDUCATED_OWL_FLAVOR_TEXT:
    "“Flying is not merely some crude, mechanical process. It is a delicate art. Purely aesthetic. Poetry of motion. And the best way to learn it is to do it!”",
  ARIEL_ON_HUMAN_LEGS_FLAVOR_TEXT: "“. . .”",
  ARIEL_WHOSEIT_COLLECTOR_FLAVOR_TEXT: "“You want thingamabobs? I got twenty.”",
  AURORA_BRIAR_ROSE_FLAVOR_TEXT:
    "“There was something strange about that voice. Too beautiful to be real . . .” - Prince Phillip",
  AURORA_DREAMING_GUARDIAN_FLAVOR_TEXT:
    "As the princess slumbered, her power awoke.",
  AURORA_REGAL_PRINCESS_FLAVOR_TEXT:
    "“They say if you dream a thing more than once, it's sure to come true!”",
  A_WHOLE_NEW_WORLD_ACTION_SONG_FLAVOR_TEXT:
    "Shining, shimmering, splendid . . .",
  BEAST_HARDHEADED_FLAVOR_TEXT:
    "“She will never se me as anything... but a monster”",
  BEAST_MIRROR_FLAVOR_TEXT:
    "Ashamed of his monstrous form, the Beast concealed himself inside his castle, with a magic mirror as his only window to the outside world.",
  BEAST_WOLFSBANE_FLAVOR_TEXT: "“I'll take on all of you if I have to!”",
  BEFUDDLE_ACTION_FLAVOR_TEXT:
    "Never be afraid to have your mind boggled now and then.",
  BELLE_INVENTIVE_ENGINEER_FLAVOR_TEXT:
    "A little ingenuity and a lot of heart will take you far in this world.",
  BELLE_STRANGE_BUT_SPECIAL_FLAVOR_TEXT:
    "“Far-off places, daring sword fights, magic spells, a prince in disguise . . .”",
  BE_PREPARED_ACTION_SONG_FLAVOR_TEXT: "Out teeth and ambitions are bared!",
  BREAK_ACTION_FLAVOR_TEXT: "No one throws a tantrum like a beast.",
  CAPTAIN_COLONELS_LIEUTENANT_FLAVOR_TEXT:
    "“Barking signal. It's an alert. Report to the Colonel at once!”",
  CAPTAIN_HOOK_FORCEFUL_DUELIST_FLAVOR_TEXT:
    "He loves to make light of a foe's predicament.",
  CAPTAIN_HOOK_CAPTAIN_OF_THE_JOLLY_ROGER_FLAVOR_TEXT:
    "“A pretty sight, Mr. Smee. We'll pot 'em like sitting ducks.”",
  CAPTAIN_HOOK_RUTHLESS_PIRATE_FLAVOR_TEXT:
    "“You wouldn't dare fight old Hook man-to-man!”",
  CERBERUS_THREE_HEADED_DOG_FLAVOR_TEXT:
    "Always vigilant. Always fierce. Always fighting over the ball.",
  CHESHIRE_CAT_NOT_ALL_THERE_FLAVOR_TEXT:
    "“You may have noticed that I'm not all there myself.”",
  CHIEF_TUI_RESPECTED_LEADER_FLAVOR_TEXT:
    "“You can always rely on the strength of those who love you.”",
  CONSIDER_THE_COCONUT_ITEM_FLAVOR_TEXT:
    "The coconut is a versatile gift from the gods, used to make nearly everything - including baskets to carry more coconuts.",
  CRUELLA_DE_VIL_MISERABLE_AS_USUAL_FLAVOR_TEXT:
    "When she stops by, misery is company.",
  CUT_TO_THE_CHASE_ACTION_FLAVOR_TEXT: "“Surprise!”",
  DEVELOP_YOUR_BRAIN_ACTION_FLAVOR_TEXT:
    "“Knowledge, wisdom-there's the real power!” -Merlin",
  DINGLEHOPPER_ITEM_FLAVOR_TEXT: "Enjoy the finest of human hairstyles.",
  DO_IT_AGAIN_ACTION_FLAVOR_TEXT:
    "“. . . Then scrub the terrace, sweep the halls and the stairs, clean the chimneys. And of course there's the mending, and the sewing, and the laundry . . .” -Lady Tremaine",
  DONALD_DUCK_BOISTEROUS_FOWL_FLAVOR_TEXT:
    "“Who you callin' boisterous, buster?”",
  DONALD_DUCK_STRUTTING_HIS_STUFF_FLAVOR_TEXT: "Walk smarter, not harder.",
  DRAGON_FIRE_ACTION_FLAVOR_TEXT:
    "Rare is the hero who can withstand a dragon's wrath.",
  DR_FACILIERS_CARDS_ITEM_FLAVOR_TEXT:
    "“Take a little trip into your future with me!” -Dr. Facilier",
  DR_FACILIER_CHARLATAN_FLAVOR_TEXT:
    "“Enchantée. A tip of the hat from Dr. Facilier.”",
  DUKE_OF_WESELTON_PPORTUNISTIC_OFFICIAL_FLAVOR_TEXT:
    "“Sorcery! I knew there was something dubious going on here.”",
  ELSA_ICE_SURFER_FLAVOR_TEXT:
    "My sister has always been there for me. I need to be there for her.”",
  ELSA_QUEEN_REGENT_FLAVOR_TEXT: "“I never knew what I was capable of.”",
  ELSA_SNOW_QUEEN_FLAVOR_TEXT:
    "Recreated by magical ink, Elsa found herself in an unfamiliar new world. Fortunately, ice works the same way everywhere.",
  ELSA_SPIRIT_OF_WINTER_FLAVOR_TEXT: "Ice is stronger than you may think.",
  EYE_OF_THE_FATES_ITEM_FLAVOR_TEXT:
    "You can change the future once you know what you're looking at.",
  FAN_THE_FLAMES_ACTION_FLAVOR_TEXT:
    "Pretty words can move a crowd, but so can ugly ones.",
  FIRE_THE_CANNONS_ACTION_FLAVOR_TEXT:
    "Captain Hook: “Double the powder and shorten the fuse!” Mr. Smee: “Shorten the powder and double the fuse!”",
  FISHBONE_QUILL_ITEM_FLAVOR_TEXT:
    "“If you want to cross the bridge, my sweet, you've got to pay the toll.” -Ursula",
  FLOTSAM_URSULAS_SPY_FLAVOR_TEXT:
    "“We know someone who can help you . . . for a price.”",
  FLOUNDER_VOICE_OF_REASON_FLAVOR_TEXT:
    "“Excitment... adventure... danger lurking around every cor- AAAAAGGH!”",
  FLYNN_RIDER_CHARMING_ROGUE_FLAVOR_TEXT:
    "“I didn't want to have to do this, but you leave me no choice. . . .”",
  FREEZE_ACTION_FLAVOR_TEXT: "It's time for you to chill.",
  FRIENDS_ON_THE_OTHER_SIDE_ACTION_SONG_FLAVOR_TEXT:
    "The cards, the cards the cards will tell . . .",
  FRYING_PAN_ITEM_FLAVOR_TEXT:
    "It's a fine piece of cookware, but as a weapon it's truly stunning.",
  GANTU_GALACTIC_FEDERATION_CAPTAIN_FLAVOR_TEXT:
    "“Relax, enjoy the trip... and don't get any ideas!”",
  GASTON_ARROGANT_HUNTER_FLAVOR_TEXT:
    "It's not arrogance when you really are the best.",
  GENIE_ON_THE_JOB_FLAVOR_TEXT:
    "“Can your friends go 'Abracadabra, let 'er rip' and then make the sucker disappear?”",
  GENIE_THE_EVER_IMPRESSIVE_FLAVOR_TEXT:
    "“You can wish for nearly anything! Do you want the short version, or should I give you the whole song and dance?”",
  GOOFY_DAREDEVIL_FLAVOR_TEXT:
    "Sometimes you gotta give it the ol' jump and hyuck.",
  GOOFY_MUSKETEER_FLAVOR_TEXT: "“En gawrsh!”",
  GOONS_MALEFICENTS_UNDERLINGS_FLAVOR_TEXT:
    "They may seem useless, but they came with the castle.",
  GRAB_YOUR_SWORD_ACTION_SONG_FLAVOR_TEXT:
    "We don't like what we don't understand. In fact, it scares us",
  GRAMMA_TALA_STORYTELLER_FLAVOR_TEXT:
    "Moana: “Is there something you want to tell me?” Gramma Tala: “Is there something you want to hear?”",
  HADES_INFERNAL_SCHEMER_FLAVOR_TEXT:
    "“He's gotta have a weakness, because everybody's got a weakness.”",
  HADES_KING_OF_OLYMPUS_FLAVOR_TEXT:
    "Oh hey, I'm gonna need new business cards.",
  HADES_LORD_OF_THE_UNDERWORLD_FLAVOR_TEXT:
    "“Production is up, costs are down, the rivers are full. Time to talk expansion.”",
  HAKUNA_MATATA_ACTION_SONG_FLAVOR_TEXT: "What a wonderful phrase!",
  HANS_SCHEMING_PRINCE_FLAVOR_TEXT:
    "“Rules are like older siblings. All they do is get in the way.”",
  HANS_THIRTEENTH_IN_LINE_FLAVOR_TEXT:
    "Tired of being last, he decided to cut the line.",
  HEALING_GLOW_ACTION_FLAVOR_TEXT: "“Don't freak out!” Rapunzel",
  HEIHEI_BOAT_SNACK_FLAVOR_TEXT:
    "“Sometimes, our strengths lie beneath the surface. Far beneath, in some cases. . . .” -Moana",
  HERCULES_TRUE_HERO_FLAVOR_TEXT: "“You gotta admit, that was pretty heroic.”",
  HES_GOT_A_SWORD_ACTION_FLAVOR_TEXT: "“We've all got swords!” -Razoul",
  HORACE_NO_GOOD_SCOUNDREL_FLAVOR_TEXT: "“Well, they ain't in here, Jasper.”",
  IF_ITS_NOT_BAROQUE_ACTION_FLAVOR_TEXT: "“. . . Don't fix it.”",
  JAFAR_KEEPER_OF_SECRETS_FLAVOR_TEXT:
    "There's more than one way to bury secrets.",
  JAFAR_WICKED_SORCERER_FLAVOR_TEXT:
    "“Enough skulking about. It's time to show that sniveling sultan what a sorcerer can do.”",
  JASMINE_DISGUISED_FLAVOR_TEXT:
    "“Try to understand. I've never done a thing on my own. I've never had any real friends. . . . I've never even been outside the palace walls.”",
  JASPER_COMMON_CROOK_FLAVOR_TEXT:
    "“Now, look here, Horace, I warned you about thinkin.”",
  JETSAM_URSULAS_SPY_FLAVOR_TEXT:
    "“We can help you get anything you want. . . .”",
  JOHN_SILVER_ALIEN_PIRATE_FLAVOR_TEXT:
    "“Don't be too put off by this . . . hunk of hardware.”",
  JUMBA_JOOKIBA_RENEGADE_SCIENTIST_FLAVOR_TEXT:
    "“Created something? Ha! But that would be irresponsible and unethical. I would never, ever . . . make more than one.”",
  JUST_IN_TIME_ACTION_FLAVOR_TEXT:
    "The best heroes always arrive at the perfect moment- whether they know it or not.",
  KRISTOFF_OFFICIAL_ICE_MASTER_FLAVOR_TEXT:
    "Kristoff: “You want to talk about a supply and, problem? I sell ice for a living.” Anna: “Ooh, that's a rough business to be in right now. I mean, that is really - ah, mm. That's unfortunate.”",
  KRONK_RIGHT_HAND_MAN_FLAVOR_TEXT: "“Oh yeah. It's all coming together!”",
  KUZCO_TEMPERAMENTAL_EMPEROR_FLAVOR_TEXT:
    "“I asked for emerald and that is clearly jade! What is wrong with you people?”",
  LADY_TREMAINE_WICKED_STEPMOTHER_FLAVOR_TEXT:
    "“If your chores are done, then clearly you...”",
  LANTERN_ITEM_FLAVOR_TEXT:
    "Lanterns fill the sky on one special night, beacons of hope and love.",
  LEFOU_BUMBLER_FLAVOR_TEXT: "You need a good toady to be a proper bad guy.",
  LEFOU_INSTIGATOR_FLAVOR_TEXT:
    "All a mob needs is a push in the wrong direction.",
  LET_IT_GO_ACTION_SONG_FLAVOR_TEXT:
    "It's time to see what I can do. To test the limits and break through",
  LILO_GALACTIC_HERO_FLAVOR_TEXT:
    "“Space. That's where aliens come from. And also tourists!”",
  LILO_MAKING_A_WISH_FLAVOR_TEXT:
    "“A falling star . . . I have to make a wish!”",
  MAD_HATTER_GRACIOUS_HOST_FLAVOR_TEXT:
    "Mad Hatter: “Would you like a little more tea?” Alice: “I haven't had any yet, so I can't very well take more.”",
  MAGIC_BROOM_BUCKET_BRIGADE_FLAVOR_TEXT:
    "In the immense story-forge known as the Great Illuminary, there is always work to be done.",
  MAGIC_GOLDEN_FLOWER_ITEM_FLAVOR_TEXT:
    "“Once upon a time, a single drop of sunlight fell from the heavens. . . .” -Flynn Rider",
  MAGIC_MIRROR_ITEM_FLAVOR_TEXT: "“What wouldst thou know, my Queen?”",
  MALEFICENT_BIDING_HER_TIME_FLAVOR_TEXT:
    "“One mustn't rush these things, or the greatest plan might come to nothing.“",
  MALEFICENT_MONSTROUS_DRAGON_FLAVOR_TEXT:
    "The ninth Rule of Villainy: When all else fails, turn into a dragon.",
  MALEFICENT_SINISTER_VISITOR_FLAVOR_TEXT:
    "“The princess shall indeed grow in grace and beauty, beloved by all who know her. But before the sun sets on her sixteenth birthday, she shall prick her finger on the spindle of a spinning wheel...”",
  MALEFICENT_SORCERESS_FLAVOR_TEXT:
    "“You dare challenge me? Fool, my magic is more powerful than you could possibly imagine!”",
  MALEFICENT_UNINVITED_FLAVOR_TEXT:
    "She had no invitation-and needed no introduction.",
  MARSHMALLOW_PERSISTENT_GUARDIAN_FLAVOR_TEXT:
    "“Hey! We were just talking about you! All good things, all good things.” -Olaf",
  MAUI_DEMIGOD_FLAVOR_TEXT:
    "When the gods gift you a boat, you take it. The boat's owner is optional.",
  MAUI_HERO_TO_ALL_FLAVOR_TEXT:
    "“What I believe you were trying to say is 'Thank you.”",
  MAXIMUS_RELENTLESS_PURSUER_FLAVOR_TEXT:
    "He pursues his quarry with courage, discipline, and a touch of class.",
  MEGARA_PULLING_THE_STRINGS_FLAVOR_TEXT:
    "“A deal's a deal. But falling in love was never supposed to be part of it.”",
  MICKEY_MOUSE_ARTFUL_ROGUE_FLAVOR_TEXT: "Quiet as a . . . well, you know.",
  MICKEY_MOUSE_BRAVE_LITTLE_TAILOR_FLAVOR_TEXT:
    "When defeat looms and victory hangs by a thread, a hero bolts to the rescue, patching things up through shear determination.",
  MICKEY_MOUSE_DETECTIVE_FLAVOR_TEXT:
    "Wherever the seaweed had come from, Mickey was sure of one thing: something fishy was going on.",
  MICKEY_MOUSE_STEAMBOAT_PILOT_FLAVOR_TEXT:
    "On rivers throughout the Inklands, the little steamboat's whistle answers the cheery tunes of its pilot.",
  MICKEY_MOUSE_TRUE_FRIEND_FLAVOR_TEXT:
    "As long as he's around, newcomers to the Great Illuminary will always get a warm welcome.",
  MICKEY_MOUSE_WAYWARD_SORCERER_FLAVOR_TEXT:
    "He always goes for the clean sweep.",
  MINNIE_MOUSE_ALWAYS_CLASSY_FLAVOR_TEXT:
    "Her fashion sense is always spot on.",
  MINNIE_MOUSE_BELOVED_PRINCESS_FLAVOR_TEXT:
    "Wherever the princess goes, her musketeers are. . . well, they're around somewhere, probably.",
  MOANA_CHOSEN_BY_THE_OCEAN_FLAVOR_TEXT: "“You know who you are.”",
  MOANA_OF_MOTUNUI_FLAVOR_TEXT:
    "“I am Moana of Motunui. You will board my boat, sail across the sea, and restore the heart of Te Fiti.”",
  MOTHER_GOTHEL_SELFISH_MANIPULATOR_FLAVOR_TEXT:
    "“Great. Now I'm the bad guy.”",
  MOTHER_KNOWS_BEST_ACTION_SONG_FLAVOR_TEXT:
    "One way or another Something will go wrong, I swear",
  MR_SMEE_LOYAL_FIRST_MATE_FLAVOR_TEXT:
    "“Mr. Smee ist a kind, gentle soul who lives to bring comfort and aid to a twisted old villain. Now, what good is kindness like that?” - Peter Pan",
  MUFASA_KING_OF_THE_PRIDE_LANDS_FLAVOR_TEXT:
    "“A king must care for all of the creatures in his kingdom, no matter their size.”",
  MUSKETEER_TABARD_ITEM_FLAVOR_TEXT:
    "There's no such thing as a lone musketeer.",
  OLAF_FRIENDLY_SNOWMANFLAVOR_TEXT: "“I'm Olaf and I like warm hugs!”",
  ONE_JUMP_AHEAD_ACTION_SONG_FLAVOR_TEXT:
    "Gotta eat to live, gotta steal to eat - Tell you all about it when I got the time",
  PART_OF_YOUR_WORLD_ACTION_SONG_FLAVOR_TEXT:
    "What would I give If I could live out of these waters?",
  PASCAL_RAPUNZELS_COMPANION_FLAVOR_TEXT:
    "A true friend is always there for you, whether you can see them or not.",
  PETER_PAN_FEARLESS_FIGHTER_FLAVOR_TEXT:
    "“Nobody calls Pan a coward and lives! I'll fight you man-to-man, with one hand behind my back.”",
  PETER_PAN_NEVER_LANDING_FLAVOR_TEXT:
    "“What's the matter, Hook? Can't you fly?”",
  PHILOCTETES_TRAINER_OF_HEROES_FLAVOR_TEXT:
    "“Ya gotta be the best to train the best. And I train the best!”",
  PLASMA_BLASTER_ITEM_FLAVOR_TEXT:
    "“You don't have to say 'pew pew' when you use it, but it doesn't hurt.” -Lilo, galactic hero",
  POISONED_APPLE_ITEM_FLAVOR_TEXT:
    "“One taste of the poisoned apple, and the victim's eyes will close forever. . . .” -The Queen",
  PONGO_OL_RASCAL_FLAVOR_TEXT:
    "“At first I had no particular plan, just anything to attract attention. You know, stir things up a bit.”",
  PRINCE_ERIC_DASHING_AND_BRAVE_FLAVOR_TEXT:
    "“I lost her once! I'm not gonna lose her again!”",
  PRINCE_PHILLIP_DRAGONSLAYER_FLAVOR_TEXT:
    "“The road to true love may be barred by still many more dangers, which you alone will have to face.” -Flora",
  PUMBAA_FRIENDLY_WARTHOG_FLAVOR_TEXT:
    "“You gotta put your behind in your past.”",
  RAFIKI_MISCHIEVOUS_MONKEY_FLAVOR_TEXT:
    "“The past can hurt. But the way I see it, you can either run from it or learn from it.”",
  RANSACK_ACTION_FLAVOR_TEXT: "Who has time to read labels?",
  RAPUNZEL_LETTING_DOWN_HER_HAIR_FLAVOR_TEXT:
    "“Who are you? And how did you find me?”",
  REFLECTION_ACTION_SONG_FLAVOR_TEXT:
    "When will my reflection show Who I am inside?",
  ROBIN_HOOD_UNRIVALED_ARCHER_FLAVOR_TEXT:
    "“We never rob. We just sort of borrow a bit from those who can afford it.”",
  SCAR_FIERY_USURPER_FLAVOR_TEXT: "Consumed by the flames of ambition.",
  SCAR_MASTERMIND_FLAVOR_TEXT:
    "“The best plans involve a little danger. Just not for me.”",
  SCAR_SHAMELESS_FIREBRAND_FLAVOR_TEXT:
    "“The time has come to take what is ours!“",
  SEBASTIAN_COURT_COMPOSER_FLAVOR_TEXT:
    "“I should be writing symphonies, not tagging along after some headstrong teenager.”",
  SERGEANT_TIBBS_COURAGEOUS_CAT_FLAVOR_TEXT:
    "“Yes, sir. Righto, sir. Right away, sir...”",
  SHIELD_OF_VIRTUE_ITEM_FLAVOR_TEXT:
    "“Arm thyself with this enchanted Shield of Virtue and this mighty Sword of Truth, for these weapons of righteousness will triumph over evil.” -Flora",
  SIMBA_FUTURE_KING_FLAVOR_TEXT:
    "“I'm gonna be the best king the Pride Lands have ever seen!”",
  SIMBA_PROTECTIVE_CUB_FLAVOR_TEXT: "Courage comes in all sizes.",
  SIMBA_RETURNED_KING_FLAVOR_TEXT:
    "“I'll do whatever it takes to save my kingdom.”",
  SIMBA_RIGHTFUL_HEIR_FLAVOR_TEXT:
    "“I can't hide anymore. It's time to accept my destiny.”",
  SMASH_ACTION_FLAVOR_TEXT: "“Go away!”",
  STAMPEDE_ACTION_FLAVOR_TEXT:
    "A wildebeest stampede is like a raging river: best experienced from a distance.",
  STARKEY_HOOKS_HENCHMAN_FLAVOR_TEXT:
    "A pirate must be tough, loyal, and strong. “Smart” doesn't even make the list.",
  STEAL_FROM_THE_RICH_ACTION_FLAVOR_TEXT:
    "“Wonder how much ol' Prince John spent on all those fancy locks.” -Little John",
  STITCH_ABOMINATION_FLAVOR_TEXT:
    "“His destructive programming is taking effect. He will be irresistibly drawn to large cities, where he will back up sewers, reverse street signs, and steal everyone's left shoe.” -Jumba Jookiba",
  STITCH_CAREFREE_SURFER_FLAVOR_TEXT:
    "“So you're from outer space, huh? I hear the surfing's choice.” -David",
  STITCH_NEW_DOG_FLAVOR_TEXT:
    "Lilo: “David! I got a new dog!” David: “Auwe! . . . You sure it's a dog?” Lilo: “Uh-huh. He used to be a collie before he got ran over.”",
  STITCH_ROCK_STAR_FLAVOR_TEXT:
    "The best part about a beachside concert is that there's always room for one more.",
  STOLEN_DUST_ITEM_FLAVOR_TEXT:
    "Sometimes you've got to take what you can get.",
  SUDDEN_CHILL_ACTION_SONG_FLAVOR_TEXT:
    "Cruella De Vil, Cruella De Vil If she doesn't scare you, no evil thing will",
  SVEN_OFFICIAL_ICE_DELIVERER_FLAVOR_TEXT:
    "“Reindeer comin' through!” -Kristoff",
  SWORD_OF_TRUTH_ITEM_FLAVOR_TEXT: "Almost as powerful as True Love's Kiss.",
  TAMATOA_DRAB_LITTLE_CRAB_FLAVOR_TEXT:
    "“Someday, I'll grow up to be the most crabulous crustacean the world has ever seen!”",
  TAMATOA_SO_SHINY_FLAVOR_TEXT:
    "“Watch me dazzle like a diamond in the rough!”",
  TANGLE_ACTION_FLAVOR_TEXT:
    "“Stay right here! I mean, you don't have a choice, I guess. But still! Don't move!” - Rapunzel",
  TE_KA_HEARTLESS_FLAVOR_TEXT:
    "Maui: “Ever defeat a lava monster?” Moana: “No. Have you?”",
  TE_KA_THE_BURNING_ONE_FLAVOR_TEXT:
    "She burns for that which was stolen from her.",
  THE_BEAST_IS_MINE_ACTION_FLAVOR_TEXT:
    "“It's only fitting that the finest hunter gets the foulest beast!” - Gaston",
  THE_QUEEN_WICKED_AND_VAIN_FLAVOR_TEXT:
    "Sublime beauty matched with peerless cunning. Is there any question who is fairest?",
  THE_WARDROBE_BELLES_CONFIDANT_FLAVOR_TEXT:
    "When you simply must have the hautest couture.",
  TIGGER_WONDERFUL_THING_FLAVOR_TEXT:
    "“I'm the bounciest bouncer that ever bounced!”",
  TIMON_GRUB_RUSTLER_FLAVOR_TEXT:
    "“There's all manner of tasty treats in the world-ya just gotta know where to look.”",
  TINKER_BELL_TINY_TACTICIAN_FLAVOR_TEXT:
    "Sometimes all you need is a little tactical genius.",
  TRITON_THE_SEA_KING_FLAVOR_TEXT:
    "“Isn't 'Because I said so' enough of a reason?”",
  YOU_HAVE_FORGOTTEN_ME_ACTION_FLAVOR_TEXT:
    "“You are more than what you have become.” -Mufasa",
  URSULAS_CAULDRON_ITEM_FLAVOR_TEXT:
    "Perfect for mixing potions and stealing voices.",
  URSULA_POWER_HUNGRY_FLAVOR_TEXT:
    "The first Rule of Villainy: If you're going to be evil, you've got to have style.",
  URSULAS_SHELL_NECKLACE_ITEM_FLAVOR_TEXT:
    "“Singing is a lovely pastime . . . if you've got the voice for it.” -Ursula",
  VICIOUS_BETRAYAL_ACTION_FLAVOR_TEXT:
    "“A true king takes matters into his own claws.” -Scar",
  WHITE_RABBITS_POCKET_WATCH_ITEM_FLAVOR_TEXT:
    "“No wonder you're late. Why, this clock is exactly two days slow.” -The Mad Hatter",
  WORK_TOGETHER_ACTION_FLAVOR_TEXT:
    "Pacha: “Put your whole back into it!” Kuzco: “This is my whole back!”",
  YZMA_ALCHEMIST_FLAVOR_TEXT:
    "“When I want your opinion, I will give it to you!”",
  ZEUS_GOD_OF_LIGHTNING_FLAVOR_TEXT:
    "“A little lightning solves a whole lot of problems.”",

  // KEYWORDS TEXT set 1    CHALLENGER_X, SHIFT_X, SINGER_X
  ALADDIN_HEROIC_OUTLAW_SHIFT_TEXT:
    "5 (You may pay 5 ink to play this on top of one of your characters named Aladdin.)",
  ARIEL_SPECTACULAR_SINGER_SINGER_TEXT:
    "5 (This character counts as cost 5 to sing songs.)",
  AURORA_DREAMING_GUARDIAN_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Aurora.)",
  CAPTAIN_HOOK_FORCEFUL_DUELIST_CHALLENGER_TEXT:
    "+2 (While challenging, this character get +2 strength.)",
  CAPTAIN_HOOK_THINKING_A_HAPPY_THOUGHT_CHALLENGER_TEXT:
    "+3 (While challenging, this character gets +3 strength.)",
  CAPTAIN_HOOK_THINKING_A_HAPPY_THOUGHT_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Captain Hook.)",
  CINDERELLA_GENTLE_AND_KIND_SINGER_TEXT:
    "5 (This character counts as cost 5 to sing songs.)",
  DR_FACILIER_AGENT_PROVOCATEUR_SHIFT_TEXT:
    "5 (You may pay 5 ink to play this on top of one of your characters named Dr. Facilier.)",
  DR_FACILIER_CHARLATAN_CHALLENGER_TEXT:
    "+2 (When challenging, this character get +2 strength.)",
  ELSA_GLOVES_OFF_CHALLENGER_TEXT:
    "+3 (While challenging, this character gets +3 strength)",
  ELSA_SPIRIT_OF_WINTER_SHIFT_TEXT:
    "6 (You may pay 6 ink to play this on top of one of your characters named Elsa.)",
  GENIE_POWER_UNLEASHED_SHIFT_TEXT:
    "6 (You may pay 6 ink to play this on top of one of your characters named Genie.)",
  HADES_KING_OF_OLYMPUS_SHIFT_TEXT:
    "6 (You may pay 6 ink to play this on top of one of your characters named Hades.)",
  JAFAR_WICKED_SORCERER_CHALLENGER_TEXT:
    "+3 (When challenging, this character get +3 strength.)",
  MICKEY_MOUSE_ARTFUL_ROGUE_SHIFT_TEXT:
    "Shift 5 (You may pay 5 inks to play this on top of one of your characters named Mickey Mouse.)",
  PRINCE_ERIC_DASHING_AND_BRAVE_CHALLENGER_TEXT:
    "+2 (While challenging, this character gets +2 strength.)",
  SCAR_SHAMELESS_FIREBRAND_SHIFT_TEXT:
    "6 (You may pay 6 ink to play this on top of one of your characters named Scar.)",
  SEBASTIAN_COURT_COMPOSER_SINGER_TEXT:
    "4 (This character counts as cost 4 to sing songs.)",
  SIMBA_RETURNED_KING_CHALLENGER_TEXT:
    "+4 (While challenging, this character gets +4 strength.)",
  STITCH_ROCK_STAR_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Stitch.)",
  TINKER_BELL_GIANT_FAIRY_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Tinker Bell.)",
  ZEUS_GOD_OF_LIGHTNING_CHALLENGER_TEXT:
    "+4 (When challenging, this character get +4 strength.)",

  // SET 2
  // ITEM CARD set 2
  BINDING_CONTRACT: "Binding Contract",
  CROQUET_MALLET: "Croquet Mallet",
  DINNER_BELL: "Dinner Bell",
  DRAGON_GEM: "Dragon Gem",
  PERPLEXING_SIGNPOSTS: "Perplexing Signposts",
  PETER_PANS_DAGGER: "Peter Pan's Dagger",
  RATIGANS_MARVELOUS_TRAP: "Ratigan's Marvelous Trap",
  SLEEPYS_FLUTE: "Sleepy's Flute",
  SWORD_IN_THE_STONE: "Sword In The Stone",
  THE_SORCERERS_SPELLBOOK: "The Sorcerer's Spellbook",
  FANG_CROSSBOW: "Fang Crossbow",
  GUMBO_POT: "Gumbo Pot",
  MAURICES_WORKSHOP: "Maurice's Workshop",
  PAWPSCICLE: "Pawpsicle",
  SARDINE_CAN: "Sardine Can",
  LAST_CANNON: "Last Cannon",
  MOUSE_ARMOR: "Mouse Armor",
  WEIGHT_SET: "Weight Set",

  // ACTION CARD set 2
  BOUNCE: "Bounce",
  FALLING_DOWN_THE_RABBIT_HOLE: "Falling Down the Rabbit Hole",
  LAST_STAND: "Last Stand",
  LAUNCH: "Launch",
  HOLD_STILL: "Hold Still",
  HYPNOTIZE: "Hypnotize",
  IM_STUCK: "I'm Stuck!",
  IMPROVISE: "Improvise",
  NOTHING_TO_HIDE: "Nothing to Hide",
  PACK_TACTICS: "Pack Tactics",
  RING_THE_BELL: "Ring The Bell",
  WHAT_DID_YOU_CALL_ME: "What did you call me?",
  CHARGE: "Charge",
  PICK_A_FIGHT: "Pick a Fight",

  // ACTION SONG CARD set 2
  BIBBIDI_BOBBIDI_BOO: "Bibbidi Bobbidi Boo",
  FOUR_DOZEN_EGGS: "Four Dozen Eggs",
  GO_THE_DISTANCE: "Go the Distance",
  GRUESOME_AND_GRIM: "Gruesome And Grim",
  LEGEND_OF_THE_SWORD_IN_THE_STONE: "Legend of the Sword in the Stone",
  TEETH_AND_AMBITIONS: "Teeth and Ambitions",
  THE_MOST_DIABOLICAL_SCHEME: "The Most Diabolical Scheme",
  PAINTING_THE_ROSES_RED: "Painting the Roses Red",
  WORLDS_GREATEST_CRIMINAL_MIND: "World's Greatest Criminal Mind",
  YOU_CAN_FLY: "You Can Fly",
  ZERO_TO_HERO: "Zero To Hero",
  LET_THE_STORM_RAGE_ON: "Let the Storm Rage On",
  STRENGTH_OF_A_RAGING_FIRE: "Strength of a Raging Fire",

  // VERSION NAME set 2
  S2_001_VERSION_NAME: "Hopeless Romantic",
  S2_002_VERSION_NAME: "Adventurer",
  S2_003_VERSION_NAME: "Ballroom Sensation",
  S2_004_VERSION_NAME: "Just a Social Worker",
  S2_005_VERSION_NAME: "Leader of the Seven Dwarfs",
  S2_006_VERSION_NAME: "Always Playful",
  S2_007_VERSION_NAME: "Accomplished Seamstress",
  S2_008_VERSION_NAME: "Baritone Bully",
  S2_009_VERSION_NAME: "Advisor to the King",
  S2_010_VERSION_NAME: "Bad-Tempered",
  S2_011_VERSION_NAME: "Good-Natured",
  S2_012_VERSION_NAME: "Jungle VIP",
  S2_013_VERSION_NAME: "Friendly Face",
  S2_014_VERSION_NAME: "Betrayed Leader",
  S2_015_VERSION_NAME: "Free Spirit",
  S2_016_VERSION_NAME: "Reflecting",
  S2_017_VERSION_NAME: "Darling Family Pet",
  S2_018_VERSION_NAME: "Very Small Animal",
  S2_019_VERSION_NAME: "Gifted Artist",
  S2_020_VERSION_NAME: "Sunshine",
  S2_021_VERSION_NAME: "Nodding Off",
  S2_022_VERSION_NAME: "Very Allergic",
  S2_023_VERSION_NAME: "Lost in the Forest",
  S2_024_VERSION_NAME: "Unexpected Houseguest",
  S2_025_VERSION_NAME: "Well Wisher",
  S2_026_VERSION_NAME: "Commanding Presence",
  S2_027_VERSION_NAME: "Regal Monarch",
  S2_035_VERSION_NAME: "Wizard's Apprentice",
  S2_036_VERSION_NAME: "Rewarding Good Deeds",
  S2_037_VERSION_NAME: "Gentle Soul",
  S2_038_VERSION_NAME: "Savvy Opportunist",
  S2_039_VERSION_NAME: "Gloves Off",
  S2_040_VERSION_NAME: "Here to Help",
  S2_041_VERSION_NAME: "Mystic Armorer",
  S2_042_VERSION_NAME: "Pure Heart",
  S2_043_VERSION_NAME: "Persistent Presence",
  S2_044_VERSION_NAME: "Pinocchio's Conscience",
  S2_045_VERSION_NAME: "Wanted Llama",
  S2_046_VERSION_NAME: "Fox",
  S2_047_VERSION_NAME: "Purple Dragon",
  S2_048_VERSION_NAME: "Rival of Merlin",
  S2_049_VERSION_NAME: "Snake",
  S2_050_VERSION_NAME: "Crab",
  S2_051_VERSION_NAME: "Goat",
  S2_052_VERSION_NAME: "Rabbit",
  S2_053_VERSION_NAME: "Shapeshifter",
  S2_054_VERSION_NAME: "Squirrel",
  S2_055_VERSION_NAME: "Not Sewn On",
  S2_056_VERSION_NAME: "Star Attraction",
  S2_057_VERSION_NAME: "On the Run",
  S2_058_VERSION_NAME: "Talkative Puppet",
  S2_059_VERSION_NAME: "Hunny Wizard",
  S2_060_VERSION_NAME: "Scary Beyond All Reason",
  S2_061_VERSION_NAME: "Without Beauty Sleep",
  S2_069_VERSION_NAME: "Trained Swordsman",
  S2_070_VERSION_NAME: "Relentless",
  S2_071_VERSION_NAME: "Bookworm",
  S2_072_VERSION_NAME: "Hidden Archer",
  S2_073_VERSION_NAME: "Squirrel Squeak Tutor",
  S2_074_VERSION_NAME: "Always Grinning",
  S2_075_VERSION_NAME: "From the Shadows",
  S2_076_VERSION_NAME: "Secret Agent",
  S2_077_VERSION_NAME: "Perfect Gentleman",
  S2_078_VERSION_NAME: "Sleepwalker",
  S2_079_VERSION_NAME: "Fortune Teller",
  S2_080_VERSION_NAME: "Unexpected Judge",
  S2_081_VERSION_NAME: "Confident Vagabond",
  S2_082_VERSION_NAME: "His Own Biggest Fan",
  S2_083_VERSION_NAME: "Scheming Suitor",
  S2_084_VERSION_NAME: "Loyal Friend",
  S2_085_VERSION_NAME: "Cunning Cat",
  S2_086_VERSION_NAME: "Underworld Imp",
  S2_087_VERSION_NAME: "Underworld Imp",
  S2_088_VERSION_NAME: "Bad Guy",
  S2_089_VERSION_NAME: "Greediest of All",
  S2_090_VERSION_NAME: "Quick-Tempered",
  S2_091_VERSION_NAME: "Criminal Mastermind",
  S2_092_VERSION_NAME: "Easygoing Firefly",
  S2_093_VERSION_NAME: "Disguised Peddler",
  S2_094_VERSION_NAME: "True Princess",
  S2_095_VERSION_NAME: "Fang Chief",
  S2_103_VERSION_NAME: "Fun-Loving Bear",
  S2_104_VERSION_NAME: "Precocious Entrepreneur",
  S2_105_VERSION_NAME: "Full Deck",
  S2_106_VERSION_NAME: "Not Again!",
  S2_107_VERSION_NAME: "Always Hungry",
  S2_108_VERSION_NAME: "Ratigan's Henchman",
  S2_109_VERSION_NAME: "Not That Honest",
  S2_110_VERSION_NAME: "Imperious Queen",
  S2_111_VERSION_NAME: "Overbearing Matriarch",
  S2_112_VERSION_NAME: "Hotheaded Candelabra",
  S2_113_VERSION_NAME: "Stylish Surfer",
  S2_114_VERSION_NAME: "Wide-Eyed Diver",
  S2_115_VERSION_NAME: "Zipping Around",
  S2_116_VERSION_NAME: "Withered and Wicked",
  S2_117_VERSION_NAME: "Soldier in Training",
  S2_118_VERSION_NAME: "Nemesis",
  S2_119_VERSION_NAME: "Impulsive Ruler",
  S2_120_VERSION_NAME: "Sensing Weakness",
  S2_121_VERSION_NAME: "Very Large Mouse",
  S2_122_VERSION_NAME: "Headstrong",
  S2_123_VERSION_NAME: "Leader of Heart",
  S2_124_VERSION_NAME: "Warrior of Kumandra",
  S2_125_VERSION_NAME: "Vicious Cheater",
  S2_126_VERSION_NAME: "Menacing Predator",
  S2_127_VERSION_NAME: "One of a Kind",
  S2_128_VERSION_NAME: "Wrecking Ball",
  S2_137_VERSION_NAME: "Growing Girl",
  S2_138_VERSION_NAME: "Great Mouse Detective",
  S2_139_VERSION_NAME: "Of Baker Street",
  S2_140_VERSION_NAME: "Perceptive Investigator",
  S2_141_VERSION_NAME: "Calm and Collected",
  S2_142_VERSION_NAME: "Grandfather Clock",
  S2_143_VERSION_NAME: "Talking Clock",
  S2_144_VERSION_NAME: "Fashionable Cruiser",
  S2_145_VERSION_NAME: "Perfectly Wretched",
  S2_146_VERSION_NAME: "Small-Time Crook",
  S2_147_VERSION_NAME: "Intellectual Powerhouse",
  S2_148_VERSION_NAME: "Oldest and Wisest",
  S2_149_VERSION_NAME: "Toymaker",
  S2_150_VERSION_NAME: "Role Model",
  S2_151_VERSION_NAME: "Heir of Agrabah",
  S2_152_VERSION_NAME: "Optimistic Officer",
  S2_153_VERSION_NAME: "Housekeeper",
  S2_154_VERSION_NAME: "Wily Fox",
  S2_155_VERSION_NAME: "Orphaned Thief",
  S2_156_VERSION_NAME: "Logical Lecturer",
  S2_157_VERSION_NAME: "Heir to the Throne",
  S2_158_VERSION_NAME: "Reluctant Host",
  S2_159_VERSION_NAME: "Divine Water Dragon",
  S2_160_VERSION_NAME: "Water Spirit",
  S2_161_VERSION_NAME: "Having a Think",
  S2_171_VERSION_NAME: "Forbidding Recluse",
  S2_172_VERSION_NAME: "Selfless Protector",
  S2_173_VERSION_NAME: "Tragic Hero",
  S2_174_VERSION_NAME: "Guardian of the Dragon Gem",
  S2_175_VERSION_NAME: "Respected Officer",
  S2_176_VERSION_NAME: "Knight in Training",
  S2_177_VERSION_NAME: "Stouthearted",
  S2_178_VERSION_NAME: "Deep-Sea Diver",
  S2_179_VERSION_NAME: "Big Daddy",
  S2_180_VERSION_NAME: "Knight for a Day",
  S2_181_VERSION_NAME: "Divine Hero",
  S2_182_VERSION_NAME: "Hero in Training",
  S2_183_VERSION_NAME: "Dreadnought",
  S2_184_VERSION_NAME: "Royal Vizier",
  S2_185_VERSION_NAME: "Junior Chipmunk",
  S2_186_VERSION_NAME: "Jealous Manservant",
  S2_187_VERSION_NAME: "Archery Instructor",
  S2_188_VERSION_NAME: "Industrial Model",
  S2_189_VERSION_NAME: "Morning Mist",
  S2_190_VERSION_NAME: "Village Leader",
  S2_191_VERSION_NAME: "Penniless Royal",
  S2_192_VERSION_NAME: "Capricious Monarch",
  S2_193_VERSION_NAME: "Capable Fighter",
  S2_194_VERSION_NAME: "Reluctant Enforcer",
  S2_195_VERSION_NAME: "Never Gives Up",
  S2_196_VERSION_NAME: "Celebrating Princess",
  S2_197_VERSION_NAME: "Diligent Waitress",

  // ABILITIES set 2
  S2_001_ABILITY:
    "OH, GOSH: This character can't quest unless you have another Seven Dwarfs character in play.",
  S2_002_ABILITY:
    "WE'LL ALWAYS BE TOGETHER: Whenever you ready this character, if you have 2 or more other characters in play, gain 2 lore.",
  S2_005_ABILITY:
    "SHARE AND SHARE ALIKE: Whenever this character quests, you pay 1 ink less for the next character you play this turn.",
  S2_006_ABILITY:
    "ODD ONE OUT: When this character is banished, your other Seven Dwarfs characters get +2 strength until the start of your next turn.",
  S2_009_ABILITY:
    "YES, YOUR MAJESTY: Your Prince, Princess, King and Queen characters gain +1 strength.",
  S2_010_ABILITY:
    "THERE'S TROUBLE A-BREWIN': Your other Seven Dwarfs characters get +1 strength.",
  S2_012_ABILITY:
    "LAY IT ON THE LINE: Whenever another character is banished, you may remove up to 2 damage from this character.",
  S2_013_ABILITY:
    "GLAD YOU’RE HERE!: Whenever this character quests, you pay 3 inks less for the next character you play this turn.",
  S2_014_ABILITY:
    "THE SUN WILL SET: When this character is banished, you may reveal the top card of your deck. If it's a character card, you may play that character for free and they enter play exerted. Otherwise, put it on the top of your deck.",
  S2_016_ABILITY:
    "HONOR TO THE ANCESTORS: Whenever this character quests, you may reveal the top card of your deck. If it's a song card, you may play it for free. Otherwise, put it on the top of your deck.",
  S2_017_ABILITY:
    "NURSEMAID: Whenever you play a Floodborn character, you may remove all damage from chosen character.",
  S2_019_ABILITY:
    "LET YOUR POWER SHINE: Whenever you remove 1 or more damage from one of your characters, you may draw a card.",
  S2_020_ABILITY:
    "MAGIC HAIR: Exert -  Remove up to 2 damage from chosen character.",
  S2_021_ABILITY: "YAWN!: This character enters play exerted.",
  S2_022_ABILITY:
    "AH-CHOO!: Whenever you play this character or another Seven Dwarfs character, you may give chosen character -1 strength this turn.",
  S2_023_ABILITY:
    "I WON'T HURT YOU: When you play this character, you may remove up to 2 damage from chosen character.",
  S2_024_ABILITY:
    "HOW DO YOU DO?: You pay 1 ink less to play Seven Dwarfs characters.",
  S2_025_ABILITY:
    "WISHES COME TRUE: Whenever this character quests, you may return a character card from your discard to your hand.",
  S2_026_ABILITY:
    "WHO IS THE FAIREST?: Whenever this character quests, chosen opposing character gets -4 strength this turn and chosen character gets +4 strength this turn.",
  S2_033_ABILITY:
    "BRING BACK TO LIFE: Exert, pay 3 inks -  Return a character card with Support from your discard to your hand.",
  S2_034_ABILITY:
    "A SILLY SONG: Exert - If you played a song this turn, gain 1 lore.",
  S2_035_ABILITY:
    "STUDENT: Whenever this character quests, you may return another chosen character of yours to your hand to gain 2 lore.",
  S2_036_ABILITY:
    "ETHEREAL GLOW: Whenever you play a Floodborn character, you may draw a card.",
  S2_041_ABILITY:
    "FORGET THE COACH, HERE'S A SWORD: Whenever this character quests, your characters gain Challenger +3 and “When this character is banished in a challenge, return this card to your hand” this turn. (They get +3 strength while challenging.)",
  S2_042_ABILITY:
    "JUST LEAVE IT TO ME: Whenever you play a character named Cinderella, you may exert chosen character.",
  S2_043_ABILITY:
    "HE'S BACK!: When this character is banished in a challenge, return this card to your hand.",
  S2_044_ABILITY:
    "THAT STILL, SMALL VOICE: When you play this character, if you have a character named Pinocchio in play, you may draw a card.",
  S2_045_ABILITY:
    "OK, WHERE AM I?: When this character is banished, you may draw a card.",
  S2_046_ABILITY:
    "CHASING THE RABBIT: When you play this character, banish her or return another chosen character of yours to your hand.",
  S2_047_ABILITY:
    "I WIN, I WIN!: When you play this character, banish her or return another 2 chosen characters of yours to your hand.",
  S2_048_ABILITY:
    "GRUESOME AND GRIM: Exert - Play a character with cost 4 or less for free. They gain Rush. At the end of the turn, banish them. They can challenge the turn they're played.",
  S2_049_ABILITY:
    "JUST YOU WAIT: When you play this character, banish her or return another chosen character of yours to your hand.",
  S2_050_ABILITY:
    "READY OR NOT!: When you play this character and when he leaves play, chosen character gains Challenger +3 this turn. (They get +3 strength while challenging.)",
  S2_051_ABILITY:
    "HERE I COME!: When you play this character and when he leaves play, gain 1 lore.",
  S2_052_ABILITY:
    "HOPPITY HIP!: When you play this character and when he leaves play, you may draw a card.",
  S2_053_ABILITY:
    "BATTLE OF WITS: Whenever one of your other characters is returned to your hand from play, this character gets +1 lore this turn.",
  S2_054_ABILITY:
    "LOOK BEFORE YOU LEAP: When you play this character and when he leaves play, look at the top card of your deck. Put it on either the top or the bottom of your deck.",
  S2_055_ABILITY: "TIPTOE: Your other characters with Rush gain Evasive.",
  S2_057_ABILITY:
    "LISTEN TO YOUR CONSCIENCE: When you play this character, you may return chosen character or item with cost 3 or less to their player's hand.",
  S2_058_ABILITY:
    "TELLING LIES: When you play this character, you may exert chosen opposing character.",
  S2_060_ABILITY:
    "CRUEL IRONY: When you play this character, shuffle another chosen character card into their player's deck. That player draws 2 cards.",
  S2_065_ABILITY:
    "FOR ALL ETERNITY: Exert, exert one of your characters - Exert chosen character.",
  S2_066_ABILITY:
    "HURTLING HEDGEHOG: Banish this item - Chosen character gains Rush this turn. (They can challenge the turn they're played.)",
  S2_067_ABILITY:
    "TO WONDERLAND: Banish this item - Return chosen character of yours to your hand.",
  S2_068_ABILITY: "KNOWLEDGE: Exert, pay 1 ink - Gain 1 lore.",
  S2_070_ABILITY:
    "SECOND WIND: Whenever an opposing character is damaged, you may ready this character.",
  S2_071_ABILITY:
    "USE YOUR IMAGINATION: While an opponent has no cards in their hand, this character gets +2 lore.",
  S2_072_ABILITY:
    "THORNY ARROWS: Whenever this character is challenged, the challenging character’s player discards all cards in their hand.",
  S2_073_ABILITY:
    "SQUEAK: Whenever you play a Floodborn character, each opponent chooses and discards a card.",
  S2_075_ABILITY: "WICKED SMILE: exert - Banish chosen damaged character.",
  S2_076_ABILITY:
    "THWART: Whenever this character quests, each opponent chooses and discards a card.",
  S2_077_ABILITY:
    "ALLOW ME: At the start of your turn, each player may draw a card.",
  S2_078_ABILITY:
    "STARTLED AWAKE: Whenever you play an action, this character gets +2 strength this turn.",
  S2_079_ABILITY:
    "YOU'RE IN MY WORLD: Whenever this character quests, chosen opposing character can't quest during their next turn.",
  S2_080_ABILITY:
    "TRUE FORM: While being challenged, this character gets +2 strength.",
  S2_082_ABILITY:
    "ONE LAST, BIG SCORE: This character gets -1 lore for each card in your opponents' hands.",
  S2_083_ABILITY:
    "YES, I'M INTIMIDATING: While one or more opponents have no cards in their hands, this character gets +3 strength.",
  S2_085_ABILITY:
    "MOUSE CATCHER: When you play this character, each opponent chooses and discards either 2 cards or 1 action card.",
  S2_086_ABILITY:
    "COMING, YOUR MOST LUGUBRIOUSNESS: While this character has 5 strength or more, he gets + 2 lore.",
  S2_087_ABILITY:
    "I CAN HANDLE IT: When you play this character, chosen character gets +2 strength this turn. If the chosen character is named Pain, he gets +4 strength instead.",
  S2_088_ABILITY:
    "<1>TAKE THAT!:</> Whenever you play an action, this character gets +2 strength this turn. <2/><1>WHO'S NEXT:</1> While this character has 7 strength or more, he gets +2 lore.",
  S2_089_ABILITY:
    "I SENTENCE YOU: Whenever your opponent discards 1 or more cards, you may draw a card for each card discarded.",
  S2_090_ABILITY:
    "ROYALE RAGE: When you play this character, deal 1 damage to chosen damaged opposing character.",
  S2_093_ABILITY:
    "A PERFECT DISGUISE: exert, Choose and discard a character card - Gain lore equal to the discarded character's lore.",
  S2_102_ABILITY:
    "SNAP! BOOM! TWANG!: Banish this item - Each opponent loses 2 lore.",
  S2_106_ABILITY:
    "PHOOEY!: This character gets +1 lore for each 1 damage on him.",
  S2_109_ABILITY:
    "EASY STREET: Whenever you play a Floodborn character, each opponent loses 1 lore.",
  S2_110_ABILITY:
    "POWER TO RULE AT LAST: When you play this character, each opponent chooses and banishes one of their characters.",
  S2_111_ABILITY:
    "NOT FOR YOU: When you play this character, each opponent with more lore than you loses 1 lore.",
  S2_114_ABILITY:
    "UNDERSEA ADVENTURE: Whenever you play a second action in a turn, this character gets +2 lore this turn.",
  S2_116_ABILITY:
    "WHAT HAVE YOU DONE?!: This character enters play with 3 damage.",
  S2_118_ABILITY:
    "THIS SHOULDN'T TAKE LONG: exert, Banish this character - Banish chosen character.",
  S2_120_ABILITY:
    "LET THE GAME BEGIN: Whenever one of your characters challenges another character, you may draw a card.",
  S2_121_ABILITY:
    "THIS IS MY KINGDOM: When you play this character, exert chosen opposing character with 3 strength or less. Chose one of your characters and ready them. They can't quest for the rest of this turn.",
  S2_122_ABILITY:
    "NOTE TO SELF, DON’T DIE: During your turn, whenever this character banishes another character in a challenge, you may ready this character. She can’t quest for the rest of this turn.",
  S2_123_ABILITY:
    "CHAMPION OF KUMANDRA: Whenever this character challenges a damaged character, she takes no damage from the challenge.",
  S2_125_ABILITY:
    "DADDY ISN’T HERE TO SAVE YOU: During your turn, whenever this character banishes another character in a challenge, you may ready this character. He can't quest for the rest of this turn.",
  S2_126_ABILITY:
    "DON'T INSULT MY INTELLIGENCE: Whenever one of your characters challenges another character, gain 1 lore.",
  S2_127_ABILITY:
    "ENERGETIC: Whenever you play an action, this character gets +2 strength this turn.",
  S2_134_ABILITY:
    "YOU KNOW WHAT HAPPENS: exert, 2 inks - Draw cards equal to the damage on chosen character of yours, then banish them.",
  S2_137_ABILITY:
    "<1>GOOD ADVICE:</1> Your other characters gain Support. (Whenever they quest, you may add their strength to another chosen character's strength this turn.) <2/><1>WHAT DID I DO?:</1> While this character has 10 strength or more, she gets +4 lore.",
  S2_138_ABILITY:
    "THERE'S ALWAYS A CHANCE: If you used Shift to play this character, you may draw 2 cards when he enters play.",
  S2_142_ABILITY:
    "UNWIND: Your other characters gain Resist +1 (Damage dealt to them is reduced by 1.)",
  S2_143_ABILITY:
    "WAIT A MINUTE: Your character with Reckless gain “exert - Gain 1 lore.”",
  S2_144_ABILITY:
    "NOW GET GOING: During your turn, this character gains Evasive. (They can challenge characters with Evasive.)",
  S2_145_ABILITY:
    "OH, NO YOU DON'T: Whenever this character quests, chosen opposing character gets -2 strength this turn.",
  S2_147_ABILITY:
    "DEVELOPED BRAIN: When you play this character, look at the top 3 cards of your deck. You may put one into your hand. Put the rest on the bottom of your deck in any order.",
  S2_148_ABILITY:
    "ANCIENT INSIGHT: Whenever you remove 1 or more damage from one of your characters, gain 2 lore.",
  S2_149_ABILITY:
    "ARTIFICER: When you play this character and whenever he quests, you may banish one of your items to draw 2 cards.",
  S2_150_ABILITY:
    "NEVER, EVER LOSE SIGHT: When this character is banished, you may put this card into your inkwell facedown and exerted.",
  S2_151_ABILITY:
    "I'M A FAST LEARNER: When you play this character, remove up to 1 damage from chosen character of yours.",
  S2_152_ABILITY:
    "DON'T CALL ME CUTE: When you play this character, you may banish chosen item. Its player draws a card.",
  S2_153_ABILITY:
    "TIDY UP: Whenever you play a Floodborn character, you may put the top card of your deck into your inkwell facedown and exerted.",
  S2_154_ABILITY:
    "IT'S CALLED A HUSTLE: When you play this character, you may return an item card named Pawpsicle from your discard to your hand.",
  S2_155_ABILITY:
    "HIDE AND SEEK: While you have an item in play, this character gains Resist +1 and Ward. (Damage dealt to this character is reduced by 1. Opponents can't choose this character except to challenge.)",
  S2_159_ABILITY:
    "I TRUST YOU: Whenever this character quests, look at the top 2 cards of your deck. You may put one into your hand. Put the rest on the bottom of your deck in any order.",
  S2_161_ABILITY:
    "HUNNY POT: Whenever this character quests, you may put a card from your hand into your inkwell facedown.",
  S2_166_ABILITY:
    "<1>CAREFUL AIM:</1> exert, 2 inks - Chosen character gets -2 strength this turn. <2/><1>STAY BACK!:</1> exert, Banish this item - Banish chosen Dragon character.",
  S2_167_ABILITY:
    "THE BEST I'VE EVER TASTED: exert - Remove 1 damage each from up to 2 chosen characters.",
  S2_168_ABILITY:
    "LOOKING FOR THIS?: Whenever you play another item, you may pay 1 ink to draw a card.",
  S2_169_ABILITY:
    "<1>JUMBO POP:</1> When you play this item, you may draw a card. <2/><1>THAT'S REDWOOD:</1> Banish this item - Remove up to 2 damage from chosen character.",
  S2_170_ABILITY:
    "FLIGHT CABIN: Your exerted characters gain Ward. (Opponents can’t choose them except to challenge.)",
  S2_171_ABILITY:
    "YOU'RE NOT WELCOME HERE: When you play this character, you may deal 1 damage to chosen character.",
  S2_172_ABILITY:
    "SHIELD ANOTHER: Whenever one of your other characters would be dealt damage, put that many damage counters on this character instead.",
  S2_173_ABILITY:
    "IT’S BETTER THIS WAY: At the start of your turn, if this character has no damage, draw a card. Otherwise, he gets +4 strength this turn.",
  S2_174_ABILITY:
    "WE HAVE A CHOICE: When you play this character, you may banish chosen item.",
  S2_175_ABILITY:
    "INSUBORDINATION!: Whenever you play a Floodborn character, deal 1 damage to each opposing character.",
  S2_176_ABILITY:
    "HAVE COURAGE: When you play this character, you may draw a card, then choose and discard a card.",
  S2_177_ABILITY:
    "THE SINGING SWORD: Whenever you play a song, this character may challenge ready characters this turn.",
  S2_183_ABILITY:
    "NOW WHERE WERE WE?: During your turn, whenever this character banishes another character in a challenge, you may draw a card.",
  S2_184_ABILITY:
    "I DON'T TRUST HIM, SIRE: During your turn, this character gains Evasive. (They can challenge characters with Evasive.)",
  S2_185_ABILITY:
    "SCOUT LEADER: During your turn, whenever this character banishes another character in a challenge, you may deal 2 damage to chosen character.",
  S2_186_ABILITY:
    "PAYBACK: While this character has no damage, he gets +4 strength.",
  S2_187_ABILITY:
    "ARCHERY LESSON: Whenever this character quests, your characters gain Evasive this turn. (They can challenge characters with Evasive.)",
  S2_188_ABILITY:
    "MAKE IT SHINE: When you play this character, chosen character gains Resist +1 until the start of your next turn. (Damage dealt to them is reduced by 1.)",
  S2_189_ABILITY: "BLADES: This character can challenge ready characters.",
  S2_192_ABILITY:
    "OFF WITH THEIR HEADS!: Whenever an opposing character is banished, you may ready this character.",
  S2_193_ABILITY: "SKIRMISH: exert - Deal 1 damage to chosen character.",
  S2_194_ABILITY:
    "CHANGE OF HEART: Whenever this character quests, you may draw a card, then choose and discard a card.",
  S2_196_ABILITY:
    "WHAT YOU GIVE IS WHAT YOU GET: While this character is exerted and you have no cards in your hand, opponents can’t play actions.",
  S2_202_ABILITY:
    "ARM YOURSELF: 1 ink, Banish this item - Chosen character gains Challenger +3 this turn. (They get +3 strength while challenging.)",
  S2_203_ABILITY:
    "PROTECTION: exert - Chosen character gains Resist +1 until the start of your next turn. (Damage dealt to them is reduced by 1.)",
  S2_204_ABILITY:
    "TRAINING: Whenever you play a character with 4 strength or more, you may pay 1 ink to draw a card.",

  // BODY set 2
  S2_028_BODY: "Remove up to 4 damage from chosen character.",
  S2_029_BODY: "Banish chosen character who was challenged this turn.",
  S2_030_BODY:
    "(A character with cost 2 or more can exert to sing this song for free.) Up to 2 chosen characters get -1 strength this turn. Draw a card.",
  S2_031_BODY:
    "(A character with cost 3 or more can exert to sing this song for free.) Banish chosen character with 5 strength or more.",
  S2_032_BODY:
    "A character with cost 2 or more can exert to sing this song for free.) Count the number of characters you have in play. You pay that amount of ink less for the next character you play this turn.",
  S2_062_BODY:
    "A character with cost 3 or more can exert to sing this song for free.) Play a character with cost 4 or less for free. They gain Rush. At the end of the turn, banish them. (They can challenge the turn they're played.)",
  S2_063_BODY:
    "Chosen exerted character can't ready at the start of their next turn.",
  S2_064_BODY:
    "(A character with cost 2 or more can exert to sing this song for free.) Chosen character gains Challenger +3 this turn. (They get +3 strength while challenging.)",
  S2_096_BODY:
    "A character with cost 3 or more can exert to sing this song for free.) Return chosen character of yours to your hand to play another character with the same cost or less for free.",
  S2_097_BODY:
    "Return chosen character of yours to your hand to return another chosen character to their player's hand.",
  S2_098_BODY: "Each opponent chooses and discards a card. Draw a card.",
  S2_099_BODY: "Chosen character gets +1 strength this turn. Draw a card.",
  S2_100_BODY: "Gain 1 lore for each damaged character opponents have in play.",
  S2_101_BODY: "Banish chosen damaged character.",
  S2_129_BODY:
    "(A character with cost 2 or more can exert to sing this song for free.) Ready chosen damaged character of yours. They can't quest for the rest of this turn. Draw a card.",
  S2_130_BODY:
    "A character with cost 2 or more can exert to sing this song for free.) Deal 2 damage to chosen character of yours to deal 2 damage to another chosen character.",
  S2_131_BODY:
    "(A character with cost 3 or more can exert to sing this song for free.) Banish chosen Villain of yours to banish chosen character.",
  S2_132_BODY: "Chosen damaged character gets +3 strength this turn.",
  S2_133_BODY:
    "(A character with cost 2 or more can exert to sing this song for free.) Chosen character gains Evasive until the start of your next turn. (Only characters with Evasive can challenge them.)",
  S2_135_BODY: "Your characters with Evasive get +1 strength.",
  S2_136_BODY:
    "exert, 2 inks - Chosen character gets +1 strength this turn for each 1 damage on them.",
  S2_162_BODY:
    "Each player chooses one of their characters and puts them into their inkwell facedown and exerted.",
  S2_163_BODY:
    "(A character with cost 4 or more can exert to sing this song for free.) Your characters gain Resist +2 until the start of your next turn. (Damage dealt to them is reduced by 2.)",
  S2_164_BODY:
    "Banish chosen item of yours to deal 5 damage to chosen character.",
  S2_165_BODY: "Each opponent reveals their hand. Draw a card.",
  S2_198_BODY:
    "Chosen character gains Challenger +2 and Resist +2 this turn. (They get +2 strength while challenging. Damage dealt to them is reduced by 2.)",
  S2_199_BODY:
    "(A character with cost 3 or more can exert to sing this song for free.) Deal 2 damage to chosen character. Draw a card.",
  S2_200_BODY: "Chosen character can challenge ready characters this turn.",
  S2_201_BODY:
    "A character with cost 3 or more can exert to sing this song for free.) Deal damage to chosen character equal to the number of characters you have in play.",

  // FLAVOR
  S2_001_FLAVOR: "Life is sweeter with friends.",
  S2_002_FLAVOR:
    "“Look, Pooh! Have you ever seen anything so grand?” -Christopher Robin",
  S2_003_FLAVOR:
    "With a magical dress and a song in her heart, she dazzled everyone at the ball.",
  S2_004_FLAVOR:
    "“Let me get this straight. Your ‘magic’ spell book got washed away in a flood of ‘magic’ ink?”",
  S2_005_FLAVOR: "He’s in charge of this outfit.",
  S2_006_FLAVOR: "He's a real gem.",
  S2_007_FLAVOR: "Love holds everything together.",
  S2_008_FLAVOR: "No one . . . sings like Gaston!",
  S2_009_FLAVOR: "He takes being opinionated to a higher level.",
  S2_010_FLAVOR: "Sour as a green gooseberry!",
  S2_011_FLAVOR: "You couldn't pick a better friend.",
  S2_012_FLAVOR: "“Cool it, boy. Unwind yourself.”",
  S2_013_FLAVOR: "“Come on in-there’s lots to explore.”",
  S2_015_FLAVOR: "Everything looks better when you're free to be yourself.",
  S2_017_FLAVOR: "Children are a dog's best friend.",
  S2_018_FLAVOR: "For a very small animal, he has an awfully big heart.",
  S2_020_FLAVOR:
    "“We can all make the world a little brighter in our own way.”",
  S2_021_FLAVOR: "He never gets tired of naps.",
  S2_022_FLAVOR: "Look out! He's gonna blow!",
  S2_023_FLAVOR: "“Why, you're all alone, just like me.”",
  S2_024_FLAVOR: "“Nothing says ‘hello’ better than a fresh-baked pie.”",
  S2_027_FLAVOR:
    "Don't question her. Don't doubt her. And above all, don't disobey her.",
  S2_028_FLAVOR: "“This might sting a little.”",
  S2_029_FLAVOR: "“Let’s finish this, binturi.” -Namaari",
  S2_033_FLAVOR: "Hope shines in even the darkest situations.",
  S2_035_FLAVOR: "“Hmm . . what spell should I try next?”",
  S2_036_FLAVOR:
    "“To make Geppetto’s wish come true will be entirely up to you.” -Blue Fairy",
  S2_037_FLAVOR: "“Wanna see me do a trick?”",
  S2_039_FLAVOR:
    "The power of ice may not stop the flood, but it will help protect Lorcana.",
  S2_040_FLAVOR:
    "“Use a humdrum spell, and you'll end up with humdrum magic. I like my magic to have something special!”",
  S2_042_FLAVOR:
    "“We'll have to hurry, because even miracles take a little time.”",
  S2_043_FLAVOR: "Power. Beauty. HeiHei.",
  S2_044_FLAVOR: "“Say, that's pretty swell.”",
  S2_045_FLAVOR: "“So there I was. Perfectly in control of the situation.”",
  S2_047_FLAVOR: "“Did I say no purple dragons? Did I?”",
  S2_049_FLAVOR: "“I’ve got you rattled now!”",
  S2_050_FLAVOR: "He’ll be out of this in a snap!",
  S2_051_FLAVOR: "“He always was a stubborn old goat.” -Madam Mim",
  S2_052_FLAVOR: "It was turning out to be a bad hare day.",
  S2_053_FLAVOR: "“Oh, blast it all-I can’t make up my mind.”",
  S2_054_FLAVOR: "“You can’t always trust to luck, boy.”",
  S2_056_FLAVOR:
    "“With that personality, that profile, that physique . . . Why, I can see your name in lights, lights six feet high.” -Honest John",
  S2_057_FLAVOR: "He raced into the Inklands without a thought.",
  S2_058_FLAVOR:
    "“A lie keeps growing and growing until it's as plain as the nose on your face.” -Blue Fairy",
  S2_059_FLAVOR:
    "He’d always felt a kinship with honey. They were both golden, and sweet, and likely to end up in sticky situations.",
  S2_061_FLAVOR:
    "Yzma: “Llamas! All I see when I close my eyes is llamas!” Kronk: “Weird. I just saw one in the flood. Poor little guy.”",
  S2_063_FLAVOR: "“Oh, bother-not again.”",
  S2_064_FLAVOR:
    "A legend is sung of when England was young And knights were brave and bold",
  S2_065_FLAVOR: "Just a standard form, nothing to worry about.",
  S2_067_FLAVOR:
    "Alice: “I just wanted to ask you which way I ought to go.” Cheshire Cat: “Well, that depends on where you want to get to.”",
  S2_068_FLAVOR:
    "Illumineers seek the power of knowledge-but must be aware of the price.",
  S2_069_FLAVOR:
    "“It's not just fancy horses and swinging a sword around, you know! A true master must use his brain as well as his blade.” -Merlin",
  S2_071_FLAVOR: "There's nothing more tempting than a pile of unread books.",
  S2_072_FLAVOR: "She slips through the trees as easily as shadow.",
  S2_073_FLAVOR: "“There’s a lot of nuance to squirrel.” -Kronk",
  S2_074_FLAVOR:
    "Alice felt quite confused. “But I don’t see much ink here at all. How can the flood still be changing the Inklands?” “Things are always changing, you know,” said the Cat. “It would be quite a change if they didn't.”",
  S2_078_FLAVOR: "Heading toward a rude awekening!",
  S2_080_FLAVOR: "Appearances can be deceiving.",
  S2_081_FLAVOR:
    "“I love a good fan club, but they could at least try to get the nose right!”",
  S2_083_FLAVOR: "“Don't I deserve the best?”",
  S2_084_FLAVOR:
    "“What's the rush, Rob? Take a load off! There's plenty of time to go lookin' for lore lost in that crazy ink.”",
  S2_085_FLAVOR: "“There must be something good about him.” -Cinderella",
  S2_086_FLAVOR:
    "“Get a move on! I'm a busy god, lots to do-meetings, curses, a little light scheming.” -Hades",
  S2_087_FLAVOR:
    "“Who says it's hard to find good help these days? Oh, yeah . . . ME!” -Hades",
  S2_089_FLAVOR: "“Taxes! Taxes! Beautiful, lovely taxes!”",
  S2_090_FLAVOR:
    "“You know, we could make her really angry. Shall we try?” -Cheshire Cat",
  S2_091_FLAVOR:
    "“I've outdone myself this time! Soon I will have everything I deserve. Riches . . . power . . . an entire kingdom at my feet!”",
  S2_092_FLAVOR: "He may be hard to follow, but his heart isn't.",
  S2_093_FLAVOR: "“This is no ordinary apple . . .”",
  S2_094_FLAVOR: "Finding your true self will set your heart aglow.",
  S2_095_FLAVOR:
    "“I must make the hard decisions to protect my daughter, especially in this unfamiliar world.”",
  S2_096_FLAVOR: "It'll do magic, believe it or not",
  S2_097_FLAVOR: "“Are you ready for some bouncing?” -Tigger",
  S2_098_FLAVOR: "“Look me in the eye when I’m speaking to you.”",
  S2_099_FLAVOR:
    "Shan-Yu: “It looks like you’re out of ideas.” Mulan: “Not quite!”",
  S2_100_FLAVOR:
    "Pacha: “You want to survive the jungle? Start thinking like you belong here.” Kuzco: “No problem . . . Grrr, look at me, I'm a jaguar.”",
  S2_101_FLAVOR: "“I’m afraid that you’ve gone and upset me.“ - Ratigan",
  S2_102_FLAVOR: "Simple in purpose, elaborate in execution-just like Ratigan.",
  S2_103_FLAVOR:
    "“The bees are buzzin’ in the tree to make some honey just for me!”",
  S2_104_FLAVOR: "“If you see any hungry faces, send ’em my way.”",
  S2_105_FLAVOR: "You'll have to deal with them sooner or later.",
  S2_107_FLAVOR:
    "“This isn't how most cat-and-mouse games go, is it, Dr. Dawson?” -Basil",
  S2_108_FLAVOR: "When a normal henchman just won't cut it.",
  S2_109_FLAVOR: "“A thing like that ought to be worth a fortune to someone!”",
  S2_110_FLAVOR:
    "The twelfth Rule of Villainy: If you don't have a throne, take one.",
  S2_111_FLAVOR:
    "“Make no mistake: this time I will make certain the key remains safe!”",
  S2_112_FLAVOR: "When things heat up, no one can hold a candle to him.",
  S2_113_FLAVOR: "“This goes into my top ten most fun things!”",
  S2_115_FLAVOR: "Zero to fun in under three seconds!",
  S2_116_FLAVOR: "Her feelings are written all over her face.",
  S2_117_FLAVOR: "“I have to do something!”",
  S2_118_FLAVOR:
    "“I don't need swords to beat you. They just make it more fun.”",
  S2_119_FLAVOR:
    "The glow of lore in the flood cought her eye. “The spellbook!” she cried. “Don't let it get away!”",
  S2_121_FLAVOR: "“This time, nothing, not even Basil, can stand in my way!”",
  S2_122_FLAVOR:
    "Two parts bravery, one part cleverness, and a whole lot of determination.",
  S2_124_FLAVOR:
    "“My ba dreams of a united Kumandra. I fight to honor that dream.”",
  S2_126_FLAVOR:
    "The sixth Rule of Villainy: Keep your mind sharp and your claws sharper.",
  S2_127_FLAVOR: "Bouncing in to save the day!",
  S2_128_FLAVOR: "A good friend is always ready to roll.",
  S2_130_FLAVOR:
    "Of course, quid pro quo, you’re expected To take certain duties on board",
  S2_131_FLAVOR:
    "Now comes the real tour de force Tricky and wicked, of course",
  S2_132_FLAVOR:
    "“No one can have a higher opinion of you than I have, and I think you’re a slimy, contemptible sewer rat!” -Basil",
  S2_134_FLAVOR: "The delicate sound of impending doom.",
  S2_135_FLAVOR:
    "Like so much other lore, Peter Pan's dagger was safe in the Great Illuminary until the flood.",
  S2_136_FLAVOR:
    "“Whoso pulleth out this sword of this stone and anvil is rightwise king born of England.”",
  S2_138_FLAVOR: "A solution always presents itself.",
  S2_139_FLAVOR:
    "“What an ingenious device! If its light is refracted through these, then its images must resolve somewhere below.”",
  S2_140_FLAVOR:
    "“There is no question: something is afoot in the Great Illuminary.”",
  S2_141_FLAVOR: "“Keep your tempo.”",
  S2_143_FLAVOR: "“This has gone far enough. I'm charge here.”",
  S2_144_FLAVOR:
    "“Isn't it just gorgeous, darling? And just as stylish as I am.”",
  S2_145_FLAVOR: "“It's ink couture, darling. I wear only the best!”",
  S2_146_FLAVOR: "“It’s Wee-sel-ton.”",
  S2_148_FLAVOR: "When he talks, even sky listens.",
  S2_149_FLAVOR:
    "His creations are even more wondrous with the Illuminary's resources at his fingertips.",
  S2_151_FLAVOR: "She may be young, but she’s got the spirit of a true leader.",
  S2_152_FLAVOR:
    "“I'll get to the bottom of what happened with that locked lorebook. You can count on me!”",
  S2_153_FLAVOR:
    "“I know just the thing. Let me fetch you a pot of tea and some of my fresh cheese crumpets.“",
  S2_154_FLAVOR: "“It's criminal how good these things taste!”",
  S2_156_FLAVOR:
    "“For instance, based on the quality of the light and the subtle change in wind direction, I can safely say that it is time for tea.”",
  S2_157_FLAVOR:
    "He'd searched across the Inklands for the young woman who'd stolen his heart at the ball, only to find more mysteries.",
  S2_158_FLAVOR:
    "Pooh: “Isn’t there anybody here at all?” Rabbit: “Nobody!” Pooh: “Somebody's there . . . because somebody must have said ‘Nobody.’”",
  S2_159_FLAVOR: "No matter her shape, you can’t mistake her heart.",
  S2_160_FLAVOR: "As elusive as water.",
  S2_161_FLAVOR:
    "When he thought, he thought in the most thoughtful way he could think.",
  S2_162_FLAVOR:
    "Down, down, down she went, floating in a swirl of ink. How curious!",
  S2_164_FLAVOR: "Ready . . . aim . . . coconut?",
  S2_165_FLAVOR: "Helps you avoid unpleasant surprises.",
  S2_167_FLAVOR: "“A gift this special just got to be shared.” -James",
  S2_168_FLAVOR: "The solution you need could be just a few adjustments away.",
  S2_170_FLAVOR: "“Flight 3759 boarding now! Let’s go get that lore!” -Orville",
  S2_171_FLAVOR: "Woe to the one who draws his gaze.",
  S2_172_FLAVOR: "“You’ll have to go through me first.”",
  S2_173_FLAVOR: "“It must be my destiny-to remain a beast forever.”",
  S2_174_FLAVOR: "“Don’t mistake spirit for skill.”",
  S2_175_FLAVOR:
    "“We can confirm the ink flood was caused by an explosion. We have it under control-now clear the area.”",
  S2_176_FLAVOR:
    "She's always had the heart of a champion - now she'll have the skills, too.",
  S2_178_FLAVOR:
    "“You go ahead, Minnie! I'm going to see if there's any lore over here.”",
  S2_179_FLAVOR:
    "“I don't suppose y'all could whip up some more magical beignets with them inkcasters?”",
  S2_180_FLAVOR:
    "It’s a banner day for Sir Goofy, who is steeled to prove his mettle against anyone courting trouble-joust in case.",
  S2_181_FLAVOR:
    "A good guy to have around when something wrecks your inkworks.",
  S2_182_FLAVOR: "No need to call IX-I-I!",
  S2_184_FLAVOR: "“Soon you’ll learn who holds the real power!”",
  S2_186_FLAVOR: "In this new world, the crown could finally be his.",
  S2_187_FLAVOR: "“Learn what to do, then learn to do it without thought.”",
  S2_188_FLAVOR:
    "Even with a hauling weight of “seriously, a lot,” it can only do so much in a magical flood.",
  S2_190_FLAVOR: "“Don't be fooled by the folksy peasant look.” -Kuzco",
  S2_191_FLAVOR: "All he's got is his charm.",
  S2_192_FLAVOR:
    "The fourth Rule of Villainy: Do whatever it takes to get ahead.",
  S2_193_FLAVOR: "“Capable? You don't know the half of it.” -Little John",
  S2_194_FLAVOR: "“Run away, hide! In the woods, anywhere!”",
  S2_197_FLAVOR:
    "“My place is going to be special, with great food to fill people’s bellies an hot jazz to feed their souls!”",
  S2_198_FLAVOR:
    "Sometimes subtlety is required. This is not one of those times.",
  S2_199_FLAVOR: "The cold never bothered me anyway",
  S2_200_FLAVOR: "“I'm gonna wreck it!”",
  S2_201_FLAVOR: "Tranquil as a forest But on fire within",
  S2_202_FLAVOR: "One shot can change everything.",
  S2_203_FLAVOR: "Built by the tiniest of hands for the bravest of hearts.",
  S2_204_FLAVOR: "Personally endorsed by Hercules himself!",

  // KEYWORDS TEXT CHARACTERS: SHIFT_X set 2
  S2_016_SHIFT_TEXT:
    "2 (You may pay 2 ink to play this on top of one of your characters named Mulan.)",
  S2_019_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Rapunzel.)",
  S2_025_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Snow White.)",
  S2_026_SHIFT_TEXT:
    "2 (You may pay 2 ink to play this on top of one of your characters named The Queen.)",
  S2_041_SHIFT_TEXT:
    "2 (You may pay 2 ink to play this on top of one of your characters named Fairy Godmother.)",
  S2_048_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Madam Mim.)",
  S2_057_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Pinocchio.)",
  S2_060_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Yzma.)",
  S2_072_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Belle.)",
  S2_075_SHIFT_TEXT:
    "5 (You may pay 5 ink to play this on top of one of your characters named Cheshire Cat.)",
  S2_077_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Donald Duck.)",
  S2_082_SHIFT_TEXT:
    "2 (You may pay 2 ink to play this on top of one of your characters named Flynn Rider.)",
  S2_110_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Lady Tremaine.)",
  S2_114_SHIFT_TEXT:
    "2 (You may pay 2 ink to play this on top of one of your characters named Minnie Mouse.)",
  S2_120_SHIFT_TEXT:
    "2 (You may pay 2 ink to play this on top of one of your characters named Queen of Hearts.)",
  S2_123_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Raya.)",
  S2_138_SHIFT_TEXT:
    "5 (You may pay 5 ink to play this on top of one of your characters named Basil.)",
  S2_142_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Cogsworth.)",
  S2_145_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Cruella De Vil.)",
  S2_147_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Gaston.)",
  S2_173_SHIFT_TEXT:
    "3 (You may pay 3 ink to play this on top of one of your characters named Beast.)",
  S2_177_SHIFT_TEXT:
    "5 (You may pay 5 ink to play this on top of one of your characters named Cinderella.)",
  S2_181_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Hercules.)",
  S2_183_SHIFT_TEXT:
    "2 (You may pay 2 ink to play this on top of one of your characters named Jafar.)",
  S2_206_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Snow White.)",
  S2_211_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Lady Tremaine.)",
  S2_215_SHIFT_TEXT:
    "4 (You may pay 4 ink to play this on top of one of your characters named Hercules.)",

  // SET 3

  // ITEM CARD set 3
  CLEANSING_RAINWATER: "Cleansing Rainwater",
  HEART_OF_ATLANTIS: "Heart of Atlantis",
  WILDCATS_WRENCH: "Wildcat's Wrench",
  THE_LAMP: "The Lamp",
  THE_SORCERERS_HAT: "The Sorcerer's Hat",
  AIRFOIL: "Airfoil",
  ROBINS_BOW: "Robin's Bow",
  STARLIGHT_VIAL: "Starlight Vial",
  MAUIS_FISH_HOOK: "Maui's Fish Hook",
  SUMERIAN_TALISMAN: "Sumerian Talisman",
  AURELIAN_GYROSENSOR: "Aurelian Gyrosensor",
  HEART_OF_TE_FITI: "Heart of Te Fiti",
  LUCKY_DIME: "Lucky Dime",
  SCROOGES_TOP_HAT: "Scrooge's Top Hat",
  VAULT_DOOR: "Vault Door",
  CAPTAIN_HOOKS_RAPIER: "Captain Hook's Rapier",
  GIZMOSUIT: "Gizmosuit",
  MAP_OF_TREASURE_PLANET: "Map of Treasure Planet",

  // ACTION CARD set 3
  PUPPIES_99: "99 Puppies",
  BOSSS_ORDERS: "Boss's Orders",
  QUICK_PATCH: "Quick Patch",
  BESTOW_A_GIFT: "Bestow a Gift",
  LAST_DITCH_EFFORT: "Last-Ditch Effort",
  DIVEBOMB: "Divebomb",
  ON_YOUR_FEET_NOW: "On Your Feet! Now!",
  VOYAGE: "Voyage",
  DISTRACT: "Distract",
  REPAIR: "Repair",
  BA_BOOM: "Ba-Boom!",
  OLYMPUS_WOULD_BE_THAT_WAY: "Olympus Would Be That Way",
  RISE_OF_THE_TITANS: "Rise of the Titans",

  // ACTION SONG CARD set 3
  HEAL_WHAT_HAS_BEEN_HURT: "Heal What Has Been Hurt",
  THE_BARE_NECESSITIES: "The Bare Necessities",
  IT_CALLS_ME: "It Calls Me",
  THE_BOSS_IS_ON_A_ROLL: "The Boss Is on a Roll",
  I_WILL_FIND_MY_WAY: "I Will Find My Way",
  STRIKE_A_GOOD_MATCH: "Strike a Good Match",
  I_HAVE_GOT_A_DREAM: "I've Got a Dream",
  FRIEND_LIKE_ME: "Friend Like Me",
  HOW_FAR_ILL_GO: "How Far I'll Go",
  AND_THEN_ALONG_CAME_ZEUS: "And Then Along Came Zeus",

  // LOCATION CARD set 3
  NEVER_LAND: "Never Land",
  PRIDE_LANDS: "Pride Lands",
  TIANAS_PALACE: "Tiana's Palace",
  FORBIDDEN_MOUNTAIN: "Forbidden Mountain",
  THE_QUEENS_CASTLE: "The Queen's Castle",
  THE_SORCERERS_TOWER: "The Sorcerer's Tower",
  DE_VIL_MANOR: "De Vil Manor",
  FANG: "Fang",
  KUZCOS_PALACE: "Kuzco's Palace",
  AGRABAH: "Agrabah",
  JOLLY_ROGER: "Jolly Roger",
  RLS_LEGACY: "RLS Legacy",
  BELLES_HOUSE: "Belle's House",
  MCDUCK_MANOR: "McDuck Manor",
  MOTUNUI: "Motunui",
  MAUIS_PLACE_OF_EXILE: "Maui's Place of Exile",
  NOTTINGHAM: "Nottingham",
  THE_BAYOU: "The Bayou",

  // VERSION NAME set 3
  S3_001_VERSION_NAME: "von Bruinwald XIII",
  S3_002_VERSION_NAME: "Brand-New Agent",
  S3_003_VERSION_NAME: "Evildoer",
  S3_004_A_VERSION_NAME: "Tail Wagger",
  S3_005_VERSION_NAME: "The Doctor",
  S3_006_VERSION_NAME: "Atlantean",
  S3_007_VERSION_NAME: "Protector of Atlantis",
  S3_008_VERSION_NAME: "The 15th Puppy",
  S3_009_VERSION_NAME: "Musical Artist",
  S3_010_VERSION_NAME: "Rescue Aid Society Agent",
  S3_011_VERSION_NAME: "Inept Businessman",
  S3_012_VERSION_NAME: "Protective Sister",
  S3_013_VERSION_NAME: "Ace Pilot",
  S3_014_VERSION_NAME: "Intimidating Pup",
  S3_015_VERSION_NAME: "Devoted Mother",
  S3_016_VERSION_NAME: "Pooh Pirate Captain",
  S3_017_VERSION_NAME: "Determined Defender",
  S3_018_VERSION_NAME: "Friendly Pooch",
  S3_019_VERSION_NAME: "Determined Father",
  S3_020_VERSION_NAME: "Wonderland Empress",
  S3_021_VERSION_NAME: "Hungry Pup",
  S3_022_VERSION_NAME: "Generous Fairy",
  S3_023_VERSION_NAME: "Talented Sailor",
  S3_032_VERSION_NAME: "Mermaid Lagoon",
  S3_033_VERSION_NAME: "Pride Rock",
  S3_034_VERSION_NAME: "Jazz Restaurant",
  S3_035_VERSION_NAME: "Tea Alchemist",
  S3_036_VERSION_NAME: "Creatures of Evil",
  S3_037_VERSION_NAME: "Faithful Pet",
  S3_038_VERSION_NAME: "Supportive Friend",
  S3_039_VERSION_NAME: "Ice Titan",
  S3_040_VERSION_NAME: "Pretty Polly",
  S3_041_VERSION_NAME: "Lamp Thief",
  S3_042_VERSION_NAME: "Striking Illusionist",
  S3_043_VERSION_NAME: "Rebellious Teenager",
  S3_044_VERSION_NAME: "Dancing Duster",
  S3_045_VERSION_NAME: "Swift Cleaner",
  S3_046_VERSION_NAME: "The Big Sweeper",
  S3_047_VERSION_NAME: "Flying Rug",
  S3_048_VERSION_NAME: "Ambitious Witch",
  S3_049_VERSION_NAME: "The Midas Touch",
  S3_050_VERSION_NAME: "Thieving Sorceress",
  S3_051_VERSION_NAME: "Mistress of All Evil",
  S3_052_VERSION_NAME: "Voice of Wisdom",
  S3_053_VERSION_NAME: "Potbellied Buddy",
  S3_054_VERSION_NAME: "Mystical Fighter",
  S3_055_VERSION_NAME: "Tornado Titan",
  S3_056_VERSION_NAME: "Force of Destruction",
  S3_057_VERSION_NAME: "Hateful Rival",
  S3_058_VERSION_NAME: "Protector of the Cave",
  S3_059_VERSION_NAME: "Sea Witch",
  S3_066_VERSION_NAME: "Maleficent's Castle",
  S3_067_VERSION_NAME: "Mirror Chamber",
  S3_068_VERSION_NAME: "Wondrous Workspace",
  S3_069_VERSION_NAME: "Mighty Lost Boy",
  S3_070_VERSION_NAME: "Ursula's Handiwork",
  S3_071_VERSION_NAME: "Prince of Pirates",
  S3_072_VERSION_NAME: "Riffraff",
  S3_073_VERSION_NAME: "Priest of Nottingham",
  S3_074_VERSION_NAME: "Femme Fatale",
  S3_075_VERSION_NAME: "Vengeful Partner",
  S3_076_VERSION_NAME: "Riffraff",
  S3_077_VERSION_NAME: "Tough Guy",
  S3_078_VERSION_NAME: "Cunning Mercenary",
  S3_079_VERSION_NAME: "Clever Cartographer",
  S3_080_VERSION_NAME: "King of Atlantis",
  S3_081_VERSION_NAME: "Space Goo",
  S3_082_VERSION_NAME: "Lost Boy Leader",
  S3_083_VERSION_NAME: "Phony King",
  S3_084_VERSION_NAME: "Daydreamer",
  S3_085_VERSION_NAME: "Hyena Pack Leader",
  S3_086_VERSION_NAME: "Aggravating Asp",
  S3_087_VERSION_NAME: "Energetic Rabbit",
  S3_088_VERSION_NAME: "Devious Pirate",
  S3_089_VERSION_NAME: "Covert Agent",
  S3_090_VERSION_NAME: "Deceiver",
  S3_091_VERSION_NAME: "Deceiver of All",
  S3_092_VERSION_NAME: "Mechanic",
  S3_093_VERSION_NAME: "Steward of the Pride Lands",
  S3_100_VERSION_NAME: "Cruella's Estate",
  S3_101_VERSION_NAME: "River City",
  S3_102_VERSION_NAME: "Home of the Emperor",
  S3_103_VERSION_NAME: "Adventurous Collector",
  S3_104_VERSION_NAME: "Keeper of the Map",
  S3_105_VERSION_NAME: "Master Swordsman",
  S3_106_VERSION_NAME: "Unstoppable Mom",
  S3_107_VERSION_NAME: "Accidental Explorer",
  S3_108_VERSION_NAME: "Deadly Serpent",
  S3_109_VERSION_NAME: "Space Traveler",
  S3_110_VERSION_NAME: "Thrill Seeker",
  S3_111_VERSION_NAME: "Menacing Sailor",
  S3_112_VERSION_NAME: "The Boss",
  S3_113_VERSION_NAME: "Soaring Demigod",
  S3_114_VERSION_NAME: "Whale",
  S3_115_VERSION_NAME: "Spirited Scholar",
  S3_116_VERSION_NAME: "Born Leader",
  S3_117_VERSION_NAME: "Undeterred Voyager",
  S3_118_VERSION_NAME: "Vulture Henchman",
  S3_119_VERSION_NAME: "Never Land Hero",
  S3_120_VERSION_NAME: "Pirate's Bane",
  S3_121_VERSION_NAME: "Expert Helmsman",
  S3_122_VERSION_NAME: "Backstabber",
  S3_123_VERSION_NAME: "Scrappy Cub",
  S3_124_VERSION_NAME: "Lost Boy",
  S3_125_VERSION_NAME: "Little Rocket",
  S3_126_VERSION_NAME: "Not-So-Sharp Shooter",
  S3_127_VERSION_NAME: "Enthusiastic Duck",
  S3_134_VERSION_NAME: "Marketplace",
  S3_135_VERSION_NAME: "Hook's Ship",
  S3_136_VERSION_NAME: "Solar Galleon",
  S3_137_VERSION_NAME: "The Engineer",
  S3_138_VERSION_NAME: "First in Command",
  S3_139_VERSION_NAME: "Showy Nephew",
  S3_140_VERSION_NAME: "Lone Cheater",
  S3_141_VERSION_NAME: "Cramped in the Lamp",
  S3_142_VERSION_NAME: "Keeper of Ancient Stories",
  S3_143_VERSION_NAME: "Spirit of the Ocean",
  S3_144_VERSION_NAME: "Gadget Whiz",
  S3_145_VERSION_NAME: "Savvy Nephew",
  S3_146_VERSION_NAME: "Bandleader",
  S3_147_VERSION_NAME: "Navigator",
  S3_148_VERSION_NAME: "Spunky Bear Cub",
  S3_149_VERSION_NAME: "Chill Nephew",
  S3_150_VERSION_NAME: "Delightful Dreamer",
  S3_151_VERSION_NAME: "Mystical Maven",
  S3_152_VERSION_NAME: "Mickey's Clever Friend",
  S3_153_VERSION_NAME: "Orphanage Cat",
  S3_154_VERSION_NAME: "Richest Duck in the World",
  S3_155_VERSION_NAME: "Uncle Moneybags",
  S3_156_VERSION_NAME: "Mirror Seeker",
  S3_157_VERSION_NAME: "Very Clever Fairy",
  S3_158_VERSION_NAME: "Authority on Peter Pan",
  S3_168_VERSION_NAME: "Maurice's Workshop",
  S3_169_VERSION_NAME: "Scrooge's Mansion",
  S3_170_VERSION_NAME: "Island Paradise",
  S3_171_VERSION_NAME: "Proud of Motunui",
  S3_172_VERSION_NAME: "Overstuffed Donkey",
  S3_173_VERSION_NAME: "Terror of the Kingdom",
  S3_174_VERSION_NAME: "Hotheaded Ruler",
  S3_175_VERSION_NAME: "Right-Hand Woman",
  S3_176_VERSION_NAME: "Greedy Treasure Seeker",
  S3_177_VERSION_NAME: "Royal Warrior",
  S3_178_VERSION_NAME: "Resourceful Outlaw",
  S3_179_VERSION_NAME: "Robin's Pal",
  S3_180_VERSION_NAME: "Rock Titan",
  S3_181_VERSION_NAME: "Stalwart Explorer",
  S3_182_VERSION_NAME: "Trumpeter",
  S3_183_VERSION_NAME: "Funky Spelunker",
  S3_184_VERSION_NAME: "Bumbling Mate",
  S3_185_VERSION_NAME: "Champion of the Pride Lands",
  S3_186_VERSION_NAME: "Fierce Friend",
  S3_187_VERSION_NAME: "Lava Titan",
  S3_188_VERSION_NAME: "Palace Guard",
  S3_189_VERSION_NAME: "Beloved Outlaw",
  S3_190_VERSION_NAME: "Champion of Sherwood",
  S3_191_VERSION_NAME: "Corrupt Official",
  S3_192_VERSION_NAME: "Fighting Prince",
  S3_193_VERSION_NAME: "Rightful King",
  S3_194_VERSION_NAME: "Metallic Leader",
  S3_202_VERSION_NAME: "Hidden Island",
  S3_203_VERSION_NAME: "Prince John's Castle",
  S3_204_VERSION_NAME: "Mysterious Swamp",

  // ABILITIES set 3
  S3_001_ABILITY:
    "LET'S MAKE LIKE A TREE: When this character is banished, gain 2 lore.",
  S3_002_ABILITY:
    "I’LL CHECK IT OUT: At the end of your turn, if this character is exerted, you may ready another chosen character of yours.",
  S3_003_ABILITY:
    "<1>THE POWER OF EVIL:</1> When you play this character, pay 1 ink less for every character card in your discard. <2/><1>SUMMON THE SPIRITS:</1> When you play this character, shuffle all character cards from your discard into your deck.",
  S3_004_A_ABILITY:
    "WHERE DID THEY ALL COME FROM?: You may have up to 99 copies of Dalmatian Puppy - Tail Wagger in your deck.",
  S3_007_ABILITY:
    "PERHAPS WE CAN SAVE OUR FUTURE: When you play this character, all characters get -3 strength until the start of your next turn.",
  S3_008_ABILITY:
    "<1>GOOD AS NEW:</1> exert - Reveal the top 3 cards of your deck. You may put each character card with cost 2 or less into your hand. Put the rest on the bottom of your deck in any order. <2/><1>PUPPY LOVE:</1> Whenever this character quests, if you have 4 or more other characters in play, your other characters get +1 lore this turn.",
  S3_009_ABILITY:
    "ENTOURAGE: Whenever you play a character with Bodyguard, you may remove up to 2 damage from chosen character.",
  S3_014_ABILITY:
    "BARK: exert - Chosen character gets -2 strength until the start of your next turn.",
  S3_015_ABILITY:
    "COME ALONG, CHILDREN: When you play this character and whenever she quests, you may play a character with cost 2 or less from your discard for free.",
  S3_016_ABILITY:
    "AND I'M THE CAPTAIN!: While you have 2 or more other characters in play, this characters gets +2 lore.",
  S3_017_ABILITY:
    "GUARD DOG: At the start of your turn, remove up to 3 damage from this character.",
  S3_018_ABILITY:
    "GOOD DOG: exert - You pay 1 ink less for the next character you play this turn.",
  S3_019_ABILITY:
    "TWILIGHT BARK: Once per turn, you may pay 2 ink to reveal the top card of your deck. If it's a character card, put it into your hand. Otherwise, put it on the bottom of your deck.",
  S3_020_ABILITY:
    "ALL WAYS HERE ARE MY WAYS: Whenever this character quests, your other Villain characters get +1 lore this turn.",
  S3_022_ABILITY:
    "MAKE A NEW FRIEND: When you play this character, look at the top 4 cards of your deck. You may reveal a character card and put it into your hand. Place the rest on the bottom of your deck in any order.",
  S3_029_ABILITY:
    "ANCIENT POWER: Banish this item - Remove up to 2 damage from each of your characters.",
  S3_030_ABILITY:
    "LIFE GIVER: exert - You pay 2 ink less for the next character you play this turn.",
  S3_031_ABILITY:
    "REBUILD: exert - Remove up to 2 damage from chosen location.",
  S3_033_ABILITY:
    "<1>WE ARE ALL CONNECTED:</1> Characters get +2 willpower while here. <2/><1>LION HOME:</1> If you have a Prince or King character here, you pay 1 ink less to play characters.",
  S3_034_ABILITY: "NIGHT OUT: Characters can't be challenged while here.",
  S3_035_ABILITY:
    "CURIOUSER AND CURIOUSER: exert - Exert chosen opposing character and all other opposing characters with the same name.",
  S3_036_ABILITY:
    "RESTLESS SOULS: Whenever this character quests, you may banish this character to draw a card.",
  S3_037_ABILITY:
    "IN SEARCH OF AURORA: Whenever you play a character named Maleficent, you may look at the top card of your deck. Put it on either the top or the bottom of your deck.",
  S3_038_ABILITY:
    "THREE WISHES: Whenever this character quests, you may shuffle this card into your deck to draw 3 cards.",
  S3_039_ABILITY: "BLIZZARD: exert - Exert chosen character.",
  S3_041_ABILITY:
    "I AM YOUR MASTER NOW: When you play this character, look at the top 2 cards of your deck. Put one on the top of your deck and the other on the bottom.",
  S3_042_ABILITY:
    "POWER BEYOND MEASURE: During your turn, while this character is exerted, whenever you draw a card, gain 1 lore.",
  S3_044_ABILITY:
    "POWER CLEAN: When you play this character, if you have a Sorcerer character in play, you may exert chosen opposing character. They can't ready at the start of their next turn.",
  S3_045_ABILITY:
    "CLEAN THIS, CLEAN THAT: When you play this character, you may shuffle all Broom cards from your discard into your deck.",
  S3_046_ABILITY:
    "CLEAN SWEEP: While this character is at a location, it gets +2 strength.",
  S3_047_ABILITY:
    "FIND THE WAY: exert - Move a character of yours to a location for free.",
  S3_049_ABILITY:
    "ALL MINE: Whenever this character quests, gain lore equal to the cost of one of your items in play.",
  S3_050_ABILITY:
    "TELEKINESIS: exert - Return chosen item with cost equal to or less than this character's strength to its player's hand.",
  S3_051_ABILITY:
    "<1>DARK KNOWLEDGE:</1> Whenever this character quests, you may draw a card. <2/><1>DIVINATION:</1> During your turn, whenever you draw a card, you may move 1 damage counter from a chosen character to a chosen opposing character.",
  S3_052_ABILITY:
    "LISTEN TO YOUR MAMA NOW: Whenever this character quests, you may move up to 2 damage counters from chosen character to chosen opposing character.",
  S3_053_ABILITY:
    "ALWAYS THERE: When this character is banished, you may shuffle this card into your deck.",
  S3_054_ABILITY:
    "ANCIENT SKILLS: Whenever he challenges a Hyena character, this character takes no damage from the challenge.",
  S3_055_ABILITY:
    "CYCLONE: exert - Gain lore equal to the number of Titan characters you have in play.",
  S3_058_ABILITY:
    "WHO DISTURBS MY SLUMBER?: This character can't challenge or quest unless it is at a location.",
  S3_059_ABILITY:
    "YOU'RE TOO LATE: Whenever this character quests, chosen opposing character can't ready at the start of their next turn.",
  S3_064_ABILITY:
    "GOOD OR EVIL: Banish this item - If you have a character named Jafar in play, draw 2 cards. If you have a character named Genie in play, return chosen character with cost 4 or less to their player's hand.",
  S3_065_ABILITY:
    "INCREDIBLE ENERGY: exert, 1 ink - Name a card, then reveal the top card of your deck. If it's the named card, put that card into your hand. Otherwise, put it on the top of your deck.",
  S3_067_ABILITY:
    "USING THE MIRROR: At the start of your turn, for each character you have here, you may draw a card.",
  S3_068_ABILITY:
    "<1>BROOM CLOSET:</1> Your characters named Magic Broom may move here for free. <2/><1> MAGICAL POWER:</1> Characters get +1 lore while here.",
  S3_069_ABILITY:
    "THE BEAR: Whenever this character moves to a location, he gets +3 strength this turn.",
  S3_070_ABILITY:
    "POOR SOULS: Whenever this character is challenged, each opponent chooses and discards a card.",
  S3_072_ABILITY: "EERIE PAIR: Your characters named Jetsam get +3 strength.",
  S3_073_ABILITY:
    "YOU THIEVING SCOUNDREL: When you play this character, the player or players with the most cards in their hand chooses and discards a card.",
  S3_074_ABILITY:
    "THIS CHANGES EVERYTHING: Whenever this character quests, you may deal 3 damage to chosen damaged character.",
  S3_075_ABILITY:
    "NOTHING PERSONAL: When this character is challenged and banished, banish the challenging character.",
  S3_076_ABILITY: "EERIE PAIR: Your characters named Flotsam gain Ward.",
  S3_077_ABILITY:
    "SKYSURFING: When you play this character, you may return chosen opposing character with 2 strength or less to their player's hand.",
  S3_078_ABILITY:
    "WELL, NOW YOU KNOW: When you play this character, chosen opposing character gains Reckless during their next turn. (They can’t quest and must challenge if able.) <2/><1>THANKS FOR VOLUNTEERING:</1> Whenever one of your other characters is banished, each opponent loses 1 lore.",
  S3_080_ABILITY:
    "TAKE THEM BY SURPRISE: When this character is banished, return all opposing characters to their players’ hands.",
  S3_081_ABILITY:
    "MIMICRY: You may play any character with Shift on this character as if this character had any name.",
  S3_082_ABILITY:
    "I CAME TO LISTEN TO THE STORIES: Once per turn, when this character moves to a location, gain lore equal to that location's lore.",
  S3_083_ABILITY:
    "COLLECT TAXES: Whenever this character quests, each opponent with more Lore than you loses 2 Lore.",
  S3_085_ABILITY:
    "<1>I WILL DO IT:</1> When this character is at a location, she gets +3 strength. <2/><1>WHAT’S THE HURRY?:</1> When this character is at a location, when she challenges another character, you may draw a card.",
  S3_089_ABILITY:
    "HIDE: While this character is at a location, he gains Ward. (Opponents can't choose them except to challenge.)",
  S3_090_ABILITY:
    "YOU'LL NEVER EVEN MISS IT: When you play this character, chosen opponent reveals their hand and discards a song card of your choice.",
  S3_091_ABILITY:
    "WHAT A DEAL: Whenever this character sings a song, you may play that song again from your discard for free, then put it on the bottom of your deck.",
  S3_092_ABILITY: "DISASSEMBLE: exert - Banish chosen item.",
  S3_093_ABILITY:
    "IT’S TIME TO LEAVE!: While this character is at a location, he gets +1 lore.",
  S3_097_ABILITY:
    "I GOT TO BE GOING: exert - If you’ve played 2 or more actions this turn, draw a card.",
  S3_098_ABILITY:
    "<1>FOREST’S GIFT:</1> exert - Deal 1 damage to chosen damaged character or location. <2/><1>A BIT OF A LARK:</1> Whenever a character of yours named Robin Hood quests, you may ready this item.",
  S3_099_ABILITY:
    "<1>EFFICIENT ENERGY:</1> exert - You pay 2 ink less for the next action you play this turn. <2/><1>TRAP:</1> 2 ink, Banish this item - Draw 2 cards, then choose and discard a card.",
  S3_101_ABILITY:
    "SURROUNDED BY WATER: Characters gain Ward and Evasive while here. (Opponents can't choose them except to challenge. Only characters with Evasive can challenge them.)",
  S3_102_ABILITY:
    "CITY WALLS: Whenever a character is challenged and banished while here, banish the challenging character.",
  S3_103_ABILITY:
    "INSPIRING VOICE: Whenever you play a song, chosen character of yours gains Evasive until the start of your next turn.",
  S3_105_ABILITY:
    "<1>NEMESIS:</1> During your turn, whenever this character banishes another character in a challenge, ready this character. He can't quest for the rest of this turn. <2/><1>MAN-TO-MAN:</1> Characters named Peter Pan lose Evasive and can't gain Evasive.",
  S3_107_ABILITY:
    "MINDLESS WANDERING: Once per turn, when this character moves to a location, each opponent loses 1 lore.",
  S3_108_ABILITY:
    "WATCH THE TEETH: When this character is damaged, deal the same amount of damage to a chosen opposing character.",
  S3_109_ABILITY:
    "<1>THIS IS IT!:</1> When you play this character, you may play a location with cost 4 or less for free. <2/><1>TAKE THE HELM:</1> Whenever you play a location, this character may move there for free.",
  S3_111_ABILITY:
    "PLUNDER: When you play this character, each opponent loses 1 Lore.",
  S3_112_ABILITY:
    "THAT TERRIBLE WOMAN: When you play this character, banish chosen opposing character with 3 strength or less.",
  S3_113_ABILITY:
    "IN MY STOMACH: Whenever one of your characters named Heihei quests, this character gets +1 lore and loses Reckless for this turn.",
  S3_114_ABILITY:
    "<1>THIS MISSION IS CURSED:</1> This character can't ready at the start of your turn. <2/><1>I GOT YOUR BACK:</1> 2 ink - Ready this character. He can’t quest for the rest of this turn.",
  S3_115_ABILITY:
    "I’M YOUR MAN!: While this character is at a location, he gets +2 strength.",
  S3_116_ABILITY:
    "WELCOME TO MY BOAT: Whenever this character quests while at a location (Location), ready all other characters here. They can't quest for the rest of this turn.",
  S3_119_ABILITY:
    "OVER HERE, TINK: While you have a character named Tinker Bell in play, this character gets +2 strength.",
  S3_120_ABILITY:
    "YOU'RE NEXT!: Whenever he challenges a Pirate character, this character takes no damage from the challenge.",
  S3_121_ABILITY:
    "SURPRISE MANEUVER: When this character is banished, you may banish chosen character.",
  S3_122_ABILITY:
    "BRUTE: While this character has damage, he gets +3 strength.",
  S3_124_ABILITY:
    "THE FOX: If you have a character named Peter Pan in play, you pay 1 ink less to play this character.",
  S3_126_ABILITY: "OLD BETSY: Your characters named Nutsy gain +1 lore.",
  S3_132_ABILITY:
    "<1>IT'S MAUI TIME!:<1/> If you have a character named Maui in play, you may use this item's Shapeshift ability for free. <2/><1>SHAPESHIFT:</1> exert, 2 ink - Choose one: · Chosen character gains Evasive until the start of your next turn. (Only characters with Evasive can challenge them.) · Chosen character gets +3 strength this turn.",
  S3_133_ABILITY:
    "SOURCE OF MAGIC: During your turn, whenever one of your characters is banished in a challenge, you may draw a card.",
  S3_135_ABILITY:
    "<1>LOOK ALIVE, YOU SWABS!:</1> Characters gain Rush while here. (They can challenge the turn they're played.) <2/><1>ALL HANDS ON DECK!:</1> Your Pirate characters may move here for free.",
  S3_136_ABILITY:
    "<1>THIS IS OUR SHIP:</1> Characters gain Evasive while here. (Only characters with Evasive can challenge them.) <2/><1>HEAVE TOGETHER NOW:</1> If you have a character here, you pay 2 ink less to move a character of yours here.",
  S3_137_ABILITY:
    "SPARE PARTS: Whenever this character quests, ready one of your items.",
  S3_138_ABILITY:
    "DISCIPLINE: During your turn, this character gains Evasive. (They can challenge characters with Evasive.)",
  S3_140_ABILITY:
    "THEY'LL NEVER SEE IT COMING!: During your turn, this character gains Evasive. (They can challenge characters with Evasive.)",
  S3_142_ABILITY:
    "THERE WAS ONLY OCEAN: When you play this character, look at the top 2 cards of your deck. You may add one into your hand. Put the rest on the bottom of your deck in any order.",
  S3_143_ABILITY:
    "DO YOU KNOW WHO YOU ARE?: Whenever a card is put into your inkwell, gain 1 lore.",
  S3_144_ABILITY:
    "NOW TRY TO KEEP UP: exert — Put an item card from your discard to the top of your deck.",
  S3_145_ABILITY:
    "THREE NEPHEWS: Whenever this character quests, if you have characters named Dewey and Louie in play, you may draw 3 cards.",
  S3_150_ABILITY:
    "HIGHBORN LADY: When you play this character, chosen character gets -2 strength this turn.",
  S3_151_ABILITY:
    "THIS GOING TO BE GOOD: Whenever you play a song, you may put the top card of your deck into your inkwell facedown and exerted.",
  S3_153_ABILITY:
    "TOO OLD TO BE CHASING MICE: When this character is banished, you may put this card into your inkwell facedown and exerted.",
  S3_154_ABILITY:
    "<1>I'M GOING HOME!:</1> During your turn, this character gains Evasive. (They can challenge characters with Evasive.) <2/><1>I DIDN'T GET RICH BY BEING STUPID:</1> During your turn, whenever this character banishes another character in a challenge, you may play an item for free.",
  S3_155_ABILITY:
    "TREASURE FINDER: Whenever this character quests, you pay 1 ink less for the next item you play this turn.",
  S3_156_ABILITY:
    "CALCULATING AND VAIN: Whenever this character quests, you may look at the top 3 cards of your deck and put them back in any order.",
  S3_157_ABILITY:
    "I CAN USE THAT: Whenever one of your items is banished, you may put that card into your inkwell facedown and exerted.",
  S3_163_ABILITY:
    "SEEKING KNOWLEDGE: Whenever one of your characters quests, you may look at the top card of your deck. Put it on either the top or the bottom of your deck.",
  S3_164_ABILITY:
    "CREATE LIFE: exert, 2 ink - Put the top card of your deck into your inkwell facedown and exerted.",
  S3_165_ABILITY:
    "NUMBER ONE: exert, 2 ink - Choose a character of yours and gain lore equal to their lore.",
  S3_166_ABILITY:
    "BUSINESS EXPERTISE: exert - You pay 1 ink less for the next item you play this turn.",
  S3_167_ABILITY:
    "SEALED AWAY: Your locations and character at locations gain Resist +1. (Damage dealt to them is reduced by 1.)",
  S3_168_ABILITY:
    "LABORATORY: If you have a character here, you pay 1 ink less to play items.",
  S3_170_ABILITY:
    "REINCARNATION: Whenever a character is banished while here, you may put that card into your inkwell facedown and exerted.",
  S3_173_ABILITY:
    "<1>ALL TIED UP:</1> This character enters play exerted and can't ready at the start of your turn. <2/><1>BREAK FREE:</1> During your turn, whenever one of your other characters banishes another character in a challenge, you may ready this character.",
  S3_174_ABILITY: "CALLING THE TITANS: exert - Ready your Titan characters.",
  S3_176_ABILITY:
    "CHART YOUR OWN COURSE: For each location you have in play, this character gains Resist +1 and gets +1 lore. (Damage dealt to them is reduced by 1.)",
  S3_178_ABILITY:
    "OKAY, BIG SHOT: While this character is exerted, your characters with Bodyguard gain Resist +1 and get +1 lore. (Damage dealt to them is reduced by 1.)",
  S3_179_ABILITY:
    "DISGUISED: During your turn, this character gains Evasive. (They can challenge characters with Evasive.)",
  S3_180_ABILITY:
    "STONE SKIN: exert - Chosen character gains Resist +2 this turn.",
  S3_181_ABILITY:
    "LET'S TAKE A LOOK: This character gets +1 strength for each location you have in play.",
  S3_182_ABILITY: "BUGLE CALL: exert, 2 ink - Play a character for free.",
  S3_183_ABILITY:
    "JOURNEY: While this character is at a location, she gets +2 strength.",
  S3_184_ABILITY:
    "OH DEAR, DEAR, DEAR: At the end of your turn, if this character is exerted and you don't have a Captain character in play, deal 1 damage to this character.",
  S3_187_ABILITY:
    "ERUPTION: During your turn, whenever this character banishes another character in a challenge, you may ready chosen character.",
  S3_188_ABILITY:
    "LOOKY HERE: While this character has no damage, he gets +2 strength.",
  S3_190_ABILITY:
    "<1>SKILLED COMBATANT:</1> During your turn, whenever this character banishes another character in a challenge, gain 2 lore. <2/><1>THE GOOD OF OTHERS:</1> When this character is banished in a challenge, you may draw a card.",
  S3_191_ABILITY:
    "TAXES SHOULD HURT: Whenever you discard a card, you may deal 1 damage to chosen opposing character.",
  S3_192_ABILITY:
    "SUBMIT OR FIGHT: When you play this character and whenever this character banishes another character in a challenge during your turn, choose one: · You may draw 2 cards, then choose and discard 2 cards. · You may deal 2 damage to chosen character.",
  S3_193_ABILITY:
    "TRIUMPHANT STANCE: During your turn, whenever this character banishes another character in a challenge, chosen opposing character can't challenge during their next turn.",
  S3_194_ABILITY:
    "MY TEETH ARE SHARPER: Whenever this character quests while at a location, you may deal 1 damage to chosen character.",
  S3_199_ABILITY:
    "<1>GET THOSE SCURVY BRATS!:</1> During your turn, whenever one of your characters banishes another character in a challenge, you may pay 1 ink to draw a card. <2/><1>LET’S HAVE AT IT!:</1> Your characters named Captain Hook gain Challenger +1. (They get +1 strength while challenging.)",
  S3_200_ABILITY:
    "CYBERNETIC ARMOR: Banish this item - Chosen character gains Resist +2 until the start of your next turn. (Damage dealt to them is reduced by 2.)",
  S3_201_ABILITY:
    "<1>KEY TO THE PORTAL:</1> exert - You pay 1 ink less for the next location you play this turn. <2/><1>SHOW THE WAY:</1> You pay 1 ink less to move your characters to a location.",
  S3_202_ABILITY:
    "ISOLATED: Characters gain Resist +1 while here. (Damage dealt to them is reduced by 1.)",
  S3_204_ABILITY:
    "SHOW ME THE WAY: Whenever a character quests while here, you may draw a card, then choose and discard a card.",

  // BODY set 3
  S3_024_BODY: "Whenever one of your characters quests this turn, gain 1 lore.",
  S3_025_BODY:
    "Chosen character gains Support this turn. (Whenever they quest, you may add their strength to another chosen character's strength this turn.)",
  S3_026_BODY:
    "(A character with cost 3 or more can exert to sing this song for free.) Remove up to 3 damage from chosen character. Draw a card.",
  S3_027_BODY: "Remove up to 3 damage from chosen location.",
  S3_028_BODY:
    "(A character with cost 2 or more can exert to sing this song for free.) Chosen opponent reveals their hand and discards a non-character card of your choice.",
  S3_060_BODY:
    "Move 1 damage counter from chosen character to chosen opposing character.",
  S3_061_BODY:
    "(A character with cost 1 or more can exert to sing this song for free.) Draw a card. Shuffle up to 3 cards from your opponent’s discard into your opponent’s deck.",
  S3_062_BODY:
    "Exert chosen opposing character. Then chosen character of yours gains Challenger +2 this turn. (They get +2 strength while challenging.)",
  S3_063_BODY:
    "(A character with cost 3 or more can exert to sing this song for free.) Look at the top 5 cards of your deck. Put any number of them on the top or the bottom of your deck in any order. Gain 1 lore.",
  S3_094_BODY:
    "(A character with cost 2 or more can exert to sing this song for free.) Banish chosen item.",
  S3_095_BODY:
    "(A character with cost 1 or more can exert to sing this song for free.) Chosen character of yours gets +2 strength this turn. They may move to a location for free.",
  S3_096_BODY:
    "(A character with cost 2 or more can exert to sing this song for free.) Draw 2 cards, then choose and discard a card.",
  S3_128_BODY:
    "Banish one of your characters with Reckless to banish chosen character with less strength than that character.",
  S3_129_BODY:
    "(A character with cost 2 or more can exert to sing this song for free.) Ready chosen character of yours at a location. They can't quest for the rest of this turn. Gain lore equal to that location lore.",
  S3_130_BODY:
    "Ready all your characters and deal 1 damage to each of them. They can't quest for the rest of this turn.",
  S3_131_BODY:
    "Move up to 2 characters of yours to the same location for free.",
  S3_159_BODY: "Chosen character gets -2 strength this turn. Draw a card.",
  S3_160_BODY:
    "(A character with cost 5 or more can exert to sing this song for free.) Each player puts the top 3 cards of their deck into their inkwell facedown and exerted.",
  S3_161_BODY:
    "(A character with cost 4 or more can exert to sing this song for free.) Look at the top 2 cards of your deck. Put one into your hand and the other into your inkwell facedown and exerted.",
  S3_162_BODY:
    "Remove up to 3 damage from one of your locations or characters.",
  S3_195_BODY:
    "(A character with cost 4 or more can exert to sing this song for free.) Deal 5 damage to chosen character or location.",
  S3_196_BODY: "Deal 2 damage to chosen character or location.",
  S3_197_BODY:
    "Your characters get +3 strength this turn while challenging a location.",
  S3_198_BODY: "Banish chosen location or item.",

  // FLAVOR TEXT set 3
  S3_002_FLAVOR: "“You stay there. I’ll look for scattered lore.“",
  S3_003_FLAVOR: "The darkness calls all its subjects.",
  S3_004_A_FLAVOR: "First they steal your heart. Then they steal your chair.",
  S3_005_FLAVOR:
    "“Heading out to the Inklands? Come on back if you need patching up.”",
  S3_006_FLAVOR: "Welcome to the Inklands. (Atlantean language)",
  S3_007_FLAVOR: "“She has been chosen.” -King Kashekim Nedakh",
  S3_009_FLAVOR: "Her musical talents are off the charts!",
  S3_010_FLAVOR:
    "“Our society has never failed to answer a call for help.” -Mr. Chairman",
  S3_011_FLAVOR:
    "“This is simple, Snoops! I want that lore! The one that shines like a diamond!” -Madame Medusa",
  S3_012_FLAVOR: "“Let me handle this, Lilo”",
  S3_013_FLAVOR:
    "Damn, look at the speed of these machines! What do you call that again? Ink Runners? I wish I could fly like that.",
  S3_014_FLAVOR: "Fast like lightning, and ready to take on any bad guy.",
  S3_015_FLAVOR: "Her pups will follow her anywhere.",
  S3_016_FLAVOR: "“Ahoy! There's lore out there, and I'm g-gonna find it!”",
  S3_018_FLAVOR: "He just can't wait for new friends to arrive!",
  S3_019_FLAVOR: "“We'll find our way. I know we will!”",
  S3_020_FLAVOR: "The more of the Inklands she claims, the more she wants.",
  S3_021_FLAVOR: "“I'm hungry, Mother. I really am.”",
  S3_022_FLAVOR: "Plenty of berries - and friendship - to go around.",
  S3_023_FLAVOR:
    "With Tink’s upgrades in place, Wendy took them to the skies to find the missing mermaid.",
  S3_024_FLAVOR:
    "“Two, four, six, and three is nine, plus two is 11 . . .” -Roger",
  S3_025_FLAVOR:
    "“Snoops! I know you can look harder! Find me that lore!” -Madame Medusa",
  S3_026_FLAVOR: "Let your power shine Make the clock reverse . . .",
  S3_027_FLAVOR: "“Good as new! Well, almost.”",
  S3_028_FLAVOR: "Forget about your worries and your strife. . . .",
  S3_029_FLAVOR: "Rainwater lands as stone melts and dragons fly again.",
  S3_030_FLAVOR:
    "“It’s what’s keeping you-all of Atlantis-alive!“ -Milo Thatch",
  S3_031_FLAVOR: "The right tool makes all the difference.",
  S3_032_FLAVOR:
    "The mermaids told Peter they'd seen some items floating by several days earlier, but they were more concerned that one of their sisters had gone missing.",
  S3_034_FLAVOR: "In New Orleans, dreams can come true.",
  S3_035_FLAVOR:
    "With the right tea leaves and a little magic, she creates the perfect cup for any party guest.",
  S3_036_FLAVOR: "Let chaos reign.",
  S3_037_FLAVOR: "With a little ink-enhanced magic, nothing escapes his sight.",
  S3_038_FLAVOR:
    "“Right here, direct from the lamp, right here for your very much wish fulfillment.”",
  S3_039_FLAVOR: "“Freeze Zeus!”",
  S3_040_FLAVOR: "“Your majesty certainly has a way with dumb animals.” -Jafar",
  S3_041_FLAVOR: "“I will rule as far as the eye can see.”",
  S3_043_FLAVOR: "Born from the shadows. Saved by friendship.",
  S3_044_FLAVOR: "Super-smooth tidiness.",
  S3_045_FLAVOR: "Super-speedy tidiness.",
  S3_046_FLAVOR: "Super-sized tidiness.",
  S3_047_FLAVOR:
    "“Carpet and I can swoop in, grab the lore, and be back before anyone knows it!” -Aladdin",
  S3_048_FLAVOR:
    "“Feed my power, Dark Eclipse. Free my form from the abyss. Dormant magic now unchain, the Shadow Queen be whole again!”",
  S3_049_FLAVOR:
    "“I can feel my powers growing the closer I get to Scrooge's precious dime!”",
  S3_050_FLAVOR: "“Do you know what I call this? A good start!”",
  S3_052_FLAVOR: "“That's the way to give it what it needs, Juju!”",
  S3_053_FLAVOR: "Always ready to lend a hand . . . er, snout.",
  S3_054_FLAVOR: "Mind the stick!",
  S3_055_FLAVOR: "“Blow Zeus away!”",
  S3_056_FLAVOR:
    "It rages through the forest, Wings spread wide with flame Spring sings its ancient chorus And green renews its claim",
  S3_057_FLAVOR:
    "“A cobweb's strand to hold her fast An eagle's eye to see her well Sands of time to steal her past Thus I cast my magic spell!”",
  S3_058_FLAVOR: "“Only one may enter here.”",
  S3_059_FLAVOR: "The secret contract has already been written.",
  S3_060_FLAVOR:
    "“From magic ink I call this gift Fly my minion, thy wings be swift!” -Maleficent",
  S3_061_FLAVOR: "I am everything I’ve learned and more",
  S3_062_FLAVOR: "“I got your back”",
  S3_063_FLAVOR: "Go ahead! Make your choice!",
  S3_065_FLAVOR:
    "Minnie approached it cautiously. Whoever had placed it here might have prepared traps.",
  S3_066_FLAVOR:
    "An eerie quiet surrounds the castle-but beware of the dangerous occupant within.",
  S3_067_FLAVOR:
    "Those who visit the mirror can choose their question-but not the answer.",
  S3_068_FLAVOR: "Everything you need to make some magic.",
  S3_069_FLAVOR:
    "With Peter holding off Hook, Cubby did what he does best: break stuff.",
  S3_070_FLAVOR:
    "“Now it’s happened once or twice, someone couldn’t pay the price . . .” -Ursula",
  S3_071_FLAVOR:
    "“You never thought you’d find me here, right? To be honest, neither did I.”",
  S3_072_FLAVOR: "“Ursula needs lore to complete her plan. . . .”",
  S3_073_FLAVOR: "“Get outta my church! Out! Out! Out!”",
  S3_075_FLAVOR: "“You said we were in this together!”",
  S3_076_FLAVOR: "“There must be powerful lore here, very powerful.”",
  S3_077_FLAVOR: "“It's been fun, guys, but I got to be going. Yahoooo!”",
  S3_079_FLAVOR: "“Believe me, studying gibberish really can take you places!“",
  S3_080_FLAVOR: "“You don’t know what you’re tampering with.“",
  S3_081_FLAVOR: "“You jiggle-headed blob of mischief!” -John Silver",
  S3_082_FLAVOR:
    "The Illumineers needed someone to find a missing spellbook, and Peter was the first to volunteer.",
  S3_083_FLAVOR:
    "“Incredible as he is inept, whenever the history books are kept…” -Sheriff of Nottingham",
  S3_084_FLAVOR: "“Some days, it’s nice to let the river do the running.”",
  S3_085_FLAVOR: "“We don't you stay for dinner?”",
  S3_086_FLAVOR: "Prince John isn't the only one collecting “taxes”!",
  S3_087_FLAVOR: "Even the bravest outlaw knows when to lie low.",
  S3_088_FLAVOR: "Point him in the right direction, and he'll do the rest.",
  S3_089_FLAVOR: "For such a flashy dresser, he sure is hard to spot.",
  S3_091_FLAVOR: "“I don't want much-just the most powerful lore!”",
  S3_092_FLAVOR: "“I can take anything apart in under five minutes!”",
  S3_093_FLAVOR:
    "“I’d be lying if I said I was in charge here, but the Pride Lands still welcomes you!“",
  S3_094_FLAVOR:
    "He’s not real smart, and yet, he’s touched my little cowhide heart.",
  S3_095_FLAVOR: "I would go most anywhere To feel like I belong",
  S3_096_FLAVOR: "Please bring honor to us Please bring honor to us all",
  S3_097_FLAVOR: "Discovered in the lost Sea Duck, it looked good as new.",
  S3_098_FLAVOR: "“The forest always provides just what you need.” -Robin Hood",
  S3_099_FLAVOR: "In the wrong hands, this vial of magic could be disastrous.",
  S3_100_FLAVOR:
    "“They say the ol' place is haunted, or bewitched, or some such fiddle-faddle.” -Colonel",
  S3_101_FLAVOR:
    "A nation protected by fierce assassins and their even fiercer cats.",
  S3_102_FLAVOR:
    "“Sure it's a little small, but also it DOESN'T HAVE A POOL!” -Kuzco",
  S3_103_FLAVOR:
    "Shipwrecks are great places to find lore - and hide from sharks!",
  S3_104_FLAVOR:
    "“He's after me chest, that fiendish cyborg and his band of cutthroats.”",
  S3_106_FLAVOR:
    "“I survived the moon. How tough could the flooded Inklands be?”",
  S3_107_FLAVOR: "Considering the coconut.",
  S3_108_FLAVOR: "More heads are better than one.",
  S3_109_FLAVOR: "“You’re gonna rattle the stars, you are.” -John Silver",
  S3_110_FLAVOR:
    "If you want a bigger reward, you’ve got to take bigger risks.",
  S3_111_FLAVOR: "There's a bunch more where he came from.",
  S3_112_FLAVOR:
    "“Finding lore will be as easy as taking candy from children.”",
  S3_113_FLAVOR: "“Do you still have any chicken around?”",
  S3_114_FLAVOR: "Always ready to make a splash.",
  S3_115_FLAVOR: "“My grandpa never told me about this place!“",
  S3_117_FLAVOR: "No fire or storm will keep her from her goal.",
  S3_118_FLAVOR: "“Nutsy, button your beak.” -Trigger",
  S3_119_FLAVOR: "With Peter, plans often meant diving headlong into danger.",
  S3_121_FLAVOR:
    "The storm came out of nowhere, forcing Eric to turn back before he reached the mysterious structure at the edge of Lorcana.",
  S3_122_FLAVOR: "Always keep him in front of you. And downwind, if possible.",
  S3_123_FLAVOR: "He'll join the hunt for lore after he's had a quick snack.",
  S3_124_FLAVOR: "“Pirates sure are easy to trick!”",
  S3_125_FLAVOR: "“Who thought giving him power armor was a good idea?!” -Nani",
  S3_126_FLAVOR:
    "“Criminently, Trigger! Point that peashooter the other way.” -Sheriff of Nottingham",
  S3_127_FLAVOR:
    "“Finding lore is a lot easier when you have a grappling hook!”",
  S3_129_FLAVOR:
    "Tor would like to quit and be a florist Gunther does interior design",
  S3_130_FLAVOR: "“Catch them! Before they get away!”",
  S3_131_FLAVOR: "“We were voyagers! Why’d we stop?” -Moana",
  S3_133_FLAVOR:
    "“Summoned spirit from the dark Show thyself before this arc.” -Lena Sabrewing",
  S3_134_FLAVOR:
    "“Welcome to Agrabah, city of mystery, of enchantment, and the finest merchandise this side of the river. -Merchant”",
  S3_137_FLAVOR: "“How’d you break this thing, anyway?”",
  S3_138_FLAVOR:
    "“I've spotted a strange structure on the horizon. Hard to port, helmsman!”",
  S3_139_FLAVOR: "“I got it! I knew I could do it! Dewey for the win!”",
  S3_140_FLAVOR: "“Nobody tells Flintheart Glomgold what to do!”",
  S3_141_FLAVOR: "Ten-thousand years will give ya such a crick in the neck!",
  S3_142_FLAVOR:
    "Listen with your whole heart, and the ancestors will guide you.",
  S3_143_FLAVOR:
    "She will always be with you, no matter how far your journey takes you.",
  S3_144_FLAVOR: "“Maybe the bulb tech will be less evil this time...”",
  S3_145_FLAVOR: "“This looks like part of a larger crystal!”",
  S3_146_FLAVOR: "“Hold up, cuz. Why not sit back and enjoy some sweet beats?”",
  S3_148_FLAVOR:
    "Thrilled to have his airfoil back, he launched into the Inklands.",
  S3_149_FLAVOR: "“Found the last one!”",
  S3_150_FLAVOR: "She makes every space more beautiful.",
  S3_151_FLAVOR: "“You dig a little deeper, you'll find everything you need.”",
  S3_152_FLAVOR:
    "Pluto's job was to keep Gustav's attention so Minnie could get to the cave.",
  S3_153_FLAVOR: "Faith and a little blue bird, which you see in the distance.",
  S3_154_FLAVOR: "He can make money anywhere.",
  S3_155_FLAVOR:
    "A strange new world is the perfect place to make a strange new fortune!",
  S3_156_FLAVOR: "She has only one question, and she'd better like the answer.",
  S3_157_FLAVOR: "She lives in a world of possibilities.",
  S3_162_FLAVOR: "“I'm thinkin' about opening a shop here. What do you think?”",
  S3_163_FLAVOR:
    "“It can point toward lost lore, but if you're not careful, it'll lead you off a cliff.” -Venturo, an Illumineer",
  S3_164_FLAVOR: "It takes a pure heart to calm the raging storm within.",
  S3_165_FLAVOR: "This one simple coin changed Scrooge’s life forever.",
  S3_166_FLAVOR: "Just the thing to top off another brilliant deal.",
  S3_167_FLAVOR:
    "“Only Scrooge knows about this vault. And he’s going to keep it that way.”",
  S3_168_FLAVOR:
    "Some of the most important tools in Lorcana are crafted here.",
  S3_169_FLAVOR: "“It's only the coolest home in Lorcana!” -Webby Vanderquack",
  S3_171_FLAVOR: "“Look at our home! There is no better place to be.”",
  S3_172_FLAVOR: "“Not much of a roadblock, but I suppose I'll do.”",
  S3_174_FLAVOR:
    "“Brothers! Titans! Look at you, in your miserable prison. Who put you down there?”",
  S3_175_FLAVOR: "“That was an order, not a suggestion. Let’s go!“",
  S3_176_FLAVOR: "“I was never much good at games. Always hated to lose.”",
  S3_177_FLAVOR: "She’s seen strange things before, but nothing like this.",
  S3_180_FLAVOR: "“Crush Zeus!”",
  S3_181_FLAVOR:
    "Mickey knew just where to start searching for the Sorcerer's Hat, but he didn't expect it to be guarded.",
  S3_183_FLAVOR: "She'll never cave under pressure.",
  S3_184_FLAVOR: "“Catch those little-OUCH!”",
  S3_185_FLAVOR: "Even his name makes enemies quiver in fear.",
  S3_186_FLAVOR: "“Pinned ya again.”",
  S3_187_FLAVOR: "“Melt Zeus!”",
  S3_188_FLAVOR:
    "“There's no princess here to save you this time, street rat.”",
  S3_189_FLAVOR:
    "“You call this an ambush? Tsk, tsk. It's barely a bushwhack.”",
  S3_191_FLAVOR:
    "The thirteenth Rule of Villainy: Take everything you can, but do it with a smile.",
  S3_192_FLAVOR: "He is ready to restore his past glory to the Pride Lands.",
  S3_193_FLAVOR:
    "With dangers multiplying all around, the pride needs its guardian.",
  S3_194_FLAVOR: "“Soon the bells will ring in the sound of your doom!“",
  S3_195_FLAVOR:
    "He hurled his thunderbolt-He zapped Locked those suckers in a vault-They're trapped And on his own stopped chaos in its tracks",
  S3_196_FLAVOR: "Bigger than your average boom!",
  S3_197_FLAVOR:
    "“Now that I set you free, what is the first thing you are going to do?” -Hades",
  S3_198_FLAVOR: "“Oh, we're in trouble, big trouble!” -Hermes",
  S3_200_FLAVOR:
    "It stands in the Hall of Lorcana, waiting for someone to speak the secret words.",
  S3_201_FLAVOR:
    "“Gentlemen, this must be kept under lock and key.” -Captain Amelia",
  S3_202_FLAVOR: "Nothing but boulders and sand-easy to miss.",
  S3_203_FLAVOR:
    "Sir Hiss: “I say, ssssire, your mother’s castle would be the perfect place to bring our plan to life!” Prince John: “Mummy!”",
  S3_204_FLAVOR: "A place to find what you need, not just what you want.",

  // KEYWORDS TEXT CHARACTERS: SHIFT_X set 3
  S3_007_SHIFT_TEXT:
    "Shift 3 (You may pay 3 ink to play this on top of one of your characters named Kida.)",
  S3_017_SHIFT_TEXT:
    "Shift 5 (You may pay 5 ink to play this on top of one of your characters named Pluto.)",
  S3_042_SHIFT_TEXT:
    "Shift 5 (You may pay 5 ink to play this on top of one of your characters named Jafar.)",
  S3_049_SHIFT_TEXT:
    "Shift 5 (You may pay 5 ink to play this on top of one of your characters named Magica De Spell.)",
  S3_074_SHIFT_TEXT:
    "Shift 3 (You may pay 3 ink to play this on top of one of your characters named Helga Sinclair.)",
  S3_080_SHIFT_TEXT:
    "Shift 4 (You may pay 4 ink to play this on top of one of your characters named Milo Thatch.)",
  S3_116_SHIFT_TEXT:
    "Shift 3 (You may pay 3 ink to play this on top of one of your characters named Moana.)",
  S3_120_SHIFT_TEXT:
    "Shift 4 (You may pay 4 ink to play this on top of one of your characters named Peter Pan.)",
  S3_143_SHIFT_TEXT:
    "Shift 5 (You may pay 5 ink to play this on top of one of your characters named Gramma Tala.)",
  S3_147_SHIFT_TEXT:
    "Shift 3 (You may pay 3 ink to play this on top of one of your characters named Kit Cloudkicker.)",
  S3_178_SHIFT_TEXT:
    "Shift 4 (You may pay 4 ink to play this on top of one of your characters named Little John.)",
  S3_190_SHIFT_TEXT:
    "Shift 3 (You may pay 3 ink to play this on top of one of your characters named Robin Hood.)",

  // SET 4

  // ITEM CARD set 4
  IMPERIAL_PROCLAMATION: "Imperial Proclamation",
  MYSTICAL_ROSE: "Mystical Rose",
  RECORD_PLAYER: "Record Player",
  SIGNED_CONTRACT: "Signed Contract",
  TRITONS_TRIDENT: "Triton's Trident",
  VISION_SLAB: "Vision Slab",

  // ACTION CARD set 4
  BRAWL: "Brawl",
  BRUNOS_RETURN: "Bruno's Return",
  SIGN_THE_SCROLL: "Sign The Scroll",

  // ACTION SONG CARD set 4
  A_PIRATES_LIFE: "A Pirate's Life",
  BE_KING_UNDISPUTED: "Be King Undisputed",
  DIG_A_LITTLE_DEEPER: "Dig A Little Deeper",
  LOOK_AT_THIS_FAMILY: "Look At This Family",
  LOST_IN_THE_WOODS: "Lost in the Woods",
  SECOND_STAR_TO_THE_RIGHT: "Second Star To The Right",
  SELDOM_ALL_THEY_SEEM: "Seldom All They Seem",
  THE_MOB_SONG: "The Mob Song",
  TREASURES_UNTOLD: "Treasures Untold",
  UNDER_THE_SEA: "Under The Sea",
  WE_DONT_TALK_ABOUT_BRUNO: "We Don't Talk About Bruno",

  // LOCATION CARD set 4
  CASA_MADRIGAL: "Casa Madrigal",
  SNUGGLY_DUCKLING: "Snuggly Duckling",
  THE_WALL: "The Wall",
  URSULAS_GARDEN: "Ursula's Garden",
  URSULAS_LAIR: "Ursula's Lair",

  // VERSION NAME set 4 (desordenados para subirlos mientras las revelan)
  S4_006_VERSION_NAME: "Lovely Lady",
  S4_009_VERSION_NAME: "Fun-Loving Family Man",
  S4_013_VERSION_NAME: "Excellent Cook",
  S4_018_VERSION_NAME: "Gift of the Family",
  S4_038_VERSION_NAME: "Out of the Shadows",
  S4_041_VERSION_NAME: "Easy Listener",
  S4_047_VERSION_NAME: "Magically Strong One",
  S4_058_VERSION_NAME: "Sea Witch Queen",
  S4_107_VERSION_NAME: "Super Goof",
  S4_137_VERSION_NAME: "Braving the Storm",
  S4_146_VERSION_NAME: "Noble Scoundrel",
  S4_153_VERSION_NAME: "Appreciative Artist",
  S4_175_VERSION_NAME: "Sonic Warrior",
  S4_223_VERSION_NAME: "Powerful Sorcerer",
  S4_224_VERSION_NAME: "Elite Archer",
  S4_225_VERSION_NAME: "Playful Sorcerer",
  S4_084_VERSION_NAME: "Gift for Hercules",
  S4_184_VERSION_NAME: "Rock of the Family",
  S4_115_VERSION_NAME: "Enemy of Entanglement",
  S4_105_VERSION_NAME: "Mulan's Father",
  S4_144_VERSION_NAME: "Collector's Companion",
  S4_102_VERSION_NAME: "Full of the Unfortunate",
  S4_185_VERSION_NAME: "Aerial Cleaner",
  S4_108_VERSION_NAME: "Clumsy Kid",
  S4_090_VERSION_NAME: "Proclaimers of Heroes",
  S4_135_VERSION_NAME: "Disreputable Pub",
  S4_072_VERSION_NAME: "Interior Designer",
  S4_091_VERSION_NAME: "Florist",
  S4_024_VERSION_NAME: "Eric's Bride",
  S4_044_VERSION_NAME: "Entangling Eels",
  S4_070_VERSION_NAME: "Devoted Herald",
  S4_112_VERSION_NAME: "Valorous General",
  S4_149_VERSION_NAME: "Carrot Enthusiast",
  S4_171_VERSION_NAME: "Brave Rescuer",
  S4_003_VERSION_NAME: "Singing Mermaid",
  S4_124_VERSION_NAME: "Emboldened Warrior",
  S4_192_VERSION_NAME: "Royal Protector",
  S4_077_VERSION_NAME: "Connoisseur of Climbing",
  S4_110_VERSION_NAME: "Beloved Steed",
  S4_056_VERSION_NAME: "Ever-Present Pursuer",
  S4_069_VERSION_NAME: "Lucky Cricket",
  S4_068_VERSION_NAME: "Eye of the Storm",
  S4_042_VERSION_NAME: "Storm Chaser",
  S4_177_VERSION_NAME: "Imperial Advisor",
  S4_178_VERSION_NAME: "Imperial Soldier",
  S4_183_VERSION_NAME: "Imperial Soldier",
  S4_203_VERSION_NAME: "Border Fortress",
  S4_053_VERSION_NAME: "Weather Maker",
  S4_040_VERSION_NAME: "Prankster",
  S4_045_VERSION_NAME: "Golden Child",
  S4_067_VERSION_NAME: "Casita",
  S4_104_VERSION_NAME: "Brave Mediator",

  // ABILITIES set 4 “”     //  para doble habilidad: "<1>¡AGARRA A ESOS MOCOSOS PIRATAS!:</1> Durante tu turno... <2/><1>¡VAMOS A POR ELLOS!:</1> Tus personajes llamados...)",
  S4_013_ABILITY: "SIGNATURE RECIPE: When you play this character, you may remove up to 2 damage from chosen character. If you removed damage this way, you may draw a card.",
  S4_018_ABILITY: "SAVING THE MIRACLE: Whenever this character quests, your other Madrigal characters get +1 lore this turn.",
  S4_038_ABILITY: "IT WAS YOUR VISION: When you play this character, chosen character gains “When this character is banished in a challenge, you may return this card to your hand” this turn.",
  S4_041_ABILITY: "MAGICAL INFORMANT: When you play this character, if an opponent has an exerted character in play, you may draw a card.",
  S4_058_ABILITY: "<1>NOW I AM THE RULER!:<1> Whenever this character quests, exert chosen character. <2/><1>YOU'LL LISTEN TO ME!:</1> Other characters can't exert to sing songs.",
  S4_066_ABILITY: "SYMBOL OF POWER: Banish this item — Chosen character gets +1 strength this turn for each card in your hand.",
  S4_107_ABILITY: "SUPER PEANUT POWERS: Whenever this character challenges another character, gain 2 lore.",
  S4_137_ABILITY: "I WAS BORN READY: If you have another Hero character in play, this character gets +1 lore.",
  S4_146_ABILITY: "ROYAL SCHEMES: When you play this characer, if a Princess or Queen character is in play, gain 1 lore.",
  S4_153_ABILITY: "PERCEPTIVE PARTNER: While you have a character named Pascal in play, this character gains Ward. (Opponents can't chose them except to challenge.)",
  S4_175_ABILITY: "AMPLIFIED VOICE: Whenever you play a song, you may pay ink to deal 3 daamge to chosen character.",
  S4_223_ABILITY: "<1>TIMELY INTERVENTION:</1> When you play this character, if you have a character named Magic Broom in play, you may draw a card. <2/><1>ARCANE STUDY:</1> While you have 2 or more Broom characters in play, this character gets +2 lore.",
  S4_224_ABILITY: "<1>STRAIGHT SHOOTER:</1> When you play this character, if you used Shift to play her, she gets +3 strength this turn.<2/><1> TRIPPLE SHOT:</1> During your turn, whenever this character deals damage to another character in a challenge, deal the same amount of damage to up to 2 other chosen characters.",
  S4_225_ABILITY: "SWEEP AWAY: When you play this character, deal damage to chosen character equal to the number of Broom characters you have in play.",
  S4_032_ABILITY: "<1>LOOK AT THIS!:</1> Whenever you play a song, chosen character gets -2 strength until the start of your next turn. <2/><1>HIT PARADE:</1> Your characters named Stitch count as having +1 cost to sing songs.",
  S4_184_ABILITY: "I'M THE STRONG ONE: While you have another character in play, this character gets +2 strength.",
  S4_115_ABILITY: "TIME TO SHINE: Whenever you play an action, this character gets +2 strength this turn.",
  S4_105_ABILITY: "<1>WAR WOUND:</1> This character cannot challenge. <2/><1>HEAD OF FAMILY:</1> Exert - Ready chosen character named Mulan. They can't quest for the rest of the turn.",
  S4_144_ABILITY: "I'M NOT A GUPPY: If you have a character named Ariel in play, you pay 1 ink less to play this character.",
  S4_099_ABILITY: "FINE PRINT: Whenever an opponent plays a song, you may draw a card.",
  S4_102_ABILITY: "ABANDON HOPE: While you have an exerted character here, opposing characters get -1 lore.",
  S4_064_ABILITY: "DISPEL THE ENTANGLEMENT: Banish this item - Chosen character named Beast gets +2 lore this turn. If you have a character named Belle in play, move up to 3 damage counters from chosen character to chosen opposing character.",
  S4_185_ABILITY: "WINGED FOR A DAY: During your turn, this character gains Evasive. (They can challenge characters with Evasive.)",
  S4_090_ABILITY: "THE GOSPEL TRUTH: Whenever you play a song, you may return chosen character with 2 strength or less to their player's hand.",
  S4_135_ABILITY: "ROUTINE RUCKUS: Whenever a character with 3 strength or more challenges another character while here, gain 1 lore. If the challenging character has 6 strength or more, gain 3 lore instead.",
  S4_072_ABILITY: "SAD-EYED PUPPY: When this character is challenged and banished, each opponent chooses one of their characters and returns that card to their hand.",
  S4_024_ABILITY: "VANESSA'S DESIGN: Whenever this character quests, chosen opponent reveals their hand and discards a non-character card of your choice.",
  S4_070_ABILITY: "CIRCLE FAR AND WIDE: During each opponent's turn, whenever they draw a card while this character is exerted, you may draw a card.",
  S4_112_ABILITY: "LEAD THE CHARGE: Your characters with 4 strength or more get +1 lore.",
  S4_149_ABILITY: "CARROTS ALL AROUND!: Whenever he quests, each of your other characters gets +strength equal to this character's strength this turn.",
  S4_171_ABILITY: "CRASHING THROUGH: Whenever this character quests, you may banish chosen item.",
  S4_124_ABILITY: "SURGE OF POWER : This character gets +1 strength for each card in opponent's hands.",
  S4_192_ABILITY: "STEADY GAZE: While you have no cards in your hand, characters with cost 4 or less can't challenge this character.",
  S4_077_ABILITY: "SNEAKY IDEA: When you play this character, chosen opposing character gains Reckless during their next turn. (They can't quest and must challenge if able.)",
  S4_069_ABILITY: "SPREADING GOOD FORTUNE: When you play this character, your other characters get +3 strength this turn.",
  S4_068_ABILITY: "<1>SLIPPERY HALLS:</1> Whenever a characters is banished in a challenge while here, you may return them to your hand. <2/><1>SEAT OF POWER:</1> Characters named Ursula get +1 lore while here.",
  S4_042_ABILITY: "TEMPEST: Exert- Chosen character gains Challenger +2 and Rush this turn. (They get +2 strength while challenging. They can challenge the turn they're played.)",
  S4_131_ABILITY: "CALL TO THE FRONT: Whenever one of your characters challenges another character, you pay 1 ink less for the next character you play this turn.",
  S4_177_ABILITY: "OVERLY CAUTIOUS: While this character has no damage, he gets +2 lore.",
  S4_183_ABILITY: "FULL OF SPIRIT: Your Hero characters get +1 strength.",
  S4_203_ABILITY: "PROTECT THE REALM: While you have an exerted character here, your other locations can't be challenged.",
  S4_100_ABILITY: "<1>DANGER REVEALED:</1> At the start of your turn, if an opposing character has damage, gain 1 lore. TRAPPED!:</1> Damage counters can't be removed.",
  S4_053_ABILITY: "IT LOOKS LIKE RAIN: When you play this character, you may exert chosen opposing character. That character can't ready at the start of their next turn unless you're at a location.",
  S4_040_ABILITY: "<1>MANY FORMS: At the start of your turn, you may chose one: • This character gets +1 lore this turn. • This character gain Challenger +2 this turn. (While challenging, this character gets +2 strength.)",
  S4_045_ABILITY: "<1>LADIES FIRST:</1> During your turn, if no other character has quested this turn, this character gets +3 lore. <2/><1>LEAVE IT TO ME:</1> Whenever this character quests, your other characters can't quest for the rest of this turn.",
  S4_067_ABILITY: "OUR HOME: At the start of your turn, if you have a character here, gain 1 lore.",

  // BODY set 4
  S4_097_BODY: "(A character with cost 5 or more can exert to sing this song for free.) Return chosen character to their player's hand, then that player discards a card at random.",
  S4_030_BODY: "Each opponent may chose and discard a chard. For each opponent who doesn't, you gain 2 lore.",
  S4_130_BODY: "Banish chosen character with 2 strength or less.",
  S4_129_BODY: "(A character with cost 4 or more can exert to sing this song for free.) Each opponent chooses and banishes one of their characters.",
  S4_029_BODY: "(A character with cost 4 or more can exert to sing this song for free.) All opposing characters get -2 strength until the start of your next turn.",
  S4_164_BODY: "(A character with cost 2 or more can exert to sing this song for free.) Chosen character gets -3 strength this turn.",
  S4_165_BODY: "(A character with cost 6 or more can exert to sing this song for free.) Return up to 2 item cards from your discard into your hand.",
  S4_044_BODY: "(This character counts as being named both Flotsam and Jetsam)",
  S4_028_BODY: "Look at the top 5 cards of your deck. You may reveal up to 2 character cards and put them into your hand. Put the rest on the bottom of your deck in any order.",
  S4_061_BODY: "Chosen player draws 5 cards.",
  S4_095_BODY: "Put all opposing characters with 2 strength or less on the bottom of their players' decks in any order.",
  S4_128_BODY: "Each opponent loses 2 lore. You gain 2 lore.",
  S4_162_BODY: "Look at the top 7 cards of your deck. Put 2 into your hand. Put the rest on teh bottom of your deck in any order.",
  S4_198_BODY: "Deal 3 damage to up to 3 chosen characters and/or locations.",
  S4_026_BODY: "Return a character card from your discard to your hand. Then remove up to 2 damage from chosen character.",

  // FLAVOR TEXT set 4
  S4_006_FLAVOR: "Sweet Dasiy, the fairest duck I ever met Each flaxen lock a rush of flowing gold Her bill the color of summer sunset Exquisite plumes, a wonder to behold",
  S4_009_FLAVOR: "Who needs a gift when you're having this much fun?",
  S4_013_FLAVOR: "“Eat this, mi amor.”",
  S4_047_FLAVOR: "“This rock? No problem. Go get that prophecy, hermana!”",
  S4_066_FLAVOR: "“Just imagine all this power in the wrong hands...” — Ursula",
  S4_107_FLAVOR: "Never underestimate the power of a Goof.",
  S4_137_FLAVOR: "After talking to Olaf, Anna marched into the unexpected storm to save Kristoff.",
  S4_146_FLAVOR: "Hans was confident he could bring Anna to Ursula — all he needed was something of Kristoff's to lure her in.",
  S4_153_FLAVOR: "“Pascal! A new flower for the wall!”",
  S4_084_FLAVOR: "“His name is Pegasus. And he's all yours....” — Zeus",
  S4_097_FLAVOR: "Your fate is sealed when your prophecy is read!",
  S4_184_FLAVOR: "There's no way Ursula's creatures are getting to that donkey.",
  S4_115_FLAVOR: "Ursula's messengers fled, leaving behind tendrils of dark ink.",
  S4_105_FLAVOR: "“I am ready to serve the Emperor.”",
  S4_144_FLAVOR: "“Ariel, Ariel! You won't believe what I found!”",
  S4_099_FLAVOR: "“I Would love to help you, of course, but there's the little matter of the contract...” -Ursula",
  S4_064_FLAVOR: "Ink surrounded Belle's last hope to heal the Beast. With no other choice, she reached out for it . . .",
  S4_185_FLAVOR: "It spends its days keeping the treasured glimmers in the Hall of Lorcana sparkling clean.",
  S4_108_FLAVOR: "“Nice Catch, Jerkules.” -Village boy",
  S4_130_FLAVOR: "There are two ways to leave the Snuggly Duckling - the door or the window.",
  S4_072_FLAVOR: "“I hate to cover this trap door. It really pulls the room together!”",
  S4_091_FLAVOR: "They say that his arrangements are exquisite, His composites and bouquets are so divine. But when the crowds try to come and visit, There's always quite a fight to form a line.",
  S4_129_FLAVOR: "Respected, saluted",
  S4_029_FLAVOR: "I'm left behind, wondering if I should follow",
  S4_164_FLAVOR: "I know you I walked with you once upon a dream",
  S4_165_FLAVOR: "How many wonders can one cavern hold?",
  S4_003_FLAVOR: "“Watch and you'll see-some day l'Il be part of your world!”",
  S4_124_FLAVOR: "Sometimes the only way to fight the unimaginable is with the incredible.",
  S4_192_FLAVOR: "As regal as his namesake and just as powerful.",
  S4_077_FLAVOR: "Teamwork makes the cheese work.",
  S4_110_FLAVOR: "As silent as a shadow and faster than the wind: brave Khan, the mightiest stallion.",
  S4_056_FLAVOR: "“That cursed beast liked the teaste of me so well he's followed me ever since...” - Captian Hook",
  S4_069_FLAVOR: "Everyone feels better just knowing he's around.",
  S4_061_FLAVOR: "Lead us to the land we dream of",
  S4_095_FLAVOR: "Such wonderful things surround you",
  S4_128_FLAVOR: "Give me a career As a buccaneer",
  S4_042_FLAVOR: "After Elsa dispersed Ursula's storm, Anna was nowhere to be found.",
  S4_131_FLAVOR: "“By order of the Emperor, one man from every family must server in the Imperial Army” -Chi Fu",
  S4_177_FLAVOR: "“You , there! Keep that fighting away from here! It is imperative that I write to the Emperor.”",
  S4_183_FLAVOR: "A good friend is handy in a fight.",
  S4_100_FLAVOR: "“Tío Bruno! What's happening to him? We have to help!” -Mirabel",
  S4_026_FLAVOR: "“I feel like I missed something important”",
  S4_104_FLAVOR: "“We must work together to heal the entanglements.”",

  // KEYWORDS TEXT CHARACTERS: SHIFT_X set 4
  S4_058_SHIFT_TEXT: "Shift 5 (You may pay 5 ink to play this on top of one of your characters named Ursula.)",
  S4_175_SHIFT_TEXT: "Shift 4 (You may pay 4 ink to play this on top of one of your characters named Ariel.)",
  S4_224_SHIFT_TEXT: "Shift 5 (You may pay 5 ink to play this on top of one of your characters named Mulan.)",
  S4_187_SHIFT_TEXT: "Shift 3 (You may pay 3 ink to play this on top of one of your characters named Mickey Mouse)",
  S4_024_SHIFT_TEXT: "Discard a song card (You may discard a song card to play this on top of one of your characters named Ursula.)",
  S4_044_SHIFT_TEXT: "Discard 2 cards (You may discard 2 cards to play this on top of one of your characters named Flotsam or Jetsam.)",
  S4_070_SHIFT_TEXT: "Discard an action card (You may discard an action card to play this on top of one of your characters named Diablo.)",
  S4_112_SHIFT_TEXT: "Discard a character card (You may discard a character card to play this on top of one of your characters named Li Shang.)",
  S4_149_SHIFT_TEXT: "Discard an item card (You may discard an item card to play this on top of one of your characters named Olaf.)",
  S4_171_SHIFT_TEXT: "Discard a location card (You may discard a location card to play this on top of one of your characters named Aladdin.)",
};

export default TRANSLATIONS_EN;
